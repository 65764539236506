import { ApolloClient, createHttpLink, InMemoryCache } from "@apollo/client";
import { setContext } from "apollo-link-context";
import gql from "graphql-tag";
import { getCookie } from "../../utils";

interface DefaultOptions {
  watchQuery: any;
  query: any;
}
interface CategoryInput {
  domainId: string;
  name: string;
  description: string;
}

interface CategoryUpdateInput {
  domainId: string;
  name: string;
  description: string;
  categoryId: string;
}
const httpLink: any = createHttpLink({
  uri: `${process.env.REACT_APP_GRAPHQL_URL}`,
  fetch,
});

const authLink: any = setContext((_, { headers }) => {
  const token = getCookie("token");

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});
const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: "no-cache",
    errorPolicy: "ignore",
  },
  query: {
    fetchPolicy: "no-cache",
    errorPolicy: "all",
  },
};
const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
  defaultOptions: defaultOptions,
});

export const getCategories = async (domainId: string) => {
  const query = gql`
    query Category($domainId: ID!) {
      category(domainId: $domainId) {
        name
        uuid
        image
        description
        id
      }
    }
  `;

  const variables = {
    domainId,
  };

  try {
    return await client.query({
      query,
      variables,
    });
  } catch (err) {
    console.log(
      "getCategoriesGqlQuery graphql error occurred",
      JSON.stringify(err)
    );
  }
};

export const getAllCaetgory = async () => {
  const query = gql`
    query allCategory {
      allCategory {
        name
        uuid
        image
        description
        id
      }
    }
  `;
  try {
    const request = await client.query({
      query,
    });
    return request;
  } catch (err) {
    return { data: err, status: 0 };
  }
};

export const getCategoryNameById = async (categoryId: any) => {
  const query = gql`
    query categoryNameById($categoryId: ID!) {
      categoryNameById(categoryId: $categoryId) {
        id
        uuid
        name
        description
        domainId
        image
      }
    }
  `;
  const variables = {
    categoryId,
  };
  try {
    const request = await client.query({
      query,
      variables,
    });
    return request;
  } catch (err) {
    console.log("getCategoryNameById graphql error occurred", err);
  }
};
export const createCategory = async (categoryInput: CategoryInput) => {
  const createCategory = gql`
    mutation createCategory($categoryInput: CategoryInput!) {
      createCategory(categoryInput: $categoryInput) {
        id
        name
        description
        uuid
        status
      }
    }
  `;
  try {
    return await client.mutate({
      mutation: createCategory,
      variables: { categoryInput },
    });
  } catch (err) {
    console.log("createCategory graphql error occurred", err);
  }
};

export const updateCategoryData = async (
  categoryUpdateInput: CategoryUpdateInput
) => {
  const updateCategory = gql`
    mutation updateCategory($categoryUpdateInput: CategoryUpdateInput!) {
      updateCategory(categoryUpdateInput: $categoryUpdateInput) {
        id
        message
      }
    }
  `;
  try {
    return await client.mutate({
      mutation: updateCategory,
      variables: { categoryUpdateInput },
    });
  } catch (err: any) {
    console.log(
      "updateDomain graphql error occurred",
      JSON.stringify(err?.message)
    );
  }
};

export const deleteCategory = async (categoryId: string) => {
  const query = gql`
    query deleteCategory($categoryId: String!) {
      deleteCategory(categoryId: $categoryId) {
        uuid
        id
        categoryId
      }
    }
  `;
  const variables = {
    categoryId,
  };
  try {
    const result = await client.query({
      query,
      variables,
    });
    return result;
  } catch (err) {
    console.log(
      " Request DomainGqlQuery graphql error occurred",
      JSON.stringify(err)
    );
  }
};
