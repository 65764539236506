import React from "react";
import { BreadcrumbComp } from "../../../Breadcrumb/Breadcrumb";

export default function PageHeading({breadcrumb,title}:any) {
  return (
    <div className="my-2">
      <p className=" text-black mb-0 font-primary text-2xl font-medium">
        {title}
      </p>
      <BreadcrumbComp breadcrumbData={breadcrumb} />
    </div>
  );
}
