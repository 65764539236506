import { ApolloClient, createHttpLink, InMemoryCache } from "@apollo/client";
import { setContext } from "apollo-link-context";
import gql from "graphql-tag";
import { getCookie } from "../../utils";

interface DefaultOptions {
  watchQuery: any;
  query: any;
}

const httpLink: any = createHttpLink({
  uri: `${process.env.REACT_APP_GRAPHQL_URL}`,
  fetch,
});

const authLink: any = setContext((_, { headers }) => {
  const token = getCookie("token");

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});
const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: "no-cache",
    errorPolicy: "ignore",
  },
  query: {
    fetchPolicy: "no-cache",
    errorPolicy: "all",
  },
};
const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
  defaultOptions: defaultOptions,
});

export const deleteDomain = async (domainId: string) => {
  const query = gql`
    query deleteDomain($domainId: String!) {
      deleteDomain(domainId: $domainId) {
        uuid
        id
        domainId
        message
      }
    }
  `;
  const variables = {
    domainId,
  };
  try {
    const result = await client.query({
      query,
      variables,
    });
    return result;
  } catch (err) {
    console.log(" Request DomainGqlQuery graphql error occurred", err);
  }
};

export const getDomains = async (userId: string) => {
  const query = gql`
    query Domains($userId: String!) {
      domains(userId: $userId) {
        uuid
        name
        id
        domain_type
        domain_Amount
        status
        description
        image
        userId
        message
      }
    }
  `;

  try {
    const result = await client.query({
      query,
      variables: { userId },
    });
    return result;
  } catch (err) {
    console.log("getDomainGqlQuery graphql error occurred", err);
  }
};

export const getSearchData = async (
  name: string,
  userId: string,
  type: string,
  domainId: string
) => {
  const query = gql`
    query searchFeildData(
      $name: String!
      $userId: String!
      $type: String!
      $domainId: String!
    ) {
      searchFeildData(
        name: $name
        userId: $userId
        type: $type
        domainId: $domainId
      ) {
        uuid
        name
        id
        domain_type
        domain_Amount
        status
        description
        image
        userId
        message
      }
    }
  `;

  try {
    const result = await client.query({
      query,
      variables: { name, userId, type, domainId },
    });
    return result;
  } catch (err) {
    console.log("getDomainGqlQuery graphql error occurred", err);
  }
};

//get search data on

export const searchData = async (
  name: string,
  userId: string | undefined,
  start: string | number,
  end: string | number,
  type: string,
  uuid: string
) => {
  const query = gql`
    query searchData(
      $name: String!
      $userId: String!
      $start: ID!
      $end: ID!
      $type: String!
      $uuid: String!
    ) {
      searchData(
        name: $name
        userId: $userId
        start: $start
        end: $end
        type: $type
        uuid: $uuid
      ) {
        uuid
        name
        id
        domain_type
        domain_Amount
        status
        description
        image
        userId
        message
      }
    }
  `;

  try {
    const result = await client.query({
      query,
      variables: { name, userId, start, end, type, uuid },
    });

    return result;
  } catch (err) {
    console.log(
      "Search Data pagination graphql error occurred",
      JSON.stringify(err)
    );
  }
};
export const requestDomains = async (userId: string, domainId: string) => {
  const query = gql`
    query requestDomains($userId: String!, $domainId: String!) {
      requestDomains(userId: $userId, domainId: $domainId) {
        uuid
        id
        status
        userId
        domainId
        message
        name
        domain_Amount
      }
    }
  `;
  try {
    const result = await client.query({
      query,
      variables: { userId, domainId },
    });
    return result;
  } catch (err) {
    console.log("requestDomains graphql error occurred", JSON.stringify(err));
  }
};

export const getDomainNamebyuser = async (userId: string | undefined) => {
  const query = gql`
    query domainNameByuser($userId: String!) {
      domainNameByuser(userId: $userId) {
        id
        uuid
        name
        status
        domainId
        image
        description
      }
    }
  `;
  const variables = {
    userId,
  };
  try {
    const request = await client.query({
      query,
      variables,
    });
    return request;
  } catch (err) {
    console.log(
      "getDomainNameById graphql error occurred",
      JSON.stringify(err)
    );
  }
};
