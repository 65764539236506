import React from "react";
import { TimeDisplay } from "./TimeDisplay";
import { useCountdown } from "../../../../../components/customHook/useCountdown";
const ExpiredNotice = () => {
  return (
    <></>
    // <div className="expired-notice">
    //   <span>Expired!!!</span>
    //   <p>Please select a future date and time.</p>
    // </div>
  );
};

const ShowCounter = ({ days, hours, minutes, seconds, type }) => {
  return (
    <div>
      <div className="flex flex-row ">
        {/* <TimeDisplay value={days} type={"Days"} isDanger={days <= 3} />
        <p>:</p> */}
        {type == "event" && (
          <>
            <TimeDisplay value={days} isDanger={false} />
            <p className="py-0 m-0 px-0">dd:</p>
          </>
        )}
        <TimeDisplay value={hours} isDanger={false} />
        <p className="py-0 m-0 px-0">hh:</p>
        <TimeDisplay value={minutes} isDanger={false} />
        <p className="py-0 m-0 px-0">mm:</p>
        <TimeDisplay value={seconds} isDanger={false} />
      </div>
    </div>
  );
};

export const CountdownTimer = (props: any) => {
  const [days, hours, minutes, seconds] = useCountdown(props.targetDate);
  props.expireTimestatus(days + hours + minutes + seconds);
  if (days + hours + minutes + seconds <= 0) {
    return <ExpiredNotice />;
  } else {
    return (
      <ShowCounter
        type={props?.type}
        days={days}
        hours={hours}
        minutes={minutes}
        seconds={seconds}
      />
    );
  }
};
