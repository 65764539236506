import React, { useEffect, useState } from "react";
import { Select, Spin } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import {
  getDomainNameById,
} from "../../../../../serviceApi/superAdminApi/DomainApi";
import { getCategoryNameById } from "../../../../../serviceApi/superAdminApi/CategoryApi";
import {
  getAssessmentNameById,
  getSkillNameBySkillId,
} from "../../../../../serviceApi/api";

import { McqQuestions } from "./mcqQuestions";
import { MediaQuestions } from "./mediaQuestions";
import { CodingQuestions } from "./codingQuestions";
import "./question.css";
import {
  getSkills,
  getUserValidateSkill,
} from "../../../../../serviceApi/adminApi/SkillApi";
import { useAuth0 } from "@auth0/auth0-react";
import { BreadcrumbComp } from "../../../../../components/Breadcrumb/Breadcrumb";

export const CreateQuestion = () => {
  const navigate = useNavigate();
  const { domainId, categoryId, skillId, assessmentId }: any = useParams();
  const { user } = useAuth0();
  const userId: string = user?.email as string;

  const [domainName, setDomainName] = React.useState();
  const [categoryName, setCategoryName] = React.useState();
  const [skillName, setSkillName] = React.useState();
  const [name, setName] = useState("");
  const [questionType, setQuestionType] = useState("");
  const [isAuthenticate, setIsAuthenticate] = useState(false);

  const getDomainName = async () => {
    const response = await getDomainNameById(domainId);
    setDomainName(response?.data?.domainNameById[0]?.name);
  };
  const getCategoryName = async () => {
    const response = await getCategoryNameById(categoryId);
    setCategoryName(response?.data?.categoryNameById[0]?.name);
  };
  const getSkillName = async () => {
    const response = await getSkillNameBySkillId(skillId);
    setSkillName(response?.data?.skillNameById[0]?.name);
  };

  const getAssessmentName = async () => {
    const response = await getAssessmentNameById(assessmentId);
    if (response?.data?.assessmentNameById?.length > 0) {
      setName(response?.data?.assessmentNameById[0]?.name);
    } else {
      navigate("/404");
    }
  };

  const getUserValidateSkillApi = async () => {
    const isValidate = await getUserValidateSkill(userId, skillId);
    if (isValidate?.data?.skillValidateUser.length > 0) {
      setIsAuthenticate(true);
      getAssessmentName();
      getSkillName();
    } else {
      setIsAuthenticate(false);
      navigate("/404");
    }
  };

  useEffect(() => {
    getUserValidateSkillApi();
  }, []);
  const breadcrumbData = [
    {
      path: "/subadmin/AllDomains/list",
      text: "Dashboard",
    },
    { text: "skill" },
    {
      path: `/subadmin/skills/list`,
      text: skillName,
    },
    { text: "assessment" },
    {
      path: `/subadmin/domain/skill/${skillId}/assessment/list`,
      text: name,
    },
    { text: "New Question" },
  ];

  return (
    <>
      {isAuthenticate ? (
        <section className="w-full mt-8 max-h-[86vh] overflow-y-auto lg:p-10 p-5">
          <div className="mb-5">
            <p className="text-2xl capitalize font-primary text-black font-semibold  my-0">
              {" "}
              Create Question
            </p>
            <div className="mb-5">
              <BreadcrumbComp breadcrumbData={breadcrumbData} />
            </div>

            <div className="w-full mt-2">
              <label
                htmlFor="assesment-time "
                className="font-primary capitalize text-sm lg:text-base"
              >
                Select question type
                <span className="text-red-600 text-lg"> *</span>
              </label>
              <div className="mt-2">
                <Select
                  className="assessment-validity"
                  // value={assessmentExpireDate}
                  onChange={setQuestionType}
                  // showSearch
                  style={{ width: "100%" }}
                  placeholder="Select question type"
                  optionFilterProp="children"
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? "")
                      .toLowerCase()
                      .localeCompare((optionB?.label ?? "").toLowerCase())
                  }
                  options={[
                    {
                      value: "mcq",
                      label: "MCQ",
                    },
                    {
                      value: "media",
                      label: "Media type",
                    },
                    {
                      value: "descriptive",
                      label: "Descriptive type",
                    },
                  ]}
                />
              </div>
            </div>
            {/* select question type dropdown end */}

            {/* question content for question creation start */}
            {questionType != "" ? (
              <div className="mt-5 border bg-white shadow-lg  rounded-xl">
                <div className="w-full pt-4">
                  {questionType === "mcq" ? (
                    <McqQuestions />
                  ) : questionType === "media" ? (
                    <MediaQuestions />
                  ) : questionType === "descriptive" ? (
                    <CodingQuestions assessmentIdForQuestion={assessmentId} />
                  ) : null}
                </div>
              </div>
            ) : null}
            {/* question content for question creation end */}
          </div>
        </section>
      ) : (
        <div
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <Spin tip="Loading..."></Spin>
        </div>
      )}
    </>
  );
};
