const PieCharts = (props) => {
  const unique: any = [];
  if (props?.studentLangauge?.language?.length > 0) {
    props?.studentLangauge?.language?.forEach((element) => {
      if (element != "") {
        if (!unique.includes(element)) {
          unique.push(element);
        }
      }
    });
  }
  return (
    <div>
      {props?.studentLangauge?.language?.length > 0 && unique.length > 0 ? (
        unique.map((language: any, i: number) => {
          return (
            <div key={i}>
              <span key={i}>{language ? language : "Language Not Found"} </span>
            </div>
          );
        })
      ) : (
        <div>
          <span>Languages not found</span>
        </div>
      )}
    </div>
  );
};

export default PieCharts;
