import { Spin } from "antd";
import React from "react";

export const DataLoader = (props) => {
  return (
    <div className="absolute z-20  w-full flex flex-row items-center justify-center text-center  inset-0">
      <div>
        <Spin tip={props.title} size="large" spinning={props.loading}></Spin>
      </div>
    </div>
  );
};
