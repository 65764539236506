import { Breadcrumb } from "antd";
import React, { useEffect, useState } from "react";
import { PrimaryButton } from "../../../components/buttons/PrimaryButton";
import { InputField } from "../../../components/inputFields/InputField";
import { Link } from "react-router-dom";
import {
  getCategoryNameById,
  updateCategoryData,
} from "../../../serviceApi/superAdminApi/CategoryApi";
import { useNavigate, useParams } from "react-router";
import Swal from "sweetalert2";
import axios from "axios";
import { getCookie } from "../../../utils";
import { containsSpecialChars } from "../../../utils/validation";
import ImageField from "../../../components/imageField/ImageField";
import { deleteQuestion } from "../../../serviceApi/api";

const CategoryEdit = () => {
  const navigate = useNavigate();
  const { domainId, categoryId }: any = useParams();
  const catId: any = categoryId;
  const [name, setName] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [categoryImage, setCategoryImage] = useState(null);
  const [categoryImageEdit, setCategoryImageEdit] = useState("");
  const [categoryNameErr, setCategoryNameErr] = useState("");
  const [status, setStatus] = useState(false);
  const [categoryImagePreview, setCategoryImagePreview] = useState<any>();

  const updateCategory = async () => {
    const response = await updateCategoryData({
      domainId,
      name,
      description,
      categoryId,
    });
    if (response?.data?.updateCategory?.message) {
      if (categoryImage) {
        const formData = new FormData();
        formData.append("image", categoryImage);
        formData.append("uuid", catId);
        formData.append("type", "category");
        try {
          const { data } = await axios.post(
            `${process.env.REACT_APP_MEDIA_URL}/api/media/upload`,
            formData,
            {
              headers: {
                enctype: "multipart/form-data",
                Authorization: `Bearer ${getCookie("token")}`,
              },
            }
          );
          if (data.status == true) {
            Swal.fire({
              icon: "success",
              title: "Category updated successfully",
              showConfirmButton: false,
              timer: 2000,
            });
            setTimeout(() => {
              navigate(`/home/dashboard/domain/${domainId}/category/create`);
            }, 2000);
          } else {
            Swal.fire({
              icon: "error",
              title: "something went wrong!",
              showConfirmButton: false,
              timer: 2000,
            });
          }
        } catch (error) {
          console.log(error, "error in file upload in category");
        }
      } else {
        Swal.fire({
          icon: "success",
          title: "Category updated successfully",
          showConfirmButton: false,
          timer: 2000,
        });
        navigate(`/home/dashboard/domain/${domainId}/category/create`);
      }
    } else {
      Swal.fire({
        icon: "warning",
        title: "Invalid Data remove symbols",
        showConfirmButton: false,
        timer: 2000,
      });
    }
  };

  const CategoryImageUpload = async (event: any) => {
    event.preventDefault();
    const selectedFiles = event.target.files[0];
    if (selectedFiles.size >= 2097152) {
      Swal.fire({
        icon: "warning",
        title: "Image size should be max 2MB.",
        showConfirmButton: false,
        timer: 3000,
      });
    } else if (
      selectedFiles.type === "image/png" ||
      selectedFiles.type === "image/jpg" ||
      selectedFiles.type === "image/jpeg" ||
      selectedFiles.type === "image/webp" ||
      selectedFiles.type === "image/jfif" ||
      selectedFiles.type === "image/svg"
    ) {
      setCategoryImage(selectedFiles);
      setCategoryImagePreview(URL.createObjectURL(selectedFiles));
    } else {
      Swal.fire({
        icon: "warning",
        title: "Image formate should be png,jpg,jpeg,webp,jfif,svg",
        showConfirmButton: false,
        timer: 3000,
      });
    }
  };

  const deleteCategoryImage = () => {
    setCategoryImagePreview(URL.revokeObjectURL(categoryImagePreview));
    setCategoryImage(null);
  };

  const filter = async (value) => {
    const keyword = value;
    if (keyword.length > 60) {
      setStatus(true);
    } else {
      setStatus(false);
    }
    setName(keyword);
  };

  const getCategoryData = async () => {
    const response = await getCategoryNameById(categoryId);

    setName(response?.data?.categoryNameById[0]?.name);
    setDescription(response?.data?.categoryNameById[0]?.description);
    setCategoryImageEdit(response?.data?.categoryNameById[0]?.image);
  };

  useEffect(() => {
    if (
      !containsSpecialChars(name) &&
      name != " " &&
      name?.trim().length <= 60 &&
      name?.trim().length >= 2 &&
      description != " " &&
      description?.length > 0 &&
      (categoryImage != null || categoryImageEdit)
    ) {
      setStatus(false);
    } else {
      setStatus(true);
    }
  });

  const deleteImage = async () => {
    const formData = new FormData();
    formData.append("image", categoryImageEdit);
    formData.append("uuid", categoryId);
    formData.append("type", "category");
    const { data }: any = await axios.post(
      `${process.env.REACT_APP_MEDIA_URL}/api/media/delete`,
      formData,
      {
        headers: {
          enctype: "multipart/form-data",
          Authorization: `Bearer ${getCookie("token")}`,
        },
      }
    );
    if (data?.status == true) {
      await deleteQuestion(categoryId, "category");
      getCategoryData();
    } else {
      Swal.fire({
        icon: "error",
        title: "Something went wrong!",
        showConfirmButton: false,
        timer: 3000,
      });
    }
  };
  useEffect(() => {
    getCategoryData();
  }, []);

  return (
    <>
      <div className="w-full mt-2 max-h-[86vh] overflow-y-auto lg:p-10 p-5">
        <div className="lg:my-4 my-2 lg:sticky">
          <p className="text-2xl mb-0 pb-0 font-primary font-medium text-gray-800">
            Update Category
          </p>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/home/dashboard">Dashboard</Link>{" "}
            </Breadcrumb.Item>
            <Breadcrumb.Item>Update Category</Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <section className="mt-20 max-w-2xl  flex lg:flex-row flex-col justify-between gap-5 items-start ">
          <section className="bg-white w-full shadow-xl  rounded-xl ">
            <h1 className="text-2xl  text-black border-b border-gray-300 p-5  lg:px-10 font-primary font-medium text-left">
              Update Category
            </h1>
            <div className="flex flex-col px-10 mt-2 lg:mt-5 ">
              <InputField
                placeholder="Create a category"
                onChange={filter}
                value={name}
                type="text"
                id="category name"
                containsSpecialCharsMsg={setCategoryNameErr}
              />
            </div>
            <span className="text-red-500 mt-5 ml-12">{categoryNameErr}</span>
            <div className="flex flex-col mt-5 px-10">
              <label className="text-base font-primary lg:text-base pb-0  mb-2   text-black ">
                Category Description <span className="text-red-600">*</span>
              </label>
              <textarea
                className="focus:outline-none text-sm lg:text-sm  rounded-md w-full border border-gray-300 p-4 "
                placeholder="Add a Description..."
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </div>
            <div className="px-10 mt-5 flex flex-col">
              <label className="text-base font-primary lg:text-base pb-0  mb-2   text-black ">
                Category Image <span className="text-red-500">*</span>
              </label>
              {categoryImagePreview || categoryImageEdit ? (
                <div className="h-44 border relative  mb-10 w-44">
                  {categoryImagePreview ? (
                    <img
                      className="h-full w-full p-1 rounded-lg object-cover object-center"
                      src={categoryImagePreview}
                      alt={categoryImagePreview}
                    />
                  ) : (
                    <ImageField
                      image={
                        categoryImageEdit
                          ? categoryImageEdit
                          : categoryImagePreview
                      }
                    />
                  )}
                  <div
                    onClick={
                      categoryImageEdit ? deleteImage : deleteCategoryImage
                    }
                    className="bg-black cursor-pointer  w-8 h-8 flex items-center justify-center absolute top-0 right-0 rounded-full"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-5 h-5 mx-auto text-white"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                      />
                    </svg>
                  </div>
                </div>
              ) : (
                <button>
                  <div className="flex items-center justify-center w-44 h-44">
                    <label
                      htmlFor="dropzone-file"
                      className="flex flex-col items-center justify-center w-full h-auto border-2 bg-gray-50 border-gray-300 border-dashed rounded-lg cursor-pointer  dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
                    >
                      <div className="flex flex-col items-center justify-center pt-5 pb-6">
                        <svg
                          aria-hidden="true"
                          className="w-10 h-10 mb-3 text-gray-400"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                          ></path>
                        </svg>
                        <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                          <span className="font-semibold">Click to upload</span>{" "}
                        </p>
                        <p className="text-xs text-center text-gray-500 dark:text-gray-400">
                          WEBP, PNG, JPG or GIF (MAX Size. 2MB)
                        </p>
                      </div>
                      {categoryImageEdit ? (
                        <div className="group  -mt-44 relative bg-white p-1 rounded-xl border border-dashed border-black">
                          <div className="h-44 w-44  text-2xl  group-hover:opacity-100 opacity-0 absolute z-20  text-white flex items-center justify-center inset-0">
                            <svg
                              aria-hidden="true"
                              className="w-10 h-10 mb-3 text-white"
                              fill="none"
                              stroke="currentColor"
                              viewBox="0 0 24 24"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                              ></path>
                            </svg>
                            <p className="mb-2 text-sm text-text-white dark:text-gray-400">
                              <span className="font-semibold">
                                Click to upload
                              </span>{" "}
                            </p>
                          </div>
                          <div className="absolute group-hover:bg-black opacity-40 text-center w-full h-full rounded-xl  text-white"></div>
                          <img
                            className="w-44 h-44 object-cover object-center rounded-xl"
                            src={`${process.env.REACT_APP_MEDIA_URL}/api/media/download${categoryImageEdit}`}
                            alt={categoryImageEdit}
                          />
                        </div>
                      ) : null}
                      <input
                        id="dropzone-file"
                        onChange={CategoryImageUpload}
                        type="file"
                        className="hidden"
                      />
                    </label>
                  </div>
                </button>
              )}
            </div>

            <div className="mt-5 px-10 xl:pb-16 2xl:pb-10 lg:pb-16 md:pb-16 sm:pb-16">
              <div data-testid="updateCategoryButtons">
                <PrimaryButton
                  disabledStatus={status}
                  onclick={() => updateCategory()}
                  text="Update"
                  className={`${
                    status
                      ? "bg-gray-300 cursor-not-allowed border-gray-300"
                      : "bg-black hover:bg-transparent hover:border-black border-black hover:text-black"
                  }`}
                />
              </div>
            </div>
          </section>
        </section>
      </div>
    </>
  );
};

export default CategoryEdit;
