import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useState } from "react";
import { DomainCard } from "../../../../../components/card/DomainCard";
import { getUsers } from "../../../../../serviceApi/api/index";
import { getDomains } from "../../../../../serviceApi/superAdminApi/DomainApi";
import { CardSkeletonLoader } from "../../../../../components/skeleton/CardSkeleton";
import { SearchFilterInput } from "../../../../../components/search/SearchInput";
import { BreadcrumbComp } from "../../../../../components/Breadcrumb/Breadcrumb";
import PaginationData from "../../../../../components/pagination/Pagination";
import { getDomainNamebyuser } from "../../../../../serviceApi/adminApi/DomainApi";

export const Domain = () => {
  const { user }: any = useAuth0();
  const userId: string = user?.email;
  const [domain, setDomain] = useState([]);
  const [role, setRole] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [paginatedData, setPaginatedData] = useState([]);
  const [getSearch, setGetSearch] = useState(true);
  const [isSearch, setIsSearch] = useState(false);
  const [input, setInput] = useState("");
  const getUserRole = async () => {
    const userRole = await getUsers(userId);
    setRole(userRole?.data?.user[0]?.role);
  };

  const getDomainsListbyUser = async () => {
    const domains = await getDomainNamebyuser(user?.email);
    if (domains?.data?.domainNameByuser) {
      setDomain(domains?.data?.domainNameByuser);
      setIsLoading(false);
    }
  };

  const getDomainsList = async () => {
    const domains = await getDomains(userId);
    if (domains?.data?.domains) {
      setDomain(domains?.data?.domains);
      setIsLoading(false);
    }
  };

  const getSearchContent = (data, getClick) => {
    if (data?.length > 0 && typeof data != "string") {
      if (getClick) {
        setGetSearch(true);
        setPaginatedData(data);
        setIsSearch(true);
        setDomain(data);
      } else {
        setPaginatedData(data);
        setGetSearch(true);
        setIsSearch(!isSearch);
        setDomain(data);
      }
    } else if (data === "value not filled" && typeof data === "string") {
      if (role === "Admin") {
        getDomainsListbyUser();
      } else {
        getDomainsList();
      }
      setPaginatedData([]);
      setGetSearch(true);
      setIsSearch(false);
      setDomain(paginatedData);
      setInput("");
    } else if (data === "not found data" && typeof data === "string") {
      setPaginatedData([]);
      setGetSearch(false);
      setIsSearch(!isSearch);
      // getDomainsList();
      // setPaginatedData([]);
    }
  };

  useEffect(() => {
    if (userId) {
      getUserRole();
    }
  }, [userId]);

  useEffect(() => {
    if (role == "Student") {
      getDomainsList();
    } else if (role == "Admin") {
      getDomainsListbyUser();
    } else {
      setIsLoading(true);
    }
  }, [role]);

  const breadcrumbData = [
    {
      path:
        role === "Student" ? "/student/dashboard" : "/subadmin/AllDomains/list",
      text: "Dashboard",
    },
    {
      text: "Domains",
    },
  ];
  return (
    <section className="w-full max-h-[86vh] overflow-y-auto lg:p-10 p-5">
      <div className="h-full">
        <p className="text-2xl mb-0 pb-0 font-primary capitalize font-medium text-gray-800">
          Select Domain to Continue
        </p>
        <div className="lg:mb-4">
          <BreadcrumbComp breadcrumbData={breadcrumbData} />
        </div>
        <div className="mb-5 ml-auto lg:w-64 w-full">
          {domain?.length > 0 && (
            <SearchFilterInput
              SearchContent={getSearchContent}
              data={domain}
              userId={userId}
              type={role === "Admin" ? "Domain_Approved" : "Domain"}
              domainId=""
              name={setInput}
            />
          )}
        </div>
        <div className="border-t pt-5 grid lg:grid-cols-4 md:grid-cols-2  pb-20 gap-5 lg:gap-10">
          {isLoading ? (
            <>
              <CardSkeletonLoader />
              <CardSkeletonLoader />
              <CardSkeletonLoader />
              <CardSkeletonLoader />
              <CardSkeletonLoader />
              <CardSkeletonLoader />
              <CardSkeletonLoader />
              <CardSkeletonLoader />
            </>
          ) : domain?.length > 0 && paginatedData?.length > 0 ? (
            paginatedData?.map((list: any, i: number) => {
              if (role === "Admin") {
                return (
                  <div className="cursor-none" key={i}>
                    <DomainCard
                      key={i}
                      CardHeight="h-44"
                      image={list?.image ? list.image : ""}
                      title={list?.name}
                      type={list?.domain_type}
                      description={list?.description}
                      route={`/subadmin/domain/${list?.uuid}/category`}
                    />
                  </div>
                );
              } else if (role === "Student") {
                return (
                  <div className="cursor-pointer" key={i}>
                    <DomainCard
                      key={i}
                      CardHeight="h-44"
                      image={list.image ? list.image : ""}
                      title={list.name}
                      type={list.domain_type}
                      description={list.description}
                      route={`/student/assesment/domain/category/${list.uuid}`}
                    />
                  </div>
                );
              }
            })
          ) : (
            <div className="shadow-md flex  flex-row items-center justify-center h-32 w-60 border-gray-300 rounded-md">
              <h1 className="font-primary justify-center items-center text-black text-sm ">
                No Approved Domains
              </h1>
            </div>
          )}
        </div>

        {paginatedData && getSearch && (
          <div
            className={`${
              domain?.length >= 8
                ? paginatedData?.length >= 5
                  ? "mb-2"
                  : "absolute left-0 right-0 bottom-20 mb-2"
                : "hidden"
            }`}
          >
            {role == "Admin" || role == "Student" ? (
              <PaginationData
                data={role == "Admin" ? domain : domain}
                paginatedData={setPaginatedData}
                userId={userId}
                getSearchActive={isSearch}
                type={
                  role == "Admin"
                    ? "Domain_Approved"
                    : role == "Student"
                    ? "Domain"
                    : ""
                }
                domainId=""
                name={input}
              />
            ) : (
              ""
            )}
          </div>
        )}
      </div>
    </section>
  );
};
