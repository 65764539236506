import { useAuth0 } from "@auth0/auth0-react";
import React, { Fragment, useContext, useEffect, useState } from "react";
import {
  getAllStudentsResultData,
  getChartData,
  getStatus,
  getUserScoreForRewards,
  getUsers,
  insertUserWallet,
  invitationAssessment,
} from "../../../serviceApi/api/index";
import { Modal, Skeleton } from "antd";
import domainIcon from "../../../assets/studenticons/domainsIcon.svg";
import assessmentIcon from "../../../assets/studenticons/assessmentIcon.svg";
import codingIcon from "../../../assets/studenticons/codingIcon.svg";
import skills from "../../../assets/studenticons/skillsIcon.svg";
import PieChart from "./Charts/PieCharts";
import ColumnChart from "./Charts/ColumnChart";
import { Tabs } from "antd";
import type { TabsProps } from "antd";
import { totalAssessment } from "../../../serviceApi/api/index";
import {
  fetchUserCoursesOnActivity,
  getAllCodingQuestions,
  getAllSkills,
  getRecentActivityCourses,
} from "../../../serviceApi/adminApi/SkillApi";
import { getDomains } from "../../../serviceApi/superAdminApi/DomainApi";
import { CardSkeletonLoader } from "../../../components/skeleton/CardSkeleton";
import { getEvents } from "../../../serviceApi/adminApi/EventApi";
import EventActivityChart from "./Charts/EventActivityChart";
import Swal from "sweetalert2";
import { loginContext } from "../../../components/context/LoginContext";
import axios from "axios";
import CourseCard from "../../../components/card/CourseCard";

export const StudentDefaultDashboard = () => {
  const {
    assessmentuuid,
    setAssessmentUuid,
    isAttempted,
    setIsAttempted,
  }: any = useContext(loginContext);

  const [assNumber, setAssNumber] = useState(0);
  const [skillNumber, setSkillNumber] = useState(0);
  const [domainsNumber, setDomainsNumber] = useState(0);
  const [codingNumber, setCodingNumber] = useState(0);
  const [registerEvent, setRegisterEvent] = useState(0);
  const [privateAssessment, setPrivateAssessment] = useState<any>([]);
  const [response, setResponse] = useState<any>([]);
  const [eventDetails, setEventDetails] = useState<any>([]);
  const [studentDetails, setStudentDetails] = useState<any>({});
  const [comAss, setComAss] = useState<number>(0);
  const [isLoading, setIsLoading] = useState(true);
  const [key, setKey] = useState(1);
  const [filterKey, setFilterKey] = useState("1");
  const [csvUserData, setCsvUserData] = useState([]);
  const [report, setReport] = useState<any>([]);
  const [courses, setCourses] = useState<any>([]);
  const [studentId, setStudentId] = useState();
  const [playerActive, setPlayerActive] = useState("");
  const [isIframe, setIsIframe] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { user } = useAuth0();
  const email: string = user?.email as string;

  const invitationAssesment = async (email: string) => {
    const result = await invitationAssessment(email);
    if (result?.data?.invitationAssesment?.length > 0) {
      setPrivateAssessment(result?.data?.invitationAssesment);
    }
  };

  let pending = 0;
  let expire = 0;
  privateAssessment?.forEach((list) => {
    if (list?.status === "Pending") {
      pending++;
    } else {
      expire++;
    }
  });

  const getDomainsList = async (userId: any) => {
    const domains = await getDomains(userId);
    if (domains?.data?.domains) {
      setDomainsNumber(domains?.data?.domains.length);
    } else {
      setDomainsNumber(0);
    }
  };

  const assessmentNumber = async () => {
    const totalAssessmentNumber = await totalAssessment();
    if (totalAssessmentNumber?.data?.assessmentsNumber) {
      setAssNumber(totalAssessmentNumber?.data?.assessmentsNumber.length);
    } else {
      setAssNumber(0);
    }
  };

  const totalSkills = async (email: string) => {
    if (email) {
      const skills = await getAllSkills(email);
      if (skills?.data?.skillList) {
        setSkillNumber(skills?.data?.skillList.length);
      } else {
        setSkillNumber(0);
      }
    }
  };

  const totalCodingQuestions = async () => {
    const allCodingQuestions = await getAllCodingQuestions();
    if (allCodingQuestions?.data?.codingList) {
      setCodingNumber(allCodingQuestions?.data?.codingList.length);
    } else {
      setCodingNumber(0);
    }
  };

  const cardData = [
    {
      header: "Domains",
      attempted: domainsNumber <= 0 ? 0 : domainsNumber,
      icon: domainIcon,
    },
    {
      header: "Assessments",
      attempted: assNumber,
      icon: assessmentIcon,
    },
    // {
    //   header: "Coding",
    //   attempted: codingNumber,
    //   icon: codingIcon,
    // },
    {
      header: "Registered Events",
      attempted: registerEvent,
      icon: codingIcon,
    },
    {
      header: "Skills",
      attempted: skillNumber,
      icon: skills,
    },
  ];

  const getStudentDetails = async () => {
    if (email != "") {
      const students = await getUsers(email);
      if (students?.data?.user) {
        setStudentDetails(students?.data?.user[0]);
        setStudentId(students?.data?.user[0]?.id);
        if (assessmentuuid) {
          getAssessmentReport(students?.data?.user[0]?.id);
        } else {
          getLastActivityCourses(students?.data?.user[0]?.id);
        }
      }
    }
  };

  const getResultDetails = async (email, key) => {
    const resultInfo = await getChartData(email, key.toString());
    if (resultInfo?.data?.getStudentChartData) {
      setResponse(resultInfo?.data?.getStudentChartData);
      const resultData = resultInfo?.data?.getStudentChartData;
      const newArray = resultData.map((m) => [m.assessmentName, m]);
      const newMap = new Map(newArray);
      const iterator: any = newMap.values();
      const uniqueResult: any = [...iterator];
      setCsvUserData(uniqueResult);
      setComAss(uniqueResult?.length);
      setIsLoading(false);
    }
  };

  const getAssessmentReport = async (studentId) => {
    setAssessmentUuid("");
    const response = await getStatus(email, assessmentuuid);
    if (response?.data?.getStatus) {
      setReport(response?.data?.getStatus);
      let parseData;
      var filterCourseData = response?.data?.getStatus?.map(
        (result, i: number) => {
          const arrayOfObjects = JSON.parse(result?.topic || "");

          // // Use a Map to keep track of unique keys and their values
          const keyToValueMap: any = new Map();
          const keyToValueMap1: any = new Map();

          // Iterate through the array of objects
          arrayOfObjects.forEach((obj) => {
            const topic = obj.topic;
            const marks = obj.marks;
            const total = obj.total;

            // If the key is not in the Map, add it along with the value
            if (!keyToValueMap.has(topic)) {
              keyToValueMap.set(topic, marks);
              keyToValueMap1.set(topic, total);
            } else {
              // If the topic is already in the Map, add the object's marks to the existing marks
              keyToValueMap.set(topic, keyToValueMap.get(topic) + marks);
              keyToValueMap1.set(topic, keyToValueMap1.get(topic) + total);
            }
          });

          // Convert the Map back to an array of objects
          const resultArray = [...keyToValueMap.entries()].map(
            ([topic, marks, total, studentId, createdAt]) => ({
              topic,
              marks,
              total,
              studentId,
              createdAt,
            })
          );
          const resultArray1 = [...keyToValueMap1.entries()].map(
            ([topic, total]) => ({
              topic,
              total,
            })
          );
          resultArray.forEach((obj: any, i) => {
            resultArray1.forEach((obj1, j) => {
              if (i == j) {
                obj.total = obj1.total;
              }
            });
          });

          let dataForCourses = resultArray?.filter((data) => {
            const totalScale = (data?.marks * 100) / data?.total / 10;
            if (totalScale < 6) {
              data.marks = totalScale;
              data.topic = data.topic;
              return JSON.stringify(data.topic);
            }
          });
          parseData = JSON.stringify(dataForCourses);
          return dataForCourses;
        }
      );

      const assessmentParseData = filterCourseData[0].flat();
      for (let i = 0; i < assessmentParseData.length; i++) {
        const res = await axios.post(
          `${process.env.REACT_APP_RECOMMENDATION_ENGINE_URL}/user_test_marks_data`,
          {
            user_id: studentId,
            topic_id: assessmentParseData[i].topic,
            marks: assessmentParseData[i].marks,
            timestamp: Date.now(),
          }
        );

        if (res?.data?.message) {
          const res = await axios.post(
            `${process.env.REACT_APP_RECOMMENDATION_ENGINE_URL}/user_test_course_data`,
            {
              user_id: studentId,
              course_id: assessmentParseData[i].topic,
            }
          );
          if (res?.data?.message) {
            // navigate("/student/dashboard");
          }
        }
      }

      const res = await fetchUserCoursesOnActivity(
        studentId,
        assessmentuuid,
        JSON.stringify(assessmentParseData)
      );
      setCourses(JSON.parse(res?.data?.fetchActivityCourses[0]?.Video || ""));
    }
  };

  const getLastActivityCourses = async (studentId) => {
    const response = await getRecentActivityCourses(
      studentId,
      "assessmentuuid",
      "Activity"
    );

    if (response?.data?.getRecentActivityCourses?.length > 0) {
      const data = response?.data?.getRecentActivityCourses?.map(
        (courses) => courses?.courses
      );
      const coursesData = JSON.parse(data[0] || "")?.map((data) =>
        JSON.parse(data?.Video || "")
      );
      setCourses(coursesData.flat());
    }
    setIsLoading(false);
  };

  const getCheckScore = async (email) => {
    if (assessmentuuid) {
      const resultInfo = await getUserScoreForRewards(email, assessmentuuid);
      if (resultInfo?.data?.checkScoreForRewards?.length > 0) {
        if (resultInfo?.data?.checkScoreForRewards[0]?.finalPer * 100 > 60) {
          const userWallet = await insertUserWallet(email);
          Swal.fire({
            icon: "success",
            title: "5 Coins Credited into Wallet",
            timer: 3000,
            timerProgressBar: true,
          });
        }
      }
    }
    setAssessmentUuid("");
  };

  const getEventsDetails = async (email: any) => {
    const eventInfo = await getEvents(email);
    if (eventInfo?.data?.getEvents) {
      setEventDetails(eventInfo?.data?.getEvents);
      const eventData = eventInfo?.data?.getEvents;
      const newArray = eventData.map((m) => [m.name, m]);
      const newMap = new Map(newArray);
      const iterator: any = newMap.values();
      const uniqueResult: any = [...iterator];
      setEventDetails(uniqueResult);
      setRegisterEvent(uniqueResult.length);
    }
  };

  const activeTabs = (e, index) => {
    setKey(index);
  };

  const item: TabsProps["items"] = [
    {
      key: "1",
      label: (
        <div className="flex">
          <img width="30" height="30" src="/Assets/7_days.png" alt="days" />
          Last 7 Days
        </div>
      ),
      children:
        response != "" ? (
          <ColumnChart response={response} />
        ) : (
          "My Assessment Activity is not found"
        ),
    },
    {
      key: "2",
      label: (
        <div className="flex">
          <img width="30" height="30" src="/Assets/month.png" alt="month" />
          Last Month
        </div>
      ),
      children:
        response != "" ? (
          <ColumnChart response={response} />
        ) : (
          "My Assessment Activity is not found"
        ),
    },
    {
      key: "3",
      label: (
        <div className="flex">
          <img width="30" height="30" src="/Assets/year.png" alt="year" /> Last
          Year{" "}
        </div>
      ),
      children:
        response != "" ? (
          <ColumnChart response={response} />
        ) : (
          "My Assessment Activity is not found"
        ),
    },
  ];

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: (
        <div className="flex">
          <img
            width="20"
            height="20"
            src="https://img.icons8.com/external-outline-design-circle/66/external-Task-Complete-project-management-outline-design-circle.png"
            alt="external-Task-Complete-project-management-outline-design-circle"
          />
          Completed
        </div>
      ),
      children:
        comAss > 0 ? (
          <div className="grid grid-cols-3 gap-6 w-full">
            {comAss > 0 ? (
              <p>{comAss} Assessments</p>
            ) : (
              <p>{comAss} Assessments</p>
            )}
          </div>
        ) : (
          <p>{comAss} Assessments</p>
        ),
    },
    {
      key: "2",
      label: (
        <div className="flex">
          <img
            width="20"
            height="20"
            src="https://img.icons8.com/ios/50/hourglass.png"
            alt="hourglass"
          />
          Pending
        </div>
      ),
      children: (
        <div className="grid grid-cols-3 gap-6 w-full">
          <p>{pending} Assessments</p>
        </div>
      ),
    },
    {
      key: "3",
      label: (
        <div className="flex">
          <img
            width="20"
            height="20"
            src="https://img.icons8.com/wired/64/expired.png"
            alt="expired"
          />{" "}
          Expired{" "}
        </div>
      ),
      children: (
        <div className="grid grid-cols-3 gap-6 w-full">
          <p>{expire} Assessments</p>
        </div>
      ),
    },
  ];

  useEffect(() => {
    if (email) {
      getStudentDetails();
      totalCodingQuestions();
      getResultDetails(email, 1);
      assessmentNumber();
      totalSkills(email);
      getDomainsList(email);
      invitationAssesment(email);
      getEventsDetails(email);
      getCheckScore(email);
      setIsAttempted(true);
    }
  }, [email]);

  useEffect(() => {
    return () => {
      cleanUp();
    };
  }, []);

  const cleanUp = () => {
    totalCodingQuestions();
    getResultDetails(email, 1);
    assessmentNumber();
    totalSkills(email);
    getDomainsList(email);
    invitationAssesment(email);
    getEventsDetails(email);
  };
  const getIframe = () => {
    return (
      <div
        dangerouslySetInnerHTML={{ __html: playerActive ? playerActive : "" }}
      />
    );
  };

  const getVideoPlayer = async (videoId) => {
    axios
      .get(
        `https://www.googleapis.com/youtube/v3/videos?id=${videoId}&type=video&part=player&key=AIzaSyAub-QMZmP69tj49JKuvcUJvQeOyD3DzRw`
      )
      .then((response) => {
        setIsIframe(true);
        setIsModalOpen(true);
        setPlayerActive(response.data.items[0].player.embedHtml.toString());
        return (
          <div
            dangerouslySetInnerHTML={{
              __html: response.data.items[0].player.embedHtml.toString()
                ? response.data.items[0].player.embedHtml.toString()
                : "",
            }}
          />
        );
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: error.message + " Something went wrong!",
          showConfirmButton: false,
        });
      });
  };

  const handleTabChange = (key) => {
    setFilterKey(key);
    getResultDetails(email, key);
  };

  return (
    <div>
      {isLoading ? (
        <div className="max-h-[86vh] overflow-y-auto w-full lg:p-10 p-5">
          <p className="text-3xl font-primary font-bold">Dashboard</p>
          <div className="grid grid-cols-4 lg:grid-cols-4 gap-8 w-full ">
            <Fragment>
              <div className="flex items-start flex-row justify-between">
                <CardSkeletonLoader />
              </div>
              <div className="flex items-start flex-row justify-between">
                <CardSkeletonLoader />
              </div>
              <div className="flex items-start flex-row justify-between">
                <CardSkeletonLoader />
              </div>
              <div className="flex items-start flex-row justify-between">
                <CardSkeletonLoader />
              </div>
            </Fragment>
          </div>
          {/* student */}
          <section className="mt-5 flex items-start justify-between flex-row space-x-8">
            {/*1st Statistics */}
            <div className="p-6 bg-white rounded-xl border shadow-lg w-3/4 ">
              <p className=" text-black font-primary text-base lg:text-[21px] xl:text-2xl font-medium">
                Assessment Activity
              </p>
              <Skeleton active paragraph={{ rows: 6 }} />
            </div>
            <div className="h-[320px] mt-5 p-6 bg-white border border-gray-200 rounded-lg shadow-md w-1/5 ml-5 flex flex-col">
              <h1 className="text-black font-primary text-base lg:text-[21px] xl:text-2xl font-medium">
                Languages and Skills
              </h1>
              <hr className="h-px my-5 bg-gray-200 border-1 w-full ... dark:bg-black-700" />

              <Skeleton active paragraph={{ rows: 4 }} />
            </div>
          </section>

          <section className="mt-2 w-full flex flex-row justify-between">
            <div className="flex items-start flex-col p-6 rounded-lg bg-white border border-gray-200 shadow-md w-[74%] ">
              <p className=" text-black font-primary text-base lg:text-[21px] xl:text-2xl font-medium">
                Assessment Details
              </p>
              <Skeleton active paragraph={{ rows: 6 }} />
            </div>
          </section>
        </div>
      ) : (
        //Activity with chart
        <div className="max-h-[86vh] overflow-y-auto w-full lg:p-10 p-5">
          <p className="text-3xl font-primary font-bold">Dashboard</p>
          {/* cards */}
          <div className="grid grid-cols-4 gap-8 w-full">
            {cardData?.map((data: any, key: number) => (
              <div
                key={key}
                className="p-5 bg-white border border-gray-200 rounded-xl shadow-lg cursor-pointer"
                onClick={(e) => activeTabs(e, key)}
              >
                <div className="flex items-start flex-row justify-between">
                  <div>
                    <p className="font-primary text-gray-500 overflow-para word-wrap: break-word lg:text-sm sm:text-sm">
                      {data.header}
                    </p>
                    <h4 className=" font-bold font-primary text-3xl">
                      {data.attempted}
                    </h4>
                    <img
                      src={data.icon}
                      alt="image"
                      className="flex items-start flex-col"
                      height={40}
                      width={40}
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
          <section
            className={`mt-10 flex justify-between flex-row space-x-8 w-full ${
              response?.length > 0 ? `h-[600px]` : `h-[200px]`
            }`}
          >
            <div className="h-[600px] w-full">
              {key !== 2 ? (
                <div className="p-6 overflow-y-auto bg-white rounded-xl border shadow-lg w-full ">
                  <div className="flex space-x-8">
                    <p className=" text-black font-primary text-2xl font-medium">
                      My Assessment Activity
                    </p>
                  </div>
                  <Tabs
                    defaultActiveKey={filterKey}
                    items={item}
                    onChange={(key) => handleTabChange(key)}
                  />
                </div>
              ) : (
                <div className="p-6 overflow-y-auto bg-white rounded-xl border shadow-lg w-3/4 ">
                  <p className=" text-black font-primary text-2xl font-medium">
                    My Event Registration Activity
                  </p>
                  {eventDetails != "" ? (
                    <EventActivityChart response={eventDetails} />
                  ) : (
                    "My event registration Activity is not found"
                  )}
                </div>
              )}
            </div>

            {/* charts */}
            <div className="space-y-5 ">
              <div className=" p-6 bg-white border overflow-auto border-gray-200 rounded-lg shadow-md w-full ml-5 flex flex-col">
                <h1 className="text-black font-primary text-2xl font-medium">
                  Languages and Skills
                </h1>
                <hr className="h-px my-8 bg-gray-200 border-1 w-full ... dark:bg-black-700" />
                <div>
                  {studentDetails != "" ? (
                    <PieChart studentLangauge={studentDetails} />
                  ) : (
                    <PieChart />
                  )}
                </div>
              </div>

              <div
                className={`p-6 bg-white border overflow-auto border-gray-200  overflow-x-hidden ${
                  courses?.length > 0
                    ? ` overflow-auto overflow-y-scroll h-[600px]`
                    : `h-[200px]`
                } rounded-lg shadow-md w-full ml-5 flex flex-col`}
              >
                <h1 className="text-black font-primary text-2xl font-medium">
                  Courses based on last activity
                </h1>
                <hr className="h-px my-8 bg-gray-200 border-1 w-full ... dark:bg-black-700" />
                <div>
                  {studentDetails != "" ? (
                    courses?.length > 0 ? (
                      courses?.map((list: any, i: number) => {
                        return (
                          <div
                            className="cursor-pointer"
                            key={i}
                            onClick={(e) => getVideoPlayer(list?.id)}
                          >
                            <CourseCard
                              key={i}
                              CardHeight="h-44"
                              image={
                                list?.thumbnailUrl ? list?.thumbnailUrl : ""
                              }
                              title={list?.title}
                              type={list?.domain_type}
                              description={list?.description}
                              route={`/student/assesment/domain/category/${list.uuid}`}
                            />
                          </div>
                        );
                      })
                    ) : (
                      "Courses not found"
                    )
                  ) : (
                    <PieChart />
                  )}
                </div>
              </div>
            </div>
          </section>

          <section className="flex flex-row justify-between mt-10 w-full">
            <div className="flex items-start flex-col p-6 rounded-lg bg-white border border-gray-200 shadow-md w-[77%] ">
              <p className="text-black font-primary text-2xl font-medium">
                Assessment Details
              </p>
              <Tabs defaultActiveKey="1" items={items} />
            </div>
          </section>
        </div>
      )}

      {isIframe && (
        <Modal
          open={isModalOpen}
          onOk={() => {
            setIsModalOpen(false);
          }}
          onCancel={() => {
            setIsModalOpen(false);
          }}
        >
          <div className="mt-5"> {getIframe()}</div>
        </Modal>
      )}
    </div>
  );
};
