import React, { useContext, useEffect, useState } from "react";
import { Select, message } from "antd";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import { getCategories } from "../../../../serviceApi/superAdminApi/CategoryApi";
import { PrimaryButton } from "../../../../components/buttons/PrimaryButton";
import { getSkills } from "../../../../serviceApi/adminApi/SkillApi";
import axios from "axios";
import { BreadcrumbComp } from "../../../../components/Breadcrumb/Breadcrumb";
import { getDomains } from "../../../../serviceApi/superAdminApi/DomainApi";
import { getUsers } from "../../../../serviceApi/api";
import { DataLoader } from "../../../../components/loader/DataLoader";
import Courses from "./Courses";
import GoBackbutton from "../../../../components/buttons/GoBackbutton";
import Swal from "sweetalert2";
import { loginContext } from "../../../../components/context/LoginContext";

export default function StudentCourse() {
  const navigate = useNavigate();
  const { user }: any = useAuth0();
  const { isSaved, setIsSaved }: any = useContext(loginContext);
  const userId: string = user?.email;
  const [messageApi, contextHolder] = message.useMessage();
  const [domain, setDomain] = React.useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [skillList, setSkillList] = useState([]);
  const [value, setValue] = useState<string>();
  const [catValue, setCatValue] = useState<string>();
  const [skillValue, setSkillValue] = useState<string>();
  const [createSkills, setCreateSkills] = useState<boolean>(false);
  const [status, setStatus] = useState(true);
  const [learnerId, setLearnerId] = useState();
  const [isCoursesView, setIsCoursesView] = useState(false);

  const getDomainsList = async () => {
    const domains = await getDomains(userId);
    setDomain(domains?.data?.domains);
  };

  const getUserId = async () => {
    const user = await getUsers(userId);
    setLearnerId(user.data.user[0].id);
  };

  const handleChange = async (newValue: string) => {
    if (categoryList.length > 0) {
      setCatValue("");
    }
    setValue(newValue);
    const categories = await getCategories(newValue);
    if (categories?.data?.category) {
      setCategoryList(categories?.data?.category);
    }
  };

  const selectCategory = async (newValue: string) => {
    if (categoryList.length > 0) {
      setSkillValue("");
    }
    setCatValue(newValue);
    const res = await getSkills(newValue);
    if (res?.data?.skill) {
      setSkillList(res?.data?.skill);
    }
  };

  const selectSkill = async (newValue: string) => {
    setSkillValue(newValue);
  };

  const createSkillList = async () => {
    setStatus(true);
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_RECOMMENDATION_ENGINE_URL}/user_test_marks_data`,
        {
          user_id: learnerId,
          topic_id: skillValue,
          marks: 2,
          timestamp: Date.now(),
        }
      );
      if (res?.data?.message) {
        const res = await axios.post(
          `${process.env.REACT_APP_RECOMMENDATION_ENGINE_URL}/user_test_course_data`,
          {
            user_id: learnerId,
            course_id: skillValue,
          }
        );
        if (res?.data?.message) {
          setCreateSkills(true);
          setIsSaved(true);
          setStatus(true);
          const res = await axios.post(
            `${process.env.REACT_APP_RECOMMENDATION_ENGINE_URL}/train`
          );
        }
      }
    } catch (err: any) {
      setCreateSkills(false);
      messageApi.open({
        type: "error",
        content: err?.message,
      });
    }
  };

  const handleDashboard = () => {
    navigate("/student/dashboard");
  };

  useEffect(() => {
    getDomainsList();
    getUserId();
  }, [userId]);

  const domainData: any = domain?.map((list: any) => {
    return {
      value: list?.uuid,
      label: list?.name,
    };
  });

  const categoryData: any = categoryList.map(
    (list: { uuid: string; name: string }) => {
      return {
        value: list.uuid,
        label: list.name,
      };
    }
  );
  const skillData: any = skillList.map(
    (list: { uuid: string; name: string }) => {
      return {
        value: list.name,
        label: list.name,
      };
    }
  );

  const sweetAlert = () => {
    Swal.fire({
      title: "Your interest saved successfully",
      text: "Thank you for your interest. It will take some time to generate better course recommendations for you, so please wait or check back later.",
      icon: "success",
      confirmButtonText: "Go to Dashboard",
      showCancelButton: false,
    }).then((result) => {
      handleDashboard();
    });
  };

  useEffect(() => {
    if (catValue && domain?.length && skillValue) {
      setStatus(false);
    } else setStatus(true);
  }, [domain?.length, catValue, skillValue]);

  const breadcrumbData = [
    {
      text: "Select skill",
    },
  ];

  // useEffect(() => {
  //   // window.history.pushState(null, "", window.location.href);
  //   // window.onpopstate = function () {
  //   //   window.history.pushState(null, "", window.location.href);
  //   // };
  //   window.onbeforeunload = function () {
  //     navigate("/student/dashboard");
  //     return true;
  //   };
  // }, []);

  useEffect(() => {
    // Prompt confirmation when reload page is triggered
    window.onbeforeunload = () => {
      return "";
    };

    // Unmount the window.onbeforeunload event
    return () => {
      window.onbeforeunload = null;
    };
  }, []);

  return (
    <>
      <section className="w-full max-h-[86vh] overflow-y-auto lg:p-5 p-5">
        {contextHolder}
        <p className="text-2xl mb-0 pb-0 font-primary font-medium text-gray-800">
          Select skills for better course recommendations based on interest
        </p>
        <div className="lg:mb-4 lg:mt-0 my-3">
          <BreadcrumbComp breadcrumbData={breadcrumbData} />
        </div>
        <div className="bg-white rounded-2xl mt-10 p-5 shadow-lg border">
          <div className="grid lg:grid-cols-2 gap-5 lg:gap-x-20">
            <div>
              <p className="lg:text-sm text-sm pb-0 mb-1 md:mb-2 lg:mb-2  capitalize text-black">
                Select domain <span className="text-red-600">*</span>
              </p>
              <Select
                value={value}
                onChange={handleChange}
                showSearch
                className="md:w-full w-full assessment-validity rounded-md"
                placeholder="Search / Select domain"
                optionFilterProp="children"
                filterOption={(input, option: any) =>
                  (option?.label ?? "").includes(input)
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? "").toLowerCase())
                }
                options={domainData}
              />
            </div>

            <div>
              <p className="lg:text-sm text-sm pb-0 mb-1 md:mb-2 lg:mb-2  capitalize text-black  ">
                Select Category <span className="text-red-600">*</span>
              </p>
              <Select
                value={catValue}
                onChange={selectCategory}
                className="lg:w-full md:w-full w-full assessment-validity rounded-md"
                placeholder={catValue == null ? "Search / Select Category" : ""}
                filterOption={(input: any, option: any) =>
                  (option?.label ?? "").includes(input)
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? "").toLowerCase())
                }
                options={categoryData ? categoryData : "Not Found"}
              />
            </div>

            <div>
              <p className="lg:text-sm text-sm pb-0 mb-1 md:mb-2 lg:mb-2  capitalize text-black  ">
                Select skill <span className="text-red-600">*</span>
              </p>
              <Select
                value={skillValue}
                onChange={(e) => selectSkill(e)}
                className="lg:w-full md:w-full w-full assessment-validity rounded-md"
                placeholder={catValue == null ? "Search / Select Skill" : ""}
                filterOption={(input: any, option: any) =>
                  (option?.label ?? "").includes(input)
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? "").toLowerCase())
                }
                options={skillData ? skillData : "Not Found"}
              />
            </div>
          </div>
          <div className="mt-10">
            {isCoursesView ? (
              <PrimaryButton
                text="Go to Dashboard"
                onclick={handleDashboard}
                // disabledStatus={status ? true : false}
                className={`${"bg-black hover:bg-transparent hover:border-black border-black hover:text-black"}`}
              />
            ) : (
              <PrimaryButton
                text="Save"
                onclick={createSkillList}
                disabledStatus={status ? true : false}
                className={`${
                  status
                    ? "bg-gray-300 cursor-not-allowed border-gray-300"
                    : "bg-black hover:bg-transparent hover:border-black border-black hover:text-black"
                }`}
              />
            )}
          </div>
        </div>
        {isCoursesView && <Courses />}
        <GoBackbutton />
      </section>
      {createSkills ? sweetAlert() : ""}
    </>
  );
}
