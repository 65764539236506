import React, { useState, useEffect } from "react";
const InfiniteScroll = ({ fetchData, renderData, hasMore, page }) => {
  // const [page, setPage] = useState(1);
  const [isScroll, setIsScroll] = useState(false);

  useEffect(() => {
    const handleScroll1 = () => {
      if (
        window.innerHeight + document.documentElement.scrollTop + 1 >=
        document.documentElement.offsetHeight
      ) {
        if (!hasMore) {
          return;
        }
        fetchData(page);
        // setPage(page + 1);
      }
    };

    // const isScroll = (document.getElementById("scroll")as HTMLDataElement)?.addEventListener("scroll", handleScroll1);
    // console.log("isScroll", isScroll);
    handleScroll1();
    return () => window.removeEventListener("scroll", handleScroll1);
  }, [page]);

  useEffect(() => {
    const display = () => {
      if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
        // fetchData(page);
      }
      setIsScroll(!isScroll);

      // fetchData(page);
    };
    (document.getElementById("scroll") as HTMLDataElement)?.addEventListener(
      "scroll",
      display
    );
    display();
    return () => window.removeEventListener("scroll", display);
  }, []);

  // useEffect(() => {
  //   // handleScroll1();
  //   // fetchData(page);
  // }, [page]);

  return <div>{renderData()}</div>;
};

export default InfiniteScroll;
