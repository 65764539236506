import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import {
  requestDomains,
} from "../../serviceApi/adminApi/DomainApi";
import Swal from "sweetalert2";

export const ActivateButton = (props) => {
  const { user } = useAuth0();
  const userId: string | undefined = user?.email as string;

  const activateDomain = async () => {
    const request = await requestDomains(userId, props?.uuid);
    if (request?.data?.requestDomains == null || request?.data?.requestDomains) {
      Swal.fire({
        icon: "success",
        title: "Your domain request has been sent for the approval",
        showConfirmButton: false,
        timer: 3000,
      });
      props.reqStatus(true);
    }
  };
  return (
    <>
      <button
        onClick={activateDomain}
        disabled={props.status === "Pending" ? true : false}
        className={`${[
          props.status === "Pending" ? "bg-orange-600 cursor-not-allowed" : "bg-[#0E80FD]",
        ]}  mx-auto relative flex items-center rounded-full py-2 px-8 text-white font-primary font-semibold`}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-6 h-6 text-white"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M15 11.25l-3-3m0 0l-3 3m3-3v7.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>
        {props.status === "Pending" ? "Pending" : "Activate"}
      </button>
    </>
  );
};
