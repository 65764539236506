import React, {
  useEffect,
  useRef,
  useState,
} from "react";
import { useNavigate, useParams } from "react-router";
import { QuestionCircleOutlined } from "@ant-design/icons";
import QuillEditor from "react-quill";
import "react-quill/dist/quill.snow.css";

import {
  addAnswersList,
  deleteQuestion,
  questionAnswerCreate,
} from "../../../../../serviceApi/api";
import { Form, Select, Spin } from "antd";

import { InputField } from "../../../../../components/inputFields/InputField";
import { nameInputValid } from "../../../../../utils/validation";
import { PrimaryButton } from "../../../../../components/buttons/PrimaryButton";
import SelectField from "../../../../../components/selectField/SelectField";
import { Editor } from "@tinymce/tinymce-react";

export const McqQuestions = () => {
  const { domainId, categoryId, skillId, assessmentId }: any = useParams();
  const quillRef = useRef<any>(null);
  const insertSymbol = (symbol) => {
    if (quillRef.current) {
      const quill = quillRef.current.getEditor();
      const selection = quill.getSelection(true);
      if (selection) {
        quill.insertText(selection, symbol);
      } else {
        quill.insertText(selection, symbol);
      }
    }
  };
  const navigate = useNavigate();

  const [createdQuestionId, setCreatedQuestionId] = useState("");
  const [duplicateMessage, setDuplicateMessage] = React.useState(false);
  const [topic, setTopic] = useState("");
  const [questionLevel, setQuestionLevel] = useState("");

  const [weightage, setWeightage] = useState("0");
  const [question, setQuestion] = useState("");
  const [correctAnswers, setCorrectAnswers] = useState<any>([]);
  const [answersList, setAnswersList] = useState<any>([{}]);
  const [topicError, setTopicError] = useState("");
  const [weightageErr, setWeightageErr] = useState("");
  const [formStatus, setFormStatus] = useState(true);
  const [answerFormStatus, setAnswerFormStatus] = useState(true);
  const [showcorrectAnswer, setShowcorrectAnswer] = useState(false);
  const [selectKey, setSelectKey] = useState(0);
  const [apiDataLoader, setApiDataLoader] = useState(false);

  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ align: ["right", "center", "justify"] }],
      [{ script: "sub" }, { script: "super" }],
      [{ list: "ordered" }, { list: "bullet" }],
      ["image"],
      [{ color: ["red", "#785412"] }],
      [{ background: ["red", "#785412"] }],
    ],
  };
  const formats = [
    "header",
    "bold",
    "italic",
    "symbol",
    "script",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "align",
    "color",
    "background",
    "script",
    "size",
    "color",
    "clean",
  ];

  const answerModules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ align: ["right", "center", "justify"] }],
      [{ script: "sub" }, { script: "super" }],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ color: ["red", "#785412"] }],
      [{ background: ["red", "#785412"] }],
    ],
  };
  const answerFormats = [
    "header",
    "bold",
    "italic",
    "symbol",
    "script",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "align",
    "color",
    "background",
    "script",
    "size",
    "color",
    "clean",
  ];

  const addInputField = () => {
    setAnswersList([...answersList, { uuid: "", option: "" }]);
    setAnswerFormStatus(true);
  };
  interface DataType {
    uuid: string;
    option: string;
  }

  const removeInputFields = async (value: DataType, index: number) => {
    setFormStatus(true);
    setAnswerFormStatus(true);
    setCorrectAnswers([]);
    if (value?.uuid) {
      try {
        const result = await deleteQuestion(value?.uuid, "option");
        if (result) {
          setSelectKey((prevKey) => prevKey + 1);
          const list = [...answersList];
          list.splice(index, 1);
          setAnswersList(list);
          setAnswerFormStatus(false);
        }
      } catch (error: any) {
        throw new Error(error);
      }
    } else {
      setSelectKey((prevKey) => prevKey + 1);
      const list = [...answersList];
      list.splice(index, 1);
      setAnswersList(list);
      setAnswerFormStatus(false);
    }
  };

  const handleEditorChange = (event, index: number) => {
    // const { value } = event.target;
    const list = [...answersList];
    list[index] = { uuid: "", option: event };
    setAnswersList(list);
  };

  const onFinish = async () => {
    setFormStatus(true);
    setApiDataLoader(true);
    const response = await questionAnswerCreate({
      assessmentId,
      question: question,
      weightage: weightage,
      answers: answersList,
      CorrectAnswer: correctAnswers,
      topic: topic,
      questionId: createdQuestionId,
      questionType: "1", //Question Type:- 1->MCQ question text,answer text
      action: "create",
      questionLevel,
    });
    if (response?.data?.createQuestionAnswer?.id) {
      setCreatedQuestionId(response?.data?.createQuestionAnswer?.uuid);
      setApiDataLoader(true);
      navigate(
        `/subadmin/domain/skill/${skillId}/assessment/${assessmentId}/questions/list`
      );
    } else if (response?.data?.createQuestionAnswer?.id === null) {
      navigate(
        `/subadmin/domain/skill/${skillId}/assessment/${assessmentId}/questions/list`
      );
    }
  };

  const selectCorrectAnswer = (newValue: string) => {
    setCorrectAnswers(newValue);
  };

  useEffect(() => {
    const quilToolbar = document.getElementsByClassName("ql-toolbar ql-snow");
    const spanSigma = document.createElement("span");
    spanSigma.style.cursor = "pointer";
    spanSigma.innerHTML = "∑";
    spanSigma.setAttribute("id", "ql-formula");
    quilToolbar[0].appendChild(spanSigma);
    spanSigma.addEventListener("click", () => {
      insertSymbol("∑");
    });
    const spanIntegral = document.createElement("span");
    spanIntegral.style.cursor = "pointer";
    spanIntegral.style.marginLeft = "5px";
    spanIntegral.innerHTML = "∫";
    spanIntegral.setAttribute("id", "ql-formula");
    quilToolbar[0].appendChild(spanIntegral);
    spanIntegral.addEventListener("click", () => {
      insertSymbol("∫");
    });
    const spanSqrt = document.createElement("span");
    spanSqrt.style.cursor = "pointer";
    spanSqrt.style.marginLeft = "5px";
    spanSqrt.innerHTML = "√";
    spanSqrt.setAttribute("id", "ql-formula");
    quilToolbar[0].appendChild(spanSqrt);
    spanSqrt.addEventListener("click", () => {
      insertSymbol("√");
    });
  }, []);

  useEffect(() => {
    if (answersList?.length >= 2) {
      const checkanswer = answersList?.every((item: any) => {
        const formateItem = item?.option?.replace(/(<([^>]+)>)/g, "");
        return formateItem?.trim()?.length > 0;
      });
      if (!checkanswer) {
        setFormStatus(true);
        setAnswerFormStatus(true);
      } else {
        if (
          Number(weightage) <= 10 &&
          Number(weightage) >= 1 &&
          weightage &&
          nameInputValid(topic) &&
          !duplicateMessage &&
          topic?.trim().length <= 60 &&
          topic?.trim().length >= 2 &&
          topic &&
          question?.replace(/(<([^>]+)>)/g, "")?.trim()?.length > 0 &&
          answersList?.length >= 2 &&
          correctAnswers?.length >= 1
        ) {
          setFormStatus(false);
        } else {
          setFormStatus(true);
        }
      }
    }
  }, [
    answersList,
    weightage,
    topic,
    question,
    answersList?.length,
    correctAnswers?.length,
  ]);

  // enter answer into database with onBlur
  const addAnswers = async (index: number, e: any, uuid: string) => {
    setAnswerFormStatus(true);
    const answer = e;
    const list = [...answersList];
    const answerWithoutTag = answer?.replace(/(<([^>]+)>)/g, "");
    if (answerWithoutTag?.trim()?.length <= 0) {
      setAnswerFormStatus(true);
      setFormStatus(true);
      setSelectKey((prevKey) => prevKey + 1);
    } else {
      const map = {};
      let result = false;
      const answeroptionArr: any = [];
      for (let i = 0; i < answersList?.length; i++) {
        answeroptionArr.push(answersList[i]?.option && answersList[i]?.option);
        const value = answeroptionArr[i]?.toLowerCase();
        // check if object contains entry with this element as key
        if (map[value]) {
          result = true;
          // terminate the loop
          break;
        }
        // add entry in object with the element as key
        map[value] = true;
      }

      if (result) {
        setDuplicateMessage(true);
        setAnswerFormStatus(true);
        setFormStatus(true);
      } else {
        setDuplicateMessage(false);

        const response = await addAnswersList({
          answerId: uuid ? uuid : "",
          answer,
        });
        if (response?.data?.createAnswersList?.uuid) {
          list[index] = {
            uuid: response?.data?.createAnswersList?.uuid,
            option: response?.data?.createAnswersList?.option,
          };
          setAnswersList(list);
          setSelectKey((prevKey) => prevKey + 1);
          setCorrectAnswers([]);
          setAnswerFormStatus(false);
        }
      }
    }
  };

  const optionsData = answersList?.map((list: any) => {
    const val = list?.option;
    if (list?.uuid) {
      return {
        value: list?.uuid,
        label: val?.replace(/(<([^>]+)>)/g, ""), //replace html tags
      };
    } else
      return {
        value: "",
        label: "",
      };
  });

  const options = [
    {
      value: "Easy",
      label: "Easy",
    },
    {
      value: "Medium",
      label: "Medium",
    },
    {
      value: "Difficult",
      label: "Difficult",
    },
  ];

  const handleQuestionLevel = (newValue: any) => {
    setQuestionLevel(newValue);
  };
  return (
    <div className="p-5 pb-10">
      {/* question section start */}
      <p className="text-xl font-semibold font-primary">
        Create MCQ Question and answer.
      </p>
      <div className="grid grid-cols-3 gap-x-10 w-full pr-5">
        <div className="relative">
          <InputField
            id="Weightage"
            type="number"
            placeholder="Weightage"
            onChange={setWeightage}
            containsWeightageMsg={setWeightageErr}
          />
          <span className="text-red-500 mt-[6px] absolute">{weightageErr}</span>
        </div>
        <div className="relative">
          <InputField
            id="Topic Name"
            type="topic"
            placeholder="Topic"
            onChange={setTopic}
            containsSpecialCharsMsg={setTopicError}
          />
          <span className="text-red-500 mt-[6px] absolute">{topicError}</span>
        </div>
        <div className="relative">
          <SelectField
            label="select question level"
            options={options}
            showSearch
            placeholder="Select Question Level"
            onChange={(e) => handleQuestionLevel(e)}
          />
        </div>
      </div>
      <div className="mx-auto mt-6 pr-5">
        <div className="relative">
          <label htmlFor="Question">
            Question <span className="text-red-600">*</span>
          </label>

          <QuillEditor
            ref={quillRef}
            theme="snow"
            formats={formats}
            modules={modules}
            value={question}
            onChange={(e) => setQuestion(e)}
            placeholder="Enter Question"
            data-testid="question"
            className="quill-editor-question"
          />
        </div>
        <div className="grid grid-cols-2 gap-x-10 gap-y-5 mt-4">
          {answersList?.map((data, index: number) => {
            return (
              <div
                key={index}
                className="flex flex-row justify-between w-full items-center"
              >
                <div
                  className={`relative flex flex-col w-full  ${
                    index === 0 ? "mt-0" : "mt-0"
                  }`}
                >
                  <label
                    className="font-primary capitalize text-sm lg:text-base"
                    htmlFor="answerSelect"
                  >
                    Answer <span className="text-red-600">*</span>
                  </label>
                  <QuillEditor
                    theme="snow"
                    // style={{ height: "130px" }}
                    // ref={(el) => (quill.current = el)}
                    formats={answerFormats}
                    modules={answerModules}
                    value={data?.option ? data?.option : ""}
                    onChange={(event) => handleEditorChange(event, index)}
                    onBlur={(e) =>
                      addAnswers(
                        index,
                        data?.option,
                        data?.uuid ? data?.uuid : ""
                      )
                    }
                    placeholder="Answer"
                    className="quill-editor-answer"
                  />
                  {answersList.length > 1 ? (
                    <svg
                      onClick={() => removeInputFields(data, index)}
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="absolute right-[-25px] w-6 h-6 mt-10 cursor-pointer"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                      />
                    </svg>
                  ) : null}
                </div>
              </div>
            );
          })}
        </div>
        {duplicateMessage && (
          <p className="text-xs text-red-500 p-0 mb-0 mt-2">
            Answer option should be unique!
          </p>
        )}
        {showcorrectAnswer || answersList?.length >= 6 ? (
          ""
        ) : (
          <button
            disabled={answerFormStatus ? true : false}
            className={`${
              answerFormStatus
                ? "bg-gray-200  text-gray-400  cursor-not-allowed"
                : "bg-brand-500 text-white cursor-pointer"
            } w-32 text-center mt-5 rounded-lg font-primary  p-2`}
            onClick={() => addInputField()}
          >
            Add New
          </button>
        )}

        {answersList?.length > 0 ? (
          <Form.Item
            label="Correct Answer"
            name="CorrectAnswer"
            className="correctAnswer"
            rules={[
              {
                required: true,
                message: "Please Select correct answer.",
              },
            ]}
          >
            <Select
              key={selectKey}
              onChange={selectCorrectAnswer}
              mode="multiple"
              placeholder="Search / Select Correct answers"
              optionFilterProp="children"
              style={{
                width: "99%",
              }}
              filterOption={(input, option: any) =>
                (option?.label ?? "")?.includes(input)
              }
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? "")
                  ?.toLowerCase()
                  .localeCompare((optionB?.label ?? "")?.toLowerCase())
              }
              options={optionsData}
            />
          </Form.Item>
        ) : (
          ""
        )}

        {answersList?.length < 2 && (
          <div className="text-black mt-2 border-spacing-1 border-l-8 bg-orange-300 shadow-sm height-2 gap-2">
            <QuestionCircleOutlined />
            <span className="ml-2">
              Important: Answer options should be two or more than two
            </span>
          </div>
        )}

        <div className="mt-5">
          <PrimaryButton
            disabledStatus={formStatus ? true : false}
            type="submit"
            text="Save"
            onclick={() => onFinish()}
            className={`${
              formStatus
                ? "bg-gray-300 cursor-not-allowed border-gray-300"
                : "bg-black hover:bg-transparent hover:border-black border-black hover:text-black"
            }`}
          />
        </div>
        {/* )} */}
      </div>

      {/* question section end */}

      {apiDataLoader && (
        <div
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <Spin tip="Loading..."></Spin>
        </div>
      )}
    </div>
  );
};
