import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AssesmentDataTable } from "../../../../components/tables/AssesmentDataTable";
import { useParams } from "react-router";
import {
  getAssessments,
  getSkillNameBySkillId,
} from "../../../../serviceApi/api";
import { useAuth0 } from "@auth0/auth0-react";
import {
  getApprovedDomain,
  getDomainNameById,
} from "../../../../serviceApi/superAdminApi/DomainApi";
import { getCategoryNameById } from "../../../../serviceApi/superAdminApi/CategoryApi";
import { TableSkeleton } from "@ant-design/pro-components";
import { BreadcrumbComp } from "../../../../components/Breadcrumb/Breadcrumb";
import {
  getSkills,
  getUserValidateSkill,
} from "../../../../serviceApi/adminApi/SkillApi";

export const Assessments = () => {
  const { user }: any = useAuth0();
  const userId: string = user?.email;
  const navigate = useNavigate();
  const { domainId, categoryId, skillId }: any = useParams();
  const [assessmentList, setAssessmentList] = useState();
  const [name, setName] = useState();
  const [domainName, setDomainName] = React.useState();
  const [deleteStatus, setDeleteStatus] = React.useState(false);
  const [isresponse, setIsresponse] = React.useState(false);
  const [categoryName, setCategoryName] = React.useState();
  const [isAuthenticate, setIsAuthenticate] = React.useState(false);

  const getAssesmentsListBySkillId = async () => {
    if (userId) {
      const assessments = await getAssessments(skillId, userId);
      if (assessments?.data?.assessments?.length > 0) {
        setIsresponse(false);
        setAssessmentList(assessments?.data?.assessments);
        setDeleteStatus(false);
      } else {
        setAssessmentList(assessments?.data?.assessments);
        setIsresponse(false);
      }
    }
  };

  const skillNameBySkillId = async () => {
    const skillNameById = await getSkillNameBySkillId(skillId);
    if (skillNameById?.data?.skillNameById) {
      setName(skillNameById?.data?.skillNameById[0]?.name);
    }
  };
  const getUserValidateSkillApi = async () => {
    const isValidate = await getUserValidateSkill(userId, skillId);
    if (isValidate?.data?.skillValidateUser?.length > 0) {
      setIsresponse(false);
      getAssesmentsListBySkillId();
      skillNameBySkillId();
      setIsAuthenticate(true);
    } else {
      navigate("/404");
      setIsresponse(false);
    }
  };

  useEffect(() => {
    getUserValidateSkillApi();
  }, [userId, deleteStatus, skillId]);

  const breadcrumbData = [
    {
      path: "/subadmin/AllDomains/list",
      text: "Dashboard",
    },
    {
      text: "My Assessments",
    },
  ];

  return (
    <>
      {isAuthenticate && (
        <div className="w-full mt-2 max-h-[86vh] overflow-y-auto lg:p-10 p-5">
          <div className="bg-white lg:hidden md:hidden shadow-xl justify-center mt-32 m-3 text-center">
            <h1 className="text-lg text-black font-primary mb-2">
              Your device is not compatible for it. Please login on large device
              (e.g Tablet or laptop).
            </h1>
            <Link
              to="/subadmin"
              className="bg-brand-500 mt-2 px-10 font-primary hover:bg-brand-600 hover:text-white  text-white font-semibold p-2 rounded-md"
            >
              Back
            </Link>
          </div>
          <div className="mb-2">
            <p className="text-2xl capitalize font-primary text-black font-semibold  my-0">
              Assessment List
            </p>
            <BreadcrumbComp breadcrumbData={breadcrumbData} />
          </div>
          <div className="flex flex-row justify-between relative">
            <div className="flex items-start flex-col mt-5  h-26 p-6 bg-white border border-gray-200 shadow-md w-full ">
              <div className="py-2 flex lg:flex-no-wrap flex-wrap lg:flex-row justify-between items-center w-full">
                <p className="text-lg font-primary capitalize text-brand-500 font-semibold  my-3 lg:my-0">
                  {" "}
                  <span className="text-black">Skill Name : </span>
                  {name}
                </p>
              </div>
              <hr className="mb-5 mt-3 w-full bg-black text-black" />
              <Link
                to={`/subadmin/domain/skills/${skillId}/assessment/create`}
                className="bg-brand-500 flex items-center  font-primary hover:bg-black-600 hover:text-white  text-white  px-5 py-2  rounded-full"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={2}
                  stroke="currentColor"
                  className="w-4 mr-1 h-4 text-white"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 4.5v15m7.5-7.5h-15"
                  />
                </svg>
                Create New Assessment
              </Link>
              <div className="w-full max-h-[86vh] overflow-y-auto lg:p-10 p-5">
                {isresponse ? (
                  <TableSkeleton active={true} />
                ) : (
                  <AssesmentDataTable
                    domainId={domainId}
                    categoryId={categoryId}
                    skillId={skillId}
                    assessmentList={assessmentList}
                    deleteStatus={setDeleteStatus}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
