import React, { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router";
import date from "date-and-time";
import { useAuth0 } from "@auth0/auth0-react";

import { CardSkeletonLoader } from "../../../../components/skeleton/CardSkeleton";
import PaginationData from "../../../../components/pagination/Pagination";
import { BreadcrumbComp } from "../../../../components/Breadcrumb/Breadcrumb";
import {
  getEventPrivateAssessment,
  getEvents,
} from "../../../../serviceApi/adminApi/EventApi";
import { PrivateAssessmentCard } from "../studentCards/PrivateAssessmentCard";
import { Link } from "react-router-dom";
import { EditButton } from "../../../../components/buttons/EditButton";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { AssessmentCard } from "../studentCards/AssessmentCard";
import { EventCard } from "./EventCard";

export const MyEvents = () => {
  const { user }: any = useAuth0();
  const userId = user?.email;

  const dateFormat = Date.now();
  const [events, setEvents] = useState([]);
  const [, setActive] = useState(false);
  const [dateToday, setDate] = useState<any>();
  const [, setInputValue] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [paginatedData, setPaginatedData] = useState([]);
  const [getSearch, setGetSearch] = useState(true);
  const [isSearch, setIsSearch] = useState(false);
  const [input, setInput] = useState("");
  const [addResponse, setAddResponse] = useState(false);
  const getRegisteredEvent = async () => {
    if (userId) {
      const res = await getEvents(userId);
      setTimeout(() => {
        if (res?.data?.getEvents?.length > 0) {
          setIsLoading(false);
          setEvents(res?.data?.getEvents);
          setDate(dateFormat);
        } else {
          setIsLoading(false);
        }
      }, 1000);
    }
  };

  const getSkillId = async (event) => {
    setInputValue(event.target.value);
  };

  const getSearchContent = (data: any, getClick) => {
    if (data?.length > 0 && typeof data != "string") {
      if (getClick) {
        setGetSearch(true);
        setPaginatedData(data);
        setIsSearch(true);
        setActive(true);
        setEvents(data);
      } else {
        setPaginatedData(data);
        setGetSearch(true);
        setIsSearch(!isSearch);
        setEvents(data);
      }
    } else if (data === "value not filled" && typeof data === "string") {
      getRegisteredEvent();
      setPaginatedData([]);
      setGetSearch(true);
      setIsSearch(false);
      setInput("");
    } else if (data === "not found data" && typeof data === "string") {
      setPaginatedData([]);
      setGetSearch(false);
      setIsSearch(!isSearch);
      getRegisteredEvent();
    }
  };
  useEffect(() => {
    getRegisteredEvent();
  }, [userId, addResponse]);

  const studentbreadcrumbData = [
    {
      path: "/student/dashboard",
      text: "Dashboard",
    },
    {
      text: "All Events",
    },
  ];
  return (
    <>
      <div className="w-full max-h-[86vh] overflow-y-auto lg:p-10 p-5">
        <h1 className="text-2xl pb-0 mb-0  font-primary  capitalize text-black-500 font-semibold">
          All Events
        </h1>
        <div className="mb-4">
          <BreadcrumbComp breadcrumbData={studentbreadcrumbData} />
        </div>
        {/* filer added */}
        <div className="mb-5 flex flex-row items-center justify-between gap-x-4 ml-auto lg:w-80 w-full">
          {/* {assessmentList?.length > 0 && (
            <> */}
          {/* <StudentDataFilter /> */}
          {/* <SearchFilterInput
                SearchContent={getSearchContent}
                data={assessmentList}
                userId={userId}
                type="allAssessment"
                domainId=""
                name={setInput}
              /> */}
          {/* </>
          )} */}
        </div>

        <div className="border-t pt-5 grid lg:grid-cols-4 sm:grid-cols-2 grid-cols-1 gap-5 lg:gap-10 pb-10">
          {isLoading ? (
            <>
              <CardSkeletonLoader />
              <CardSkeletonLoader />
              <CardSkeletonLoader />
              <CardSkeletonLoader />
              <CardSkeletonLoader />
              <CardSkeletonLoader />
              <CardSkeletonLoader />
              <CardSkeletonLoader />
            </>
          ) : events && events.length > 0 ? (
            events.map((data: any, i) => {
              var date = new Date(Number(data?.eventEndDate)).toUTCString();

              var endDate = new Date(date.split("GMT")[0]).getTime();
              var utcDate = new Date(
                Number(data?.eventStartDate)
              ).toUTCString();

              var startDate = new Date(utcDate.split("GMT")[0]).getTime();
              const expiryCondition =
                dateToday > data?.eventEndDate
                  ? true
                  : dateToday <= data?.eventStartDate
                  ? true
                  : false;

              return (
                <Fragment key={i}>
                  <div className="  bg-white rounded-xl">
                    {events?.length > 0 ? (
                      events?.map((item: any, index: number) => {
                        return (
                          <EventCard item={item} key={item?.uuid} />
                        );
                      })
                    ) : (
                      <div className="mb-10  flex flex-col lg:flex-col lg:space-x-5 h-full mx-auto bg-white">
                        No Events created
                      </div>
                    )}
                  </div>
                </Fragment>
              );
            })
          ) : (
            <div className=" bg-white shadow-lg p-5 max-w-2xl mx-auto">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 256 256"
                className="text-center text-green-600 h-24 w-24 mx-auto"
              >
                <rect width="256" height="256" fill="none"></rect>
                <circle
                  cx="128"
                  cy="128"
                  r="96"
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="16"
                ></circle>
                <circle cx="92" cy="108" r="12"></circle>
                <circle cx="164" cy="108" r="12"></circle>
                <path
                  d="M169.6,152a48.1,48.1,0,0,1-83.2,0"
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="16"
                ></path>
              </svg>
              <p className="text-xl font-semibold font-primary text-center">
                There are no registered event.
              </p>
            </div>
          )}
        </div>
        {/* {paginatedData && getSearch && (
          <div
            className={`${
              assessmentList.length >= 8
                ? paginatedData.length >= 5
                  ? "mb-2"
                  : "absolute left-0 right-0 bottom-20 mb-2"
                : "mx-auto hidden"
            }`}
          >
            <PaginationData
              data={assessmentList}
              paginatedData={setPaginatedData}
              userId={userId}
              getSearchActive={isSearch}
              type="allAssessment"
              domainId=""
              name={input}
            />
          </div>
        )} */}
      </div>
    </>
  );
};
