import React from "react";

import ReactDOM from "react-dom";
import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Auth0Provider } from "@auth0/auth0-react";
import LoginContext from "./components/context/LoginContext";

const client = new ApolloClient({
  uri: `${process.env.REACT_APP_AUTH_AUDIENCE}`,
  cache: new InMemoryCache(),
});

// dotenv.config({ path: "../.env" });
// const client = new ApolloClient({
//   uri: 'https://api.skillcef.smartedgetechsolutions.com.au/',
//   cache: new InMemoryCache()
// });

ReactDOM.render(
  <React.StrictMode>
    <Auth0Provider
      domain={`${process.env.REACT_APP_AUTH_DOMAIN}`}
      clientId={`${process.env.REACT_APP_AUTH_CLIENT_ID}`}
      redirectUri={`${process.env.REACT_APP_AUTH_REDIRECT_URL}`}
      cacheLocation="localstorage"
      referrerId="skill@123"
      // useRefreshTokens={true}
      //audience="https://dev-lr73hsue.us.auth0.com/api/v2/"
      audience={`${process.env.REACT_APP_AUTH_AUDIENCE}`}
    >
      <ApolloProvider client={client}>
        <LoginContext>
          <App />
        </LoginContext>
      </ApolloProvider>
    </Auth0Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
