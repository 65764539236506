export const message = {
    resultStatus: [
      "Unsatisfactory,Needs Improvement",
      "Satisfactory",
      "Very Good",
      "Excellent",
      "Outstanding",
    ],
    assessmentBadgePercent:60
  };
  