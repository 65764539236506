import React from "react";

export const LoginButton = () => {
  return (
    <>
      <button name="login" className="bg-[#FF561E] focus:outline-none hover:bg-[#f5450b]  text-white px-14 py-2 text-center uppercase rounded-full">
          Login
      </button>
    </>
  );
};
