import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useState } from "react";
import { DomainCard } from "../../../../../components/card/DomainCard";
import { getDomains } from "../../../../../serviceApi/superAdminApi/DomainApi";
import { CardSkeletonLoader } from "../../../../../components/skeleton/CardSkeleton";
import { SearchFilterInput } from "../../../../../components/search/SearchInput";
import { BreadcrumbComp } from "../../../../../components/Breadcrumb/Breadcrumb";
import PaginationData from "../../../../../components/pagination/Pagination";

interface data {
  description: string;
  domain_Amount: string;
  domain_type: string;
  id: number | string;
  image: string;
  message: string;
  name: string;
  status: string;
  userId: number;
  uuid: string;
}

// Define an interface for the array of objects

const AllDomains = () => {
  const { user } = useAuth0();
  const userId: string | undefined = user?.email as string;
  const [domains, setDomains] = React.useState<data[]>([]);
  const [response, setRespnose] = React.useState(false);
  const [activate, setActivate] = React.useState<boolean>();
  const [paginatedData, setPaginatedData] = useState<data[]>([]);
  const [getSearch, setGetSearch] = useState(true);
  const [isSearch, setIsSearch] = useState(false);
  const [input, setInput] = useState("");
  const getDomainsList = async (userId: string) => {
    const response = await getDomains(userId);
    if (response?.data?.domains) {
      setDomains(response?.data?.domains);
      setRespnose(true);
      setActivate(false);
    } else {
      setRespnose(true);
    }
  };

  const getSearchContent = (data: data[] | string, getClick) => {
    if (data?.length > 0 && typeof data != "string") {
      if (getClick) {
        setGetSearch(true);
        setPaginatedData(data);
        setIsSearch(true);
        setDomains(data);
      } else {
        setGetSearch(true);
        setIsSearch(!isSearch);
        setPaginatedData(data);
        setDomains(data);
      }
    } else if (data == "value not filled" && typeof data == "string") {
      setGetSearch(true);
      getDomainsList(userId);
      setPaginatedData([]);
      setIsSearch(false);
      setInput("");
    } else if (data == "not found data" && typeof data == "string") {
      setGetSearch(true);
      setPaginatedData([]);
      setIsSearch(!isSearch);
      getDomainsList(userId);
    }
  };

  useEffect(() => {
    getDomainsList(userId);
  }, [activate]);

  const breadcrumbData = [
    {
      path: "/subadmin/AllDomains/list",
      text: "Dashboard",
    },
    {
      path: "/subadmin/AllDomains/list",
      text: "Domains",
    },
  ];

  return (
    <>
      <section>
        <div className="w-full mt-8 max-h-[86vh] overflow-y-auto lg:p-10 p-5">
          <p className="text-2xl mb-0 pb-0 font-primary font-medium text-gray-800">
            All Domains
          </p>
          <div className="mb-4">
            <BreadcrumbComp breadcrumbData={breadcrumbData} />
          </div>
          <div className=" mb-5 ml-auto lg:w-64 w-full">
            {domains?.length > 0 && (
              <SearchFilterInput
                SearchContent={getSearchContent}
                data={domains}
                userId={userId}
                type="Domain"
                domainId=""
                name={setInput}
              />
            )}
          </div>
          <div className="border-t pt-5 grid lg:grid-cols-4 sm:grid-cols-2 grid-cols-1 gap-5 lg:gap-10 pb-10">
            {response ? (
              paginatedData && paginatedData?.length > 0 ? (
                paginatedData.map((item, i: number) => {
                  return (
                    <DomainCard
                      status={item?.status}
                      CardHeight="h-48"
                      key={i}
                      uuid={item?.uuid}
                      image={item?.image ? item.image : ""}
                      title={item?.name}
                      description={item?.description}
                      role="Admin"
                      reqStatus={setActivate}
                    />
                  );
                })
              ) : (
                <div className="shadow-md flex  flex-row items-center justify-center h-32 w-60 border-gray-300 rounded-md">
                  <h1
                    className="font-
                   justify-center items-center text-black text-sm "
                  >
                    No Domains Found
                  </h1>
                </div>
              )
            ) : (
              <>
                <CardSkeletonLoader />
                <CardSkeletonLoader />
                <CardSkeletonLoader />
                <CardSkeletonLoader />
                <CardSkeletonLoader />
                <CardSkeletonLoader />
                <CardSkeletonLoader />
                <CardSkeletonLoader />
              </>
            )}
          </div>
          {paginatedData && getSearch && (
            <div
              className={`${
                domains?.length >= 8
                  ? paginatedData?.length >= 5
                    ? "mb-2"
                    : "absolute left-0 right-0 bottom-20 mb-2"
                  : " hidden"
              }`}
            >
              <PaginationData
                data={domains}
                paginatedData={setPaginatedData}
                userId={userId}
                getSearchActive={isSearch}
                type="Domain"
                domainId=""
                name={input}
              />
            </div>
          )}
        </div>
      </section>
    </>
  );
};

export default AllDomains;
