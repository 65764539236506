import React, { useEffect } from "react";
import { DashboardHeader } from "../components/header/AuthenticateHeader";
import { DashboardFooter } from "../components/footer/AuthenticateFooter";
import { LeftNavbar } from "../components/leftNavbar/LeftNavbar";
import { Outlet, useNavigate } from "react-router-dom";
import { getCookie } from "../utils";
import GoBackbutton from "../components/buttons/GoBackbutton";
export const DashboardLayout = () => {
  const navigate = useNavigate();
  const token = getCookie("token");

  useEffect(() => {
    if (!token) {
      // window.history.pushState(
      //   { prevUrl: window.location.href },
      //   window.location.pathname
      // );
      navigate("/");
    }
  }, [token]);
  return (
    <>
      {token && (
        <>
          <div className="flex flex-row justify-between">
            <div className="lg:pl-4 lg:pr-0  border-r pl-4 sm:w-1/4 lg:w-80 sm:block md:block lg:block hidden">
              <LeftNavbar />
            </div>
            <div className="w-full">
              <DashboardHeader />
              <GoBackbutton />
              <main className=" lg:w-full border-l w-full bg-[#FBFEFF] max-h-full overflow-y-auto">
                <Outlet />
              </main>
            </div>
          </div>
          <DashboardFooter />
        </>
      )}
    </>
  );
};
