import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { QuestionsDataTable } from "../../../../../components/tables/QuestionsDataTable";
import { useParams } from "react-router";
import {
  getAssessmentNameById,
  getQuestions,
  getSkillNameBySkillId,
} from "../../../../../serviceApi/api";
import {
  getApprovedDomain,
  getDomainNameById,
} from "../../../../../serviceApi/superAdminApi/DomainApi";
import { getCategoryNameById } from "../../../../../serviceApi/superAdminApi/CategoryApi";
import { TableSkeleton } from "@ant-design/pro-components";
import { BreadcrumbComp } from "../../../../../components/Breadcrumb/Breadcrumb";
import {
  getSkills,
  getUserValidateSkill,
} from "../../../../../serviceApi/adminApi/SkillApi";
import { useAuth0 } from "@auth0/auth0-react";

export const QuestionList = () => {
  const navigate = useNavigate();
  const { user } = useAuth0();
  const userId: string = user?.email as string;
  const { domainId, categoryId, skillId, assessmentId }: any = useParams();
  const [name, setName] = React.useState();
  const [questionList, setQuestionList] = React.useState();
  const [domainName, setDomainName] = React.useState();
  const [categoryName, setCategoryName] = React.useState();
  const [skillName, setSkillName] = React.useState();
  const [fetchDeleteData, setFetchDeleteData] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(true);
  const [isAuthenticate, setIsAuthenticate] = React.useState(false);
  const getAssessmentName = async () => {
    const response = await getAssessmentNameById(assessmentId);
    setName(response?.data.assessmentNameById[0]?.name);
    setIsLoading(false);
  };
  const getQuestionsListByAssessmentId = async () => {
    const questions = await getQuestions(assessmentId);
    if (questions?.data?.questions) {
      setQuestionList(JSON.parse(questions?.data?.questions[0]?.question));
    }
  };
  const getDomainName = async () => {
    const response = await getDomainNameById(domainId);
    setDomainName(response?.data.domainNameById[0]?.name);
  };
  const getCategoryName = async () => {
    const response = await getCategoryNameById(categoryId);
    setCategoryName(response?.data.categoryNameById[0]?.name);
  };
  const getSkillName = async () => {
    const response = await getSkillNameBySkillId(skillId);
    setSkillName(response?.data.skillNameById[0].name);
  };
  const fetchDataAfterDelete = (data: any) => {
    setFetchDeleteData(data);
    getQuestionsListByAssessmentId();
  };

  const breadcrumbData = [
    {
      path: "/subadmin/AllDomains/list",
      text: "Dashboard",
    },
    { text: "skill" },
    {
      path: `/subadmin/skills/list`,
      text: skillName,
    },
    { text: "assessment" },
    {
      path: `/subadmin/domain/skill/${skillId}/assessment/list`,
      text: name,
    },
  ];
  const getUserValidateSkillApi = async () => {
    try {
      const isValidate = await getUserValidateSkill(userId, skillId);
      if (isValidate?.data?.skillValidateUser.length > 0) {
        setIsAuthenticate(true);
        getAssessmentName();
        getQuestionsListByAssessmentId();
        getSkillName();
      } else {
        setIsLoading(true);
        setIsAuthenticate(false);
        navigate("/404");
      }
    } catch (error: any) {
      throw new Error(error);
    }
  };

  useEffect(() => {
    getUserValidateSkillApi();
  }, [fetchDeleteData]);

  return (
    <>
      {isAuthenticate && (
        <div className="w-full mt-5 max-h-[86vh] overflow-y-auto lg:p-10 p-5">
          <div className="mb-4">
            <p className="text-2xl mb-0 font-primary capitalize text-black font-semibold">
              Questions List
            </p>
            <BreadcrumbComp breadcrumbData={breadcrumbData} />
          </div>
          <div className="flex flex-row justify-between relative ...">
            {/*2nd Statistics */}
            <div className="w-full flex items-start flex-col mt-5 bg-white border border-gray-200 shadow-md ">
              <p className="text-xl capitalize font-primary text-brand-500 font-normal  my-3 lg:my-2 p-6">
                <span className="text-black font-primary">
                  Assessment Name -{" "}
                </span>
                {name}
              </p>
              <div className=" w-full border-t  pt-4 p-6">
                <div className="py-6 w-full flex lg:flex-no-wrap flex-wrap lg:flex-row justify-between items-center">
                  <Link
                    to={`/subadmin/domain/skill/${skillId}/assessment/${assessmentId}/question/create`}
                    className="bg-brand-500 flex items-center hover:bg-brand-600 font-primary hover:text-white text-white font-semibold py-2 px-5 rounded-full"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={2}
                      stroke="currentColor"
                      className="w-4 mr-1 h-4 text-white"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 4.5v15m7.5-7.5h-15"
                      />
                    </svg>{" "}
                    Create New Question
                  </Link>
                </div>
                <div className="w-full max-h-[86vh] overflow-y-auto lg:p-10 p-5">
                  {isLoading ? (
                    <TableSkeleton active={true} />
                  ) : (
                    <QuestionsDataTable
                      domainId={domainId}
                      categoryId={categoryId}
                      skillId={skillId}
                      assessmentId={assessmentId}
                      questionList={questionList}
                      confirm={fetchDataAfterDelete}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
