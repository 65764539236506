import React, { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router";
import date from "date-and-time";
import { useAuth0 } from "@auth0/auth0-react";
import {
  createfavouriteAssessment,
  getAssessments,
  getSkillNameBySkillId,
} from "../../../../serviceApi/api";
import { AssessmentCard } from "../studentCards/AssessmentCard";
import { getDomainNameById } from "../../../../serviceApi/superAdminApi/DomainApi";
import { CardSkeletonLoader } from "../../../../components/skeleton/CardSkeleton";
import { getCategoryNameById } from "../../../../serviceApi/superAdminApi/CategoryApi";
import { SearchFilterInput } from "../../../../components/search/SearchInput";
import PaginationData from "../../../../components/pagination/Pagination";
import { BreadcrumbComp } from "../../../../components/Breadcrumb/Breadcrumb";
import { favassessmentDelete } from "../../../../serviceApi/adminApi/AssessmentApi";

interface FavouriteAssessmentsInput {
  assessmentId: string;
  userId: string;
}

export const AssessmentBySkill = () => {
  const { user }: any = useAuth0();
  const userId = user?.email;
  const { domainId, categoryId, skillId } = useParams();
  const currentDate = new Date();
  const dateFormat: any = date.format(currentDate, "YYYY-MM-DD");
  const [assessmentList, setAssessmentList] = useState([]);
  const [, setActive] = useState(false);
  const [name, setName] = useState();
  const [domainName, setDomainName] = React.useState();
  const [categoryName, setCategoryName] = React.useState();
  const [dateToday, setDate] = useState<any>();
  const [, setInputValue] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [paginatedData, setPaginatedData] = useState([]);
  const [getSearch, setGetSearch] = useState(true);
  const [isSearch, setIsSearch] = useState(false);
  const [input, setInput] = useState("");
  const [addResponse, setAddResponse] = useState(false);

  const getAssesmentsListBySkillId = async () => {
    if (userId) {
      const assessments = await getAssessments(skillId, userId);
      setTimeout(() => {
        if (assessments?.data?.assessments) {
          setIsLoading(false);
          setAssessmentList(assessments?.data?.assessments);
          setDate(dateFormat);
        }
      }, 1000);
    }
  };

  const getSkillId = async (event) => {
    setInputValue(event.target.value);
  };

  const getDomainName = async () => {
    const response = await getDomainNameById(domainId);
    setDomainName(response?.data.domainNameById[0].name);
  };
  const getCategoryName = async () => {
    const response = await getCategoryNameById(categoryId);
    setCategoryName(response?.data.categoryNameById[0].name);
  };

  const SkillNameBySkillId = async () => {
    const skillNameById = await getSkillNameBySkillId(skillId);

    if (skillNameById?.data?.skillNameById) {
      setName(skillNameById?.data?.skillNameById[0]?.name);
    }
  };

  const getSearchContent = (data: any, getClick) => {
    if (data?.length > 0 && typeof data != "string") {
      if (getClick) {
        setGetSearch(true);
        setPaginatedData(data);
        setIsSearch(true);
        setActive(true);
        setAssessmentList(data);
      } else {
        setPaginatedData(data);
        setGetSearch(true);
        setIsSearch(!isSearch);
        setAssessmentList(data);
      }
    } else if (data === "value not filled" && typeof data === "string") {
      getAssesmentsListBySkillId();
      setPaginatedData([]);
      setGetSearch(true);
      setIsSearch(false);
      setInput("");
    } else if (data === "not found data" && typeof data === "string") {
      setPaginatedData([]);
      setGetSearch(false);
      setIsSearch(!isSearch);
      getAssesmentsListBySkillId();
    }
  };
  useEffect(() => {
    getAssesmentsListBySkillId();
    SkillNameBySkillId();
    // getDomainName();
    // getCategoryName();
  }, [userId, addResponse]);

  const studentbreadcrumbData = [
    {
      path: "/student/dashboard",
      text: "Dashboard",
    },
    { text: "skill" },
    {
      path: `/student/assesment/skill`,
      text: name,
    },
    {
      text: "Assessments",
    },
  ];

  const addFavourites = async (uuid) => {
    const data: FavouriteAssessmentsInput = {
      userId,
      assessmentId: uuid,
    };
    const result = await createfavouriteAssessment(data);
    if (result?.data?.createfavouriteAssessment?.uuid) {
      getAssesmentsListBySkillId();
    }
  };
  const removeFavourites = async (uuid) => {
    const result = await favassessmentDelete(uuid, userId);
    getAssesmentsListBySkillId();
  };

  return (
    <>
      <div className="w-full max-h-[86vh] overflow-y-auto lg:p-10 p-5">
        {/* <section className="lg:p-5"> */}
        {/* <div className="px-4 py-5 h-full  mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-2xl md:px-5 lg:px-0"> */}
        <h1 className="text-2xl pb-0 mb-0  font-primary  capitalize text-black-500 font-semibold">
          Select Assessment to continue
        </h1>
        <div className="mb-4">
          <BreadcrumbComp breadcrumbData={studentbreadcrumbData} />
        </div>
        <div className="mb-5 ml-auto lg:w-64 w-full">
          {assessmentList?.length > 0 && (
            <SearchFilterInput
              SearchContent={getSearchContent}
              data={assessmentList}
              userId={userId}
              type="Assessment"
              domainId={skillId}
              name={setInput}
            />
          )}
        </div>

        <div className="border-t pt-5 grid lg:grid-cols-4 sm:grid-cols-2 grid-cols-1 gap-5 lg:gap-10 pb-10">
          {isLoading ? (
            <>
              <CardSkeletonLoader />
              <CardSkeletonLoader />
              <CardSkeletonLoader />
              <CardSkeletonLoader />
              <CardSkeletonLoader />
              <CardSkeletonLoader />
              <CardSkeletonLoader />
              <CardSkeletonLoader />
            </>
          ) : paginatedData && paginatedData.length > 0 ? (
            paginatedData.map((data: any, i) => {
              const expiryCondition =
                data.expire < dateToday || data.expire == dateToday;
              // const remainDate = data.expire - dateToday;
              //  console.log(remainDate, "check remain date");

              return (
                // <Link
                //   key={i}
                //   to={`${
                //     data.expire < dateToday || data.expire == dateToday
                //       ? ""
                //       : `/student/assesment/domain/${domainId}/category/${categoryId}/skill/${skillId}/assessment/${data.uuid}/user/${userId}/exam/instruction`
                //   }`}
                // >
                <Fragment key={i}>
                  <AssessmentCard
                    route={`${
                      data.expire < dateToday || data.expire == dateToday
                        ? ""
                        : `/student/assesment/${data.uuid}/exam/instruction`
                    }`}
                    active={setActive}
                    days={data.expire}
                    expire={true}
                    todayDate={dateToday}
                    id={data?.uuid}
                    image={data.image}
                    description={data.description}
                    url={data.url}
                    user={userId}
                    admin={data?.userId}
                    type="radio"
                    onChange={getSkillId}
                    header={data.name}
                    value={data.uuid}
                    status={data?.status}
                    assessmentId={data?.assessmentId}
                    addResponse={setAddResponse}
                    removeFav={(e) => {
                      removeFavourites(data?.uuid);
                    }}
                    addFav={() => {
                      addFavourites(data?.uuid);
                    }}
                    name="default-radio"
                    className="w-6 h-6  appearance-none"
                  />
                </Fragment>
              );
            })
          ) : (
            <div className=" bg-white shadow-lg p-5 max-w-2xl mx-auto">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 256 256"
                className="text-center text-green-600 h-24 w-24 mx-auto"
              >
                <rect width="256" height="256" fill="none"></rect>
                <circle
                  cx="128"
                  cy="128"
                  r="96"
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="16"
                ></circle>
                <circle cx="92" cy="108" r="12"></circle>
                <circle cx="164" cy="108" r="12"></circle>
                <path
                  d="M169.6,152a48.1,48.1,0,0,1-83.2,0"
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="16"
                ></path>
              </svg>
              <p className="text-xl font-semibold font-primary text-center">
                There are no assessment Created.
              </p>
            </div>
          )}
        </div>
        {/* </div> */}
        {paginatedData && getSearch && (
          <div
            className={`${
              assessmentList.length >= 8
                ? paginatedData.length >= 8
                  ? "mb-2"
                  : "absolute left-0 right-0 bottom-20 mb-2"
                : "mx-auto hidden"
            }`}
          >
            <PaginationData
              data={assessmentList}
              paginatedData={setPaginatedData}
              userId={userId}
              getSearchActive={isSearch}
              type="Assessment"
              domainId={skillId}
              name={input}
            />
          </div>
        )}
        {/* </section> */}
      </div>
    </>
  );
};
