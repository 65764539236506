import React, { useEffect, useState } from "react";
import { InputField } from "../../../../../components/inputFields/InputField";
import { PrimaryButton } from "../../../../../components/buttons/PrimaryButton";
import { QuestionEditor } from "./QuestionEditor";
import { Spin } from "antd";
import axios from "axios";
import {
  deleteQuestion,
  questionAnswerCreate,
} from "../../../../../serviceApi/api";
import { getCookie } from "../../../../../utils";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { nameInputValid } from "../../../../../utils/validation";
import SelectField from "../../../../../components/selectField/SelectField";
interface assessmentType {
  assessmentIdForQuestion: string | undefined;
}
export const CodingQuestions = (props: assessmentType) => {
  const navigate = useNavigate();
  const { skillId, assessmentId } = useParams();
  const [content, setContent] = useState("");
  const [weightage, setWeightage] = useState("");
  const [topic, setTopic] = useState("");
  const [answerSelectedFiles, setAnswerSelectedFiles] = useState<any>(null);
  const [topicError, setTopicError] = useState("");
  const [weightageErr, setWeightageErr] = useState("");
  const [formStatus, setFormStatus] = useState(true);
  const [testCaseName, setTestcaseName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [questionLevel, setQuestionLevel] = useState("");
  const deleteTestcase = () => {
    setAnswerSelectedFiles(null);
  };
  const handleData = (e: any) => {
    e.preventDefault();
    const answerSelectedFiles = e.target.files[0];

    if (
      answerSelectedFiles.type ==
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
      answerSelectedFiles.type == "application/wps-office.xlsx"
    ) {
      setAnswerSelectedFiles(e?.target?.files[0]);
      setTestcaseName(answerSelectedFiles?.name);
    } else {
      Swal.fire({
        icon: "warning",
        title: "file type should be xlsx",
        showConfirmButton: false,
        timer: 3000,
      });
    }
  };

  const onFinish = async () => {
    setFormStatus(true);
    setIsLoading(true);
    const response = await questionAnswerCreate({
      assessmentId: props?.assessmentIdForQuestion,
      question: content,
      weightage,
      answers: [],
      CorrectAnswer: ["true"],
      topic,
      questionId: "",
      questionType: "5",
      action: "create",
      questionLevel,
    });
    if (response?.data.createQuestionAnswer?.id) {
      const formData = new FormData();
      formData.append("image", answerSelectedFiles);
      formData.append("uuid", response?.data.createQuestionAnswer?.uuid);
      formData.append("type", "coding");
      const { data }: any = await axios.post(
        `${process.env.REACT_APP_MEDIA_URL}/api/media/upload`,
        formData,
        {
          headers: {
            enctype: "multipart/form-data",
            Authorization: `Bearer ${getCookie("token")}`,
          },
        }
      );

      if (data?.status == true) {
        setIsLoading(false);
        navigate(
          `/subadmin/domain/skill/${skillId}/assessment/${assessmentId}/questions/list`
        );
      } else {
        Swal.fire({
          icon: "warning",
          title: data?.error + " Or Empty file",
          showConfirmButton: false,
          timer: 3000,
        });
        setIsLoading(false);
        await deleteQuestion(
          response?.data.createQuestionAnswer?.uuid,
          "question"
        );
      }
    }
  };

  useEffect(() => {
    const wordCount = countWords(content);
    if (wordCount) {
      setFormStatus(true);
    } else {
      if (
        content?.trim()?.length > 0 &&
        Number(weightage) <= 10 &&
        weightage &&
        nameInputValid(topic) &&
        topic?.trim()?.length <= 60 &&
        topic?.trim()?.length >= 2 &&
        topic &&
        answerSelectedFiles
      ) {
        setFormStatus(false);
      } else setFormStatus(true);
    }
  }, [weightage, topic, content, answerSelectedFiles]);

  const countWords = (text) => {
    // Remove HTML tags and count words
    const plainText = text.replace(/<[^>]*>/g, "");
    const words = plainText.match(/\b\w+\b/g);
    return words?.every((word) => word == "nbsp");
  };

  const options = [
    {
      value: "Easy",
      label: "Easy",
    },
    {
      value: "Medium",
      label: "Medium",
    },
    {
      value: "Difficult",
      label: "Difficult",
    },
  ];

  const handleQuestionLevel = (newValue: any) => {
    console.log("newValue", newValue);
    setQuestionLevel(newValue);
  };

  return (
    <div className="mb-10 px-5 relative flex flex-col lg:flex-col h-full mx-auto">
      {/* basic info */}
      <div className="pb-5 grid grid-cols-3 gap-x-10">
        <div className="relative">
          <InputField
            id="Weightage"
            placeholder="Weightage"
            onChange={setWeightage}
            containsWeightageMsg={setWeightageErr}
            type="number"
          />
          <span className="text-red-500 mt-[6px] absolute">{weightageErr}</span>
        </div>
        <div className="relative">
          <InputField
            id="Topic Name"
            placeholder="Topic"
            type="topic"
            containsSpecialCharsMsg={setTopicError}
            onChange={setTopic}
          />
          <span className="text-red-500  mt-[6px] absolute">{topicError}</span>
        </div>
        <div className="relative">
          <SelectField
            label="select question level"
            options={options}
            placeholder="Select Question Level"
            onChange={(e) => handleQuestionLevel(e)}
            value={questionLevel}
          />
          {/* <span className="text-red-500 mt-[6px] absolute">{topicError}</span> */}
        </div>
      </div>

      <h1 className="font-primary text-base font-normal mt-2 lg:2">
        Algorithmic Question Description <span className="text-red-500">*</span>
      </h1>
      <div>
        <QuestionEditor contentData={content} setContentData={setContent} />
      </div>
      {/* input and output form field start  */}
      <p className="font-primary mt-5 capitalize text-sm lg:text-base">
        Upload Testcase <span className="text-red-600">*</span>
      </p>
      {answerSelectedFiles ? (
        <div className="h-44 border relative  mb-10 w-44">
          <img
            className="h-full w-full p-10 rounded-lg object-cover object-center"
            src="/Assets/xlsx_logo.png"
            alt="/Assets/xlsx_logo.png"
          />
          <p className="text-center text-sm font-bold mt-2">{testCaseName}</p>
          <div
            onClick={deleteTestcase}
            className="bg-black cursor-pointer hover:bg-red-600 w-8 h-8 flex items-center justify-center absolute top-1 right-1 rounded-full"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-5 h-5 mx-auto text-white"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
              />
            </svg>
          </div>
        </div>
      ) : (
        <div className="w-44 h-44 flex items-center justify-center">
          <label
            data-testid="testFileId"
            htmlFor="dropzone-file"
            className={`flex flex-col cursor-pointer items-center justify-center w-full h-48 border-2 border-gray-300 border-dashed rounded-lg   bg-gray-50  hover:bg-gray-100`}
          >
            <div className="flex flex-col items-center justify-center pt-5 pb-6">
              <svg
                aria-hidden="true"
                className="w-10 h-10 mb-3 text-gray-400"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                ></path>
              </svg>
              <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                <span className="font-semibold">Click to upload</span>
              </p>
              <p className="text-xs text-center text-gray-500 dark:text-gray-400">
                File formate XLSX
              </p>
            </div>
            <input
              id="dropzone-file"
              type="file"
              onChange={handleData}
              className="hidden"
            />
          </label>
        </div>
      )}
      <div className="my-10">
        <PrimaryButton
          disabledStatus={formStatus ? true : false}
          text="Submit"
          type="submit"
          onclick={onFinish}
          className={`${
            formStatus
              ? "bg-gray-300 cursor-not-allowed border-gray-300"
              : "bg-black hover:bg-transparent hover:border-black border-black hover:text-black"
          }`}
        />
      </div>
      {isLoading ? (
        <div className="absolute  mx-auto w-full flex z-10 justify-center items-center inset-0">
          <Spin
            tip="Test cases uploading"
            size="large"
            className="mb-5 testcase-loader w-full "
          >
            <div className="content" />
          </Spin>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};
