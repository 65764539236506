import React from "react";
import { Editor } from "@tinymce/tinymce-react";

interface texteEditorType {
  setContentData: any;
  contentData: string;
}
export const QuestionEditor = (props: texteEditorType) => {
  const handleUpdate = (value) => {
    props.setContentData(value);
  };

  return (
    <>
      <Editor
        // apiKey="ntixit5fbli2y8amlso6zsy5m8tgiepnqpfuo3n4svl3zcc9"
        apiKey="9in9ejat4f03b0ecztsvruz0mmbsss4okw9ke68yc72a9c51"
        cloudChannel="5-dev"
        init={{
          height: 300,
          menubar: false,
          plugins: [
            "advlist autolink lists link image",
            "charmap print preview anchor help",
            "searchreplace visualblocks code",
            "insertdatetime media table paste wordcount",
          ],
          toolbar:
            "undo redo | link code image | formatselect | image link media addcomment pageembed template codesample inserttable " +
            "bold italic backcolor | alignleft aligncenter " +
            "alignright alignjustify | bullist numlist outdent indent | " +
            "removeformat",
        }}
        value={props.contentData}
        onEditorChange={handleUpdate}
      />
    </>
  );
};
