import { useAuth0 } from "@auth0/auth0-react";
import React, { useContext, useEffect, useState } from "react";
import { getUsers } from "../../../../serviceApi/api";
import { BreadcrumbComp } from "../../../../components/Breadcrumb/Breadcrumb";
import { CardSkeletonLoader } from "../../../../components/skeleton/CardSkeleton";
import CourseCard from "../../../../components/card/CourseCard";
import axios from "axios";
import {
  getCourses,
  getStoredCourses,
} from "../../../../serviceApi/adminApi/SkillApi";
import CourseCardPaginationData from "../../../../components/pagination/CourseCardPaginationData";
import Swal from "sweetalert2";
import { Modal } from "antd";
import { loginContext } from "../../../../components/context/LoginContext";
import { CourseSearchInput } from "../../../../components/search/CourseSearchInput";
import StudentCourse from "./StudentCourse";
import { useNavigate } from "react-router-dom";

export default function Courses() {
  const navigate = useNavigate();
  const { user }: any = useAuth0();
  const { isAttempted, setIsAttempted, isSaved, setIsSaved }: any =
    useContext(loginContext);

  const userId: string = user?.email;
  const [courses, setCourses] = useState([]);
  const [role, setRole] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [getSearch, setGetSearch] = useState(true);
  const [isSearch, setIsSearch] = useState(false);
  const [input, setInput] = useState("");
  const [totalResults, setTotalResults] = useState<any>(0);
  const [pageToken, setPageToken] = useState();
  const [playerActive, setPlayerActive] = useState("");
  const [isIframe, setIsIframe] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [studentId, setStudentId] = useState();
  const [totalCoursesData, setTotalCoursesData] = useState([]);
  const [isStudentCourse, setIsStudentCourse] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const getUserRole = async () => {
    const userRole = await getUsers(userId);
    setRole(userRole?.data?.user[0]?.role);
    setStudentId(userRole?.data?.user[0]?.id);
    getCoursesList(userRole?.data?.user[0]?.id);
  };

  const getSearchContent = (data: any, getClick) => {
    if (data?.length > 0 && typeof data != "string") {
      if (getClick) {
        setGetSearch(true);
        setCourses(data);
        setIsSearch(true);
        setTotalResults(data);
      } else {
        setCourses(data);
        setGetSearch(true);
        setIsSearch(!isSearch);
        setTotalResults(data);
      }
    } else if (data === "value not filled" && typeof data === "string") {
      getCoursesList(studentId);
      setCourses(courses);
      setGetSearch(true);
      setIsSearch(false);
      setTotalResults(courses);
      setInput("");
    } else if (data === "not found data" && typeof data === "string") {
      setCourses([]);
      setGetSearch(false);
      setIsSearch(!isSearch);
    }
  };

  const getCoursesList = async (learnerId) => {
    if (isAttempted) {
      const res: any = await getCourses(learnerId.toString(), 4);
      if (res?.data?.getCourses?.length > 0) {
        const data = res?.data?.getCourses?.map((courses) =>
          JSON.parse(courses?.Video)
        );
        setIsLoading(false);
        setCourses(data.flat());
        const len = data.flat();
        setTotalResults(len);
        setPageToken(res?.data?.nextPageToken);
        setIsAttempted(false);
      } else {
        const userCourses: any = await getStoredCourses(learnerId);
        if (userCourses?.data?.getUserCourses?.length > 0) {
          const data = userCourses?.data?.getUserCourses?.map(
            (courses) => courses?.courses
          );
          const coursesData = JSON.parse(data[0])?.map((data) =>
            JSON.parse(data.Video)
          );
          setIsLoading(false);
          setCourses(coursesData.flat());
          const len = coursesData.flat();
          setTotalResults(len);
          setTotalCoursesData(len);
          setPageToken(userCourses.data?.nextPageToken);
        } else {
          if (isSaved) {
            Swal.fire({
              title: "Your interest has been saved",
              text: "Thank you for your interest. It will take some time to generate better course recommendations for you, so please wait or check back later.",
              icon: "success",
              confirmButtonText: "Ok",
              showCancelButton: false,
            }).then((result) => {
              console.log("result", result);
              if (result.isConfirmed) {
                navigate("/student/dashboard");
              }
              navigate("/student/dashboard");
            });
          } else {
            setIsStudentCourse(true);
          }
        }
      }
    } else {
      const userCourses: any = await getStoredCourses(learnerId);
      if (userCourses?.data?.getUserCourses?.length > 0) {
        const data = userCourses?.data?.getUserCourses?.map(
          (courses) => courses?.courses
        );
        const coursesData = JSON.parse(data[0])?.map((data) =>
          JSON.parse(data.Video)
        );
        setIsLoading(false);
        setCourses(coursesData.flat());
        const len = coursesData.flat();
        setTotalResults(len);
        setTotalCoursesData(len);
        setPageToken(userCourses.data?.nextPageToken);
      } else {
        const res: any = await getCourses(learnerId.toString(), 4);
        if (res?.data?.getCourses?.length > 0) {
          const data = res.data?.getCourses?.map((courses) =>
            JSON.parse(courses?.Video)
          );
          setIsLoading(false);
          setCourses(data.flat());
          const len = data.flat();
          setTotalResults(len);
          setPageToken(res?.data?.nextPageToken);
        } else {
          if (isSaved) {
            Swal.fire({
              title: "Your interest has been saved",
              text: "Thank you for your interest. It will take some time to generate better course recommendations for you, so please wait or check back later.",
              icon: "success",
              confirmButtonText: "Ok",
              showCancelButton: false,
            });
          } else {
            setIsStudentCourse(true);
          }
          return;
          Swal.fire({
            title: res?.errors[0]?.message,
          });
        }
      }
    }
  };

  const getVideoPlayer = async (videoId) => {
    axios
      .get(
        `https://www.googleapis.com/youtube/v3/videos?id=${videoId}&type=video&part=player&key=AIzaSyAub-QMZmP69tj49JKuvcUJvQeOyD3DzRw`
      )
      .then((response) => {
        setIsIframe(true);
        setIsModalOpen(true);
        setPlayerActive(response.data.items[0].player.embedHtml.toString());
        return (
          <div
            dangerouslySetInnerHTML={{
              __html: response.data.items[0].player.embedHtml.toString()
                ? response.data.items[0].player.embedHtml.toString()
                : "",
            }}
          />
        );
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: error.message + " Something went wrong!",
          showConfirmButton: false,
        });
      });
  };

  const getIframe = () => {
    return (
      <div
        dangerouslySetInnerHTML={{ __html: playerActive ? playerActive : "" }}
      />
    );
  };

  //for press anykey if iframe open
  const handleKeyDown = async () => {
    setIsIframe(false);
  };

  useEffect(() => {
    if (userId) {
      getUserRole();
    }
  }, [userId]);

  const breadcrumbData = [
    {
      path:
        role === "Student" ? "/student/dashboard" : "/subadmin/AllDomains/list",
      text: "Dashboard",
    },
    {
      text: "Courses",
    },
  ];
  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown, false);
    return document.removeEventListener("keydown", handleKeyDown, false);
  }, [isIframe]);

  return (
    <section className="w-full max-h-[86vh] overflow-y-auto lg:p-10 p-5 relative">
      <div className="h-full">
        <p className="text-2xl mb-0 pb-0 font-primary font-medium text-gray-800">
          Course Recommendations
        </p>
        <div className="lg:mb-4">
          <BreadcrumbComp breadcrumbData={breadcrumbData} />
        </div>
        <div className="mb-2 ml-auto lg:w-64 w-full">
          {totalResults?.length > 0 && (
            <CourseSearchInput
              SearchContent={getSearchContent}
              data={totalCoursesData}
              userId={userId}
              type="course"
              domainId=""
              name={setInput}
            />
          )}
        </div>
        {isStudentCourse ? (
          <StudentCourse />
        ) : (
          <div className="border-t pt-5 grid lg:grid-cols-4 md:grid-cols-2  pb-20 gap-5 lg:gap-10">
            {isLoading ? (
              <>
                <CardSkeletonLoader />
                <CardSkeletonLoader />
                <CardSkeletonLoader />
                <CardSkeletonLoader />
                <CardSkeletonLoader />
                <CardSkeletonLoader />
                <CardSkeletonLoader />
                <CardSkeletonLoader />
              </>
            ) : courses?.length > 0 ? (
              courses?.map((list: any, i: number) => {
                return (
                  <div
                    className="cursor-pointer"
                    key={i}
                    onClick={(e) => getVideoPlayer(list?.id)}
                  >
                    <CourseCard
                      key={i}
                      CardHeight="h-44"
                      image={list?.thumbnailUrl ? list?.thumbnailUrl : ""}
                      title={list?.title}
                      type={list?.domain_type}
                      description={list?.description}
                      route={`/student/assesment/domain/category/${list.uuid}`}
                    />
                  </div>
                );
              })
            ) : (
              <div className="shadow-md flex  flex-row items-center justify-center h-32 w-60 border-gray-300 rounded-md">
                <h1 className="font-primary justify-center items-center text-black text-sm ">
                  No course found
                </h1>
              </div>
            )}
          </div>
        )}
        {totalResults?.length > 8 && getSearch && (
          <div
            className={`${
              totalResults.length >= 8
                ? totalResults.length >= 5
                  ? "mb-2"
                  : "absolute left-0 right-0 bottom-20 mb-2"
                : "hidden"
            }`}
          >
            <CourseCardPaginationData
              data={totalResults}
              paginatedData={setCourses}
              userId={userId}
              getSearchActive={isSearch}
              type={
                role == "Admin"
                  ? "Domain_Approved"
                  : role == "Student"
                  ? "Domain"
                  : ""
              }
              domainId=""
              name={input}
              pageToken={pageToken}
            />
          </div>
        )}
      </div>
      {isIframe && (
        <Modal
          // title="Basic Modal"
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <div className="mt-5"> {getIframe()}</div>
        </Modal>
      )}
    </section>
  );
}
