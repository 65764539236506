import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useState } from "react";
import { DomainCard } from "../../../../../components/card/DomainCard";
import { SearchFilterInput } from "../../../../../components/search/SearchInput";
import { getDomainNamebyuser } from "../../../../../serviceApi/adminApi/DomainApi";
import { CardSkeletonLoader } from "../../../../../components/skeleton/CardSkeleton";
import { BreadcrumbComp } from "../../../../../components/Breadcrumb/Breadcrumb";
import PaginationData from "../../../../../components/pagination/Pagination";



interface DomainSearch {
        uuid: string,
        name: string,
        id: number,
        domain_type: string,
        domain_Amount: string | null,
        status: string | null,
        description: string | null,
        image: string | null
        userId: string| null,
        message: string |null,
}

export const ApprovedDomain = () => {
  const { user }: any = useAuth0();
  const userId = user?.email;
  const [domain, setDomain] = useState<DomainSearch[]>([]);
  const [response, setRespnose] = React.useState(false);
  const [paginatedData, setPaginatedData] = useState<DomainSearch[]>([]);
  const [getSearch, setGetSearch] = useState(true);
  const [isSearch, setIsSearch] = useState(false);
  const [input, setInput] = useState("");

  const getDomainsListbyUser = async () => {
    const domains = await getDomainNamebyuser(user?.email);
    if (domains?.data?.domainNameByuser) {
      setDomain(domains?.data?.domainNameByuser);
      setRespnose(true);
    }
  };
  const getSearchContent = (data: DomainSearch[] | string, getClick) => {
    setRespnose(false);
    if (data?.length > 0 && typeof data != "string") {
      if (getClick) {
        setGetSearch(true);
        setPaginatedData(data);
        setIsSearch(true);
        setDomain(data);
        setRespnose(true);
      } else {
        setRespnose(true);
        setPaginatedData(data);
        setGetSearch(true);
        setDomain(data);
        setIsSearch(!isSearch);
      }
    } else if (data === "value not filled" && typeof data === "string") {
      getDomainsListbyUser();
      setPaginatedData([]);
      setGetSearch(true);
      setRespnose(true);
      setIsSearch(false);
      setInput("");
      // setDomain(paginatedData);
    } else if (data === "not found data" && typeof data === "string") {
      getDomainsListbyUser();
      setPaginatedData([]);
      setGetSearch(false);
      setRespnose(true);
      // setDomain(paginatedData);
      setIsSearch(!isSearch);
    }
  };

  useEffect(() => {
    getDomainsListbyUser();
  }, [user]);

  const breadcrumbData = [
    {
      path: "/subadmin/AllDomains/list",
      text: "Dashboard",
    },
    {
      path: "/subadmin/approved/domain",
      text: "Approved Domains",
    },
  ];

  return (
    // <section>
      <div className="w-full mt-8 max-h-[86vh] overflow-y-auto lg:p-10 p-5">
        <p className="text-2xl mb-0 pb-0 font-primary font-medium text-gray-800">
          Approved Domains
        </p>
        <div className="lg:mb-4 my-2">
          <BreadcrumbComp breadcrumbData={breadcrumbData} />
        </div>
        <div className="mb-5 ml-auto lg:w-64 w-full">
          {domain?.length > 0 && (
            <SearchFilterInput
              SearchContent={getSearchContent}
              data={domain}
              userId={userId}
              type="Domain_Approved"
              domainId=""
              name={setInput}
            />
          )}
        </div>
        <div className="border-t pt-5 grid lg:grid-cols-4 sm:grid-cols-2 grid-cols-1 gap-5 lg:gap-10 pb-24">
          {response ? (
            paginatedData && paginatedData?.length > 0 ? (
              paginatedData?.map((list, i: number) => {
                // if (list?.status === "Approved") {
                return (
                  <DomainCard
                    key={i}
                    CardHeight="h-48"
                    image={list?.image ? list.image : ""}
                    title={list?.name}
                    status="Approved"
                    // description={list.description}
                  />
                );
                // }
              })
            ) : (
              <div className="shadow-md flex  flex-row items-center justify-center h-32 w-60 border-gray-300 rounded-md">
                <h1 className="font-primary justify-center items-center text-black text-sm ">
                  No Aprroved Domains
                </h1>
              </div>
            )
          ) : (
            <>
              <CardSkeletonLoader />
              <CardSkeletonLoader />
              <CardSkeletonLoader />
              <CardSkeletonLoader />
              <CardSkeletonLoader />
              <CardSkeletonLoader />
              <CardSkeletonLoader />
              <CardSkeletonLoader />
            </>
          )}
        </div>

        {/* pagination start */}
        {paginatedData && getSearch && (
          <div
            className={`${
              domain?.length >= 8
                ? paginatedData?.length >= 5
                  ? "mb-2"
                  : "absolute left-0 right-0 bottom-20 mb-2"
                : "hidden"
            }`}
          >
            <PaginationData
              data={domain}
              paginatedData={setPaginatedData}
              userId={userId}
              getSearchActive={isSearch}
              type="Domain_Approved"
              domainId=""
              name={input}
            />
          </div>
        )}
      </div>
    // </section>
  );
};
