import React, { useEffect, useState } from "react";
import { Table, Popconfirm } from "antd";
import type { ColumnsType, TablePaginationConfig } from "antd/es/table";
import type { FilterValue, SorterResult } from "antd/es/table/interface";
import { deleteQuestion } from "../../serviceApi/api";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

interface DataType {
  name: {
    first: string;
    last: string;
  };
  duration: string;
  question: string;
  id: number;
  description: string;
}

interface TableParams {
  pagination?: TablePaginationConfig;
  sortField?: string;
  sortOrder?: string;
  filters?: Record<string, FilterValue>;
}

export const QuestionsDataTable = ({
  domainId,
  categoryId,
  skillId,
  assessmentId,
  questionList,
  confirm,
}) => {
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [page, setPage] = React.useState(1);
  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });

  useEffect(() => {
    if (questionList) {
      setTableParams({
        ...tableParams,
        pagination: {
          ...tableParams.pagination,
          total: questionList?.length,
        },
      });
    }
  }, []);

  const questionDelete = async (uuid: string) => {
    setConfirmLoading(true);
    const response = await deleteQuestion(uuid, "question");
    if (response?.data?.questionDelete?.message == null) {
      setConfirmLoading(false);
      confirm(true);
    } else {
      setConfirmLoading(false);
      confirm(false);
    }
  };

  const columns: ColumnsType<DataType> = [
    {
      title: "Sr. No.",
      dataIndex: "id",
      width: "8%",
      render: (id, record, index: any) => {
        return (
          <p className="text-center">
            {(page - 1) * Number(tableParams?.pagination?.pageSize) + index + 1}
          </p>
        );
      },
    },
    {
      title: "Question Name",
      dataIndex: "question",
      width: "60%",
      defaultSortOrder: "descend",
      render: (question, questionType: any) => {
        return (
          <>
            {questionType?.questionType === "1" ? (
              <p
                dangerouslySetInnerHTML={{ __html: question }}
                className="font-primary"
              ></p>
            ) : questionType?.questionType === "2" ? (
              <p className=" font-primary">{question}</p>
            ) : questionType?.questionType === "3" ? (
              <img
                src={`${process.env.REACT_APP_MEDIA_URL}/api/media/download${question}`}
                alt="image 3-4 type"
                className="h-24 w-24 mt-5  rounded-full"
              />
            ) : questionType?.questionType === "4" ? (
              <img
                src={`${process.env.REACT_APP_MEDIA_URL}/api/media/download${question}`}
                alt="image 3-4 type"
                className="h-24 w-24 mt-2 rounded-full"
              />
            ) : questionType?.questionType === "5" ? (
              <p
                className="font-primary text-base"
                dangerouslySetInnerHTML={{ __html: question }}
              ></p>
            ) : null}
          </>
        );
      },
      align: "left",
    },
    {
      title: "Question Type",
      dataIndex: "questionType",
      width: "10%",
      defaultSortOrder: "descend",
      render: (questionType) => {
        return (
          <>
            <p className="text-center font-primary text-base">
              {" "}
              {questionType === "1"
                ? "Objective"
                : questionType === "2"
                ? "Media"
                : questionType === "3"
                ? "Media"
                : questionType === "4"
                ? "Media"
                : questionType === "5"
                ? "Coding"
                : null}
            </p>
          </>
        );
      },
    },
    {
      title: "Action",
      dataIndex: "uuid",
      width: "10%",
      render: (uuid: string, questionDetals: any) => {
        return (
          <>
            <div className="flex flex-row justify-center space-x-3">
              {/* {active == 1 ? (
                <Tooltip title="Can't be deleted, because any student has been attempt the question.">
                  <button className="px-2 py-2 hover:cursor-not-allowed bg-gray-400 text-white text-base rounded-full">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-4 h-4"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                      />
                    </svg>
                  </button>
                </Tooltip>
              ) : ( */}
              <Popconfirm
                title="Are you sure to delete this question?"
                onConfirm={() => questionDelete(uuid)}
                okButtonProps={{ loading: confirmLoading }}
                icon={<QuestionCircleOutlined style={{ color: "red" }} />}
              >
                <button className="px-2  py-2 bg-red-600 text-white text-base rounded-full">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-4 h-4"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                    />
                  </svg>
                </button>
              </Popconfirm>
              {/* )} */}
              <Link
                to={`/subadmin/domain/skill/${skillId}/assessment/${assessmentId}/question/${encodeURIComponent(
                  questionDetals?.id
                )}/${questionDetals?.questionType}/edit`}
                className="px-2 py-2 bg-black hover:bg-gray-600  hover:text-white text-white text-base rounded-full"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-4 h-4"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                  />
                </svg>
              </Link>
            </div>
          </>
        );
      },
    },
  ];
  const handleTableChange: any = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue>,
    sorter: SorterResult<DataType>
  ) => {
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });
  };

  return (
    <>
      <Table
        columns={columns}
        rowKey={(record) => record.id}
        className="domain-request-table"
        dataSource={questionList}
        pagination={{
          onChange(current) {
            setPage(current);
            // setPaginationSize(pageSize)
          },
          defaultPageSize: 10,
          hideOnSinglePage: false,
          showSizeChanger: true,
        }}
        loading={false}
        onChange={handleTableChange}
      />
    </>
  );
};
