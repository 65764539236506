import { Dashboard } from "../views/authenticateViews/SuperAdmin/dashboard";
import { StudentResult } from "../views/authenticateViews/students/dashboard/StudentResult";
import { StudentDefaultDashboard } from "../views/authenticateViews/students/defaultDashboard";
import { AllAssessments } from "../views/authenticateViews/students/assesments/AllAssessments";
import { Domain } from "../views/authenticateViews/Admin/subAdminDashboard/domains/Domain";
import { AllCategory } from "../views/authenticateViews/students/category/AllCategory";
import { AllSkillList } from "../views/authenticateViews/students/skill/AllSkillsList";
import { SkillByCategory } from "../views/authenticateViews/students/skill/SkillByCategory";
import Courses from "../views/authenticateViews/students/courseRecommendations/Courses";
import { Category } from "../views/authenticateViews/Admin/subAdminDashboard/categories/Category";
import { Skills } from "../views/authenticateViews/Admin/subAdminDashboard/skills/Skills";
import { AssesmentLists } from "../views/authenticateViews/students/assesments/AssesmentLists";
import { AssessmentBySkill } from "../views/authenticateViews/students/assesments/AssessmentBySkill";
import { Terms } from "../views/authenticateViews/students/assesments/Terms";
import { ExamInstructions } from "../views/authenticateViews/students/assesments/ExamInstructions";
import { StudentResultDetail } from "../views/authenticateViews/students/dashboard/StudentResultDetail";
import { PrivateAssessment } from "../views/authenticateViews/students/assesments/PrivateAssessment";
import { AllJobs } from "../views/authenticateViews/Admin/job/AllJobs";

const coreRoutes = [
  {
    path: "dashboard",
    title: "Dashboard",
    component: StudentDefaultDashboard,
  },
  {
    path: "assesment",
    title: "assesment",
    component: AllAssessments,
  },
  {
    path: "private-assessment",
    title: "private-assessment",
    component: PrivateAssessment,
  },
  {
    path: "jobs-list",
    title: "jobs-list",
    component: AllJobs,
  },
  {
    path: "assesment/domain",
    title: "assesment/domain",
    component: Domain,
  },
  {
    path: "assesment/category",
    title: "assesment/category",
    component: AllCategory,
  },
  {
    path: "assesment/skill",
    title: "assesment/category",
    component: AllSkillList,
  },
  {
    path: "assesment/category/:categoryId/skills",
    title: "assesment/category/skills",
    component: SkillByCategory,
  },
  {
    path: "course-recommendations",
    title: "course-recommendations",
    component: Courses,
  },
  {
    path: "student/assesment/domain/category/:id",
    title: "student/assesment/domain/category/:id",
    component: Category,
  },
  {
    path: "student/assesment/domain/:domainId/category/:categoryId/skills",
    title: "assesment/domain",
    component: Skills,
  },
  {
    path: "student/assesment/domain/:domainId/category/:categoryId/skill/:skillId/assessment/list",
    title: "assesment/domain",
    component: AssesmentLists,
  },

  {
    path: "student/assesment/skill/:skillId/assessment/list",
    title: "assesment/AssessmentBySkill",
    component: AssessmentBySkill,
  },
  {
    path: "student/assesment/domain/:domainId/category/:categoryId/skill/:skillId/assessment/:assessmentId/user/:emailId/exam/instruction",
    title: "Terms",
    component: Terms,
  },
  {
    path: "student/skill/:skillId/assessment/:assessmentId/user/:emailId/assessmentType/:type/exam/instruction",
    title: "Terms",
    component: Terms,
  },
  {
    path: "student/assesment/:assessmentId/exam/instruction",
    title: "ExamInstructions",
    component: ExamInstructions,
  },
  {
    path: "result",
    title: "ExamInstructions",
    component: StudentResult,
  },
  {
    path: "student/result/assessment/:assessmentId",
    title: "StudentResultDetail",
    component: StudentResultDetail,
  },
];

const StudentRoutes = [...coreRoutes];
export default StudentRoutes;
