import React from "react";
import { Question } from "./Question";

export const StudentsQuestions = () => {
  return (
    <>
      <Question />
    </>
  );
};
