import React, { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router";
import date from "date-and-time";
import { useAuth0 } from "@auth0/auth0-react";

import { CardSkeletonLoader } from "../../../../components/skeleton/CardSkeleton";
import PaginationData from "../../../../components/pagination/Pagination";
import { BreadcrumbComp } from "../../../../components/Breadcrumb/Breadcrumb";
import { Drawer } from "antd";
import { getEventPrivateAssessment } from "../../../../serviceApi/adminApi/EventApi";
import { PrivateAssessmentCard } from "../studentCards/PrivateAssessmentCard";

export const PrivateAssessment = () => {
  const { user }: any = useAuth0();
  const userId = user?.email;

  const dateFormat = Date.now();
  const [assessmentList, setAssessmentList] = useState([]);
  const [, setActive] = useState(false);
  const [dateToday, setDate] = useState<any>();
  const [, setInputValue] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [paginatedData, setPaginatedData] = useState([]);
  const [getSearch, setGetSearch] = useState(true);
  const [isSearch, setIsSearch] = useState(false);
  const [input, setInput] = useState("");
  const [addResponse, setAddResponse] = useState(false);
  const getAssesmentsListBySkillId = async () => {
    if (userId) {
      const assessments = await getEventPrivateAssessment(userId);
      setTimeout(() => {
        if (assessments?.data?.getUserEventAssessment?.length > 0) {
          setIsLoading(false);
          setAssessmentList(assessments?.data?.getUserEventAssessment);
          setDate(dateFormat);
        } else {
          setIsLoading(false);
        }
      }, 1000);
    }
  };

  const getSkillId = async (event) => {
    setInputValue(event.target.value);
  };

  const getSearchContent = (data: any, getClick) => {
    if (data?.length > 0 && typeof data != "string") {
      if (getClick) {
        setGetSearch(true);
        setPaginatedData(data);
        setIsSearch(true);
        setActive(true);
        setAssessmentList(data);
      } else {
        setPaginatedData(data);
        setGetSearch(true);
        setIsSearch(!isSearch);
        setAssessmentList(data);
      }
    } else if (data === "value not filled" && typeof data === "string") {
      getAssesmentsListBySkillId();
      setPaginatedData([]);
      setGetSearch(true);
      setIsSearch(false);
      setInput("");
    } else if (data === "not found data" && typeof data === "string") {
      setPaginatedData([]);
      setGetSearch(false);
      setIsSearch(!isSearch);
      getAssesmentsListBySkillId();
    }
  };
  useEffect(() => {
    getAssesmentsListBySkillId();
  }, [userId, addResponse]);

  const studentbreadcrumbData = [
    {
      path: "/student/dashboard",
      text: "Dashboard",
    },
    {
      text: "All Assessments",
    },
  ];
  return (
    <>
      <div className="w-full max-h-[86vh] overflow-y-auto lg:p-10 p-5">
        <h1 className="text-2xl pb-0 mb-0  font-primary  capitalize text-black-500 font-semibold">
          Select Private Assessment to Continue
        </h1>
        <div className="mb-4">
          <BreadcrumbComp breadcrumbData={studentbreadcrumbData} />
        </div>
        {/* filer added */}
        <div className="mb-5 flex flex-row items-center justify-between gap-x-4 ml-auto lg:w-80 w-full">
          {/* {assessmentList?.length > 0 && (
            <> */}
          {/* <StudentDataFilter /> */}
          {/* <SearchFilterInput
                SearchContent={getSearchContent}
                data={assessmentList}
                userId={userId}
                type="allAssessment"
                domainId=""
                name={setInput}
              /> */}
          {/* </>
          )} */}
        </div>

        <div className="border-t pt-5 grid lg:grid-cols-4 sm:grid-cols-2 grid-cols-1 gap-5 lg:gap-10 pb-10">
          {isLoading ? (
            <>
              <CardSkeletonLoader />
              <CardSkeletonLoader />
              <CardSkeletonLoader />
              <CardSkeletonLoader />
              <CardSkeletonLoader />
              <CardSkeletonLoader />
              <CardSkeletonLoader />
              <CardSkeletonLoader />
            </>
          ) : assessmentList && assessmentList.length > 0 ? (
            assessmentList.map((data: any, i) => {
              var date = new Date(Number(data?.eventEndDate)).toUTCString();

              var endDate = new Date(date.split("GMT")[0]).getTime();
              var utcDate = new Date(
                Number(data?.eventStartDate)
              ).toUTCString();

              var startDate = new Date(utcDate.split("GMT")[0]).getTime();
              const expiryCondition =
                dateToday > data?.eventEndDate
                  ? true
                  : dateToday <= data?.eventStartDate
                  ? true
                  : false;

              return (
                <Fragment key={i}>
                  <PrivateAssessmentCard
                    route={`${
                      dateToday <= endDate && dateToday >= startDate
                        ? `/student/assesment/${data.uuid}/exam/instruction`
                        : ""
                    }`}
                    active={setActive}
                    event_start_date={data.eventStartDate}
                    event_end_date={data.eventEndDate}
                    expire={expiryCondition}
                    todayDate={dateToday}
                    id={data?.uuid}
                    image={data.image}
                    description={data.description}
                    url={data.url}
                    user={userId}
                    admin={data?.userId}
                    type="radio"
                    onChange={getSkillId}
                    header={data.name}
                    value={data.uuid}
                    assessmentId={data?.assessmentId}
                    addResponse={setAddResponse}
                    name="default-radio"
                    className="w-6 h-6  appearance-none"
                  />
                </Fragment>
              );
            })
          ) : (
            <div className=" bg-white shadow-lg p-5 max-w-2xl mx-auto">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 256 256"
                className="text-center text-green-600 h-24 w-24 mx-auto"
              >
                <rect width="256" height="256" fill="none"></rect>
                <circle
                  cx="128"
                  cy="128"
                  r="96"
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="16"
                ></circle>
                <circle cx="92" cy="108" r="12"></circle>
                <circle cx="164" cy="108" r="12"></circle>
                <path
                  d="M169.6,152a48.1,48.1,0,0,1-83.2,0"
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="16"
                ></path>
              </svg>
              <p className="text-xl font-semibold font-primary text-center">
                There are no private assessment invited.
              </p>
            </div>
          )}
        </div>
        {/* {paginatedData && getSearch && (
          <div
            className={`${
              assessmentList.length >= 8
                ? paginatedData.length >= 5
                  ? "mb-2"
                  : "absolute left-0 right-0 bottom-20 mb-2"
                : "mx-auto hidden"
            }`}
          >
            <PaginationData
              data={assessmentList}
              paginatedData={setPaginatedData}
              userId={userId}
              getSearchActive={isSearch}
              type="allAssessment"
              domainId=""
              name={input}
            />
          </div>
        )} */}
      </div>
    </>
  );
};
