import { Skeleton } from "antd";
import React from "react";

const CardImgSkeleton = () => {
  return (
    <Skeleton.Image
      active
      className="skeleton-img"
      style={{
        height: "192px",
        width: "100%",
        borderRadius: "10px",
        objectFit: "cover",
        objectPosition: "center",
      }}
    />
  );
};

const CardTitleSkeleton = () => {
  return (
    <Skeleton.Input
      active
      size="small"
      style={{
        width: "100%",
        // minWidth: "100px"
      }}
    />
  );
};

const CardSmallImgSkeleton = () => {
  return <Skeleton.Avatar active size="default" shape="circle" />;
};

const CardSkeletonLoader = () => {
  return (
    <>
      <div className="p-3 flex flex-col justify-between rounded-xl h-full w-full bg-white shadow-lg relative">
        <CardImgSkeleton />
        <div className="mt-2">
          <CardTitleSkeleton />
        </div>
        <div className="flex flex-row mt-2 justify-between">
          <CardSmallImgSkeleton />
          <div className="space-x-2">
            <CardSmallImgSkeleton />
            <CardSmallImgSkeleton />
          </div>
        </div>
      </div>
    </>
  );
};

const CategoryCardSkeleton = () => {
  return (
    <>
      <div className="p-3  flex flex-col justify-between rounded-xl h-full w-full bg-white shadow-lg relative">
        <Skeleton.Image
          active
          className="skeleton-img"
          style={{
            height: "192px",
            width: "100%",
            borderRadius: "10px",
            objectFit: "cover",
            objectPosition: "center",
          }}
        />
        <div className="mt-2">
          <Skeleton paragraph={{ rows: 2 }} />
        </div>
      </div>
    </>
  );
};
export {
  CardSkeletonLoader,
  CardTitleSkeleton,
  CardImgSkeleton,
  CategoryCardSkeleton,
};
