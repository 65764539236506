import React from "react";
import newLogo from "../../../assets/images/newlogo.svg";
import { LoginButton } from "../../../components/buttons/LoginButton";
import { useAuth0 } from "@auth0/auth0-react";
import { Link } from "react-router-dom";

export default function PublicHeader() {
  const { loginWithRedirect } = useAuth0();

  return (
    <div>
      <div className="bg-blue-500">
        <nav className="max-w-5xl mx-auto lg:px-0 px-2 py-2 lg:py-4  flex  flex-row items-center justify-between">
          <img src={newLogo} alt="logo-skillcef" />

          <div className="hidden lg:block md:block ">
            <ul className="list-none my-0 text-white flex flex-row space-x-10 text-base items-center font-primary font-medium">
              <li>
                <a
                  href="https://skillcef.com"
                  className="text-white hover:text-white"
                >
                  Home
                </a>
              </li>
              <Link to="/allevents" className="text-white hover:text-white">
                <li>All Events</li>
              </Link>
              <Link to="/alljobs" className="text-white hover:text-white">
                <li>All Jobs</li>
              </Link>
              <Link
                to="/mock-assessments"
                className="text-white hover:text-white"
              >
                <li>Learn</li>
              </Link>
              <li>
                <a
                  href="https://skillcef.com/blog/"
                  className="text-white hover:text-white"
                >
                  Blogs
                </a>
              </li>
              <li onClick={() => loginWithRedirect({})}>
                <LoginButton />
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </div>
  );
}
