import React, { useContext, useEffect, useState } from "react";
import { LoginButton } from "../buttons/LoginButton";
import newLogo from "../../assets/images/newlogo.svg";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router";
import { getUsers } from "../../serviceApi/api";
import Drawer from "rc-drawer";
import { UserOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { loginContext } from "../context/LoginContext";
import Swal from "sweetalert2";
import { getCookie } from "../../utils";

interface User {
  email: string;
  nickname: string;
  loginWithRedirect: any;
  user: string;
}
export const WebsiteHeader = () => {
  const { user, loginWithRedirect, isAuthenticated } = useAuth0<User>();
  const email = user?.email;
  const navigate = useNavigate();

  const { setRole }: any = useContext(loginContext);
  const [open, setOpen] = useState<boolean>(false);
  const isLoading = true;
  const getUserInfo = async (email: string) => {
    try {
      const userInfo = await getUsers(email);
      if (userInfo?.data?.user?.length > 0) {
        if (userInfo?.data?.user[0]?.role) {
          setRole(userInfo?.data?.user[0]?.role);
          getRole(userInfo?.data?.user[0]?.role);
        }
      } else {
        setRole("new user");
        getRole("new user");
      }
    } catch (error: any) {
      Swal.fire({
        icon: "error",
        title: "Something Went Wrong! Please try again later.",
        showConfirmButton: false,
        timer: 3000,
      });
      // throw new Error(error);
    }
  };
  const token = getCookie("token");
  if (!token) {
    loginWithRedirect({});
  }

  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  const getRole = (userRole) => {
    if (userRole != null) {
      if (userRole === "SuperAdmin") {
        navigate("/home/dashboard");
      } else if (userRole === "Admin") {
        navigate("/subadmin/AllDomains/list");
      } else if (userRole === "Student") {
        navigate("/student/dashboard");
      } else if (userRole === "new user") {
        navigate("/profile");
      }
    }
  };

  useEffect(() => {
    if ((email && email != "") || email != null) {
      if (isAuthenticated) {
        getUserInfo(email);
      } else {
        getUserInfo(email);
        // getRole("");
      }
    } else {
      loginWithRedirect({});
    }
  }, [email, user]);

  return (
    <>
      {isLoading ? (
        ""
      ) : (
        <section className="absolute top-0 left-0 right-0 z-20">
          <nav className="max-w-5xl mx-auto lg:px-0 px-2 py-2 lg:py-4  flex  flex-row items-center justify-between">
            <img src={newLogo} alt="logo-skillcef" />
            <button
              type="button"
              className="inline-flex items-center justify-center sm:hidden lg:hidden p-1 rounded-md text-white  focus:outline-none ring-2 ring-inset ring-white"
              aria-controls="mobile-menu"
              aria-expanded="false"
              onClick={showDrawer}
            >
              <svg
                className=" h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
            </button>
            <Drawer
              placement="right"
              onClose={onClose}
              open={open}
              width={200}
              onMouseLeave={onClose}
            >
              {/* student option */}
              <ul className="mt-0 pt-0">
                <li className="flex flex-row items-center border-b p-1 border-gray-500">
                  <UserOutlined className="font-sans  lg:hidden md:hidden sm:hidden rounded-full text-white hover:bg-gray-600 hover:text-white" />
                  <p className="my-0 ml-1 capitalize ">
                    Hello, <span className="text-bold">{user?.nickname}</span>
                  </p>
                </li>
                <Link to="/student/profile">
                  <li className="  sm:text-sm font-primary text-black my-1 font-sans text-sm border-b p-1 border-gray-500 hover:bg-white hover:text-black ">
                    Dashboard
                  </li>
                </Link>
                <Link to="/student/assesment/domain">
                  <li className="  sm:text-sm font-primary text-black my-1 font-sans text-sm border-b p-1 border-gray-500 hover:bg-white hover:text-black ">
                    Assesments
                  </li>
                </Link>
                <Link to="/student/result">
                  <li className="  sm:text-sm font-primary text-black my-1 font-sans text-sm border-b p-1 border-gray-500 hover:bg-white hover:text-black ">
                    Result
                  </li>
                </Link>
                <Link to="/student/profile">
                  <li className="  sm:text-sm font-primary text-black my-1 font-sans text-sm border-b p-1 border-gray-500 hover:bg-white hover:text-black ">
                    Profile
                  </li>
                </Link>
              </ul>
            </Drawer>
            <div className="hidden lg:block md:block">
              <ul className="list-none my-0 text-white flex flex-row space-x-10 text-base items-center font-primary font-medium">
                <li>Home</li>
                <li>Product</li>
                <li>Contact</li>
                <li>Career</li>
                <li>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                    />
                  </svg>
                </li>
                <li onClick={() => loginWithRedirect({})}>
                  <LoginButton />
                </li>
              </ul>
            </div>
          </nav>
        </section>
      )}
    </>
  );
};
