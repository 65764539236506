import React, { useContext, useEffect, useState } from "react";
import JobListing from "./JobListing";

export const AllJobs = () => {
  return (
    <>
      <JobListing />
    </>
  );
};
