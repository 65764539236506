export function nameInputValid(value: string) {
  const regex = /^[a-zA-Z ]*$/g;
  const ctrl = value;
  return regex.test(ctrl);
}

export function MobileInputValid(value: string) {
  const regex = /^[1-9]{1}[0-9]{9}$/;
  const ctrl = value;
  return regex.test(ctrl);
}

export function containsSpecialChars(str: string) {
  const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
  // const specialChars=/^[a-z0-9]+$/i;
  return specialChars.test(str);
}

export function containsNumbers(str:string) {
  return /\d/.test(str);
}
