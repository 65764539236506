import React from "react";

export const DashboardFooter = () => {
  return (
    <div className="flex fixed bottom-0 w-full justify-center flex-row">
      <div className="lg:w-full w-full">
        <div className="bg-gradient-to-t from-brand-500 to-[#02C4FF] z-20 ">
          <div className="py-2 mx-auto flex flex-row items-center  justify-center">
            <p className="mb-0 text-sm text-center md:text-lg lg:text-lg text-white font-primary">
              © Copyright {new Date().getFullYear()} Intellikraft Solutions Pty
              ltd. All rights reserved.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
