export function setCookie(
  data: { [key: string]: string },
  expiresIn = 1800
): void {
  const date = new Date();
  date.setTime(new Date().getTime() + expiresIn * 1000);
  const expires = `expires=${date.toUTCString()}`;
  Object.keys(data).forEach((key) => {
    const value = data[key];
    document.cookie = `${key}=${value};${expires};path=/;`;
  });
}

export function getCookie(cookieName: string): string {
  const decodedCookie = decodeURIComponent(document.cookie);

  const cookie = decodedCookie
    .split(";")
    .filter((cookieString) => cookieString.indexOf("=") >= 0)
    .map((cookieString) => cookieString.split("="))
    .find((cookieParts) => cookieParts[0].trim() === cookieName);

  return cookie! && cookie[1];
}

export function deleteCookie(names: string[]): void {
  names?.forEach(
    (name) =>
      (document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`)
  );
}
export function deleteCookieToken(name: string): void {
  document.cookie = name + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
}

export function parseJwt(token: string) {
  const base64Url = token?.split(".")[1];

  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");

  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      ?.map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );
  return JSON.parse(jsonPayload);
}
