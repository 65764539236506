import { Tooltip, Modal, Switch, Popconfirm } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { version as pdfjsVersion } from "pdfjs-dist";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import type { ColumnsType, TablePaginationConfig } from "antd/es/table";
import type { FilterValue, SorterResult } from "antd/es/table/interface";

import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import { getStatus } from "../../../../serviceApi/api";
import {
  DownloadOutlined,
  EyeOutlined,
  ContactsOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import type { InputRef, TableColumnsType, TableColumnType } from "antd";
import { Button, Input, Space, Table, message } from "antd";
import type { FilterDropdownProps } from "antd/es/table/interface";
// import Highlighter from "react-highlight-words";
import { ApplicantsSkillReport } from "./ApplicantsSkillReport";
import {
  getAllApplicants,
  getApplicantsCertificate,
  jobApplicantStatus,
  sendJobNotification,
} from "../../../../serviceApi/adminApi/JobApi";
import { BreadcrumbComp } from "../../../../components/Breadcrumb/Breadcrumb";
const JobApplicants = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const navigate = useNavigate();
  const { id }: any = useParams();
  const [isPreview, setIsPreview] = useState(false);
  const [applicantCv, setApplicantCv] = useState("");
  const [customMessage, setCustomMessage] = useState("");
  const [report, setReport] = useState([]);
  const [open, setOpen] = useState(false);
  const [messageOpen, setMessageOpen] = useState(false);
  const [applicants, setApplicants] = useState([]);
  const [isChecked, setIsChecked] = useState(false);
  const [checked, setChecked] = useState(false);
  const [applicantData, setApplicantData] = useState<any>();
  const [isModalSendButtonDisable, setIsModalSendButtonDisable] =
    useState(true);

  const handleCancel = async () => {
    setOpen(false);
  };

  const handleChange = async () => {
    setChecked(!checked);
  };

  const handleApplicants = async () => {
    const applicants = await getAllApplicants(id);
    for (let i in applicants?.data?.jobApplicants) {
      const report = await getApplicantsCertificate(
        applicants?.data?.jobApplicants[i].userId,
        id
      );
      var allSkills: any = [];
      for (let j in report?.data?.jobApplicantsBadges) {
        const badgeDetails: any = {};
        const skills: any = report?.data?.jobApplicantsBadges[j].assessmentName;
        const badgeId: any = report?.data?.jobApplicantsBadges[j].assessmentId;
        badgeDetails.badgeId = badgeId;
        badgeDetails.skills = skills;
        allSkills.push(badgeDetails);
      }
      applicants.data.jobApplicants[i].skills = allSkills;
    }
    setApplicants(applicants?.data?.jobApplicants);
  };
  useEffect(() => {
    handleApplicants();
  }, [isChecked]);

  const handleReports = async (
    applicants,
    assessmentId: string | undefined
  ) => {
    const response = await getStatus(applicants.userId, assessmentId);
    if (response?.data?.getStatus) {
      setReport(response?.data?.getStatus);
      setOpen(true);
    } else {
      navigate("/student/dashboard");
    }
  };

  interface DataType {
    userId: string;
    lastName: string;
    profileCv: string;
    isProfileCv: string;
    firstName: string;
    duration: number;
    question: string;
    id: number;
    description: string;
    cv: string;
    skills: any[];
    onFilter: any;
    status: string;
  }

  const columns: ColumnsType<DataType> = [
    {
      title: "Sr. No.",
      dataIndex: "id",
      width: "5%",
      render: (id, record, index: any) => {
        return <p className="text-center">{index + 1}</p>;
      },
    },

    {
      title: "Applicant Name",
      dataIndex: "firstName",
      width: "10%",
      defaultSortOrder: "descend",
      render: (firstName, applicants) => {
        return (
          <>
            <p className="text-center my-0 capitalize">
              {firstName} {applicants?.lastName}
            </p>
          </>
        );
      },
    },
    {
      title: "Applicant Id",
      dataIndex: "userId",
      width: "10%",
      defaultSortOrder: "descend",
      render: (userId, applicants) => {
        return (
          <>
            <p className="text-center my-0 capitalize">{applicants?.userId}</p>
          </>
        );
      },
    },

    {
      title: "Resume/CV Image",
      dataIndex: "cv",
      width: "15%",
      defaultSortOrder: "descend",
      render: (cv, applicants) => {
        return (
          <>
            <p>
              {" "}
              <img
                src="/Assets/pdf_preview.png"
                alt="brandimage"
                className="h-24 w-24 mx-auto rounded-lg"
              />
            </p>
            <p>
              {applicants?.isProfileCv == "true"
                ? applicants?.profileCv?.split("/")?.pop()
                : cv?.split("/")?.pop()}
            </p>
          </>
        );
      },
    },
    {
      title: "Badge",
      dataIndex: "skills",
      width: "10%",
      defaultSortOrder: "descend",
      sorter: (a, b) => a.skills?.length - b.skills?.length,
      render: (skills, applicants) => {
        return (
          <>
            <div className="text-black p-1 rounded-full text-center font-primary">
              <p>{skills.length}</p>
              {skills?.length > 0
                ? skills?.map(
                    (
                      skill: {
                        badgeId: any;
                        skills:
                          | string
                          | number
                          | boolean
                          | React.ReactElement<
                              any,
                              string | React.JSXElementConstructor<any>
                            >
                          | Iterable<React.ReactNode>
                          | React.ReactPortal
                          | null
                          | undefined;
                      },
                      i: React.Key | null | undefined
                    ) => {
                      return (
                        <div key={i}>
                          <Tooltip title="see report">
                            <ol>
                              <li
                                onClick={() =>
                                  handleReports(applicants, skill.badgeId)
                                }
                                className="hover:bg-brand-600 bg-brand rounded text-white hover:text-white py-2 px-5 font-primary cursor-pointer"
                              >
                                {skill?.skills}
                              </li>
                            </ol>
                          </Tooltip>
                        </div>
                      );
                    }
                  )
                : "No skill badges"}
            </div>
          </>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      width: "8%",
      defaultSortOrder: "descend",
      render: (status, applicants) => {
        return (
          <>
            <p className="text-center my-0 capitalize font-semibold">{applicants?.status}</p>
          </>
        );
      },
    },
    {
      title: "Action",
      width: "20%",
      dataIndex: "cv",
      render: (cv, applicants) => {
        return (
          <>
            <div className="flex flex-row justify-center space-x-3">
              <Tooltip title="Download cv">
                <Link
                  className=" flex flex-row items-center w-30 gap-x-2 hover:bg-brand-600 bg-brand rounded text-white hover:text-white py-2 px-5 font-primary"
                  to={
                    applicants?.isProfileCv == "true"
                      ? `${process.env.REACT_APP_MEDIA_URL}/api/media/download${applicants?.profileCv}`
                      : `${process.env.REACT_APP_MEDIA_URL}/api/media/download${cv}`
                  }
                  download={
                    applicants?.isProfileCv == "true"
                      ? applicants?.profileCv?.split("/")?.pop()
                      : cv?.split("/").pop()
                  }
                >
                  <DownloadOutlined
                    onClick={() => handleDownload(applicants)}
                  />
                </Link>
              </Tooltip>
              <Tooltip title="Preview cv">
                <button
                  className=" hover:bg-brand-600 bg-brand rounded text-white hover:text-white py-2 px-5 font-primary"
                  onClick={() => handlePreview(applicants)}
                >
                  <EyeOutlined />
                </button>
              </Tooltip>
              <Tooltip title="Contact by Email">
                <button
                  className=" hover:bg-brand-600 bg-brand rounded text-white hover:text-white py-2 px-5 font-primary"
                  onClick={() => handleContact(applicants)}
                >
                  <ContactsOutlined />
                </button>
              </Tooltip>
              {/* shortlist and reject */}
              {/* <Tooltip title="shortlist/reject">
                <Switch
                  checkedChildren="shortlisted"
                  unCheckedChildren="reject"
                  // checked={applicants?.status === "Shortlisted" ? true : false}
                  checked={applicants?.status == "Shortlisted" ? true : false}
                  onClick={
                    applicants?.status == "Shortlisted"
                      ? () => handleRejection(applicants)
                      : () => handleSelection(applicants)
                  }
                />
              </Tooltip> */}

              <Popconfirm
                title="Are you sure you want to shortlist this Applicant?"
                onConfirm={() => handleSelection(applicants)}
                icon={<QuestionCircleOutlined style={{ color: "red" }} />}
              >
                <div className="font-primary cursor-pointer text-center flex flex-col justify-center items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1}
                    stroke="currentColor"
                    className="w-8 h-8 text-green-500 mx-auto text-center"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>

                  <span className="font-light text-gray-500">Shortlist</span>
                </div>
              </Popconfirm>
              <Popconfirm
                title="Are you sure you want to Reject this Applicant?"
                onConfirm={() =>  handleRejection(applicants)}
                icon={<QuestionCircleOutlined style={{ color: "red" }} />}
              >
                <div className="font-primary cursor-pointer text-center flex flex-col justify-center items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1}
                    stroke="currentColor"
                    className="w-8 h-8 text-red-500"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                  <span className="font-light text-gray-500">Reject</span>
                </div>
              </Popconfirm>
            </div>
          </>
        );
      },
    },
  ];
  interface ApplicantInput {
    userId: string;
    jobId: string;
    status: string;
  }

  const handlePreview = async (applicants) => {
    if (true) {
      setIsPreview(true);
    }
    if (applicants?.isProfileCv == "true") {
      setApplicantCv(applicants?.profileCv);
    } else {
      setApplicantCv(applicants?.cv);
    }
    const data: ApplicantInput = {
      userId: applicants.userId,
      jobId: id,
      status: "CV Viewed",
    };
    const res = await jobApplicantStatus(data);

    const notificationInput = {
      jobId: id,
      userId: applicants?.userId,
      name: applicants?.title,
      employerId: applicants?.employerId,
      type: "preview",
      message: "You CV has been seen by recruiter",
    };
    const result: any = await sendJobNotification(notificationInput);
    if (result.data.message) {
      messageApi.open({
        type: "success",
        content: "Email Notification has been sent to the Applicant",
      });
    }
  };
  const handleDownload = async (applicants) => {
    const data: ApplicantInput = {
      userId: applicants.userId,
      jobId: id,
      status: "CV Downloaded",
    };
    const res = await jobApplicantStatus(data);
    const notificationInput = {
      jobId: id,
      userId: applicants?.userId,
      name: applicants?.title,
      employerId: applicants?.employerId,
      type: "download",
      message: "Your CV has been downloaded by recruiter ",
    };
    const result: any = await sendJobNotification(notificationInput);
    if (result.data.message) {
      messageApi.open({
        type: "success",
        content: "Email Notification has been sent to the Applicant",
      });
    }
  };
  const handleContact = async (applicants) => {
    setMessageOpen(true);
    setApplicantData(applicants);
  };

  const handleSend = async () => {
    const data: ApplicantInput = {
      userId: applicantData.userId,
      jobId: id,
      status: "Contacted by email",
    };
    const res = await jobApplicantStatus(data);
    const notificationInput = {
      jobId: id,
      userId: applicantData?.userId,
      name: applicantData?.title,
      employerId: applicantData?.employerId,
      type: "contacted by email",
      message: customMessage ? customMessage : "Recruiter will cotact you soon",
    };
    setMessageOpen(false);
    const result: any = await sendJobNotification(notificationInput);
    if (result.data.message) {
      setMessageOpen(false);
      messageApi.open({
        type: "success",
        content: "Email Notification has been sent to the Applicant",
      });
    }
  };

  const handleSelection = async (applicants) => {
    const data: ApplicantInput = {
      userId: applicants.userId,
      jobId: id,
      status: "Shortlisted",
    };
    const res = await jobApplicantStatus(data);
    const notificationInput = {
      jobId: id,
      userId: applicants?.userId,
      name: applicants?.title,
      employerId: applicants?.employerId,
      type: "Shortlisted",
      message: "Congratulations! you has been selected for the job",
    };
    const result: any = await sendJobNotification(notificationInput);
    if (result.data.message) {
      messageApi.open({
        type: "success",
        content: "Email Notification has been sent to the Applicant",
      });
      setIsChecked(!isChecked);
    }
  };

  const handleRejection = async (applicants) => {
    const data: ApplicantInput = {
      userId: applicants.userId,
      jobId: id,
      status: "Rejected",
    };
    const res = await jobApplicantStatus(data);
    const notificationInput = {
      jobId: id,
      userId: applicants?.userId,
      name: applicants?.title,
      employerId: applicants?.employerId,
      type: "Applied",
      message:
        "This time you are not fit for this role , we will review on your profile later",
    };
    const result: any = await sendJobNotification(notificationInput);
    if (result.data.message) {
      messageApi.open({
        type: "success",
        content: "Email Notification has been sent to the Applicant",
      });
      setIsChecked(!isChecked);
    }
  };

  interface TableParams {
    pagination?: TablePaginationConfig;
    sortField?: string;
    sortOrder?: string;
    filters?: Record<string, FilterValue>;
  }
  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });

  const breadcrumbData = [
    {
      path: "/subadmin/AllDomains/list",
      text: "Dashboard",
    },
    {
      path: "/subadmin/admin/job/list",
      text: "All jobs",
    },
    {
      text: "Applicants details",
    },
  ];

  return (
    <>
      <div className="w-full mt-8 max-h-[86vh] overflow-y-auto lg:p-10 p-5">
        {contextHolder}
        <p className="text-2xl mb-0 pb-0 font-primary font-medium text-gray-800">
          All Applicants
        </p>
        <div className="mb-4">
          <BreadcrumbComp breadcrumbData={breadcrumbData} />
        </div>

        <div className="border-t pt-5 grid lg:grid-cols-4 sm:grid-cols-2 grid-cols-1 gap-5 lg:gap-10 pb-10"></div>
        <Modal
          open={isPreview}
          onCancel={() => setIsPreview(false)}
          className="custom-modal"
          width={800}
          centered
        >
          <div style={{ height: "750px" }}>
            <Worker
              workerUrl={`https://unpkg.com/pdfjs-dist@${pdfjsVersion}/build/pdf.worker.min.js`}
            >
              <Viewer
                fileUrl={`${process.env.REACT_APP_MEDIA_URL}/api/media/download${applicantCv}`}
              />
            </Worker>
          </div>
        </Modal>

        {/* add custom message modal */}
        <Modal
          title="Custom Message"
          open={messageOpen}
          centered
          onOk={handleSend}
          onCancel={() => setMessageOpen(false)}
          okText="Send"
          okButtonProps={{
            disabled: checked
              ? customMessage?.length > 0
                ? false
                : isModalSendButtonDisable
              : false,
          }}
        >
          <label>
            <input type="checkbox" checked={checked} onChange={handleChange} />
          </label>
          <span className="ml-2">Add Message</span>

          {checked && (
            <>
              <div style={{ height: "200px" }}>
                <label className="mt-4">Message</label>
                <span className="m-2 text-red-500">*</span>
                <textarea
                  placeholder="Enter custom message"
                  value={customMessage}
                  onChange={(e) => setCustomMessage(e.target.value)}
                  className="mt-2 focus:outline-none  text-sm border border-gray-300 rounded-md w-full lg:w-full p-4"
                ></textarea>
              </div>
            </>
          )}
        </Modal>
        <Table
          columns={columns}
          rowKey={(record) => record.id}
          className="domain-request-table"
          scroll={{ y: 400 }}
          dataSource={applicants}
          pagination={{
            onChange(current) {
              // setPage(current);
              // setPaginationSize(pageSize)
            },
            // defaultPageSize: 10,
            hideOnSinglePage: false,
            showSizeChanger: true,
          }}
          loading={false}
        />
        <Modal
          title="Student Records"
          open={open}
          onCancel={handleCancel}
          className="custom-modal"
          width={900}
          centered
        >
          <ApplicantsSkillReport report={report} />
        </Modal>
      </div>
    </>
  );
};

export default JobApplicants;
