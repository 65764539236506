import React, { Fragment, useEffect, useRef, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useParams, useNavigate } from "react-router";
import { useReactToPrint } from "react-to-print";
import { getStatus } from "../../../../serviceApi/api";
import { TopStudents } from "../../students/topStudents/TopStudents";
import { StudentResultReport } from "../../students/Reports/StudentResultReport";
import { DownloadOutlined } from "@ant-design/icons";
import { Progress } from "antd";
import { red, green, orange } from "@ant-design/colors";
import Logo from "../../../../assets/leftnavicons/Logo.svg";
import GoBackbutton from "../../../../components/buttons/GoBackbutton";
import SocialShare from "../../../../components/socialShare/SocialShare";

// interface Topic {
//   topic: string;
//   marks: number;
// }

export const ApplicantsSkillReport = ({ report }) => {
  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  return (
    <div>
      <section className="flex space-x-3 flex-row overflow-hidden justify-between h-[86vh]">
        <div className="w-full p-3 print:p-5 lg:p-5 overflow-y-auto max-h-screen">
          {/* goback component added */}
          <GoBackbutton />

          {/* social-share component added */}
          {/* <SocialShare shareAssessmentId={assessmentId} /> */}

          {/* result download button start*/}
          <div className="flex lg:float-right mb-3 lg:mb-3 text-center lg:mt-2 mt-3 justify-start items-center text-white rounded-full ">
            <button
              onClick={handlePrint}
              type="button"
              className="scale-100  font-semibold items-end hover:bg-transparent border border-brand-500 text-brand-500 px-5 py-1 rounded-full hover:text-black hover:border-brand  hover:bg-gray-900"
            >
              <DownloadOutlined /> Download Result
            </button>
          </div>
          {/* result download button end */}

          <div
            ref={componentRef}
            className="relative overflow-y-auto ... screen:shadow-xl border lg:max-w-7xl print:py-5  w-full mx-auto "
          >
            <div
              className="screen:hidden h-full w-full absolute opacity-5 bg bg-repeat bg-contain "
              style={{
                backgroundImage: `url(${Logo})`,
              }}
            ></div>

            {/* <div className="text-[20rem] w-full overflow-hidden  print:text-[10rem] font-bold  absolute opacity-5  -rotate-45  print:left-36  inset-0">
              SKillCef
            </div> */}

            <div className="print:p-4 lg:p-4 p-2 overflow-hidden">
              <span className="text-xl text-black flex space-x-1">
                Hi,
                <span className="text-black lg:text-xl text-lg font-semibold">
                  {report[0]?.firstName} {report[0]?.lastName}
                </span>
              </span>
              <div className="lg:text-xl text-lg lg:max-w-md md:max-w-24 sm:max-w-24  text-black">
                Assessment Name:{" "}
                <span className="font-semibold text-black lg:text-xl text-lg ">
                  {report[0]?.assessmentName}
                </span>
              </div>
            </div>
            <hr className="h-px my-3 bg-gray-200 border-1 w-full ... dark:bg-black-700" />
            <div className=" bg-transparent space-x-2 lg:p-5 p-3">
              <div className="bg-transparent">
                {/* <p className="font-bold screen:text-2xl print:text-2xl text-black text-center">
                  Student Performance
                </p> */}
                <div className="grid lg:grid-cols-1 grid-cols-1  gap-12">
                  {report && report.length > 0
                    ? report.map((result, i: number) => {
                        const ResultInPercent = 100 * result?.finalPer;
                        const arrayOfObjects = JSON.parse(result?.topic || "");
                        // // Use a Map to keep track of unique keys and their values
                        const keyToValueMap: any = new Map();
                        const keyToValueMap1: any = new Map();

                        // Iterate through the array of objects
                        arrayOfObjects.forEach((obj) => {
                          const topic = obj.topic;
                          const marks = obj.marks;
                          const total = obj.total;

                          // If the key is not in the Map, add it along with the value
                          if (!keyToValueMap.has(topic)) {
                            keyToValueMap.set(topic, marks);
                            keyToValueMap1.set(topic, total);
                          } else {
                            // If the topic is already in the Map, add the object's marks to the existing marks
                            keyToValueMap.set(
                              topic,
                              keyToValueMap.get(topic) + marks
                            );
                            keyToValueMap1.set(
                              topic,
                              keyToValueMap1.get(topic) + total
                            );
                          }
                        });

                        // Convert the Map back to an array of objects
                        const resultArray = [...keyToValueMap.entries()].map(
                          ([topic, marks]) => ({
                            topic,
                            marks,
                          })
                        );
                        const resultArray1 = [...keyToValueMap1.entries()].map(
                          ([topic, total]) => ({
                            topic,
                            total,
                          })
                        );
                        resultArray.forEach((obj: any, i) => {
                          resultArray1.forEach((obj1, j) => {
                            if (i == j) {
                              obj.total = obj1.total;
                            }
                          });
                        });
                        return (
                          <div key={i}>
                            <div key={i} className="p-2">
                              <p className="text-xl capitalize text-black font-primary text-center font-semibold">
                                This is the #{report.length - i} attempt result
                              </p>
                              <div className="grid grid-cols-1">
                                <div className="screen:max-w-[18rem] print:max-w-[18rem] print:-mt-24 -mt-24 print:mx-auto mx-auto">
                                  <StudentResultReport
                                    mark={result?.finalPer}
                                  />
                                </div>
                              </div>
                              <hr className="screen:-mt-10 print:-mt-32" />

                              <hr />
                              {/* Problemsolving analysis start */}
                              <div className=" p-1 print:p-5 lg:py-8 lg:px-4">
                                <p className="text-xl text-black font-primary text-center font-semibold">
                                  Problem solving ability
                                </p>

                                <p className="mt-4 text-center text-base lg:text-base">
                                  <span className="text-black font-semibold capitalize">
                                    {result?.firstName}
                                  </span>{" "}
                                  has achieved an overall{" "}
                                  {Number(result?.score).toFixed(2)} score in
                                  this subject out of {result?.totalWeightage}.
                                </p>

                                <div className="grid grid-cols-5 pt-3 gap-0.5">
                                  <div className="bg-red-400 text-center text-xs lg:text-sm p-3 print:p-5 lg:p-5 relative rounded-l-full ...">
                                    Negligible
                                    {Number(ResultInPercent) >= 0 &&
                                    Number(ResultInPercent) <= 20 ? (
                                      <div className="absolute -top-5 lg:left-16 left-24">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          viewBox="0 0 24 24"
                                          fill="currentColor"
                                          className="w-5 h-5 rotate-90"
                                        >
                                          <path d="M3.478 2.405a.75.75 0 00-.926.94l2.432 7.905H13.5a.75.75 0 010 1.5H4.984l-2.432 7.905a.75.75 0 00.926.94 60.519 60.519 0 0018.445-8.986.75.75 0 000-1.218A60.517 60.517 0 003.478 2.405z" />
                                        </svg>
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                  <div className="bg-orange-400 text-center text-xs lg:text-sm p-3 print:p-5 lg:p-5 relative">
                                    Limited
                                    {Number(ResultInPercent) >= 21 &&
                                    Number(ResultInPercent) <= 40 ? (
                                      <div className="absolute -top-5 lg:left-16 left-24">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          viewBox="0 0 24 24"
                                          fill="currentColor"
                                          className="w-5 h-5 rotate-90"
                                        >
                                          <path d="M3.478 2.405a.75.75 0 00-.926.94l2.432 7.905H13.5a.75.75 0 010 1.5H4.984l-2.432 7.905a.75.75 0 00.926.94 60.519 60.519 0 0018.445-8.986.75.75 0 000-1.218A60.517 60.517 0 003.478 2.405z" />
                                        </svg>
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                  <div className="bg-yellow-500 text-center text-xs lg:text-sm p-3 print:p-5 lg:p-5 relative">
                                    Moderate
                                    {Number(ResultInPercent) >= 41 &&
                                    Number(ResultInPercent) <= 60 ? (
                                      <div className="absolute -top-5 lg:left-16 left-24">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          viewBox="0 0 24 24"
                                          fill="currentColor"
                                          className="w-5 h-5 rotate-90"
                                        >
                                          <path d="M3.478 2.405a.75.75 0 00-.926.94l2.432 7.905H13.5a.75.75 0 010 1.5H4.984l-2.432 7.905a.75.75 0 00.926.94 60.519 60.519 0 0018.445-8.986.75.75 0 000-1.218A60.517 60.517 0 003.478 2.405z" />
                                        </svg>
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                  <div className="bg-green-200 text-center text-xs lg:text-sm p-3 print:p-5 lg:p-5 relative">
                                    Significant
                                    {Number(ResultInPercent) >= 61 &&
                                    Number(ResultInPercent) <= 80 ? (
                                      <div className="absolute -top-5 lg:left-16 left-24">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          viewBox="0 0 24 24"
                                          fill="currentColor"
                                          className="w-5 h-5 rotate-90"
                                        >
                                          <path d="M3.478 2.405a.75.75 0 00-.926.94l2.432 7.905H13.5a.75.75 0 010 1.5H4.984l-2.432 7.905a.75.75 0 00.926.94 60.519 60.519 0 0018.445-8.986.75.75 0 000-1.218A60.517 60.517 0 003.478 2.405z" />
                                        </svg>
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                  <div className="bg-green-500 text-center text-xs lg:text-sm p-3 print:p-5 lg:p-5 relative rounded-r-full ...">
                                    Extensive
                                    {Number(ResultInPercent) >= 81 &&
                                    Number(ResultInPercent) <= 100 ? (
                                      <div className="absolute -top-5 lg:left-16 left-24">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          viewBox="0 0 24 24"
                                          fill="currentColor"
                                          className="w-5 h-5 rotate-90"
                                        >
                                          <path d="M3.478 2.405a.75.75 0 00-.926.94l2.432 7.905H13.5a.75.75 0 010 1.5H4.984l-2.432 7.905a.75.75 0 00.926.94 60.519 60.519 0 0018.445-8.986.75.75 0 000-1.218A60.517 60.517 0 003.478 2.405z" />
                                        </svg>
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>
                                <div className="mt-3 grid grid-cols-5  gap-x-1 mx-auto font-semibold">
                                  <div className="flex lg:flex-row print:flex-row flex-col items-center space-x-2 w-full justify-center ">
                                    <p className="m-0 p-0 text-xs">
                                      Negligible 0-20
                                    </p>
                                  </div>
                                  <div className="flex lg:flex-row print:flex-row flex-col items-center space-x-1 w-full justify-center">
                                    <p className="m-0 p-0 text-xs">
                                      Limited 21-40
                                    </p>
                                  </div>
                                  <div className="flex lg:flex-row print:flex-row flex-col items-center space-x-2 w-full justify-center">
                                    <p className="m-0 p-0 text-xs">
                                      {" "}
                                      Moderate 41-60
                                    </p>
                                  </div>
                                  <div className="flex lg:flex-row print:flex-row flex-col items-center space-x-2 w-full justify-center">
                                    <p className="m-0 p-0 text-xs">
                                      {" "}
                                      Significant 61-80
                                    </p>
                                  </div>
                                  <div className="flex lg:flex-row print:flex-row flex-col items-center space-x-2 w-full justify-center">
                                    <p className="m-0 p-0 text-xs">
                                      {" "}
                                      Extensive 81-100
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <hr className="mt-5" />

                              {/* Subject Analysis start */}
                              {resultArray?.length > 0 && (
                                <div className="screen:my-10 print:mt-5 print:mb-10">
                                  <p className="text-xl text-black font-primary text-center font-semibold">
                                    Subject Analysis
                                  </p>

                                  <div className="print:w-full print:pr-20">
                                    {resultArray?.length > 0 &&
                                      resultArray?.map(
                                        (report: any, i: number) => {
                                          return (
                                            <Fragment>
                                              <div
                                                key={i}
                                                className="flex items-center w-full space-x-3"
                                              >
                                                <span className="w-1/5 capitalize font-brand text-sm font-medium">
                                                  {report?.topic}
                                                </span>
                                                <div className="w-4/5">
                                                  <Progress
                                                    strokeColor={[
                                                      (report?.marks * 100) /
                                                        report?.total >=
                                                        1 &&
                                                      (report?.marks * 100) /
                                                        report?.total <=
                                                        30
                                                        ? red[5]
                                                        : (report?.marks *
                                                            100) /
                                                            report?.total >=
                                                            31 &&
                                                          (report?.marks *
                                                            100) /
                                                            report?.total <=
                                                            70
                                                        ? orange[5]
                                                        : (report?.marks *
                                                            100) /
                                                            report?.total >=
                                                          71
                                                        ? green[6]
                                                        : red[5],
                                                    ]}
                                                    percent={
                                                      Math.round(
                                                        ((report?.marks * 100) /
                                                          report?.total) *
                                                          100
                                                      ) / 100
                                                    }
                                                  />
                                                </div>
                                              </div>
                                              {/* <hr /> */}
                                            </Fragment>
                                          );
                                        }
                                      )}
                                  </div>
                                  <div className="pb-10 flex flex-row space-x-10 justify-center mt-8 mx-auto">
                                    <div className="flex flex-row items-center">
                                      <div className="bg-red-500 w-10 p-1 mr-2  rounded-lg"></div>
                                      <p className=" font-semibold mb-0 text-primary">
                                        1%-30% Weak
                                      </p>
                                    </div>
                                    <div className="flex flex-row items-center">
                                      <div className="bg-orange-500 w-10 p-1 mr-2  rounded-lg "></div>
                                      <p className=" font-semibold mb-0 text-primary">
                                        31%-70% Proficient
                                      </p>
                                    </div>
                                    <div className="flex flex-row items-center">
                                      <div className="bg-green-600 w-10 p-1 mr-2  rounded-lg"></div>
                                      <p className=" font-semibold mb-0 text-primary">
                                        above 70% Strong
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              )}
                              {/* Subject Analysis end */}

                              <p className="my-2 pb-5 px-2 print:px-7 lg:px-7">
                                <span className="font-semibold capitalize">
                                  {/* {report?.firstName} */}
                                  {result?.firstName}
                                </span>{" "}
                                has demonstrated a{" "}
                                {Number(ResultInPercent) >= 0 &&
                                Number(ResultInPercent) <= 30 ? (
                                  <span className="font-semibold">
                                    Unsatisfactory,Needs Improvement
                                  </span>
                                ) : Number(ResultInPercent) >= 31 &&
                                  Number(ResultInPercent) <= 50 ? (
                                  <span className="font-semibold">
                                    Satisfactory
                                  </span>
                                ) : Number(ResultInPercent) >= 51 &&
                                  Number(ResultInPercent) <= 60 ? (
                                  <span className="font-semibold">Good</span>
                                ) : Number(ResultInPercent) >= 61 &&
                                  Number(ResultInPercent) <= 70 ? (
                                  <span className="font-semibold">
                                    Very Good
                                  </span>
                                ) : Number(ResultInPercent) >= 71 &&
                                  Number(ResultInPercent) <= 90 ? (
                                  <span className="font-semibold">
                                    Excellent
                                  </span>
                                ) : (
                                  Number(ResultInPercent) >= 91 && (
                                    <span className="font-semibold">
                                      Outstanding
                                    </span>
                                  )
                                )}{" "}
                                applying his/her knowledge to practical
                                applications and he got percentage{" "}
                                {Number(ResultInPercent) <= 50 ? (
                                  <span className="font-semibold">
                                    Below Average
                                  </span>
                                ) : (
                                  <span className="font-semibold">
                                    Above Average
                                  </span>
                                )}{" "}
                                {Number(ResultInPercent).toFixed(2)}%.
                              </p>
                              <hr />

                              {/* Problemsolving analysis end */}
                            </div>
                          </div>
                        );
                      })
                    : ""}
                </div>
              </div>
            </div>
            {/* footer for print */}
            <div className=" w-full py-2 screen:hidden print:block text-base text-center text-white flex flex-row justify-between items-center bg-gray-800 ">
              <p className="p-0 m-0">
                Copyright © {new Date().getFullYear()} SkillCef. All rights
                reserved.
              </p>
              <p className="p-0 m-0">www.skillcef.com</p>
            </div>
          </div>
        </div>
        {/* <div className="overflow-y-auto overflow-x-hidden max-h-screen relative bg-white shadow-lg w-2/6 lg:block px-2">
          <TopStudents />
        </div> */}
      </section>
    </div>
  );
};
