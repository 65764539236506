import React, { useEffect, useState } from "react";
import { Select } from "antd";
import { PopupModel } from "../../SuperAdmin/popupModel";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import { getCategories } from "../../../../serviceApi/superAdminApi/CategoryApi";
import { PrimaryButton } from "../../../../components/buttons/PrimaryButton";
import { createSkill } from "../../../../serviceApi/adminApi/SkillApi";
import { getDomainNamebyuser } from "../../../../serviceApi/adminApi/DomainApi";
import Swal from "sweetalert2";
import { getCookie } from "../../../../utils";
import axios from "axios";
import { InputField } from "../../../../components/inputFields/InputField";
import { BreadcrumbComp } from "../../../../components/Breadcrumb/Breadcrumb";

export const CreateSkills = () => {
  const navigate = useNavigate();
  const { user }: any = useAuth0();
  const userId: string | undefined = user?.email;
  const [skillNameErr, setSkillNameErr] = useState("");
  const [domain, setDomain] = React.useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [value, setValue] = useState<string>();
  const [catValue, setCatValue] = useState<string>();
  const [name, setName] = useState<string>("");
  const [description, setDescription] = useState("");
  const [registeredskills, setRegisteredSkills] = useState<boolean>(false);
  const [createSkills, setCreateSkills] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState([]);
  const [status, setStatus] = useState(true);
  const [skillImage, setSkillImage] = useState<File | string | any>();
  const [skillImagePreview, setSkillImagePreview] = useState<any>();

  const getDomainsList = async () => {
    const domains = await getDomainNamebyuser(userId);
    setDomain(domains?.data?.domainNameByuser);
  };

  const handleChange = async (newValue: string) => {
    if (categoryList.length > 0) {
      setCatValue("");
    }
    setValue(newValue);
    const categories = await getCategories(newValue);
    if (categories?.data?.category) {
      setCategoryList(categories?.data?.category);
    }
  };

  const selectCategory = async (newValue: string) => {
    setCatValue(newValue);
  };

  const handleSkillImage = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const selectedFiles = e.target.files?.[0];
    const file = e.target?.files?.[0];
    if (selectedFiles && file) {
      if (selectedFiles.size >= 2097152) {
        Swal.fire({
          icon: "warning",
          title: "Image size should be max 2MB.",
          showConfirmButton: false,
          timer: 3000,
        });
      } else if (
        selectedFiles.type === "image/png" ||
        selectedFiles.type === "image/jpg" ||
        selectedFiles.type === "image/jpeg" ||
        selectedFiles.type === "image/webp" ||
        selectedFiles.type === "image/jfif" ||
        selectedFiles.type === "image/svg"
      ) {
        setSkillImage(file);
        setSkillImagePreview(URL.createObjectURL(selectedFiles));
      } else {
        Swal.fire({
          icon: "warning",
          title: "Image formate should be png,jpg,jpeg,webp,jfif,svg",
          showConfirmButton: false,
          timer: 3000,
        });
      }
    }
  };

  const deleteSkillImage = () => {
    setSkillImagePreview(URL.revokeObjectURL(skillImagePreview));
    setSkillImage("");
  };

  const createSkillList = async () => {
    const skills = await createSkill({
      userId,
      catValue,
      name,
      description,
    });
    if (skills?.data?.createSkill.status) {
      setRegisteredSkills(true);
    } else if (skills?.data?.createSkill.id) {
      const formData = new FormData();
      formData.append("image", skillImage);
      formData.append("uuid", skills?.data?.createSkill.uuid);
      formData.append("type", "skill");
      try {
        const { data } = await axios.post(
          `${process.env.REACT_APP_MEDIA_URL}/api/media/upload`,
          formData,
          {
            headers: {
              enctype: "multipart/form-data",
              Authorization: `Bearer ${getCookie("token")}`,
            },
          }
        );
        if (data.message === "image uploaded Successfully") {
          Swal.fire({
            icon: "success",
            title: "Skill Created Successfully",
            showConfirmButton: false,
            timer: 3000,
          });
          setRegisteredSkills(false);
          setName("");
          setDescription("");
          setCreateSkills(true);
          setStatus(true);
          navigate("/subadmin/skills/list");
        }
      } catch (error) {
        console.log(error, "error ");
      }
    } else {
      Swal.fire({
        icon: "warning",
        title: "Invalid data please remove symbols.",
        showConfirmButton: false,
        timer: 3000,
      });
    }
  };

  const filter1 = async (value: string) => {
    const keyword = value;
    if (keyword !== "") {
      if (keyword.length > 60) {
        setStatus(true);
      } else {
        setStatus(false);
      }
      const results = inputValue?.filter((user: any) => {
        return user?.name?.toLowerCase().includes(keyword.toLowerCase()); // Use the toLowerCase() method to make it case-insensitive
      });
      setInputValue(results);
    }
    setName(keyword);
  };

  useEffect(() => {
    getDomainsList();
  }, [userId]);

  const domainData: any = domain?.map((list: any) => {
    return {
      value: list?.domainId,
      label: list?.name,
    };
  });

  const categoryData: any = categoryList.map(
    (list: { uuid: string; name: string }) => {
      return {
        value: list.uuid,
        label: list.name,
      };
    }
  );

  const modalStatus = (data) => {
    setCreateSkills(data);
  };

  useEffect(() => {
    if (
      name?.trim()?.length <= 60 &&
      name?.trim()?.length >= 2 &&
      skillImage &&
      catValue &&
      domain.length &&
      skillNameErr == ""
    ) {
      setStatus(false);
    } else setStatus(true);
  }, [name, skillImage, catValue, domain.length, skillNameErr]);

  const breadcrumbData = [
    {
      path: "/subadmin/AllDomains/list",
      text: "Dashboard",
    },
    {
      text: "Create Skill",
    },
  ];

  return (
    <>
      <section className="w-full max-h-[86vh] overflow-y-auto lg:p-10 p-5">
        {createSkills ? (
          <PopupModel
            modalStatus={modalStatus}
            title="Skill created successfully!!"
          />
        ) : (
          ""
        )}
        <p className="text-2xl mb-0 pb-0 font-primary font-medium text-gray-800">
          Create Skill
        </p>
        <div className="lg:mb-4 lg:mt-0 my-3">
          <BreadcrumbComp breadcrumbData={breadcrumbData} />
        </div>
        <div className="bg-white rounded-2xl mt-10 p-5 shadow-lg border">
          <div className="grid lg:grid-cols-2 gap-5 lg:gap-x-20">
            <div>
              <p className="lg:text-sm text-sm pb-0 mb-1 md:mb-2 lg:mb-2  capitalize text-black">
                Select domain <span className="text-red-600">*</span>
              </p>
              <Select
                value={value}
                onChange={handleChange}
                showSearch
                className="md:w-full w-full assessment-validity rounded-md"
                placeholder="Search / Select domain"
                optionFilterProp="children"
                filterOption={(input, option: any) =>
                  (option?.label.toLowerCase() ?? "").includes(
                    input.toLowerCase()
                  )
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? "").toLowerCase())
                }
                options={domainData}
              />
            </div>

            <div>
              <p className="lg:text-sm text-sm pb-0 mb-1 md:mb-2 lg:mb-2  capitalize text-black  ">
                Select Category <span className="text-red-600">*</span>
              </p>
              <Select
                value={catValue}
                onChange={selectCategory}
                className="lg:w-full md:w-full w-full assessment-validity rounded-md"
                placeholder={catValue == null ? "Search / Select Category" : ""}
                filterOption={(input: any, option: any) =>
                  (option?.label ?? "").includes(input)
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? "").toLowerCase())
                }
                options={categoryData ? categoryData : "Not Found"}
              />
            </div>
            <div className="flex flex-col">
              <InputField
                id="Skill Name"
                className="focus:outline-none  text-sm border border-gray-300 rounded-md lg:w-[710px] p-4"
                type="text"
                placeholder="Enter skill name"
                value={name}
                onChange={filter1}
                containsSpecialCharsMsg={setSkillNameErr}
              />
              <span className="text-red-500 mt-[6px]">{skillNameErr}</span>
              {registeredskills && (
                <p className="text-red-500">Skill is already exist !</p>
              )}
            </div>
            <div className="flex flex-col mt-2">
              <label
                htmlFor="description"
                className="lg:text-sm text-sm pb-0 mb-1 md:mb-2 lg:mb-2  capitalize text-black "
              >
                Description
              </label>
              <input
                id="description"
                className="focus:outline-none  text-sm border border-gray-300 rounded-md w-full lg:w-full p-4"
                type="text"
                placeholder="Enter description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </div>
            {/* image uploading start*/}
            <div>
              <p>
                Upload Skill Image <span className="text-red-600">*</span>
              </p>
              {skillImagePreview ? (
                <div className="h-44 border relative  mb-10 w-44">
                  <img
                    className="h-full w-full p-1 rounded-lg object-cover object-center"
                    src={skillImagePreview}
                    alt={skillImagePreview}
                  />
                  <div
                    onClick={deleteSkillImage}
                    className="bg-black cursor-pointer  w-8 h-8 flex items-center justify-center absolute top-0 right-0 rounded-full"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-5 h-5 mx-auto text-white"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                      />
                    </svg>
                  </div>
                </div>
              ) : (
                <div className="flex items-center justify-center w-44 h-44">
                  <label
                    data-testid="skillImageId"
                    htmlFor="dropzone-file-skill"
                    className="flex flex-col items-center justify-center w-full h-48 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
                  >
                    <div className="flex flex-col items-center justify-center pt-5 pb-6">
                      <svg
                        aria-hidden="true"
                        className="w-10 h-10 mb-3 text-gray-400"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                        ></path>
                      </svg>
                      <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                        <span className="font-semibold">Click to upload</span>
                      </p>
                      <p className="text-xs text-center text-gray-500 dark:text-gray-400">
                        SVG, PNG, JPG or GIF (MAX. 800x400px)
                      </p>
                    </div>
                    <input
                      id="dropzone-file-skill"
                      type="file"
                      onChange={handleSkillImage}
                      className="hidden"
                    />
                  </label>
                </div>
              )}
            </div>
            {/* {/ image uploading end /} */}
          </div>
          <div className="mt-10">
            <PrimaryButton
              text="Create"
              onclick={createSkillList}
              disabledStatus={status ? true : false}
              className={`${
                status
                  ? "bg-gray-300 cursor-not-allowed border-gray-300"
                  : "bg-black hover:bg-transparent hover:border-black border-black hover:text-black"
              }`}
            />
          </div>
        </div>
      </section>
    </>
  );
};
