import React, { useEffect, useState } from "react";
import { Breadcrumb, Button, Modal, Result } from "antd";
import { Link, NavLink, useNavigate, useParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import date from "date-and-time";
import Swal from "sweetalert2";
import {
  checkAuthenticate,
  getAssessmentNameById,
  getSkillNameBySkillId,
  getStatus,
} from "../../../../serviceApi/api";
import instructionData from "../../../../components/instruction/studentInstruction.json";
import { getDomainNameById } from "../../../../serviceApi/superAdminApi/DomainApi";
import { getCategoryNameById } from "../../../../serviceApi/superAdminApi/CategoryApi";
import { deleteCookieToken } from "../../../../utils";
import { BreadcrumbComp } from "../../../../components/Breadcrumb/Breadcrumb";

export const Terms = () => {
  const navigate = useNavigate();
  const { user, logout } = useAuth0();
  const email: string = user?.email as string;
  const { domainId, categoryId, skillId, assessmentId, emailId, type } =
    useParams();
  const [status, setStatus] = useState();
  const [length, setLength] = useState(0);
  const [checked, setChecked] = useState(false);
  const [isAttempt, setIsAttempt] = useState(false);
  const [loadings, setLoadings] = useState<boolean[]>([]);
  const [name, setName] = useState();
  const [domainName, setDomainName] = React.useState();
  const [categoryName, setCategoryName] = React.useState();
  const [assessmentName, setAssessmentName] = React.useState();

  const enterLoading = (index: number) => {
    setLoadings((prevLoadings) => {
      const newLoadings = [...prevLoadings];
      newLoadings[index] = true;
      return newLoadings;
    });
    setTimeout(() => {
      navigate(`/student/assessment/${assessmentId}/exam/start`);
    }, 2000);
  };

  const getAssessmentStatus = async () => {
    const response = await getStatus(email, assessmentId);
    if (response?.data?.getStatus) {
      const status = response?.data?.getStatus[0]?.status;
      if (status == "Completed") {
        setStatus(status);
        if (type == "Private") {
          setLength(1);
        } else {
          setLength(response?.data?.getStatus[0]?.length);
        }
      }
    } else {
      if (type == "Private") {
        setLength(0);
      } else {
        setLength(0);
      }
    }
  };

  const getDomainName = async () => {
    const response = await getDomainNameById(domainId);
    if (response?.data?.domainNameById?.length > 0) {
      setDomainName(response?.data?.domainNameById[0]?.name);
    } else {
      navigate("/404");
    }
  };
  const getCategoryName = async () => {
    const response = await getCategoryNameById(categoryId);
    if (response?.data?.categoryNameById?.length > 0) {
      setCategoryName(response?.data?.categoryNameById[0]?.name);
    } else {
      navigate("/404");
    }
  };

  const SkillNameBySkillId = async () => {
    const skillNameById = await getSkillNameBySkillId(skillId);
    if (skillNameById?.data?.skillNameById?.length > 0) {
      setName(skillNameById?.data?.skillNameById[0]?.name);
    } else {
      navigate("/404");
    }
  };
  const getAssessmentName = async () => {
    const response = await getAssessmentNameById(assessmentId);
    if (response?.data?.assessmentNameById?.length > 0) {
      setAssessmentName(response?.data?.assessmentNameById[0]?.name);
    } else {
      navigate("/404");
    }
  };
  const handleChange = () => {
    setChecked(true);
    if (checked) {
      setChecked(false);
    } else {
      setChecked(true);
    }
  };

  const navigatePageToAssessment = () => {
    navigate(`/student/assesment`);
  };

  const userAuthenticate = async (emailId: any, assessmentId: any) => {
    const currentDate = new Date();
    const dateFormat: any = date.format(currentDate, "YYYY-MM-DD");
    if (email == emailId) {
      const result = await checkAuthenticate(emailId, assessmentId);
      if (result?.data?.checkAuthenticate?.length > 0) {
        if (dateFormat <= result?.data?.checkAuthenticate[0]?.expiryDate) {
          setIsAttempt(true);
        } else {
          setIsAttempt(true);
          // Swal.fire({
          //   icon: "error",
          //   title: "Access Denied due to link expiration!",
          //   showConfirmButton: false,
          //   timer: 3000,
          // });
          // navigate("/student/assesment");
        }
      } else {
        Swal.fire({
          icon: "error",
          title: "Sorry! Your are not valid student",
          showConfirmButton: false,
          timer: 3000,
        });
        navigate("/404");
      }
    } else {
      Swal.fire({
        icon: "error",
        title: "Sorry! Your are not Invited student",
        showConfirmButton: false,
        timer: 3000,
      });
      navigate("/404");
    }
  };

  useEffect(() => {
    if (email) {
      if (type == "Private") {
        userAuthenticate(emailId, assessmentId);
        getAssessmentStatus();
        SkillNameBySkillId();
        getAssessmentName();
      } else {
        getDomainName();
        getCategoryName();
        getAssessmentStatus();
        SkillNameBySkillId();
        getAssessmentName();
      }
    }
  }, [email]);

  useEffect(() => {
    if (email) {
      if (localStorage.getItem("firstLoadDone") == "1") {
        localStorage.removeItem("firstLoadDone");
        localStorage.removeItem("codeStorage");
      }
    }
  }, [email]);

  const breadcrumbData = [
    {
      path: "student/dashboard",
      text: "Dashboard",
    },
    { text: "domain" },
    {
      path: `/student/assesment`,
      text: name,
    },
    { text: "Private Assessment" },
  ];
  return (
    <>
      {length == 1 && type == "Private" && isAttempt ? (
        <Modal
          centered
          open={true}
          onOk={() => navigatePageToAssessment()}
          onCancel={() => navigatePageToAssessment()}
        >
          <Result
            status="success"
            title="You have reached maximum Limit for this assessment."
            subTitle={`Assessment Name: ${assessmentName} `}
            extra={[
              <NavLink to={`/student/assesment`}>
                <button className="bg-brand-500 font-primary text-white px-5 py-1 rounded-lg capitalize">
                  Take another assessment
                </button>
              </NavLink>,
            ]}
          />
        </Modal>
      ) : (
        ""
      )}
      <section className="w-full mt-5 max-h-[86vh] overflow-y-auto lg:p-10 p-5">
        {/* <div className="my-4"> */}
        <h1 className="font-primary font-semibold mb-0 text-lg lg:text-2xl text-black">
          Exam Instructions
        </h1>
        {type === "Private" ? (
          <BreadcrumbComp breadcrumbData={breadcrumbData} />
        ) : (
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="student/dashboard">Dashboard</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              Domain / <Link to="/student/assesment/domain">{domainName}</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              Category /{" "}
              <Link to={`/student/assesment/domain/category/${domainId}`}>
                {categoryName}
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              Skill /{" "}
              <Link
                to={`/student/assesment/domain/${domainId}/category/${categoryId}/skills`}
              >
                {name}
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              Assessment /{" "}
              <Link
                to={`/student/assesment/domain/${domainId}/category/${categoryId}/skill/${skillId}/assessment/list`}
              >
                {assessmentName}
              </Link>
            </Breadcrumb.Item>
          </Breadcrumb>
        )}
        {/* </div> */}

        <div className="w-full mt-4 h-26 p-6 bg-white border border-gray-200 rounded-lg shadow-md ">
          <h1 className="font-primary font-semibold text-lg lg:text-2xl text-black">
            Assessment Instructions
          </h1>
          <p>
            This assessment is subject to copyright protection and by accessing
            the assessment you are obliged to accept the following regulations:
          </p>
          <hr className="h-px  bg-gray-200 border-1 w-full ... dark:bg-black-700" />
          <div className="flex items-center  lg:px-0 mt-5 px-3 lg:mb-0 mb-24">
            <div className="w-full bg-white">
              {instructionData.length > 0
                ? instructionData.map((data: string, i: number) => (
                    <p
                      key={i}
                      className="content-center my-0 text-sm lg:text-base font-primary"
                    >
                      {data}
                    </p>
                  ))
                : ""}
              <>
                <div className="pt-5">
                  <input
                    id="checkboxForTerms"
                    type="checkbox"
                    className="cursor-pointer"
                    onChange={handleChange}
                  />

                  <label
                    htmlFor="checkboxForTerms"
                    className="lg:text-lg text-sm cursor-pointer pl-2 text-black font-primary"
                  >
                    By accepting this agreement you acknowledge that you have
                    read, understand and agree to be bound by its terms and
                    conditions and you are aware that breaching of any of its
                    regulations may have legal and financial consequences.
                  </label>
                </div>
                <span className="text-brand-500 text-lg lg:text-2xl  lg:py-3 font-bold">
                  "Best of Luck."
                </span>
                <div>
                  <Button
                    className="rounded-lg mt-3 font-primary capitalize  px-8 content-center "
                    type="primary"
                    disabled={checked ? false : true}
                    loading={loadings[0]}
                    onClick={() => enterLoading(0)}
                  >
                    {status == "Completed" ? "Retake" : "Start assessment"}
                  </Button>
                </div>
              </>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
