import { PlusOutlined } from "@ant-design/icons";
import React from "react";

export const CreateButton = (props) => {
  return (
    <>
      <button
        onClick={props.onclick}
        disabled={props.disabledStatus ? true : false}
        className={`${
          props.disabledStatus
            ? "bg-gray-300 cursor-not-allowed border-gray-300"
            : "bg-transparent text-brand-400   border-brand-300"
        } border items-center flex flex-row focus:outline-none text-center rounded-full text-base font-primary text-black my-0 py-0 w-auto px-5 lg:px-10`}
      >
        <div className="mr-2 my-0 py-0">
          <PlusOutlined />
        </div>
        {props.text}
      </button>
    </>
  );
};
