import React, { useEffect, useState } from "react";
import { Select, Form, Slider, Tooltip, Drawer } from "antd";
import jobbackground from "../../assets/images/jobbackground.jpeg";
import { getJobFilter } from "../../serviceApi/adminApi/JobApi";
import Input from "antd/lib/input/Input";
import { InputField } from "../inputFields/InputField";
import { PrimaryButton } from "../buttons/PrimaryButton";
export default function JobDataFilter({ jobDetails }) {
  const [jobWorkingType, setJobWorkingType] = useState<any>([]);
  const [jobWorkingMode, setJobWorkingMode] = useState<any>([]);
  const [jobLevel, setJobLevel] = useState<any>([]);
  const [jobRole, setJobRole] = useState<any>([]);
  const [jobStatus, setJobStatus] = useState<any>([]);
  const [jobPosted, setJobposted] = useState<any>([]);
  const [city, setCity] = useState<string>("");
  const [open, setOpen] = useState(false);

  const isDisabled =
    jobStatus?.length > 0 ||
    jobRole?.length > 0 ||
    jobWorkingMode?.length > 0 ||
    jobWorkingType?.length > 0 ||
    jobPosted?.length > 0 ||
    jobLevel?.length > 0;

  const roleData = [
    { value: "Software Engineer", label: "Software Engineer" },
    { value: "Data Scientist", label: "Data Scientist" },
    { value: "Product Manager", label: "Product Manager" },
    { value: "UX/UI Designer", label: "UX/UI Designer" },
    { value: "Marketing Manager", label: "Marketing Manager" },
    { value: "Sales Executive", label: "Sales Executive" },
    { value: "Financial Analyst", label: "Financial Analyst" },
    { value: "Human Resources Manager", label: "Human Resources Manager" },
    { value: "Operations Manager", label: "Operations Manager" },
    { value: "Project Manager", label: "Project Manager" },
    { value: "Business Analyst", label: "Business Analyst" },
    { value: "Network Administrator", label: "Network Administrator" },
    { value: "System Administrator", label: "System Administrator" },
    { value: "Graphic Designer", label: "Graphic Designer" },
    { value: "Content Writer", label: "Content Writer" },
    {
      value: "Customer Service Representative",
      label: "Customer Service Representative",
    },
    { value: "Quality Assurance Tester", label: "Quality Assurance Tester" },
    {
      value: "Technical Support Specialist",
      label: "Technical Support Specialist",
    },
    { value: "DevOps Engineer", label: "DevOps Engineer" },
    { value: "Cybersecurity Specialist", label: "Cybersecurity Specialist" },
    { value: "Database Administrator", label: "Database Administrator" },
    { value: "Architect", label: "Architect" },
    { value: "Civil Engineer", label: "Civil Engineer" },
    { value: "Mechanical Engineer", label: "Mechanical Engineer" },
    { value: "Electrical Engineer", label: "Electrical Engineer" },
    { value: "Environmental Scientist", label: "Environmental Scientist" },
    { value: "Biotechnologist", label: "Biotechnologist" },
    { value: "Pharmaceutical Researcher", label: "Pharmaceutical Researcher" },
    { value: "Clinical Psychologist", label: "Clinical Psychologist" },
    { value: "Social Worker", label: "Social Worker" },
    { value: "Teacher", label: "Teacher" },
    { value: "Professor", label: "Professor" },
    { value: "School Principal", label: "School Principal" },
    { value: "Research Scientist", label: "Research Scientist" },
    { value: "Medical Doctor", label: "Medical Doctor" },
    { value: "Nurse", label: "Nurse" },
    { value: "Physical Therapist", label: "Physical Therapist" },
    { value: "Pharmacist", label: "Pharmacist" },
    { value: "Dentist", label: "Dentist" },
    { value: "Veterinarian", label: "Veterinarian" },
    { value: "Legal Counsel", label: "Legal Counsel" },
    { value: "Paralegal", label: "Paralegal" },
    { value: "Judge", label: "Judge" },
    { value: "Police Officer", label: "Police Officer" },
    { value: "Firefighter", label: "Firefighter" },
    { value: "Journalist", label: "Journalist" },
    { value: "Photographer", label: "Photographer" },
    { value: "Event Planner", label: "Event Planner" },
    { value: "Chef", label: "Chef" },
    { value: "Restaurant Manager", label: "Restaurant Manager" },
    { value: "Real Estate Agent", label: "Real Estate Agent" },
    { value: "Construction Manager", label: "Construction Manager" },
    { value: "Electrician", label: "Electrician" },
    { value: "Plumber", label: "Plumber" },
    { value: "Carpenter", label: "Carpenter" },
    { value: "Automotive Technician", label: "Automotive Technician" },
    { value: "Pilot", label: "Pilot" },
    { value: "Flight Attendant", label: "Flight Attendant" },
    { value: "Logistics Coordinator", label: "Logistics Coordinator" },
    { value: "Supply Chain Manager", label: "Supply Chain Manager" },
    { value: "Economist", label: "Economist" },
    { value: "Actuary", label: "Actuary" },
    { value: "Statistician", label: "Statistician" },
    {
      value: "Public Relations Specialist",
      label: "Public Relations Specialist",
    },
    { value: "Advertising Executive", label: "Advertising Executive" },
    { value: "Copywriter", label: "Copywriter" },
    { value: "Editor", label: "Editor" },
    { value: "Publisher", label: "Publisher" },
    { value: "Librarian", label: "Librarian" },
    { value: "Museum Curator", label: "Museum Curator" },
    { value: "Artist", label: "Artist" },
    { value: "Musician", label: "Musician" },
    { value: "Actor", label: "Actor" },
    { value: "Film Director", label: "Film Director" },
    { value: "Producer", label: "Producer" },
    { value: "Animator", label: "Animator" },
    { value: "Game Developer", label: "Game Developer" },
    { value: "Sports Coach", label: "Sports Coach" },
    { value: "Athletic Trainer", label: "Athletic Trainer" },
    { value: "Fitness Instructor", label: "Fitness Instructor" },
    { value: "Nutritionist", label: "Nutritionist" },
    { value: "Dietitian", label: "Dietitian" },
    { value: "Occupational Therapist", label: "Occupational Therapist" },
    { value: "Speech Therapist", label: "Speech Therapist" },
    { value: "Audiologist", label: "Audiologist" },
    { value: "Chiropractor", label: "Chiropractor" },
    { value: "Optometrist", label: "Optometrist" },
    { value: "Radiologist", label: "Radiologist" },
    { value: "Surgeon", label: "Surgeon" },
    { value: "Anesthesiologist", label: "Anesthesiologist" },
    { value: "Cardiologist", label: "Cardiologist" },
    { value: "Dermatologist", label: "Dermatologist" },
    { value: "Endocrinologist", label: "Endocrinologist" },
    { value: "Gastroenterologist", label: "Gastroenterologist" },
    { value: "Neurosurgeon", label: "Neurosurgeon" },
    { value: "Orthopedic Surgeon", label: "Orthopedic Surgeon" },
    { value: "Pediatrician", label: "Pediatrician" },
    { value: "Psychiatrist", label: "Psychiatrist" },
    { value: "Radiologic Technologist", label: "Radiologic Technologist" },
    { value: "Respiratory Therapist", label: "Respiratory Therapist" },
    { value: "Veterinary Technician", label: "Veterinary Technician" },
    { value: "Zoologist", label: "Zoologist" },
    { value: "Marine Biologist", label: "Marine Biologist" },
    { value: "Forester", label: "Forester" },
    { value: "Park Ranger", label: "Park Ranger" },
    { value: "Geologist", label: "Geologist" },
    { value: "Meteorologist", label: "Meteorologist" },
    { value: "Astronomer", label: "Astronomer" },
    { value: "Astrophysicist", label: "Astrophysicist" },
    { value: "Chemist", label: "Chemist" },
    { value: "Physicist", label: "Physicist" },
    { value: "Mathematician", label: "Mathematician" },
    { value: "Software Architect", label: "Software Architect" },
    { value: "IT Consultant", label: "IT Consultant" },
    { value: "Blockchain Developer", label: "Blockchain Developer" },
    { value: "AI Specialist", label: "AI Specialist" },
    { value: "Robotics Engineer", label: "Robotics Engineer" },
    { value: "Cloud Engineer", label: "Cloud Engineer" },
    {
      value: "Renewable Energy Consultant",
      label: "Renewable Energy Consultant",
    },
    { value: "Urban Planner", label: "Urban Planner" },
    { value: "Fashion Designer", label: "Fashion Designer" },
    { value: "Interior Designer", label: "Interior Designer" },
    { value: "Industrial Designer", label: "Industrial Designer" },
    { value: "Logistics Manager", label: "Logistics Manager" },
    { value: "Supply Chain Analyst", label: "Supply Chain Analyst" },
    {
      value: "Business Development Manager",
      label: "Business Development Manager",
    },
    { value: "E-commerce Specialist", label: "E-commerce Specialist" },
    {
      value: "Digital Marketing Specialist",
      label: "Digital Marketing Specialist",
    },
    { value: "SEO Specialist", label: "SEO Specialist" },
    { value: "Social Media Manager", label: "Social Media Manager" },
    { value: "Content Strategist", label: "Content Strategist" },
    { value: "Technical Writer", label: "Technical Writer" },
    { value: "Web Developer", label: "Web Developer" },
    { value: "Mobile App Developer", label: "Mobile App Developer" },
    { value: "Machine Learning Engineer", label: "Machine Learning Engineer" },
    { value: "Data Engineer", label: "Data Engineer" },
    { value: "Bioinformatician", label: "Bioinformatician" },
    {
      value: "Clinical Research Coordinator",
      label: "Clinical Research Coordinator",
    },
    {
      value: "Regulatory Affairs Specialist",
      label: "Regulatory Affairs Specialist",
    },
    { value: "Patent Examiner", label: "Patent Examiner" },
    { value: "Forensic Scientist", label: "Forensic Scientist" },
    { value: "Crime Scene Investigator", label: "Crime Scene Investigator" },
    { value: "Intelligence Analyst", label: "Intelligence Analyst" },
    { value: "Diplomat", label: "Diplomat" },
    { value: "Policy Analyst", label: "Policy Analyst" },
    { value: "Political Consultant", label: "Political Consultant" },
    { value: "Campaign Manager", label: "Campaign Manager" },
    { value: "Public Policy Specialist", label: "Public Policy Specialist" },
    { value: "Nonprofit Manager", label: "Nonprofit Manager" },
    { value: "Fundraising Coordinator", label: "Fundraising Coordinator" },
    {
      value: "Community Outreach Coordinator",
      label: "Community Outreach Coordinator",
    },
    { value: "Volunteer Coordinator", label: "Volunteer Coordinator" },
    { value: "Grant Writer", label: "Grant Writer" },
    { value: "Program Director", label: "Program Director" },
    { value: "Youth Worker", label: "Youth Worker" },
    { value: "Elder Care Specialist", label: "Elder Care Specialist" },
    { value: "Mental Health Counselor", label: "Mental Health Counselor" },
    { value: "School Counselor", label: "School Counselor" },
    { value: "Career Counselor", label: "Career Counselor" },
    { value: "Rehabilitation Counselor", label: "Rehabilitation Counselor" },
    { value: "Substance Abuse Counselor", label: "Substance Abuse Counselor" },
    {
      value: "Marriage and Family Therapist",
      label: "Marriage and Family Therapist",
    },
    { value: "Clinical Social Worker", label: "Clinical Social Worker" },
    { value: "Medical Social Worker", label: "Medical Social Worker" },
    {
      value: "Industrial-Organizational Psychologist",
      label: "Industrial-Organizational Psychologist",
    },
    { value: "School Psychologist", label: "School Psychologist" },
    { value: "Research Assistant", label: "Research Assistant" },
    { value: "Laboratory Technician", label: "Laboratory Technician" },
    {
      value: "Clinical Laboratory Scientist",
      label: "Clinical Laboratory Scientist",
    },
    { value: "Pathologist", label: "Pathologist" },
    { value: "Immunologist", label: "Immunologist" },
    { value: "Geneticist", label: "Geneticist" },
    { value: "Molecular Biologist", label: "Molecular Biologist" },
    { value: "Biochemist", label: "Biochemist" },
    { value: "Biophysicist", label: "Biophysicist" },
    { value: "Bioengineer", label: "Bioengineer" },
    {
      value: "Pharmaceutical Sales Representative",
      label: "Pharmaceutical Sales Representative",
    },
    {
      value: "Medical Device Sales Representative",
      label: "Medical Device Sales Representative",
    },
    { value: "Healthcare Administrator", label: "Healthcare Administrator" },
    { value: "Hospital Administrator", label: "Hospital Administrator" },
    { value: "Clinic Manager", label: "Clinic Manager" },
    {
      value: "Health Information Technician",
      label: "Health Information Technician",
    },
    { value: "Medical Coder", label: "Medical Coder" },
    {
      value: "Medical Billing Specialist",
      label: "Medical Billing Specialist",
    },
    { value: "Patient Care Coordinator", label: "Patient Care Coordinator" },
  ];
  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const handleJobtype = async (jobType) => {
    setJobWorkingType(jobType);
  };

  const handleRoleChange = async (role) => {
    setJobRole(role);
  };

  const handleworkingMode = async (workingType) => {
    setJobWorkingMode(workingType);
  };

  const handleLevel = async (level) => {
    setJobLevel(level);
  };
  const handleStatus = async (level) => {
    setJobStatus(level);
  };
  const handleReset = () => {
    setJobWorkingMode([]);
    setJobWorkingType([]);
    setJobLevel([]);
    setJobRole([]);
    setJobStatus([]);
    setJobposted([]);
  };
  const handleJobposted = async (time) => {
    setJobposted(time);
  };

  const handleClick = async () => {
    var jobs: any = [];
    for (let i in jobWorkingType) {
      const res = await getJobFilter(jobWorkingType[i], "type");
      if (res?.data?.jobFilter?.length > 0) {
        jobs?.splice(i, 1, ...res?.data?.jobFilter);
      }
    }
    for (let i in jobLevel) {
      const res = await getJobFilter(jobLevel[i], "level");
      if (res?.data?.jobFilter?.length > 0) {
        jobs?.splice(i, 1, ...res?.data?.jobFilter);
      }
    }
    for (let i in jobWorkingMode) {
      const res = await getJobFilter(jobWorkingMode[i], "mode");
      if (res?.data?.jobFilter?.length > 0) {
        jobs?.splice(i, 1, ...res?.data?.jobFilter);
      }
    }
    for (let i in jobStatus) {
      const res = await getJobFilter(jobStatus[i], "status");
      if (res?.data?.jobFilter?.length > 0) {
        jobs?.splice(i, 1, ...res?.data?.jobFilter);
      }
    }
    for (let i in jobRole) {
      const res = await getJobFilter(jobRole[i], "role");

      if (res?.data?.jobFilter?.length > 0) {
        jobs?.splice(i, 1, ...res?.data?.jobFilter);
      }
    }

    for (let i in jobPosted) {
      const res = await getJobFilter(jobPosted[i], "jobposted");
      if (res?.data?.jobFilter?.length > 0) {
        jobs?.splice(i, 1, ...res?.data?.jobFilter);
      }
    }

    jobDetails(jobs);
  };

  return (
    <div className="relative">
      <Tooltip title="Filter Job">
        <div className="w-full flex justify-end">
          <button
            onClick={showDrawer}
            // className="text-gray-700 focus:outline-none text-[20px] mr-10 font-semibold flex items-center"
            className="text-gray-700 focus:outline-none  font-semibold  mt-4 flex gap-x-2 lg:mt-2"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
              fill="currentColor"
              className="text-gray-700"
              width={20}
              height={20}
            >
              <path d="M3.9 54.9C10.5 40.9 24.5 32 40 32H472c15.5 0 29.5 8.9 36.1 22.9s4.6 30.5-5.2 42.5L320 320.9V448c0 12.1-6.8 23.2-17.7 28.6s-23.8 4.3-33.5-3l-64-48c-8.1-6-12.8-15.5-12.8-25.6V320.9L9 97.3C-.7 85.4-2.8 68.8 3.9 54.9z" />
            </svg>
            Filter
          </button>
        </div>
      </Tooltip>

      <Drawer title="Filter Jobs by" onClose={onClose} open={open} width={350}>
        <Select
          mode="multiple"
          placeholder="Filter By Job Role"
          value={jobRole}
          onChange={handleRoleChange}
          style={{
            width: "100%",
            borderRadius: "8px",
            marginTop: "10px",
          }}
          options={roleData}
        />
        <Select
          mode="multiple"
          placeholder="Filter By Job Working Type"
          value={jobWorkingType}
          onChange={handleJobtype}
          style={{
            width: "100%",
            borderRadius: "8px",
            marginTop: "10px",
          }}
          options={[
            { value: "Full-time", label: "Full-time" },
            { value: "Part-Time", label: "Part-Time" },
            { value: "Contract", label: "Contract" },
          ]}
        />
        <Select
          mode="multiple"
          placeholder="Filter By Job Working Mode"
          value={jobWorkingMode}
          onChange={handleworkingMode}
          style={{
            width: "100%",
            borderRadius: "8px",
            marginTop: "10px",
          }}
          options={[
            { value: "Remote", label: "Remote" },
            { value: "Hybrid", label: "Hybrid" },
            { value: "On-Site", label: "On-Site" },
          ]}
        />
        <Select
          mode="multiple"
          placeholder="Filter By Job Level"
          value={jobLevel}
          onChange={handleLevel}
          style={{
            width: "100%",
            borderRadius: "8px",
            marginTop: "10px",
          }}
          options={[
            { value: "Entry Level", label: "Entry Level" },
            { value: "Junior Level", label: "Junior Level" },
            { value: "Senior Level", label: "Senior Level" },
            { value: "Expert", label: "Expert" },
          ]}
        />
        <Select
          mode="multiple"
          placeholder="Filter By Job Status"
          value={jobStatus}
          onChange={handleStatus}
          style={{
            width: "100%",
            borderRadius: "8px",
            marginTop: "10px",
          }}
          options={[
            { value: "Active", label: "Active" },
            { value: "InActive", label: "InActive" },
          ]}
        />
        <Select
          mode="multiple"
          placeholder="Filter By Job Posted"
          value={jobPosted}
          onChange={handleJobposted}
          style={{
            width: "100%",
            borderRadius: "8px",
            marginTop: "10px",
          }}
          options={[
            { value: "Past 24 hours", label: "Past 24 hours" },
            { value: "Past Week", label: "Past Week" },
            { value: "Past Month", label: "Past Month" },
            { value: "Any Time", label: "Any Time" },
          ]}
        />

        <div className="flex  gap-[10px] justify-center mt-8">
          <PrimaryButton
            text="Reset"
            onclick={handleReset}
            className={`${
              isDisabled
                ? "bg-black hover:bg-transparent hover:border-black border-black hover:text-black cursor-pointer"
                : "bg-gray-300 cursor-not-allowed border-gray-300"
            }`}
          />
          <PrimaryButton
            text="Apply"
            onclick={handleClick}
            className={`${
              isDisabled
                ? "bg-pink-500 hover:bg-transparent hover:border-pink-500 border-pink-500 hover:text-pink-500 cursor-pointer"
                : "bg-gray-300 cursor-not-allowed border-gray-300"
            }`}
          />
        </div>
      </Drawer>
    </div>
  );
}
