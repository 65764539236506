import { TableSkeleton } from "@ant-design/pro-components";
import { useAuth0 } from "@auth0/auth0-react";
import { Modal, Popconfirm, Table, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import type { ColumnsType, TablePaginationConfig } from "antd/es/table";
import { BreadcrumbComp } from "../../../../components/Breadcrumb/Breadcrumb";
import {
  getAssessmentNameById,
  getSkillNameBySkillId,
  getStatus,
} from "../../../../serviceApi/api";
import { FilterValue, SorterResult } from "antd/es/table/interface";
import xlsx from "sheetjs-style";
import * as FileSaver from "file-saver";
import { message } from "../../../../constant/message";
import { ApplicantsSkillReport } from "../job/ApplicantsSkillReport";

interface DataType {
  name: {
    first: string;
    last: string;
  };
  duration: string;
  question: any;
  id: number;
  description: string;
  userId: string;
}

interface TableParams {
  pagination?: TablePaginationConfig;
  sortField?: string;
  sortOrder?: string;
  filters?: Record<string, FilterValue>;
}

export default function StudentsReport() {
  const navigate = useNavigate();
  const { user } = useAuth0();
  const userId: any = user?.email;
  const { skillId, assessmentId }: any = useParams();
  const [name, setName] = React.useState();
  const [report, setReport] = useState<any>([]);
  const [fullReport, setFullReport] = useState<any>([]);
  const [open, setOpen] = useState(false);
  const [skillName, setSkillName] = React.useState();
  const [isLoading, setIsLoading] = React.useState(true);
  const [page, setPage] = React.useState(1);
  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: page,
      pageSize: 10,
    },
  });
  const handleReports = async (userId, assessmentId: string | undefined) => {
    const response = await getStatus(userId, assessmentId);
    if (response?.data?.getStatus) {
      setFullReport(response?.data?.getStatus);
      setOpen(true);
    } else {
      navigate("/student/dashboard");
    }
  };
  const getAssessmentName = async () => {
    const response = await getAssessmentNameById(assessmentId);
    if (response?.data?.assessmentNameById?.length <= 0) {
      navigate("/404");
    }
    setName(response?.data?.assessmentNameById[0]?.name);
    setIsLoading(false);
  };

  const getSkillName = async () => {
    const response = await getSkillNameBySkillId(skillId);
    if (response?.data?.skillNameById?.length <= 0) {
      navigate("/404");
    }
    setSkillName(response?.data?.skillNameById[0]?.name);
  };
  const breadcrumbData = [
    {
      path: "/subadmin/AllDomains/list",
      text: "Dashboard",
    },
    { text: "skill" },
    {
      path: `/subadmin/skills/list`,
      text: skillName,
    },
    { text: "assessment" },
    {
      path: `/subadmin/domain/skill/${skillId}/assessment/list`,
      text: name,
    },
    { text: "users report" },
  ];

  const columns: ColumnsType<DataType> = [
    {
      title: "Sr. No.",
      dataIndex: "id",
      width: "8%",
      render: (id, record, index: any) => {
        return (
          <p className="text-center">
            {(page - 1) * Number(tableParams?.pagination?.pageSize) + index + 1}
          </p>
        );
      },
    },
    {
      title: "Student Name",
      dataIndex: "firstName",
      width: "30%",
      defaultSortOrder: "descend",
      render: (firstName) => {
        return (
          <>
            <p className="text-center font-primary text-base">{firstName}</p>
          </>
        );
      },
      align: "left",
    },
    {
      title: "Score",
      dataIndex: "score",
      width: "10%",
      defaultSortOrder: "descend",
      render: (score) => {
        return (
          <>
            <p className="text-center font-primary text-base"> {score}</p>
          </>
        );
      },
    },
    {
      title: "Percentage",
      dataIndex: "finalPer",
      width: "10%",
      defaultSortOrder: "descend",
      render: (finalPer) => {
        return (
          <>
            <p className="text-center font-primary text-base">
              {" "}
              {(finalPer * 100).toFixed(2)} %
            </p>
          </>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "finalPer",
      width: "20%",
      defaultSortOrder: "descend",
      render: (finalPer) => {
        return (
          <>
            <p className="text-center font-primary text-base">
              {" "}
              {finalPer * 100 >= 0 && finalPer * 100 < 40
                ? "Fail"
                : finalPer * 100 >= 40 && finalPer * 100 <= 69
                ? "Pass but unsatisfactory"
                : finalPer * 100 >= 70 && finalPer * 100 <= 79
                ? "Satisfactory but needs improvement"
                : finalPer * 100 >= 80 && finalPer * 100 <= 89
                ? "Very good"
                : finalPer * 100 >= 90 && finalPer * 100 <= 100
                ? "Excellent"
                : ""}
            </p>
          </>
        );
      },
    },
    {
      title: "Action",
      dataIndex: "finalPer",
      width: "10%",
      defaultSortOrder: "descend",
      align: "center",
      render: (finalPer, report) => {
        return (
          <>
            <Tooltip title="see full report">
              <p className="text-center font-primary text-base">
                <button
                  className="px-2 py-2 bg-green-500 hover:bg-green-800 text-white text-base rounded-full"
                  onClick={() => handleReports(report?.userId, assessmentId)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="text-white w-4 h-4"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M12 6.042A8.967 8.967 0 006 3.75c-1.052 0-2.062.18-3 .512v14.25A8.987 8.987 0 016 18c2.305 0 4.408.867 6 2.292m0-14.25a8.966 8.966 0 016-2.292c1.052 0 2.062.18 3 .512v14.25A8.987 8.987 0 0018 18a8.967 8.967 0 00-6 2.292m0-14.25v14.25"
                    />
                  </svg>
                </button>
              </p>
            </Tooltip>
          </>
        );
      },
    },
  ];

  const handleTableChange: any = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue>,
    sorter: SorterResult<DataType>
  ) => {
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });
  };
  const getReport = async () => {
    const response = await getStatus(userId, assessmentId);
    if (response?.data?.getStatus) {
      const rank = response?.data?.getStatus.sort((a, b) => b.score - a.score);
      setReport(rank);
    }
  };

  const data = report?.map((item, index) => {
    return {
      id: index + 1,
      firstName: item?.firstName,
      lastName: item?.lastName,
      userId: item?.userId,
      Score: item?.score,
      Percentage: item?.finalPer * 100,
      Status:
        item?.finalPer * 100 >= 0 && item?.finalPer * 100 < 40
          ? "Fail"
          : item?.finalPer * 100 >= 40 && item?.finalPer * 100 <= 69
          ? "Pass but unsatisfactory"
          : item?.finalPer * 100 >= 70 && item?.finalPer * 100 <= 79
          ? "Satisfactory but needs improvement"
          : item?.finalPer * 100 >= 80 && item?.finalPer * 100 <= 89
          ? "Very good"
          : item?.finalPer * 100 >= 90 && item?.finalPer * 100 <= 100
          ? "Excellent"
          : "",
    };
  });

  useEffect(() => {
    getAssessmentName();
    getSkillName();
    getReport();
  }, []);

  const exportData = (fileName, jsonData) => {
    const ws: any = xlsx?.utils?.json_to_sheet(jsonData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = xlsx?.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    FileSaver.saveAs(data, fileName + ".xlsx");
  };

  return (
    <div className="w-full mt-5 max-h-[86vh] overflow-y-auto lg:p-10 p-5">
      <div className="mb-4">
        <p className="text-2xl mb-0 font-primary capitalize text-black font-semibold">
          Student Report List
        </p>
        <BreadcrumbComp breadcrumbData={breadcrumbData} />
      </div>
      <div className="flex flex-row justify-between relative ...">
        {/*2nd Statistics */}
        <div className="w-full flex items-start flex-col mt-5 bg-white border border-gray-200 shadow-md ">
          <div className="flex justify-between w-full">
            <div>
              <p className="text-xl capitalize font-primary text-brand-500 font-normal  my-3 lg:my-2 p-6">
                <span className="text-black font-primary">
                  Assessment Name -{" "}
                </span>
                {name}
              </p>
            </div>
            <div className="p-6">
              <Tooltip title="Excel">
                <button
                  className="m-5 bg-brand rounded p-2 hover:bg-blue-500 text-white text-sm"
                  onClick={(e) =>
                    exportData(`${name}-skillcef-private-assessment`, data)
                  }
                >
                  Export
                </button>
              </Tooltip>
            </div>
          </div>
          <div className=" w-full border-t  pt-0 p-6">
            <div className="py-0 w-full flex lg:flex-no-wrap flex-wrap lg:flex-row justify-between items-center"></div>
            <div className="w-full max-h-[86vh] overflow-y-auto lg:p-10 p-5">
              {false ? (
                <TableSkeleton active={true} />
              ) : (
                <Table
                  columns={columns}
                  rowKey={(record) => record.id}
                  className="domain-request-table"
                  dataSource={report}
                  pagination={{
                    onChange(current) {
                      setPage(current);
                      // setPaginationSize(pageSize)
                    },
                    defaultPageSize: 10,
                    hideOnSinglePage: false,
                    showSizeChanger: true,
                  }}
                  loading={false}
                  onChange={handleTableChange}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <Modal
        title="Student Records"
        open={open}
        onCancel={(e) => setOpen(false)}
        className="custom-modal"
        width={900}
        centered
      >
        <ApplicantsSkillReport report={fullReport} />
      </Modal>
    </div>
  );
}
