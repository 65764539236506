import React, { useState } from "react";
import { HeartOutlined, ShareAltOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { Modal, Tooltip } from "antd";
import SocialShare from "../../../../components/socialShare/SocialShare";
import { message } from "../../../../constant/message";
export const AssessmentCard = (props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <section className="border h-full relative">
        {!props?.expire && (
          <div className="cursor-pointer absolute z-[80] opacity-90  bg-white top-0 bottom-0 left-0 right-0  inset-0 flex justify-center items-center border-md ">
            <div className="p-0 flex space-y-4 flex-col justify-center items-center text-lg text-black text-center font-primary font-bold ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-14 h-14 text-brand-500"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M16.5 10.5V6.75a4.5 4.5 0 1 0-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 0 0 2.25-2.25v-6.75a2.25 2.25 0 0 0-2.25-2.25H6.75a2.25 2.25 0 0 0-2.25 2.25v6.75a2.25 2.25 0 0 0 2.25 2.25Z"
                />
              </svg>

              <p>Assessment-Expired</p>
            </div>
          </div>
        )}
        <div className="group hover:shadow-2xl hover:bg-gray-50 transition-all	 duration-500 bg-white h-full border p-3  shadow-lg rounded-xl">
          <Link
            data-testid="assessmentStudent"
            to={props.route}
            className="z-0 relative"
          >
            {/* properties */}

            <div className="flex flex-row justify-between p-[5px]">
              <div>
                <div className="flex flex-row">
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="#fab814"
                      className="w-[30px] h-[30px]"
                    >
                      <path
                        fillRule="evenodd"
                        d="M11.99 2.243a4.49 4.49 0 0 0-3.398 1.55 4.49 4.49 0 0 0-3.497 1.306 4.491 4.491 0 0 0-1.307 3.498 4.491 4.491 0 0 0-1.548 3.397c0 1.357.6 2.573 1.548 3.397a4.491 4.491 0 0 0 1.307 3.498 4.49 4.49 0 0 0 3.498 1.307 4.49 4.49 0 0 0 3.397 1.549 4.49 4.49 0 0 0 3.397-1.549 4.49 4.49 0 0 0 3.497-1.307 4.491 4.491 0 0 0 1.306-3.497 4.491 4.491 0 0 0 1.55-3.398c0-1.357-.601-2.573-1.549-3.397a4.491 4.491 0 0 0-1.307-3.498 4.49 4.49 0 0 0-3.498-1.307 4.49 4.49 0 0 0-3.396-1.549Zm3.53 7.28a.75.75 0 0 0-1.06-1.06l-6 6a.75.75 0 1 0 1.06 1.06l6-6Zm-5.78-.905a1.125 1.125 0 1 0 0 2.25 1.125 1.125 0 0 0 0-2.25Zm4.5 4.5a1.125 1.125 0 1 0 0 2.25 1.125 1.125 0 0 0 0-2.25Z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </div>
                  <h1 className="text-black pt-[5px] pl-[5px] duration-500 group-hover:text-brand-500">
                    {" "}
                    Badge : {message.assessmentBadgePercent}%
                  </h1>
                </div>
                {/* <div className="flex flex-row">
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      className="w-[25px] h-[20px]"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M17.25 6.75 22.5 12l-5.25 5.25m-10.5 0L1.5 12l5.25-5.25m7.5-3-4.5 16.5"
                      />
                    </svg>
                  </div>
                  <h1 className="text-black pl-[5px] duration-500 group-hover:text-brand-500">
                    {" "}
                    Questions:50{" "}
                  </h1>
                </div> */}
              </div>
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  width="2em"
                  height="2em"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M12 6v6h4.5m4.5 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                  />
                </svg>
                <h1 className="text-black duration-500 group-hover:text-brand-500">
                  {" "}
                  {props?.duration} MM
                </h1>
              </div>
            </div>
            <div className="h-44 overflow-hidden group-hover:rounded-lg">
              <img
                className="h-full group-hover:scale-125 group-hover:rounded-lg transition-all	 duration-500 w-full object-cover rounded-lg object-center"
                src={
                  props?.image &&
                  `${process.env.REACT_APP_MEDIA_URL}/api/media/download${props?.image}`
                }
                alt="asessment-cover-image"
              />
            </div>

            <div className="relative z-0">
              <h1 className="overflow-para word-wrap: break-word; text-xl font-semibold transition-all	 duration-500 group-hover:text-brand-500 text-black font-primary pt-2 pb-0">
                {props?.header}
              </h1>

              <div
                className="overflow-para text-sm text-gray-600 font-primary pb-2 truncated"
                title={props?.description}
              >
                {props?.description}
              </div>
              <p className="overflow-para  pb-2 my-0 py-0 text-base  text-gray-500 font-primary">
                {props?.admin}
              </p>
            </div>
          </Link>
          <Modal
            title="Share"
            centered
            open={isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
            okButtonProps={{ disabled: true }}
            cancelButtonProps={{ disabled: true }}
          >
            <SocialShare shareUrl={props?.route} />
          </Modal>
          <div className="flex justify-center items-center bottom-3 right-4 absolute z-50 gap-2">
            <p className="cursor-pointer mb-[-12px] justify-center">
              {props?.status == "true" ? (
                <Tooltip title="DisLike">
                  <svg
                    width="2em"
                    height="2em"
                    fill="currentColor"
                    viewBox="0 0 1024 1024"
                    style={{ color: "red" }}
                    onClick={props?.removeFav}
                  >
                    <path d="M923 283.6c-13.4-31.1-32.6-58.9-56.9-82.8-24.3-23.8-52.5-42.4-84-55.5-32.5-13.5-66.9-20.3-102.4-20.3-49.3 0-97.4 13.5-139.2 39-10 6.1-19.5 12.8-28.5 20.1-9-7.3-18.5-14-28.5-20.1-41.8-25.5-89.9-39-139.2-39-35.5 0-69.9 6.8-102.4 20.3-31.4 13-59.7 31.7-84 55.5-24.4 23.9-43.5 51.7-56.9 82.8-13.9 32.3-21 66.6-21 101.9 0 33.3 6.8 68 20.3 103.3 11.3 29.5 27.5 60.1 48.2 91 32.8 48.9 77.9 99.9 133.9 151.6 92.8 85.7 184.7 144.9 188.6 147.3l23.7 15.2c10.5 6.7 24 6.7 34.5 0l23.7-15.2c3.9-2.5 95.7-61.6 188.6-147.3 56-51.7 101.1-102.7 133.9-151.6 20.7-30.9 37-61.5 48.2-91 13.5-35.3 20.3-70 20.3-103.3 0.1-35.3-7-69.6-20.9-101.9z" />
                  </svg>
                </Tooltip>
              ) : (
                <Tooltip title="Like">
                  <HeartOutlined
                    style={{ fontSize: "2em" }}
                    onClick={props?.addFav}
                  />
                </Tooltip>
              )}
            </p>

            <p
              className="justify-center mb-[-12px] text-[2em] cursor-pointer"
              onClick={showModal}
            >
              <ShareAltOutlined />
            </p>
          </div>
          {props.url && (
            <p className="my-0 w-full py-0 text-xs  text-gray-500 font-primary border-t pt-3">
              <a
                href={props.url}
                className="flex flex-row items-center"
                target="_blank"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={2}
                  stroke="currentColor"
                  className="w-5 h-5 mr-1"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 21a9.004 9.004 0 008.716-6.747M12 21a9.004 9.004 0 01-8.716-6.747M12 21c2.485 0 4.5-4.03 4.5-9S14.485 3 12 3m0 18c-2.485 0-4.5-4.03-4.5-9S9.515 3 12 3m0 0a8.997 8.997 0 017.843 4.582M12 3a8.997 8.997 0 00-7.843 4.582m15.686 0A11.953 11.953 0 0112 10.5c-2.998 0-5.74-1.1-7.843-2.918m15.686 0A8.959 8.959 0 0121 12c0 .778-.099 1.533-.284 2.253m0 0A17.919 17.919 0 0112 16.5c-3.162 0-6.133-.815-8.716-2.247m0 0A9.015 9.015 0 013 12c0-1.605.42-3.113 1.157-4.418"
                  />
                </svg>
                {props.url}
              </a>
            </p>
          )}
        </div>
      </section>
    </>
  );
};
