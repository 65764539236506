import React, { HtmlHTMLAttributes, useEffect, useState } from "react";
import "./HtmlEditor.css";
import { Tooltip } from "antd";

export default function HtmlEditor() {
  const [j, setJ] = useState(0);
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState(false);
  const [isAligendRows, setIsAlignedRows] = useState(true);
  //Function for live Rendering
  function update(i) {
    setMessage("");
    if (i == 0) {
      let htmlCode = (document.getElementById("htmlCode") as HTMLInputElement)
        ?.value;
      let cssCode = (document.getElementById("cssCode") as HTMLInputElement)
        ?.value;
      let javascriptCode = (
        document.getElementById("javascriptCode") as HTMLInputElement
      )?.value;
      if (
        htmlCode.trim().length > 0 &&
        cssCode.trim().length > 0 &&
        javascriptCode.trim().length
      ) {
        setStatus(true);
      } else {
        setStatus(false);
      }
      let text =
        htmlCode +
        "<style>" +
        cssCode +
        "</style>" +
        "<scri" +
        "pt>" +
        javascriptCode +
        "</scri" +
        "pt>";
      let iframe = (document.getElementById("viewer") as any)?.contentWindow
        .document;
      iframe?.open();
      iframe?.write(text);
      iframe?.close();
    } else if (i == 1) {
      let htmlCode = (document.getElementById("htmlCode") as HTMLInputElement)
        ?.value;
      let html = htmlCode.slice(0, htmlCode.length);
      (document.getElementById("htmlCode") as HTMLInputElement).value = html;
      setJ(1);
    }
  }

  //Auto Tag Closing functionality
  const closeChars = new Map([
    ["{", "}"],
    ["[", "]"],
    ["(", ")"],
    ["<", ">"],
    ['"', '"'],
    ["'", "'"],
  ]);
  const editHtml = () => {
    //Handling Html Code Auto Closing
    const htmlCode = document.getElementById("htmlCode") as HTMLInputElement;
    htmlCode.addEventListener("input", function (e: any) {
      if (j != 1) {
        const pos = e?.target?.selectionStart;
        const val = [...e.target.value];
        const char = val.slice(pos - 1, pos)[0];
        const closeChar = closeChars.get(char);
        if (closeChar) {
          val.splice(pos, 0, closeChar);
          // e.target.value = val.join("");
          e.target.selectionEnd = pos;
        }
      }
      setJ(0);
    });

    //Handling CSS Code Auto Closing
    const cssCode = document.getElementById("cssCode") as HTMLInputElement;
    cssCode.addEventListener("input", function (e: any) {
      if (j != 1) {
        const pos = e.target.selectionStart;
        const val = [...e.target.value];
        const char = val.slice(pos - 1, pos)[0];
        const closeChar = closeChars.get(char);
        if (closeChar) {
          val.splice(pos, 0, closeChar);
          // e.target.value = val.join("");
          e.target.selectionEnd = pos;
        }
      }
      setJ(0);
    });

    //Handling Javascript Code Auto Closing
    const javascriptCode = document.getElementById(
      "javascriptCode"
    ) as HTMLInputElement;
    javascriptCode.addEventListener("input", function (e: any) {
      if (j != 1) {
        const pos = e.target.selectionStart;
        const val = [...e.target.value];

        const char = val.slice(pos - 1, pos)[0];
        const closeChar = closeChars.get(char);

        if (closeChar) {
          val.splice(pos, 0, closeChar);
          // e.target.value = val.join("");
          e.target.selectionEnd = pos;
        }
      }
      setJ(0);
    });

    // Split([".container", ".iframe-container"]);
  };

  const handleHtml = (event: any) => {
    if (event.key === 9) {
      var v = event.target.value,
        s = event.target.selectionStart,
        e = event.target.selectionEnd;
      event.target.value = v.substring(0, s) + "\t" + v.substring(e);
      event.target.selectionStart = event.target.selectionEnd = s + 1;
      return false;
    }
    if (event.key == 8) {
      update(1);
    }
  };
  function saveFile() {
    var fileName = Date.now();
    if (
      fileName != null &&
      ((document.getElementById("htmlCode") as HTMLInputElement).value != "" ||
        (document.getElementById("cssCode") as HTMLInputElement).value != "" ||
        (document.getElementById("javascriptCode") as HTMLInputElement).value !=
          "")
    ) {
      setMessage("");
      var htmlCode = (document.getElementById("htmlCode") as HTMLInputElement)
        .value;
      var cssCode = (document.getElementById("cssCode") as HTMLInputElement)
        .value;
      var javascriptCode = (
        document.getElementById("javascriptCode") as HTMLInputElement
      ).value;
      let text =
        htmlCode +
        "<style>" +
        cssCode +
        "</style>" +
        "<scri" +
        "pt>" +
        javascriptCode +
        "</scri" +
        "pt>";
      download(text, fileName + ".html", "text/plain");
    } else {
      setMessage("All fields should be mandatory");
    }
  }
  useEffect(() => {
    editHtml();
  }, []);

  // This function is from https://stackoverflow.com/questions/3665115/create-a-file-in-memory-for-user-to-download-not-through-server
  function download(data, filename, type) {
    var userFile = new Blob([data], { type: type });
    var a = document.createElement("a"),
      url = URL.createObjectURL(userFile);
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    setTimeout(function () {
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    }, 0);
  }

  const alignedRows = () => {
    if (isAligendRows) {
      (
        document.getElementById("mainContainer") as HTMLStyleElement
      ).style.flexDirection = "row";
      setIsAlignedRows(false);
    } else {
      (
        document.getElementById("mainContainer") as HTMLStyleElement
      ).style.flexDirection = "column";
      setIsAlignedRows(true);
    }
    // setIsAlignedRows(true);
    (document.getElementById("htmlCode") as HTMLStyleElement).style.height =
      "60vh";
    (document.getElementById("cssCode") as HTMLStyleElement).style.height =
      "60vh";

    (
      document.getElementById("javascriptCode") as HTMLStyleElement
    ).style.height = "60vh";
  };

  return (
    <div>
      <button
        id="btn"
        disabled={status ? false : true}
        onClick={() => saveFile()}
      >
        Save File
      </button>
      <Tooltip title="View" placement="rightTop">
        <button className="w-4 ml-2" onClick={() => alignedRows()}>
          <svg viewBox="0 0 20 20" className="">
            <path d="M0 9.002C0 8.45.455 8 .992 8h18.016c.548 0 .992.456.992 1.002v9.996c0 .553-.455 1.002-.992 1.002H.992C.444 20 0 19.544 0 18.998V9.002Zm0-8C0 .45.451 0 .99 0h4.02A.99.99 0 0 1 6 1.003v4.994C6 6.551 5.549 7 5.01 7H.99A.99.99 0 0 1 0 5.997V1.003Zm7 0C7 .45 7.451 0 7.99 0h4.02A.99.99 0 0 1 13 1.003v4.994C13 6.551 12.549 7 12.01 7H7.99A.99.99 0 0 1 7 5.997V1.003Zm7 0C14 .45 14.451 0 14.99 0h4.02A.99.99 0 0 1 20 1.003v4.994C20 6.551 19.549 7 19.01 7h-4.02A.99.99 0 0 1 14 5.997V1.003Z"></path>
          </svg>
        </button>
      </Tooltip>
      <div className="htmlcontainer" id="mainContainer">
        {/* <!-- Text area for Html Code  --> */}
        <textarea
          id="htmlCode"
          placeholder="Type HTML code here"
          spellCheck="false"
          onInput={() => update(0)}
          onKeyDown={(event) => {
            handleHtml(event);
          }}
        ></textarea>
        {/* <!-- Text area for Css Code  --> */}
        <textarea
          id="cssCode"
          placeholder="Type CSS code here"
          spellCheck="false"
          onInput={() => update(0)}
          onKeyDown={(event) => {
            handleHtml(event);
          }}
        ></textarea>
        {/* <!-- Text area for Javascript Code  --> */}
        <textarea
          id="javascriptCode"
          spellCheck="false"
          placeholder="Type JavaScript code here"
          onInput={() => update(0)}
          onKeyDown={(event) => {
            handleHtml(event);
          }}
        ></textarea>
      </div>
      {message && <p className="text-red-500 font-base">{message}</p>}
      <div className="iframe-container split">
        <iframe id="viewer"></iframe>
      </div>
    </div>
  );
}
