import React from 'react';
import { Gauge } from '@ant-design/plots';

export const StudentResultReport = ({mark}) => {
    const config = {
        percent: mark,
        range: {
          ticks: [0, 1 / 3, 3 / 4, 1],
          color: ['#FF0002', '#FAAD14', '#30BF78'],
        },
        indicator: {
          pointer: {
            style: {
              stroke: '#000',
            },
          },
          pin: {
            style: {
              stroke: '#000',
            },
          },
        },
        axis: {
          label: {
            formatter(v: any) {
              return Number(v);
            },
          },
          subTickLine: {
            count: 5,
          },
        },
        statistic: {
          content: {
            style: {
              fontSize: '24px',
              lineHeight: '36px',
              color:'black',
              marginTop:"24px"
            },
          },
        },
      };
      return <Gauge style={{backgroundColor:"transparent"}} {...config} />;
      <h1>fgdg</h1>
}
