import React, { Fragment, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { loginContext } from "../components/context/LoginContext";
import { getUsers } from "../serviceApi/api";
import { useAuth0 } from "@auth0/auth0-react";

interface Props {
  children: JSX.Element;
}

const SuperAdminProtectRoute = ({ children }: Props) => {
  const navigate = useNavigate();
  const { user } = useAuth0();
  const email: any = user?.email;

  const { role, setRole }: any = useContext(loginContext);

  const getUserInfo = async (email: string) => {
    const userInfo = await getUsers(email);
    if (userInfo?.data?.user == null) {
      navigate("/profile");
    } else {
      setRole(userInfo?.data?.user[0]?.role);
    }
  };
  const token = window?.localStorage.getItem("@@Auth");

  useEffect(() => {
    if (email) {
      getUserInfo(email);
    }
    const newUrl = window?.localStorage?.getItem("url");
    const userRouter = window?.localStorage?.getItem("Role");
    if (newUrl) {
      if (userRouter == "SuperAdmin") {
        navigate(newUrl);
      }
      if (window.localStorage.getItem("isPasted") == "1") {
        window.localStorage.removeItem("url");
        window.localStorage.removeItem("isPasted");
        window.localStorage.removeItem("Role");
      }
    }
  }, [email]);

  useEffect(() => {
    if (!token) {
      if (window.localStorage.getItem("isPasted") == null) {
        window.localStorage.setItem("url", window.location.pathname);
        window.localStorage.setItem("isPasted", "1");
        window.localStorage.setItem("Role", "SuperAdmin");
      }
      navigate("/");
    } else if (role && role != "SuperAdmin") {
      navigate("/404");
    }
  }, [role]);

  return <Fragment>{token && role === "SuperAdmin" && children}</Fragment>;
};

export default SuperAdminProtectRoute;
