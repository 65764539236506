import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router";
import { useAuth0 } from "@auth0/auth0-react";
import { DatePicker, DatePickerProps, Modal, Select } from "antd";
import moment from "moment";
import { InputField } from "../../../../components/inputFields/InputField";
import Swal from "sweetalert2";
import { getCookie } from "../../../../utils";
import { containsSpecialChars } from "../../../../utils/validation";
import { BreadcrumbComp } from "../../../../components/Breadcrumb/Breadcrumb";
import { getDomainNamebyuser } from "../../../../serviceApi/adminApi/DomainApi";
import { NavLink } from "react-router-dom";
import {
  createEvent,
  getEventsById,
  updateEvents,
} from "../../../../serviceApi/adminApi/EventApi";

export const CreateEvent = (props) => {
  const navigate = useNavigate();
  const { eventid }: any = useParams();
  const [startDate, setStartDate] = useState<any>("");
  const [endDate, setEndDate] = useState<any>("");
  const { user } = useAuth0();
  const userId: any = user?.email;
  const [name, setName] = useState("");

  const [assessmentExpireDate, setAssessmentExpireDate] = useState();
  const [formStatus, setFormStatus] = useState(false);
  const [showModal, setShowModal] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [coverImagePreview, setCoverImagePreview] = useState<any>();
  const [coverImage, setCoverImage] = useState<any>(null);
  const [description, setDescription] = useState("");
  const [assessmentImageEdit, setAssessmentImageEdit] = useState("");
  const [assessmentNameErr, setAssessmentNameErr] = useState();
  const [isAuthenticate, setIsAuthenticate] = useState(false);
  const [speakers, setSpeakers] = useState<any>([{}]);
  const [eventType, setEventType] = useState();
  const [notify, setNotify] = useState(true);
  const [speakerCoverImage, setSpeakerCoverImage] = useState<any>([]);
  const [getEventData, setEventData] = useState<any>();
  const [descriptionErr, setDescritionErr] = useState<any>();

  const addSpeaker = () => {
    setSpeakers([...speakers, { uuid: "", name: "", image: "" }]);
  };

  useEffect(() => {
    if (eventid) {
      getEvent(eventid);
    }
  }, [eventid]);

  const saveEvent = async () => {
    const response = await createEvent({
      name,
      event_start_date: startDate,
      event_end_date: endDate,
      description,
      event_type: eventType,
      speaker_details: JSON.stringify(speakers),
      notification: notify,
      // address: "",
      userId,
      // message: "",
    });
    if (response?.data?.createEvent?.status) {
      setMessage("This event name exist");
    } else if (response?.data?.createEvent?.uuid) {
      const formData = new FormData();
      formData.append("image", coverImage);
      formData.append("uuid", response?.data?.createEvent?.uuid);
      formData.append("type", "event");
      const { data }: any = await axios.post(
        `${process.env.REACT_APP_MEDIA_URL}/api/media/upload`,
        formData,
        {
          headers: {
            enctype: "multipart/form-data",
            Authorization: `Bearer ${getCookie("token")}`,
          },
        }
      );
      if (data?.status === true) {
        for (let i in speakers) {
          const formData = new FormData();
          formData.append("image", speakers[i].image);
          formData.append("uuid", response?.data?.createEvent?.uuid);
          formData.append("type", "event");
          const { data }: any = await axios.post(
            `${process.env.REACT_APP_MEDIA_URL}/api/media/upload`,
            formData,
            {
              headers: {
                enctype: "multipart/form-data",
                Authorization: `Bearer ${getCookie("token")}`,
              },
            }
          );
        }
        Swal.fire({
          icon: "success",
          title: "Event Created Successfully",
          showConfirmButton: false,
          timer: 3000,
        });
        navigate("/subadmin/AllDomains/list");
      } else {
        Swal.fire({
          icon: "error",
          title: "Something went wrong please try again!",
          showConfirmButton: false,
          timer: 3000,
        });
      }
    } else {
      Swal.fire({
        icon: "warning",
        title: "Invalid data remove symbols",
        showConfirmButton: false,
        timer: 3000,
      });
    }
  };

  const getDomainsListbyUser = async () => {
    const domains = await getDomainNamebyuser(user?.email);
    if (domains?.data?.domainNameByuser?.length <= 0) {
      setShowModal(true);
    } else {
      setShowModal(false);
      setIsAuthenticate(true);
    }
  };

  const handleSpeakerName = (event, data, index) => {
    const list = [...speakers];
    list[index] = { name: data.name, image: data?.image };
    setSpeakers(list);
  };

  const editEvent = async (e, userId) => {
    const createAssesment = await updateEvents({
      name,
      event_start_date: startDate,
      event_end_date: endDate,
      description,
      event_type: eventType,
      speaker_details: JSON.stringify(speakers),
      notification: notify,
      // address: "",
      userId,
      // message: "",
    });

    if (createAssesment?.data?.updateEvents?.message) {
      Swal.fire({
        icon: "warning",
        title: "Data Update successfully",
        showConfirmButton: false,
        timer: 3000,
      });
      navigate(`/subadmin/admin/create-event`);
    } else {
      Swal.fire({
        icon: "warning",
        title: "Invalid data remove symbols",
        showConfirmButton: false,
        timer: 3000,
      });
    }
  };

  const getEvent = async (eventid) => {
    const data = await getEventsById(eventid);
    if (data?.data?.getEventsById.length > 0) {
      const events = data?.data?.getEventsById[0];
      setName(events?.name);
      setDescription(events?.description);
      setCoverImagePreview(events?.image);
      setStartDate(
        moment(events?.event_start_date, "x").format("YYYY-MM-DD HH:mm:ss")
      );
      setEndDate(
        moment(events?.event_end_date, "x").format("YYYY-MM-DD HH:mm:ss")
      );
      setEventType(events?.event_type);
    }
    setEventData(data?.data?.getEventsById[0]);
  };

  const removeSpeakers = (data, index) => {
    const list = [...speakers];
    list.splice(index, 1);
    setSpeakers(list);
  };
  const handleChange = (newValue: any) => {
    setAssessmentExpireDate(newValue);
  };

  const handleType = async (e: any) => {
    setEventType(e);
  };
  const deleteCoverImage = () => {
    setCoverImagePreview(URL.revokeObjectURL(coverImagePreview));
    setCoverImage(null);
  };

  const deleteSpeakerImage = (e, data, index) => {
    const list = [...speakers];
    list[index] = { name: data?.name, image: "" };
    setSpeakers(list);
    speakerCoverImage[index] = "";
    setSpeakerCoverImage([...speakerCoverImage]);
  };
  const speakerImageUpload = (
    event: React.ChangeEvent<HTMLInputElement> | any,
    data,
    index
  ) => {
    event.preventDefault();
    event.stopPropagation();
    const list = [...speakers];

    const selectedFiles = event.target.files[0];
    list[index] = { name: data?.name, image: selectedFiles };
    setSpeakers(list);

    if (selectedFiles.size >= 2097152) {
      Swal.fire({
        icon: "warning",
        title: "Image size should be max 2MB.",
        showConfirmButton: false,
        timer: 3000,
      });
    } else if (
      selectedFiles.type === "image/png" ||
      selectedFiles.type === "image/jpg" ||
      selectedFiles.type === "image/jpeg"
    ) {
      setSpeakerCoverImage([
        ...speakerCoverImage,
        URL.createObjectURL(selectedFiles),
      ]);
    } else {
      Swal.fire({
        icon: "warning",
        title: "Image formate should be png,jpg,jpeg",
        showConfirmButton: false,
        timer: 3000,
      });
    }
  };
  const DomainImageUpload = (
    event: React.ChangeEvent<HTMLInputElement> | any
  ) => {
    event.preventDefault();
    event.stopPropagation();

    const selectedFiles = event.target.files[0];
    if (selectedFiles.size >= 2097152) {
      Swal.fire({
        icon: "warning",
        title: "Image size should be max 2MB.",
        showConfirmButton: false,
        timer: 3000,
      });
    } else if (
      selectedFiles.type === "image/png" ||
      selectedFiles.type === "image/jpg" ||
      selectedFiles.type === "image/jpeg"
    ) {
      setCoverImage(selectedFiles);
      setCoverImagePreview(URL.createObjectURL(selectedFiles));
    } else {
      Swal.fire({
        icon: "warning",
        title: "Image formate should be png,jpg,jpeg",
        showConfirmButton: false,
        timer: 3000,
      });
    }
  };

  useEffect(() => {
    if (
      name &&
      !containsSpecialChars(name) &&
      name?.trim()?.length <= 60 &&
      name?.trim()?.length >= 2 &&
      (coverImage || assessmentImageEdit) &&
      description?.trim()?.length >= 250 &&
      (eventType === 0 || eventType === 1) &&
      startDate &&
      endDate
    ) {
      setFormStatus(false);
    } else {
      setFormStatus(true);
    }
  }, [name, startDate, endDate, description, coverImage, eventType]);

  const breadcrumbData = [
    {
      path: "/subadmin/AllDomains/list",
      text: "Dashboard",
    },

    { text: "Edit Event" },
  ];

  const navigatePageToDomain = () => {
    navigate("/subadmin/AllDomains/list");
  };

  useEffect(() => {
    getDomainsListbyUser();
  });

  const handleNotify = () => {
    setNotify(!notify);
  };
  const onChange: DatePickerProps["onChange"] = (_, dateStr) => {
    setStartDate(dateStr);
  };
  const onChangeEndDate: DatePickerProps["onChange"] = (_, dateStr) => {
    setEndDate(dateStr);
  };

  const handleBlur = (e) => {
    const text = e.target.value;
    if (text.length < 250) {
      setDescritionErr(true);
    } else {
      setDescritionErr(false);
    }
  };

  const handleDescritionChange = (e) => {
    if (e.length < 250) {
      setDescritionErr(true);
    } else {
      setDescritionErr(false);
    }

    setDescription(e);
  };

  return (
    <>
      {isAuthenticate ? (
        <>
          <div className="w-full max-h-[86vh] overflow-y-auto lg:p-10 p-5">
            {eventid && (
              <div className="my-2">
                <p className=" text-black mb-0 font-primary text-2xl font-medium">
                  Event/Workshop Edit
                </p>
                <BreadcrumbComp breadcrumbData={breadcrumbData} />
              </div>
            )}

            <div className="flex items-start mt-5 flex-col h-26 p-6 rounded-lg bg-white border border-gray-200  shadow-xl lg:w-full md:w-full xl:w-full">
              <div className="grid xl:grid-cols-2 lg:grid-cols-2 gap-2 lg:gap-x-5 w-full">
                <div className="p-2  border-r-4 border-blue-500 relative">
                  <p className=" text-black mb-0 font-primary text-2xl font-medium">
                    Event/Workshop Details
                  </p>
                  <div>
                    <InputField
                      id="Event name"
                      placeholder="Enter Event Name.."
                      type="text"
                      value={name}
                      onChange={setName}
                      containsSpecialCharsMsg={setAssessmentNameErr}
                    />
                    <span className="text-red-500 absolute mt-[2px]">
                      {assessmentNameErr}
                    </span>
                  </div>
                  <div className="w-full mt-6">
                    <div className="w-full lg:w-full mt-2">
                      <label
                        htmlFor="event_start_time "
                        className="font-primary capitalize text-sm lg:text-base"
                      >
                        Event Start Date
                      </label>
                      <span className="text-red-600 text-lg"> *</span>
                    </div>

                    <DatePicker
                      // defaultValue={moment().add(0, "days")}
                      showTime
                      onChange={onChange}
                      disabledDate={(current) => {
                        return moment().subtract(1, "days") >= current;
                      }}
                      className="eventDatepicker lg:p-3.5 p-3.5 text-sm lg:mt-2 border border-gray-300 cursor-text focus:outline-none w-full rounded-lg"
                    />
                  </div>

                  <div className="w-full mt-6">
                    <div className="w-full lg:w-full mt-2 relative">
                      <label
                        htmlFor="event_start_time "
                        className="font-primary capitalize text-sm lg:text-base"
                      >
                        Event End Date
                      </label>
                      <span className="text-red-600 text-lg"> *</span>
                    </div>

                    <div>
                      <DatePicker
                        // defaultValue={moment().add(1, "days")}
                        showTime
                        disabledDate={(current) => {
                          return moment().subtract(1, "days") >= current;
                        }}
                        onChange={onChangeEndDate}
                        className="eventDatepicker w-full border-spacing-2 p-2"
                      />
                    </div>
                  </div>

                  <div className="mt-6">
                    <label
                      htmlFor="assesment-time "
                      className="font-primary capitalize text-sm lg:text-base"
                    >
                      Event Description
                      <span className="text-red-600 text-lg"> *</span>
                    </label>
                    <textarea
                      className="lg:p-4 h-36 p-2 text-sm lg:mt-[-118] border border-gray-300 w-full focus:outline-none rounded-lg"
                      name=""
                      id=""
                      value={description}
                      onChange={(e) => handleDescritionChange(e.target.value)}
                      onBlur={(e) => {
                        handleBlur(e);
                      }}
                      placeholder="Event description..."
                    ></textarea>

                    {description.length < 250 || descriptionErr ? (
                      <span className="text-red-600">
                        Description must be min. 250 characters
                      </span>
                    ) : (
                      ""
                    )}
                  </div>

                  <div className="w-full lg:w-full mt-6">
                    <p className="lg:text-sm text-sm pb-0 mb-1 md:mb-2 lg:mb-2  capitalize text-black">
                      Select Event Type <span className="text-red-600">*</span>
                    </p>
                    <Select
                      className="md:w-full w-full assessment-validity rounded-md"
                      defaultValue={eventType}
                      value={eventType}
                      onChange={handleType}
                      placeholder="Select Event Type"
                      filterSort={(optionA, optionB) =>
                        (optionA?.label ?? "")
                          .toLowerCase()
                          .localeCompare((optionB?.label ?? "").toLowerCase())
                      }
                      options={[
                        {
                          value: 0,
                          label: "Offline",
                        },
                        {
                          value: 1,
                          label: "Online",
                        },
                      ]}
                    />
                  </div>

                  <div className="flex flex-col mt-6">
                    <label className="text-base font-primary lg:text-base pb-0  mb-2   text-black ">
                      Event image <span className="text-red-500">*</span>
                    </label>
                    {coverImagePreview ? (
                      <div className="h-44 border relative  mb-10 w-44">
                        <img
                          className="h-full w-full p-1 rounded-lg object-cover object-center"
                          src={coverImagePreview}
                          alt={coverImagePreview}
                        />
                        <div
                          onClick={deleteCoverImage}
                          className="bg-black cursor-pointer  w-8 h-8 flex items-center justify-center absolute top-0 right-0 rounded-full"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-5 h-5 mx-auto text-white"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                            />
                          </svg>
                        </div>
                      </div>
                    ) : (
                      <button type="submit" className="h-44 w-44">
                        <div className="flex items-center justify-center">
                          <label
                            data-testid="assessmentImage"
                            htmlFor="dropzone-file"
                            className="flex flex-col items-center justify-center w-full h-auto border-2 bg-gray-50 border-gray-300 border-dashed rounded-lg cursor-pointer  dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
                          >
                            <div className="flex flex-col items-center justify-center pt-5 pb-6">
                              <svg
                                aria-hidden="true"
                                className="w-10 h-10 mb-3 text-gray-400"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                                ></path>
                              </svg>
                              <p className="mb-2 text-sm font-primary text-gray-500 dark:text-gray-400">
                                <span className="font-semibold font-primary">
                                  Click to upload
                                </span>{" "}
                              </p>
                              <p className="text-xs text-center text-gray-500 dark:text-gray-400">
                                PNG, JPG or JPEG (MAX Size. 2MB)
                              </p>
                            </div>
                            {assessmentImageEdit ? (
                              <div className="group  -mt-44 relative bg-white p-1 rounded-xl border border-dashed border-black">
                                <div className="h-full text-2xl w-full group-hover:opacity-100 opacity-0 absolute z-20  text-white flex items-center justify-center inset-0">
                                  <svg
                                    aria-hidden="true"
                                    className="w-10 h-10 mb-3 text-white"
                                    fill="none"
                                    stroke="currentColor"
                                    viewBox="0 0 24 24"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth="2"
                                      d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                                    ></path>
                                  </svg>
                                  <p className="mb-2 text-sm text-text-white dark:text-gray-400">
                                    <span className="font-semibold">
                                      Click to upload
                                    </span>{" "}
                                  </p>
                                </div>
                                <div className="absolute group-hover:bg-black opacity-40 text-center w-full h-full rounded-xl  text-white"></div>
                                <img
                                  className="w-44 h-44 object-cover object-center rounded-xl"
                                  src={`${process.env.REACT_APP_MEDIA_URL}/api/media/download${assessmentImageEdit}`}
                                  alt={assessmentImageEdit}
                                />
                              </div>
                            ) : null}
                            <input
                              id="dropzone-file"
                              onChange={(e) => DomainImageUpload(e)}
                              type="file"
                              className="hidden"
                            />
                          </label>
                        </div>
                      </button>
                    )}
                  </div>
                </div>
                <div className="p-2 relative">
                  <p className=" text-black mb-0 font-primary text-2xl font-medium">
                    Speaker Details
                  </p>

                  {speakers?.map((speaker, index) => {
                    return (
                      <div key={index} className="relative">
                        <div className="mt-2">
                          <label className="font-primary capitalize text-sm lg:text-base">
                            Speaker name {index + 1}
                          </label>
                          <input
                            type="text"
                            placeholder="Enter Speaker Name"
                            value={speaker?.name}
                            onChange={(e) =>
                              handleSpeakerName(e, speaker, index)
                            }
                            className={`${`lg:p-3.5 p-3.5`} text-sm lg:mt-2 border border-gray-300 ${"cursor-text"}  focus:outline-none w-full rounded-lg`}
                          ></input>
                          <span className="text-red-500 absolute mt-[5px]">
                            {/* {speakerNameErr} */}
                          </span>
                        </div>
                        {speakers?.length > 1 && (
                          <svg
                            onClick={() => removeSpeakers(speaker, index)}
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="absolute right-[-24px] mt-[-35px] w-6 h-6 cursor-pointer flex"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                            />
                          </svg>
                        )}
                        <div className="flex flex-col mt-6">
                          <label className="text-base font-primary lg:text-base pb-0  mb-2   text-black ">
                            Speaker image {index + 1}{" "}
                            {/* <span className="text-red-500">*</span> */}
                          </label>
                          {speaker?.image ? (
                            <div className="h-44 border relative  mb-10 w-44">
                              <img
                                className="h-full w-full p-1 rounded-lg object-cover object-center"
                                src={speakerCoverImage[index]}
                                alt={speaker?.image}
                              />
                              <div
                                onClick={(e) =>
                                  deleteSpeakerImage(e, speaker, index)
                                }
                                className="bg-black cursor-pointer  w-8 h-8 flex items-center justify-center absolute top-0 right-0 rounded-full"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  strokeWidth={1.5}
                                  stroke="currentColor"
                                  className="w-5 h-5 mx-auto text-white"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                                  />
                                </svg>
                              </div>
                            </div>
                          ) : (
                            <button type="submit" className="h-44 w-44">
                              <div className="flex items-center justify-center">
                                <label
                                  data-testid="speakerImage"
                                  htmlFor="speakerImage"
                                  className="flex flex-col items-center justify-center w-full h-auto border-2 bg-gray-50 border-gray-300 border-dashed rounded-lg cursor-pointer  dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
                                >
                                  <div className="flex flex-col items-center justify-center pt-5 pb-6">
                                    <svg
                                      aria-hidden="true"
                                      className="w-10 h-10 mb-3 text-gray-400"
                                      fill="none"
                                      stroke="currentColor"
                                      viewBox="0 0 24 24"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                                      ></path>
                                    </svg>
                                    <p className="mb-2 text-sm font-primary text-gray-500 dark:text-gray-400">
                                      <span className="font-semibold font-primary">
                                        Click to upload
                                      </span>{" "}
                                    </p>
                                    <p className="text-xs text-center text-gray-500 dark:text-gray-400">
                                      PNG, JPG or JPEG (MAX Size. 2MB)
                                    </p>
                                  </div>
                                  {assessmentImageEdit ? (
                                    <div className="group  -mt-44 relative bg-white p-1 rounded-xl border border-dashed border-black">
                                      <div className="h-full text-2xl w-full group-hover:opacity-100 opacity-0 absolute z-20  text-white flex items-center justify-center inset-0">
                                        <svg
                                          aria-hidden="true"
                                          className="w-10 h-10 mb-3 text-white"
                                          fill="none"
                                          stroke="currentColor"
                                          viewBox="0 0 24 24"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                                          ></path>
                                        </svg>
                                        <p className="mb-2 text-sm text-text-white dark:text-gray-400">
                                          <span className="font-semibold">
                                            Click to upload
                                          </span>{" "}
                                        </p>
                                      </div>
                                      <div className="absolute group-hover:bg-black opacity-40 text-center w-full h-full rounded-xl  text-white"></div>
                                      <img
                                        className="w-44 h-44 object-cover object-center rounded-xl"
                                        src={`${process.env.REACT_APP_MEDIA_URL}/api/media/download${assessmentImageEdit}`}
                                        alt={assessmentImageEdit}
                                      />
                                    </div>
                                  ) : null}
                                  <input
                                    id="speakerImage"
                                    onChange={(e) =>
                                      speakerImageUpload(e, speaker, index)
                                    }
                                    type="file"
                                    className="hidden"
                                  />
                                </label>
                              </div>
                            </button>
                          )}
                        </div>
                      </div>
                    );
                  })}
                  {speakers?.length < 4 && (
                    <div className="mt-2">
                      <button
                        className={`${
                          false
                            ? "bg-gray-200  text-gray-400  cursor-not-allowed"
                            : "bg-brand-500 text-white cursor-pointer"
                        } w-32 text-center mt-5 rounded-lg font-primary  p-2`}
                        onClick={addSpeaker}
                      >
                        Add Speaker
                      </button>
                    </div>
                  )}
                  {/* <div className="flex flex-col-2 mt-4 gap-2">
                    <input
                      type="checkbox"
                      name="notify"
                      id="notify"
                      checked={notify}
                      onChange={handleNotify}
                    ></input>
                    <label htmlFor="notify">
                      Notify 1 day before of start the Event
                    </label>
                  </div> */}
                </div>
              </div>
              <span style={{ color: "red" }}>{message}</span>
              <div>
                <button
                  onClick={
                    eventid
                      ? (e) => {
                          editEvent(e, eventid);
                        }
                      : saveEvent
                  }
                  disabled={formStatus ? true : false}
                  className={`font-primary px-8  py-2 mt-5 text-white text-lg bg-gray-900 rounded-full ${
                    formStatus ? "opacity-30 cursor-not-allowed" : "opacity-100"
                  } focus:outline-none`}
                >
                  {eventid ? "Update" : "Create"}
                </button>
              </div>
            </div>
          </div>
        </>
      ) : (
        // <DataLoader />
        ""
      )}
      <Modal
        centered
        open={showModal}
        onOk={() => navigatePageToDomain()}
        onCancel={() => navigatePageToDomain()}
      >
        <NavLink to={`/subadmin/AllDomains/list`}>
          <h1>
            Please take approval for at least one Domain before creating Event
          </h1>
          <button className="bg-brand-500 font-primary text-white px-5 py-1 rounded-lg capitalize">
            Take Domain Approval
          </button>
        </NavLink>
      </Modal>
    </>
  );
};
