import { Pagination } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";

export default function CourseCardPaginationData(props) {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSizeData, setPageSizeData] = useState(8);
  const [previousPageToten, setPreviousPageToken] = useState();

  const startIndex = (currentPage - 1) * pageSizeData;
  const endIndex = pageSizeData;

  const getPageAndPageSize = async (page: number, pageSize: number) => {
    // if (page < currentPage) {
    //   getPaginationChange();
    // } else {
    //   getPaginationChange();
    // }
    setCurrentPage(page);
    setPageSizeData(pageSize);
  };

  const getPaginationChange = async () => {
    // axios
    //   .get(
    //     `https://www.googleapis.com/youtube/v3/search?q=Advanced Pandas&type=video&part=snippet&chart=mostPopular&&maxResults=8&&order=viewCount&pageToken=${pageToken}&key=AIzaSyCxiQ_CnN6LMu_1yRYJGSAtdxnbocGIp9U`
    //   )
    //   .then((response) => {
    //     console.log("responses", response.data.items);
    props?.paginatedData(
      props?.data?.slice(
        (currentPage - 1) * pageSizeData,
        currentPage * pageSizeData
      )
    );
    // setPreviousPageToken(response.data?.nextPageToken);
    // })
    // .catch((error) => {
    //   console.log(error.message);
    // });
  };

  useEffect(() => {
    if (currentPage) {
      getPaginationChange();
    }
  }, [props.data, pageSizeData, currentPage, props.userId]);

  useEffect(() => {
    if (props?.getSearchActive) {
      setCurrentPage(1);
      getPaginationChange();
    } else {
      setCurrentPage(1);
      getPaginationChange();
    }
  }, [props?.getSearchActive, props?.name]);
  return (
    <Pagination
      className="common-pagination-main"
      total={props?.data?.length}
      showSizeChanger
      showQuickJumper
      pageSize={pageSizeData}
      onChange={getPageAndPageSize}
      current={currentPage}
      pageSizeOptions={[8, 16, 32, 64, 128]}
      responsive={true}
    />
  );
}
