import { Select } from "antd";
import { useState } from "react";
import { getSearchData } from "../../serviceApi/adminApi/DomainApi";

export const SearchInput = (props) => {
  return (
    <Select
      showSearch
      onChange={(value) => props.typedValue(value)}
      notFoundContent="data not found"
      className=" md:w-full w-full"
      placeholder={props.placeholder}
      filterOption={(input, option: any) =>
        (option?.label?.toLowerCase() ?? "").includes(input)
      }
      filterSort={(optionA, optionB) =>
        (optionA?.label ?? "")
          ?.toLowerCase()
          .localeCompare((optionB?.label ?? "")?.toLowerCase())
      }
      onSearch={props.searchValue}
      tokenSeparators={[","]}
      options={props?.data}
    />
  );
};

export const SearchFilterInput = (props) => {
  const [filterDomains, setFilterDomains] = useState([]);
  const [domainExist, setDomainExist] = useState(true);
  const [selectedName, setSelectedName] = useState("");
  const [isClicked, setIsClicked] = useState(false);

  // const debounce = (fn: any, delay: number) => {
  //   let timer: any;
  //   return function (...args: any) {
  //     clearTimeout(timer);
  //     timer = setTimeout(() => fn(...args), delay);
  //   };
  // };

  const searchDomainInput = async (e) => {
    const { value } = e.target;
    setIsClicked(false);
    // setSelectedName(value);
    setSelectedName(e.target.value);
    if (value != "") {
      props?.name(value);
      getSearchDataValue(value, props.userId, props.type, props.domainId);
    } else
        setDomainExist(true),
        setFilterDomains([]),
        props?.SearchContent("value not filled");
  };

  const handleKeyDown = (e) => {
    if (e.key == "Enter") {
      setDomainExist(true);
      setFilterDomains([]);
    }
  };
  const handleBlur = () => {
    setDomainExist(true);
    setFilterDomains([]);
  };
  const getSearchDataValue = async (value, userId, type, domainId) => {
    const results = await getSearchData(value, userId, type, domainId);
    if (results?.data.searchFeildData?.length > 0) {
      setFilterDomains(results?.data.searchFeildData);
      setDomainExist(true);
      props?.SearchContent(results?.data.searchFeildData);
    } else {
      setDomainExist(false), setFilterDomains([]);
      props?.SearchContent("not found data");
    }
  };

  const selectName = (name) => {
    props?.name("");
    const selectDomain = filterDomains.filter((item: { name: string }) => {
      return item?.name == name;
    });
    props?.SearchContent(selectDomain, true);
    setSelectedName(name);
    setIsClicked(true);
  };

  const handleChange = (e) => {
    setSelectedName(e.target.value);
    setTimeout(() => {
      searchDomainInput(e);
    }, 500);
  };

  return (
    <>
      <div className="flex w-full flex-col relative">
        <input
          type="search"
          placeholder=" Search"
          // defaultValue={selectedName}
          value={selectedName}
          onChange={(e) => {
            handleChange(e);
          }}
          onKeyDown={handleKeyDown}
          className={`lg:p-2  p-2 text-sm lg:mt-2 border border-gray-300 focus:outline-none w-full rounded-lg`}
        />

        <div onMouseLeave={handleBlur}>
          <ul className="absolute max-h-60 overflow-y-auto z-50 w-full bg-white">
            {domainExist ? (
              isClicked ? (
                ""
              ) : (
                filterDomains?.map(
                  (domain: { name: string }, index: number) => {
                    return (
                      <div key={index}>
                        <li
                          key={index}
                          className={`${
                            selectedName === domain?.name
                              ? "text-white bg-brand-500 w-full border cursor-pointer p-1 "
                              : "bg-white w-full border cursor-pointer p-1 hover:bg-brand-50"
                          }`}
                          onClick={() => selectName(domain?.name)}
                        >
                          {domain?.name}
                        </li>
                      </div>
                    );
                  }
                )
              )
            ) : (
              <li className="w-full border p-1 text-sm font-primary">
                {" "}
                Data Not found
              </li>
            )}
          </ul>
        </div>
      </div>
    </>
  );
};
