import { useAuth0 } from "@auth0/auth0-react";
import React, { useContext, useEffect, useState } from "react";
import { DomainCard } from "../../../../../components/card/DomainCard";
import { getUsers } from "../../../../../serviceApi/api";
import { getDomainNameById } from "../../../../../serviceApi/superAdminApi/DomainApi";
import { CategoryCardSkeleton } from "../../../../../components/skeleton/CardSkeleton";
import { getCategoryNameById } from "../../../../../serviceApi/superAdminApi/CategoryApi";
import { getAllSkills } from "../../../../../serviceApi/adminApi/SkillApi";
import { SearchFilterInput } from "../../../../../components/search/SearchInput";
import { BreadcrumbComp } from "../../../../../components/Breadcrumb/Breadcrumb";
import PaginationData from "../../../../../components/pagination/Pagination";
import SkillCard from "../../../../../components/card/SkillCard";
import { loginContext } from "../../../../../components/context/LoginContext";

export const AllSkills = () => {
  const {eventId,setEventId}:any =useContext(loginContext);
  const [skillsList, setSkillsList] = useState([]);
  const [role, setRole] = useState("");
  const [deleteRes, setDeleteRes] = useState<boolean>();
  const [, setLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [paginatedData, setPaginatedData] = useState([]);
  const [getSearch, setGetSearch] = useState(true);
  const [isSearch, setIsSearch] = useState(false);
  const [input, setInput] = useState("");
  const { user } = useAuth0();
  const email: string|undefined = user?.email as string;

  const getSkillsListUserId = async () => {
    setIsLoading(true);
    const skills = await getAllSkills(email);
    if (skills?.data?.skillList) {
      setIsLoading(false);
      setLoading(false);
      setSkillsList(skills?.data?.skillList);
    } else setIsLoading(false);
  };

  const getUserRole = async () => {
    if (email) {
      const userRole = await getUsers(email);
      setRole(userRole?.data.user[0]?.role);
    }
  };

  const getSearchContent = (data, getClick) => {
    if (data?.length > 0 && typeof data != "string") {
      if (getClick) {
        setGetSearch(true);
        setPaginatedData(data);
        setIsSearch(true);
        setSkillsList(data);
      } else {
        setPaginatedData(data);
        setGetSearch(true);
        setIsSearch(!isSearch);
        setSkillsList(data);
      }
    } else if (data == "value not filled" && typeof data == "string") {
      getSkillsListUserId();
      setGetSearch(true);
      setPaginatedData([]);
      setSkillsList(paginatedData);
      setIsSearch(false);
      setInput("");
    } else if (data == "not found data" && typeof data == "string") {
      setPaginatedData([]);
      setGetSearch(false);
      setIsSearch(!isSearch);
      getSkillsListUserId();
    }
  };

  useEffect(() => {
    getSkillsListUserId();
    getUserRole();
  }, [email, deleteRes]);

  const breadcrumbData = [
    {
      path:
        role === "Student" ? "/student/dashboard" : "/subadmin/AllDomains/list",
      text: "Dashboard",
    },
    {
      text: "All Skills",
    },
  ];
  return (
    <>
      <section className="w-full max-h-[86vh] overflow-y-auto lg:p-10  lg:pb-0 p-5">
        <div className="w-full">
          <p className="text-2xl mb-0 pb-0 font-primary font-medium text-gray-800">
            Select Skills to Continue
          </p>
          <div className="lg:mb-4 my-2">
            <BreadcrumbComp breadcrumbData={breadcrumbData} />
          </div>
          <div className=" mb-5 ml-auto lg:w-64 w-full">
            {skillsList?.length > 0 && (
              <SearchFilterInput
                SearchContent={getSearchContent}
                data={skillsList}
                userId={email}
                type="Skill"
                domainId=""
                name={setInput}
              />
            )}
          </div>
          <div className="grid lg:grid-cols-4 grid-cols-2 my-10 lg:gap-10 gap-5">
            {isLoading ? (
              <>
                <CategoryCardSkeleton />
                <CategoryCardSkeleton />
                <CategoryCardSkeleton />
                <CategoryCardSkeleton />
                <CategoryCardSkeleton />
                <CategoryCardSkeleton />
                <CategoryCardSkeleton />
                <CategoryCardSkeleton />
              </>
            ) : paginatedData && paginatedData?.length > 0 ? (
              paginatedData?.map((data: any, i: number) => (
                <div key={i}>
                  <SkillCard
                    CardHeight="h-40"
                    key={i}
                    uuid={data?.uuid}
                    image={data?.image ? data?.image : ""}
                    title={data?.name}
                    description={data?.description}
                    route={`${
                      role === "Admin"
                        ? `/subadmin/domain/skill/${data.uuid}/assessment/list`
                        : ""
                    }`}
                    role="Admin"
                    deleteRes={setDeleteRes}
                    editRoute={`/subadmin/domain/skill/${data.uuid}/edit`}
                  />
                </div>
              ))
            ) : (
              <div className="shadow-md flex  flex-row items-center justify-center h-32 w-60 border-gray-300 rounded-md">
                <h1 className="font-primary justify-center items-center text-black text-sm ">
                  No skills
                </h1>
              </div>
            )}
          </div>
          {paginatedData && getSearch && (
            <div
              className={`${
                skillsList?.length >= 8
                  ? paginatedData?.length >= 5
                    ? "mb-2"
                    : "absolute left-0 right-0 bottom-20 mb-2"
                  : "mx-auto hidden"
              }`}
            >
              <PaginationData
                data={skillsList}
                paginatedData={setPaginatedData}
                userId={email}
                getSearchActive={isSearch}
                type="Skill"
                domainId=""
                name={input}
              />
            </div>
          )}
        </div>
      </section>
    </>
  );
};
