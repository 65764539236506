import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router";
import {
  Select,
  Form,
  Slider,
  DatePicker,
  DatePickerProps,
  Divider,
  Space,
  Input,
  Button,
  Modal,
} from "antd";
import moment from "moment";
import { BreadcrumbComp } from "../../../../components/Breadcrumb/Breadcrumb";
import Swal from "sweetalert2";
import { InputField } from "../../../../components/inputFields/InputField";
import QuillEditor from "react-quill";
import { Country, State, City } from "country-state-city";
import { createJob } from "../../../../serviceApi/adminApi/JobApi";
import { PrimaryButton } from "../../../../components/buttons/PrimaryButton";
import { PlusOutlined } from "@ant-design/icons";
import { useAuth0 } from "@auth0/auth0-react";
import { NavLink } from "react-router-dom";
import { getDomainNamebyuser } from "../../../../serviceApi/adminApi/DomainApi";
import { getDomains } from "../../../../serviceApi/superAdminApi/DomainApi";
import { getCategories } from "../../../../serviceApi/superAdminApi/CategoryApi";
import { getSkills } from "../../../../serviceApi/adminApi/SkillApi";

interface Domain {
  uuid: string;
  domainId: string;
  name: string;
}
export const CreateJob = () => {
  const { user } = useAuth0();
  const userId: any = user?.email;
  const roleData = [
    { value: "Software Engineer", label: "Software Engineer" },
    { value: "Data Scientist", label: "Data Scientist" },
    { value: "Product Manager", label: "Product Manager" },
    { value: "UX/UI Designer", label: "UX/UI Designer" },
    { value: "Marketing Manager", label: "Marketing Manager" },
    { value: "Sales Executive", label: "Sales Executive" },
    { value: "Financial Analyst", label: "Financial Analyst" },
    { value: "Human Resources Manager", label: "Human Resources Manager" },
    { value: "Operations Manager", label: "Operations Manager" },
    { value: "Project Manager", label: "Project Manager" },
    { value: "Business Analyst", label: "Business Analyst" },
    { value: "Network Administrator", label: "Network Administrator" },
    { value: "System Administrator", label: "System Administrator" },
    { value: "Graphic Designer", label: "Graphic Designer" },
    { value: "Content Writer", label: "Content Writer" },
    {
      value: "Customer Service Representative",
      label: "Customer Service Representative",
    },
    { value: "Quality Assurance Tester", label: "Quality Assurance Tester" },
    {
      value: "Technical Support Specialist",
      label: "Technical Support Specialist",
    },
    { value: "DevOps Engineer", label: "DevOps Engineer" },
    { value: "Cybersecurity Specialist", label: "Cybersecurity Specialist" },
    { value: "Database Administrator", label: "Database Administrator" },
    { value: "Architect", label: "Architect" },
    { value: "Civil Engineer", label: "Civil Engineer" },
    { value: "Mechanical Engineer", label: "Mechanical Engineer" },
    { value: "Electrical Engineer", label: "Electrical Engineer" },
    { value: "Environmental Scientist", label: "Environmental Scientist" },
    { value: "Biotechnologist", label: "Biotechnologist" },
    { value: "Pharmaceutical Researcher", label: "Pharmaceutical Researcher" },
    { value: "Clinical Psychologist", label: "Clinical Psychologist" },
    { value: "Social Worker", label: "Social Worker" },
    { value: "Teacher", label: "Teacher" },
    { value: "Professor", label: "Professor" },
    { value: "School Principal", label: "School Principal" },
    { value: "Research Scientist", label: "Research Scientist" },
    { value: "Medical Doctor", label: "Medical Doctor" },
    { value: "Nurse", label: "Nurse" },
    { value: "Physical Therapist", label: "Physical Therapist" },
    { value: "Pharmacist", label: "Pharmacist" },
    { value: "Dentist", label: "Dentist" },
    { value: "Veterinarian", label: "Veterinarian" },
    { value: "Legal Counsel", label: "Legal Counsel" },
    { value: "Paralegal", label: "Paralegal" },
    { value: "Judge", label: "Judge" },
    { value: "Police Officer", label: "Police Officer" },
    { value: "Firefighter", label: "Firefighter" },
    { value: "Journalist", label: "Journalist" },
    { value: "Photographer", label: "Photographer" },
    { value: "Event Planner", label: "Event Planner" },
    { value: "Chef", label: "Chef" },
    { value: "Restaurant Manager", label: "Restaurant Manager" },
    { value: "Real Estate Agent", label: "Real Estate Agent" },
    { value: "Construction Manager", label: "Construction Manager" },
    { value: "Electrician", label: "Electrician" },
    { value: "Plumber", label: "Plumber" },
    { value: "Carpenter", label: "Carpenter" },
    { value: "Automotive Technician", label: "Automotive Technician" },
    { value: "Pilot", label: "Pilot" },
    { value: "Flight Attendant", label: "Flight Attendant" },
    { value: "Logistics Coordinator", label: "Logistics Coordinator" },
    { value: "Supply Chain Manager", label: "Supply Chain Manager" },
    { value: "Economist", label: "Economist" },
    { value: "Actuary", label: "Actuary" },
    { value: "Statistician", label: "Statistician" },
    {
      value: "Public Relations Specialist",
      label: "Public Relations Specialist",
    },
    { value: "Advertising Executive", label: "Advertising Executive" },
    { value: "Copywriter", label: "Copywriter" },
    { value: "Editor", label: "Editor" },
    { value: "Publisher", label: "Publisher" },
    { value: "Librarian", label: "Librarian" },
    { value: "Museum Curator", label: "Museum Curator" },
    { value: "Artist", label: "Artist" },
    { value: "Musician", label: "Musician" },
    { value: "Actor", label: "Actor" },
    { value: "Film Director", label: "Film Director" },
    { value: "Producer", label: "Producer" },
    { value: "Animator", label: "Animator" },
    { value: "Game Developer", label: "Game Developer" },
    { value: "Sports Coach", label: "Sports Coach" },
    { value: "Athletic Trainer", label: "Athletic Trainer" },
    { value: "Fitness Instructor", label: "Fitness Instructor" },
    { value: "Nutritionist", label: "Nutritionist" },
    { value: "Dietitian", label: "Dietitian" },
    { value: "Occupational Therapist", label: "Occupational Therapist" },
    { value: "Speech Therapist", label: "Speech Therapist" },
    { value: "Audiologist", label: "Audiologist" },
    { value: "Chiropractor", label: "Chiropractor" },
    { value: "Optometrist", label: "Optometrist" },
    { value: "Radiologist", label: "Radiologist" },
    { value: "Surgeon", label: "Surgeon" },
    { value: "Anesthesiologist", label: "Anesthesiologist" },
    { value: "Cardiologist", label: "Cardiologist" },
    { value: "Dermatologist", label: "Dermatologist" },
    { value: "Endocrinologist", label: "Endocrinologist" },
    { value: "Gastroenterologist", label: "Gastroenterologist" },
    { value: "Neurosurgeon", label: "Neurosurgeon" },
    { value: "Orthopedic Surgeon", label: "Orthopedic Surgeon" },
    { value: "Pediatrician", label: "Pediatrician" },
    { value: "Psychiatrist", label: "Psychiatrist" },
    { value: "Radiologic Technologist", label: "Radiologic Technologist" },
    { value: "Respiratory Therapist", label: "Respiratory Therapist" },
    { value: "Veterinary Technician", label: "Veterinary Technician" },
    { value: "Zoologist", label: "Zoologist" },
    { value: "Marine Biologist", label: "Marine Biologist" },
    { value: "Forester", label: "Forester" },
    { value: "Park Ranger", label: "Park Ranger" },
    { value: "Geologist", label: "Geologist" },
    { value: "Meteorologist", label: "Meteorologist" },
    { value: "Astronomer", label: "Astronomer" },
    { value: "Astrophysicist", label: "Astrophysicist" },
    { value: "Chemist", label: "Chemist" },
    { value: "Physicist", label: "Physicist" },
    { value: "Mathematician", label: "Mathematician" },
    { value: "Software Architect", label: "Software Architect" },
    { value: "IT Consultant", label: "IT Consultant" },
    { value: "Blockchain Developer", label: "Blockchain Developer" },
    { value: "AI Specialist", label: "AI Specialist" },
    { value: "Robotics Engineer", label: "Robotics Engineer" },
    { value: "Cloud Engineer", label: "Cloud Engineer" },
    {
      value: "Renewable Energy Consultant",
      label: "Renewable Energy Consultant",
    },
    { value: "Urban Planner", label: "Urban Planner" },
    { value: "Fashion Designer", label: "Fashion Designer" },
    { value: "Interior Designer", label: "Interior Designer" },
    { value: "Industrial Designer", label: "Industrial Designer" },
    { value: "Logistics Manager", label: "Logistics Manager" },
    { value: "Supply Chain Analyst", label: "Supply Chain Analyst" },
    {
      value: "Business Development Manager",
      label: "Business Development Manager",
    },
    { value: "E-commerce Specialist", label: "E-commerce Specialist" },
    {
      value: "Digital Marketing Specialist",
      label: "Digital Marketing Specialist",
    },
    { value: "SEO Specialist", label: "SEO Specialist" },
    { value: "Social Media Manager", label: "Social Media Manager" },
    { value: "Content Strategist", label: "Content Strategist" },
    { value: "Technical Writer", label: "Technical Writer" },
    { value: "Web Developer", label: "Web Developer" },
    { value: "Mobile App Developer", label: "Mobile App Developer" },
    { value: "Machine Learning Engineer", label: "Machine Learning Engineer" },
    { value: "Data Engineer", label: "Data Engineer" },
    { value: "Bioinformatician", label: "Bioinformatician" },
    {
      value: "Clinical Research Coordinator",
      label: "Clinical Research Coordinator",
    },
    {
      value: "Regulatory Affairs Specialist",
      label: "Regulatory Affairs Specialist",
    },
    { value: "Patent Examiner", label: "Patent Examiner" },
    { value: "Forensic Scientist", label: "Forensic Scientist" },
    { value: "Crime Scene Investigator", label: "Crime Scene Investigator" },
    { value: "Intelligence Analyst", label: "Intelligence Analyst" },
    { value: "Diplomat", label: "Diplomat" },
    { value: "Policy Analyst", label: "Policy Analyst" },
    { value: "Political Consultant", label: "Political Consultant" },
    { value: "Campaign Manager", label: "Campaign Manager" },
    { value: "Public Policy Specialist", label: "Public Policy Specialist" },
    { value: "Nonprofit Manager", label: "Nonprofit Manager" },
    { value: "Fundraising Coordinator", label: "Fundraising Coordinator" },
    {
      value: "Community Outreach Coordinator",
      label: "Community Outreach Coordinator",
    },
    { value: "Volunteer Coordinator", label: "Volunteer Coordinator" },
    { value: "Grant Writer", label: "Grant Writer" },
    { value: "Program Director", label: "Program Director" },
    { value: "Youth Worker", label: "Youth Worker" },
    { value: "Elder Care Specialist", label: "Elder Care Specialist" },
    { value: "Mental Health Counselor", label: "Mental Health Counselor" },
    { value: "School Counselor", label: "School Counselor" },
    { value: "Career Counselor", label: "Career Counselor" },
    { value: "Rehabilitation Counselor", label: "Rehabilitation Counselor" },
    { value: "Substance Abuse Counselor", label: "Substance Abuse Counselor" },
    {
      value: "Marriage and Family Therapist",
      label: "Marriage and Family Therapist",
    },
    { value: "Clinical Social Worker", label: "Clinical Social Worker" },
    { value: "Medical Social Worker", label: "Medical Social Worker" },
    {
      value: "Industrial-Organizational Psychologist",
      label: "Industrial-Organizational Psychologist",
    },
    { value: "School Psychologist", label: "School Psychologist" },
    { value: "Research Assistant", label: "Research Assistant" },
    { value: "Laboratory Technician", label: "Laboratory Technician" },
    {
      value: "Clinical Laboratory Scientist",
      label: "Clinical Laboratory Scientist",
    },
    { value: "Pathologist", label: "Pathologist" },
    { value: "Immunologist", label: "Immunologist" },
    { value: "Geneticist", label: "Geneticist" },
    { value: "Molecular Biologist", label: "Molecular Biologist" },
    { value: "Biochemist", label: "Biochemist" },
    { value: "Biophysicist", label: "Biophysicist" },
    { value: "Bioengineer", label: "Bioengineer" },
    {
      value: "Pharmaceutical Sales Representative",
      label: "Pharmaceutical Sales Representative",
    },
    {
      value: "Medical Device Sales Representative",
      label: "Medical Device Sales Representative",
    },
    { value: "Healthcare Administrator", label: "Healthcare Administrator" },
    { value: "Hospital Administrator", label: "Hospital Administrator" },
    { value: "Clinic Manager", label: "Clinic Manager" },
    {
      value: "Health Information Technician",
      label: "Health Information Technician",
    },
    { value: "Medical Coder", label: "Medical Coder" },
    {
      value: "Medical Billing Specialist",
      label: "Medical Billing Specialist",
    },
    { value: "Patient Care Coordinator", label: "Patient Care Coordinator" },
    { value: "Other", label: "Other" },
  ];

  const perksBenefits = [
    { value: "Competitive salary", label: "Competitive salary" },
    { value: "Healthcare coverage", label: "Comprehensive healthcare" },
    { value: "Flexible work options", label: "Flexible work options" },
    {
      value: "Opportunities for professional growth",
      label: "Opportunities for professional growth",
    },
    { value: "Work life balance", label: " work-life balance" },
    { value: "Collaborative team work environment", label: "Collaborative work environment" },
    { value: "Modern workspace", label: "Modern workspace" },
    {
      value: "Diverse inclusive culture",
      label: "Diverse and inclusive culture ",
    },
    { value: "Performance based rewards", label: "Performance-based rewards" },
    {
      value: "Social responsibility initiatives",
      label: " social responsibility initiatives",
    },
  ];

  const qualification = [
    {
      value: "high school diploma ",
      label: "High School Diploma or equivalent",
    },
    { value: "associate degree", label: "Associate Degree " },
    { value: "bachelor degree", label: "Bachelor's Degree " },
    { value: "master degree", label: "Master's Degree " },
    {
      value: "professional certification",
      label: "Professional certification",
    },
    {
      value: "any graduate",
      label: "Any graduate ",
    },
  ];

  const navigate = useNavigate();
  const [jobNameErr, setJobNameErr] = useState("");
  const [organisationErr, setOrganisationErr] = useState("");

  const handleJob = async () => {
    const response = await createJob({
      title,
      status,
      description,
      experience: `${experience[0]} - ${experience[1]}`,
      domain,
      skills,
      job_level: jobLevel,
      job_role: jobRole,
      job_type: jobType,
      job_workingType: jobWorkingType,
      organisation,
      employerId: userId,
      country: countriess,
      region: statedata,
      city: citydata,
      qualifications,
      benefits: selectedBenfits,
      job_start_date: startDate,
      job_end_date: endDate,
      salary: `${currency} ${salary[0] * 1000}- ${currency} ${
        salary[1] * 1000
      }/Month`,
    });
    if (response?.data?.createJob?.uuid) {
      Swal.fire({
        icon: "success",
        title: "Job Created Successfully",
        showConfirmButton: false,
        timer: 1500,
      }).then(() => {
        navigate("/subadmin/admin/job/list");
      });
    }
  };

  const [title, setTitle] = useState<string>("");
  const [jobRole, setJobRole] = useState<string>("");
  const [organisation, setOrganisation] = useState<string>("");
  const [salary, setSalary] = useState<any>([1, 50]);
  const [experience, setExperience] = useState<any>([0, 3]);
  const [skills, setSkills] = useState<any>([]);
  const [domain, setDomain] = useState<string>("");
  const [status, setStatus] = useState<string>("");
  const [jobType, setJobtype] = useState<string>("");
  const [jobWorkingType, setJobWorkingType] = useState<string>("");
  const [jobLevel, setJobLevel] = useState<string>("");
  const [countriess, setCountry] = useState<any>([]);
  const [states, setStates] = useState<any>([]);
  const [city, setCity] = useState<any>([]);
  const [citydata, setCitydata] = useState<any>([]);
  const [statedata, setStateData] = useState<any>([]);
  const [description, setDesciption] = useState<string>("");
  const [errorstatus, setErrorstatus] = useState<boolean>(true);
  const [descriptionerror, setDesciptionerror] = useState<string>("");
  const [salaryerror, setSalaryerror] = useState<string>("");
  const [qualifications, setQualification] = useState<any>("");
  const [startDate, setStartDate] = useState<any>("");
  const [endDate, setEndDate] = useState<any>("");
  const [items, setItems] = useState<any>(qualification);
  const [benefits, setBenefits] = useState<any>(perksBenefits);
  const [name, setName] = useState<string>("");
  const [currency, setCurrency] = useState<string>("");
  const [Benefitname, setBenefitName] = useState<any>("");
  const [selectedBenfits, setSelectedBenefits] = useState<any>([]);
  const [showModal, setShowModal] = React.useState(false);
  const [domains, setDomains] = React.useState([]);
  const [skillList, setSkillList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [catValue, setCatValue] = useState<string>("");

  const [index, setIndex] = useState(6);
  const inputRef = useRef<any>(null);
  const onNameChange = (event) => {
    setName(event.target.value);
  };
  const onBenfitsChanges = (event) => {
    setBenefitName(event.target.value);
  };
  const addItem = (e: any, id: any) => {
    e.preventDefault();
    const list = [...items];
    if (e.target.value.trim().length > 0) {
      list[id] = { value: e.target.value, label: e.target.value };
      setItems(list);
      setIndex(id + 1);
    }

    setName("");

    setTimeout(() => {
      inputRef.current?.focus();
    }, 0);
  };

  const handleQualification = (event) => {
    setQualification(event);
  };

  const handleBenefits = (event) => {
    setSelectedBenefits(event);
  };

  const addBenefits = (e: any, id: any) => {
    e.preventDefault();
    const list = [...benefits];
    if (e.target.value.trim().length > 0) {
      list[id] = { value: e.target.value, label: e.target.value };
      setBenefits(list);
      setIndex(id + 1);
    }

    setBenefitName("");

    setTimeout(() => {
      inputRef.current?.focus();
    }, 0);
  };

  const countries: any = Country.getAllCountries().map((country) => ({
    value: country.isoCode,
    label: country.name,
    currency: country.currency,
  }));

  const selectcountry = (countryCode, option) => {
    const { currency } = option;
    setCurrency(currency);
    setCountry(countryCode);
    const stateData = State.getStatesOfCountry(countryCode);
    const statess = stateData.map((state) => ({
      value: state.isoCode,
      label: state.name,
      country: state.countryCode,
    }));
    setStates(statess);
  };

  const selectstate = (val) => {
    setStateData(val);
    const cityData = City.getCitiesOfState(countriess, val);
    const cities = cityData.map((city) => ({
      label: city.name,
      value: city.name,
    }));
    setCity(cities);
  };

  const selectcity = (val) => {
    setCitydata(val);
  };

  const breadcrumbData = [
    {
      path: "/subadmin/AllDomains/list",
      text: "Dashboard",
    },

    { text: "Create Jobs" },
  ];

  const getDomainsListbyUser = async () => {
    const domains = await getDomainNamebyuser(user?.email);
    if (domains?.data?.domainNameByuser?.length <= 0) {
      setShowModal(true);
    } else {
      setDomains(domains?.data?.domainNameByuser);
      setShowModal(false);
    }
  };

  const handleDomain = async (domain) => {
    if (categoryList.length > 0) {
      setCatValue("");
    }
    setDomain(domain);
    const categories = await getCategories(domain);
    if (categories?.data?.category) {
      setCategoryList(categories?.data?.category);
    }
  };

  const selectCategory = async (newValue: string) => {
    if (categoryList.length > 0) {
      setSkills("");
    }
    setCatValue(newValue);
    const res = await getSkills(newValue);
    if (res?.data?.skill) {
      setSkillList(res?.data?.skill);
    }
  };

  const domainOptionsData = domains?.map((domain: Domain) => {
    return {
      value: domain?.domainId,
      label: domain?.name,
    };
  });

  const categoryOptionsData = categoryList?.map((category: Domain) => {
    return {
      value: category?.uuid,
      label: category?.name,
    };
  });

  const skillOptionsData = skillList?.map((skill: Domain) => {
    return {
      value: skill?.name,
      label: skill?.name,
    };
  });

  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ align: ["right", "center", "justify"] }],
      [{ script: "sub" }, { script: "super" }],
      [{ list: "ordered" }, { list: "bullet" }],
      ["image"],
      [{ color: ["red", "#785412"] }],
      [{ background: ["red", "#785412"] }],
    ],
  };
  const formats = [
    "header",
    "bold",
    "italic",
    "symbol",
    "script",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "align",
    "color",
    "background",
    "script",
    "size",
    "color",
    "clean",
  ];
  const onChange: DatePickerProps["onChange"] = (_, dateStr) => {
    setStartDate(dateStr);
  };
  const onChangeEndDate: DatePickerProps["onChange"] = (_, dateStr) => {
    setEndDate(dateStr);
  };
  const handleSalary = (e) => {
    setSalary(e);
  };

  const navigatePageToDomain = () => {
    navigate("/subadmin/AllDomains/list");
  };
  useEffect(() => {
    if (salary[0] < 1) {
      setSalaryerror("Salary must be greater than 0");
    } else {
      setSalaryerror("");
    }

    if (description?.trim()?.length < 250) {
      setDesciptionerror("Description must be 250 character");
    } else {
      setDesciptionerror("");
    }
    if (
      title?.trim()?.length > 0 &&
      description?.trim()?.length >= 250 &&
      experience &&
      domain?.trim()?.length > 0 &&
      catValue?.trim()?.length > 0 &&
      skills?.length > 0 &&
      jobLevel?.trim()?.length > 0 &&
      jobRole?.trim()?.length > 0 &&
      jobWorkingType?.trim()?.length > 0 &&
      countriess?.length > 0 &&
      citydata.length > 0 &&
      statedata.length > 0 &&
      organisation?.trim()?.length > 0 &&
      qualification.length > 0 &&
      status?.trim()?.length > 0 &&
      startDate &&
      endDate
    ) {
      setErrorstatus(false);
    } else {
      setErrorstatus(true);
    }
  }, [
    title,
    status,
    description,
    experience,
    domain,
    skills,
    jobLevel,
    jobRole,
    jobWorkingType,
    organisation,
    countries,
    statedata,
    salary,
    citydata,
    catValue,
    startDate,
    endDate,
  ]);

  useEffect(() => {
    getDomainsListbyUser();
  }, []);

  return (
    <>
      <section className="w-full max-h-[86vh] overflow-y-auto lg:p-10 p-5">
        <p className="text-2xl mb-0 pb-0 font-primary font-medium text-gray-800">
          Create Jobs
        </p>
        <div className="lg:mb-4 lg:mt-0 my-3">
          <BreadcrumbComp breadcrumbData={breadcrumbData} />
        </div>
        <div className="bg-white rounded-2xl mt-10 p-5 shadow-lg border">
          <div className="grid lg:grid-cols-2 gap-5 lg:gap-x-20">
            <div className="mb-2">
              <InputField
                id="Job Title"
                className="focus:outline-none  text-sm border border-gray-300 rounded-md lg:w-[800px] p-4"
                type="text"
                placeholder="Enter Job title"
                value={title}
                onChange={setTitle}
                containsSpecialCharsMsg={setJobNameErr}
              />
              <span className="text-red-500">{jobNameErr}</span>
            </div>

            <div className="flex flex-col relative">
              <Form.Item
                label={
                  <span>
                    Select Job Role
                    <span style={{ color: "red" }}> *</span>
                  </span>
                }
                name="CorrectAnswer"
                className="correctAnswer relative"
                rules={[
                  {
                    message: "Please select job role",
                  },
                ]}
              >
                <Select
                  onChange={(role) => setJobRole(role)}
                  showSearch
                  placeholder="Search / Select Job Role"
                  optionFilterProp="children"
                  style={{
                    width: "99%",
                  }}
                  filterOption={(input, option: any) =>
                    (option?.label?.toLowerCase() ?? "")?.includes(
                      input.toLowerCase()
                    )
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? "")
                      ?.toLowerCase()
                      .localeCompare((optionB?.label ?? "")?.toLowerCase())
                  }
                  options={roleData}
                />
              </Form.Item>
            </div>
            <div className="flex flex-col">
              <Form.Item
                label={
                  <span>
                    Select Job Domain
                    <span style={{ color: "red" }}> *</span>
                  </span>
                }
                name="CorrectAnswer"
                className="correctAnswer"
                rules={[
                  {
                    message: "Please Select Domain",
                  },
                ]}
              >
                <Select
                  onChange={(domain) => handleDomain(domain)}
                  placeholder="Search / Select Domain"
                  optionFilterProp="children"
                  showSearch
                  style={{
                    width: "99%",
                  }}
                  filterOption={(input, option: any) =>
                    (option?.label?.toLowerCase() ?? "")?.includes(
                      input.toLowerCase()
                    )
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? "")
                      ?.toLowerCase()
                      .localeCompare((optionB?.label ?? "")?.toLowerCase())
                  }
                  options={domainOptionsData}
                />
              </Form.Item>
            </div>

            <div className="flex flex-row gap gap-8 ml-2 ">
              <div className="w-1/2 md:w-full">
                <Form.Item
                  label={
                    <span>
                      Select Category
                      <span style={{ color: "red" }}> *</span>
                    </span>
                  }
                  name="CorrectAnswer"
                  className="correctAnswer"
                  rules={[
                    {
                      message: "Please Select Category",
                    },
                  ]}
                >
                  <Select
                    onChange={(category) => selectCategory(category)}
                    placeholder="Search / Select Job Category"
                    optionFilterProp="children"
                    style={{
                      width: "100%",
                    }}
                    filterOption={(input, option: any) =>
                      (option?.label?.toLowerCase() ?? "")?.includes(
                        input.toLowerCase()
                      )
                    }
                    filterSort={(optionA, optionB) =>
                      (optionA?.label ?? "")
                        ?.toLowerCase()
                        .localeCompare((optionB?.label ?? "")?.toLowerCase())
                    }
                    options={categoryOptionsData}
                  />
                </Form.Item>
              </div>
              <div className="w-1/2 md:w-full">
                <Form.Item
                  label={
                    <span>
                      Select Job Skills
                      <span style={{ color: "red" }}> *</span>
                    </span>
                  }
                  name="CorrectAnswer"
                  className="correctAnswer"
                  rules={[
                    {
                      message: "Please Select Skills",
                    },
                  ]}
                >
                  <Select
                    onChange={(skill) => setSkills(skill)}
                    mode="multiple"
                    placeholder="Search / Select Job Skills"
                    optionFilterProp="children"
                    style={{
                      width: "100%",
                    }}
                    filterOption={(input, option: any) =>
                      (option?.label?.toLowerCase() ?? "")?.includes(
                        input.toLowerCase()
                      )
                    }
                    filterSort={(optionA, optionB) =>
                      (optionA?.label ?? "")
                        ?.toLowerCase()
                        .localeCompare((optionB?.label ?? "")?.toLowerCase())
                    }
                    options={skillOptionsData}
                  />
                </Form.Item>
              </div>
            </div>

            <div className="flex flex-row gap-4">
              <div className="w-1/3 md:w-full">
                <Form.Item
                  label={
                    <span>
                      Select Job Country
                      <span style={{ color: "red" }}> *</span>
                    </span>
                  }
                  name="CorrectAnswer"
                  className="correctAnswer"
                  rules={[
                    {
                      message: "Please Select Country.",
                    },
                  ]}
                >
                  <Select
                    onChange={(val, option) => selectcountry(val, option)}
                    showSearch
                    placeholder="Select Country"
                    optionFilterProp="children"
                    style={{
                      marginLeft: "2px",
                    }}
                    filterOption={(input, option: any) =>
                      (option?.label?.toLowerCase() ?? "")?.includes(
                        input.toLowerCase()
                      )
                    }
                    // filterSort={(optionA, optionB) =>
                    //   (optionA?.label ?? "")
                    //     ?.toLowerCase()
                    //     .localeCompare((optionB?.label ?? "")?.toLowerCase())
                    // }
                    options={countries}
                  />
                </Form.Item>
              </div>
              <div className="w-1/3 md:w-full">
                <Form.Item
                  label={
                    <span>
                      Select Job State
                      <span style={{ color: "red" }}> *</span>
                    </span>
                  }
                  name="CorrectAnswer"
                  className="correctAnswer"
                  rules={[
                    {
                      message: "Please Select Skills.",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    onChange={(val) => selectstate(val)}
                    placeholder="Select state"
                    optionFilterProp="children"
                    style={{}}
                    filterOption={(input, option: any) =>
                      (option?.label?.toLowerCase() ?? "")?.includes(
                        input.toLowerCase()
                      )
                    }
                    filterSort={(optionA, optionB) =>
                      (optionA?.label ?? "")
                        ?.toLowerCase()
                        .localeCompare((optionB?.label ?? "")?.toLowerCase())
                    }
                    options={states}
                  />
                </Form.Item>
              </div>
              <div className="w-1/3 md:w-full">
                <Form.Item
                  label={
                    <span>
                      Select Job City
                      <span style={{ color: "red" }}> *</span>
                    </span>
                  }
                  name="CorrectAnswer"
                  className="correctAnswer"
                  rules={[
                    {
                      message: "Please Select Skills.",
                    },
                  ]}
                >
                  <Select
                    onChange={(val) => selectcity(val)}
                    showSearch
                    placeholder="Select City"
                    optionFilterProp="children"
                    style={{}}
                    filterOption={(input, option: any) =>
                      (option?.label?.toLowerCase() ?? "")?.includes(
                        input.toLowerCase()
                      )
                    }
                    filterSort={(optionA, optionB) =>
                      (optionA?.label ?? "")
                        ?.toLowerCase()
                        .localeCompare((optionB?.label ?? "")?.toLowerCase())
                    }
                    options={city}
                  />
                </Form.Item>
              </div>
            </div>

            <div className="flex flex-col">
              <label
                htmlFor="job-salary"
                className="font-primary capitalize text-sm lg:text-base"
              >
                Expected Experience in year
                <span className="text-red-600 text-sm"> *</span>
              </label>
              <Slider
                range={{ draggableTrack: true }}
                defaultValue={[1, 2]}
                max={30}
                onChange={(experience) => setExperience(experience)}
              />
              <span>
                Expected Experience is {experience[0]} - {experience[1]}
              </span>
            </div>

            <div className="flex flex-col">
              <Form.Item
                label={
                  <span>
                    Job Type
                    <span style={{ color: "red" }}> *</span>
                  </span>
                }
                name="CorrectAnswer"
                className="correctAnswer"
                rules={[
                  {
                    message: "Please Select Job Type.",
                  },
                ]}
              >
                <Select
                  onChange={(type) => setJobtype(type)}
                  placeholder="Search / Select Job Type"
                  optionFilterProp="children"
                  style={{
                    width: "99%",
                  }}
                  filterOption={(input, option: any) =>
                    (option?.label?.toLowerCase() ?? "")?.includes(
                      input.toLowerCase()
                    )
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? "")
                      ?.toLowerCase()
                      .localeCompare((optionB?.label ?? "")?.toLowerCase())
                  }
                  options={[
                    { value: "Contract", label: "Contract" },
                    { value: "Full-Time", label: "Full-Time" },
                    { value: "Part-Time", label: "Part-Time" },
                    { value: "Internship", label: "Internship" },
                  ]}
                />
              </Form.Item>
            </div>

            <div className="flex flex-col">
              <label
                htmlFor="job-salary"
                className="font-primary capitalize text-sm lg:text-base"
              >
                Expected Salary in {currency} thousands / month
                <span className="text-red-600 text-sm"> *</span>
              </label>
              <Slider
                range={{ draggableTrack: true }}
                defaultValue={[1, 50]}
                onChange={(e) => handleSalary(e)}
              />
              <span>
                Selected Salary is {currency} {salary[0] * 1000} - {currency}{" "}
                {salary[1] * 1000} /Month{" "}
              </span>
              {salaryerror && (
                <p className="text-red-600 text-sm">{salaryerror}</p>
              )}
            </div>

            <div className="flex flex-col">
              <InputField
                id="Organization"
                className="focus:outline-none  text-sm border border-gray-300 rounded-md lg:w-[710px] relative"
                type="text"
                placeholder="Organisation Name"
                value={organisation}
                onChange={setOrganisation}
                containsSpecialCharsMsg={setOrganisationErr}
              />
              <span className="text-red-500 mt-[10px]">{organisationErr}</span>
            </div>

            <div className="flex flex-col">
              <Form.Item
                label={
                  <span>
                    Job Working Type
                    <span style={{ color: "red" }}> *</span>
                  </span>
                }
                name="CorrectAnswer"
                className="correctAnswer"
                rules={[
                  {
                    message: "Please Select Working type",
                  },
                ]}
              >
                <Select
                  //   key={selectKey}
                  onChange={(wrokingtype) => setJobWorkingType(wrokingtype)}
                  placeholder="Search / Select Job Working Type"
                  optionFilterProp="children"
                  style={{
                    width: "99%",
                  }}
                  filterOption={(input, option: any) =>
                    (option?.label?.toLowerCase() ?? "")?.includes(
                      input.toLowerCase()
                    )
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? "")
                      ?.toLowerCase()
                      .localeCompare((optionB?.label ?? "")?.toLowerCase())
                  }
                  options={[
                    { value: "Remote", label: "Remote" },
                    { value: "Hybrid", label: "Hybrid" },
                    { value: "On-Site", label: "On-Site" },
                  ]}
                />
              </Form.Item>
            </div>

            <div className="flex flex-col">
              <Form.Item
                label={
                  <span>
                    Job Level
                    <span style={{ color: "red" }}> *</span>
                  </span>
                }
                name="CorrectAnswer"
                className="correctAnswer"
                rules={[
                  {
                    message: "Please Select Job Level",
                  },
                ]}
              >
                <Select
                  onChange={(level) => setJobLevel(level)}
                  placeholder="Search / Select Job Level"
                  optionFilterProp="children"
                  style={{
                    width: "99%",
                  }}
                  filterOption={(input, option: any) =>
                    (option?.label ?? "")?.includes(input)
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? "")
                      ?.toLowerCase()
                      .localeCompare((optionB?.label ?? "")?.toLowerCase())
                  }
                  options={[
                    { value: "Entry Level", label: "Entry Level" },
                    { value: "Junior Level", label: "Junior Level" },
                    { value: "Senior Level", label: "Senior Level" },
                    { value: "Expert", label: "Expert" },
                  ]}
                />
              </Form.Item>
            </div>
            <div className="flex flex-col">
              <Form.Item
                label={
                  <span>
                    Job Status
                    <span style={{ color: "red" }}> *</span>
                  </span>
                }
                name="CorrectAnswer"
                className="correctAnswer"
                rules={[
                  {
                    message: "Please Select Job Status.",
                  },
                ]}
              >
                <Select
                  onChange={(status) => setStatus(status)}
                  placeholder="Search / Select Job Status"
                  optionFilterProp="children"
                  style={{
                    width: "99%",
                  }}
                  filterOption={(input, option: any) =>
                    (option?.label ?? "")?.includes(input)
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? "")
                      ?.toLowerCase()
                      .localeCompare((optionB?.label ?? "")?.toLowerCase())
                  }
                  options={[
                    { value: "Active", label: "Active" },
                    { value: "Inactive", label: "Inactive" },
                  ]}
                />
              </Form.Item>
            </div>
            <div className="flex flex-col">
              <Form.Item
                label={
                  <span>
                    Job Benefits
                    <span style={{ color: "red" }}> *</span>
                  </span>
                }
                name="CorrectAnswer"
                className="correctAnswer"
                rules={[
                  {
                    message: "Please Select Perks and Benefits.",
                  },
                ]}
              >
                <Select
                  style={{
                    width: "99%",
                  }}
                  placeholder="Perks and Benefits"
                  mode="multiple"
                  onChange={(event) => handleBenefits(event)}
                  dropdownRender={(menu) => (
                    <>
                      {menu}
                      <Divider
                        style={{
                          margin: "8px 0",
                        }}
                      />
                      <Space
                        style={{
                          padding: "0 8px 4px",
                        }}
                      >
                        <Input
                          placeholder="Please enter item"
                          ref={inputRef}
                          value={Benefitname}
                          onChange={onBenfitsChanges}
                          onBlur={(e) => {
                            addBenefits(e, index);
                          }}
                          onKeyDown={(e) => e.stopPropagation()}
                        />
                      </Space>
                    </>
                  )}
                  options={benefits.map((item) => ({
                    label: item?.label,
                    value: item?.value,
                  }))}
                />
              </Form.Item>
            </div>
            <div className="flex flex-col">
              <Form.Item
                label={
                  <span>
                    Education Qualification
                    <span style={{ color: "red" }}> *</span>
                  </span>
                }
                name="CorrectAnswer"
                className="correctAnswer relative"
                rules={[
                  {
                    message: "Please select  Education Qualification .",
                  },
                ]}
              >
                <Select
                  style={{
                    width: "99%",
                  }}
                  onChange={(event) => handleQualification(event)}
                  placeholder="qualifications"
                  mode="multiple"
                  dropdownRender={(menu) => (
                    <>
                      {menu}
                      <Divider
                        style={{
                          margin: "8px 0",
                        }}
                      />
                      <Space
                        style={{
                          padding: "0 8px 4px",
                        }}
                      >
                        <Input
                          placeholder="Please enter item"
                          ref={inputRef}
                          value={name}
                          onChange={onNameChange}
                          onBlur={(e) => addItem(e, index)}
                          onKeyDown={(e) => e.stopPropagation()}
                        />
                      </Space>
                    </>
                  )}
                  options={items.map((item) => ({
                    label: item?.label,
                    value: item?.value,
                  }))}
                />
              </Form.Item>
            </div>

            <div className="flex flex-col">
              <Form.Item
                label={
                  <span>
                    Job Start Date
                    <span style={{ color: "red" }}> *</span>
                  </span>
                }
                name="CorrectAnswer"
                className="correctAnswer"
                rules={[
                  {
                    message: "Please Select Event Start Date",
                  },
                ]}
              >
                <DatePicker
                  // defaultValue={moment().add(0, "days")}
                  style={{
                    width: "99%",
                  }}
                  onChange={onChange}
                  disabledDate={(current) => {
                    return moment().subtract(1, "days") >= current;
                  }}
                  className="eventDatepicker lg:p-3.5 p-3.5 text-sm lg:mt-2 border border-gray-300 cursor-text focus:outline-none rounded-lg"
                />
              </Form.Item>
            </div>
            <div className="flex flex-col">
              <Form.Item
                label={
                  <span>
                    Job End Date
                    <span style={{ color: "red" }}> *</span>
                  </span>
                }
                name="CorrectAnswer"
                className="correctAnswer relative"
                rules={[
                  {
                    message: "Please Select Perks and Benefits.",
                  },
                ]}
              >
                <DatePicker
                  // defaultValue={moment().add(0, "days")}
                  style={{
                    width: "99%",
                  }}
                  onChange={onChangeEndDate}
                  disabledDate={(current) => {
                    return moment().subtract(1, "days") >= current;
                  }}
                  className="eventDatepicker lg:p-3.5 p-3.5 text-sm lg:mt-2 border border-gray-300 cursor-text focus:outline-none  rounded-lg"
                />
              </Form.Item>
            </div>
          </div>
          <div className="flex flex-col">
            <label
              htmlFor="job-description"
              className="font-primary capitalize text-sm lg:text-base"
            >
              Job Description
              <span className="text-red-600 text-sm"> *</span>
            </label>

            <QuillEditor
              theme="snow"
              formats={formats}
              modules={modules}
              style={{ width: "100%" }}
              value={description}
              onChange={(e) => setDesciption(e)}
            />
            {descriptionerror && (
              <p className="text-red-600 text-sm">{descriptionerror}</p>
            )}
          </div>

          <div className="mt-4">
            <button
              className={`${
                errorstatus
                  ? "border rounded-full text-sm lg:text-base focus:outline-none font-primary text-white py-2 px-5 lg:px-14 bg-gray-300 cursor-not-allowed border-gray-300"
                  : "border rounded-full text-sm lg:text-base focus:outline-none font-primary text-white py-2 px-5 lg:px-14 bg-gray-800  border-gray-300"
              }`}
              onClick={() => handleJob()}
              disabled={errorstatus ? true : false}
            >
              Create
            </button>
          </div>
        </div>
        <Modal
          centered
          open={showModal}
          onOk={() => navigatePageToDomain()}
          onCancel={() => navigatePageToDomain()}
        >
          <NavLink to={`/subadmin/AllDomains/list`}>
            <h1>
              Please take approval for at least one Domain before creating Job
            </h1>
            <button className="bg-brand-500 font-primary text-white px-5 py-1 rounded-lg capitalize">
              Take Domain Approval
            </button>
          </NavLink>
        </Modal>
      </section>
    </>
  );
};
