import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { getUsers } from "../../../../serviceApi/api";
import { useAuth0 } from "@auth0/auth0-react";
import {
  getAllCaetgory,
  getCategories,
} from "../../../../serviceApi/superAdminApi/CategoryApi";
import { DomainCard } from "../../../../components/card/DomainCard";
import {
  getApprovedDomain,
  getDomainNameById,
} from "../../../../serviceApi/superAdminApi/DomainApi";
import { CategoryCardSkeleton } from "../../../../components/skeleton/CardSkeleton";
import { SearchFilterInput } from "../../../../components/search/SearchInput";
import { BreadcrumbComp } from "../../../../components/Breadcrumb/Breadcrumb";
import PaginationData from "../../../../components/pagination/Pagination";
import GoBackbutton from "../../../../components/buttons/GoBackbutton";
import { Spin } from "antd";

export const AllCategory = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const domainId: any = id;
  const { user } = useAuth0();
  const email: any = user?.email;
  const [isLoading, setIsLoading] = useState(true);
  const [categoryList, setCategoryList] = useState([]);
  const [name, setName] = React.useState();
  const [role, setRole] = useState("");
  const [paginatedData, setPaginatedData] = useState([]);
  const [getSearch, setGetSearch] = useState(true);
  const [isAuthenticate, setIsAuthenticate] = useState(false);
  const [isSearch, setIsSearch] = useState(false);
  const [input, setInput] = useState("");
  const getCategoryListByDomainId = async (domainId: any) => {
    const categories = await getAllCaetgory();
    setTimeout(() => {
      if (categories?.data?.allCategory?.length > 0) {
        setIsLoading(false);
        setCategoryList(categories?.data?.allCategory);
      } else if (categories?.data?.allCategory?.length == 0) {
        setIsLoading(false);
      } else setIsLoading(false);
    }, 1000);
  };
  const getUserRole = async () => {
    if (email) {
      const userRole = await getUsers(email);
      setRole(userRole?.data?.user[0]?.role);
      if (userRole?.data?.user[0]?.role === "Admin") {
        const domains = await getApprovedDomain(email, domainId);
        if (domains?.data?.approvedDomain[0]?.status === "Approved") {
          setIsAuthenticate(true);
        } else {
          navigate("/404");
        }
      } else {
        setIsAuthenticate(true);
      }
    }
  };

  console.log("paginatedData", paginatedData);
  const getSearchContent = (data: any, getClick) => {
    if (data?.length > 0 && typeof data != "string") {
      if (getClick) {
        setGetSearch(true);
        setPaginatedData(data);
        setIsSearch(true);
        setCategoryList(data);
      } else {
        setGetSearch(true);
        setPaginatedData(data);
        setIsSearch(!isSearch);
        setCategoryList(data);
      }
    } else if (data === "value not filled" && typeof data === "string") {
      setGetSearch(true);
      setPaginatedData([]);
      getCategoryListByDomainId(domainId);
      setIsSearch(false);
      setInput("");
    } else if (data === "not found data" && typeof data === "string") {
      setPaginatedData([]);
      setGetSearch(false);
      setIsSearch(!isSearch);
      getCategoryListByDomainId(domainId);
    }
  };

  useEffect(() => {
    getCategoryListByDomainId(domainId);
  }, []);

  useEffect(() => {
    getUserRole();
  }, [email]);

  const breadcrumbData = [
    {
      path:
        role === "Student" ? "/student/dashboard" : "/subadmin/AllDomains/list",
      text: "Dashboard",
    },
    {
      text: "All Category",
    },
  ];
  return (
    <>
      {isAuthenticate ? (
        <div className="w-full max-h-[86vh] overflow-y-auto">
          <section className="lg:p-10 p-5">
            <div className="">
              <p className="text-2xl mb-0 pb-0 font-primary capitalize font-medium text-gray-800">
                Select Category to Continue
              </p>
              <div className="lg:mb-4 lg:mt-0">
                <BreadcrumbComp breadcrumbData={breadcrumbData} />
              </div>
              <GoBackbutton />
              <div className="mb-5 ml-auto lg:w-64 w-full">
                {categoryList?.length > 0 && (
                  <SearchFilterInput
                    SearchContent={getSearchContent}
                    data={categoryList}
                    userId={email}
                    type="Category"
                    domainId={domainId}
                    name={setInput}
                  />
                )}
              </div>
              <div className="grid lg:grid-cols-4 grid-cols-2 my-10 md:gap-5 lg:gap-10 gap-5">
                {isLoading ? (
                  <>
                    <CategoryCardSkeleton />
                    <CategoryCardSkeleton />
                    <CategoryCardSkeleton />
                    <CategoryCardSkeleton />
                    <CategoryCardSkeleton />
                    <CategoryCardSkeleton />
                    <CategoryCardSkeleton />
                    <CategoryCardSkeleton />
                  </>
                ) : categoryList?.length > 0 && paginatedData.length > 0 ? (
                  paginatedData.map((data: any, i) => (
                    <DomainCard
                      key={i}
                      image={data.image ? data?.image : ""}
                      title={data.name}
                      CardHeight="h-44"
                      description={
                        data?.description == null
                          ? "....."
                          : data?.description?.slice(0, 50)
                      }
                      route={`${
                        role === "Admin"
                          ? `/subadmin/domain/${domainId}/category/${data.uuid}/skills`
                          : role === "Student"
                          ? `/student/assesment/category/${data.uuid}/skills`
                          : ""
                      }`}
                    />
                  ))
                ) : (
                  <div className=" shadow-md h-32 w-60 border-gray-300 rounded-md">
                    <h1 className="font-primary justify-center items-center my-14 ml-5 text-sm ">
                      No category found
                    </h1>
                  </div>
                )}
              </div>
              {paginatedData && getSearch && (
                <div
                  className={`${
                    categoryList?.length >= 8
                      ? paginatedData?.length >= 5
                        ? "flex justify-center items-center mb-5"
                        : "absolute left-0 right-0 bottom-20 mb-2"
                      : "mx-auto hidden"
                  }`}
                >
                  <PaginationData
                    data={categoryList}
                    paginatedData={setPaginatedData}
                    userId={email}
                    getSearchActive={isSearch}
                    type="allCategory"
                    domainId=""
                    name={input}
                  />
                </div>
              )}
            </div>
          </section>
        </div>
      ) : (
        <div
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <Spin tip="Loading..."></Spin>
        </div>
      )}
    </>
  );
};
