import { Drawer, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { getDomains } from "../../serviceApi/superAdminApi/DomainApi";
import { useAuth0 } from "@auth0/auth0-react";
import {
  getAllCaetgory,
  getCategories,
  getCategoryNameById,
} from "../../serviceApi/superAdminApi/CategoryApi";
import { getSkillNameBySkillId } from "../../serviceApi/api";
import { getAllSkills, getSkills } from "../../serviceApi/adminApi/SkillApi";
import { PrimaryButton } from "../buttons/PrimaryButton";

export default function StudentDataFilter() {
  const navigate = useNavigate();
  const { user } = useAuth0();
  const userId: any = user?.email;
  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };
  const [category, setCategory] = useState("");
  const [domain, setDomain] = useState("");
  const [allDomain, setAllDomain] = useState<any>([]);
  const [allCategoryList, setAllCategoryList] = useState<any>([]);
  const [skill, setSkill] = useState("");
  const [domainUuid, setDomainUuid] = useState("");
  const [categoryUuid, setCategoryUuid] = useState("");

  const [assessment, setAssessment] = useState("");

  const [listOpen, setListOpen] = useState(false);
  const [skillList, setSkillList] = useState<any>([]);
  const [isSkillOpen, setIsSkillOpen] = useState(false);
  const [categoryList, setCategoryList] = useState(false);
  const [skillUuid, setSkillUuid] = useState("");

  const filteredProducts = allDomain?.filter((product) =>
    product.name.toLowerCase().includes(domain.toLowerCase())
  );
  const filteredCategory = allCategoryList.filter((product) =>
    product.name.toLowerCase().includes(category.toLowerCase())
  );
  const filteredSkill = skillList?.filter((product) =>
    product.name.toLowerCase().includes(skill.toLowerCase())
  );
  const handleList = () => {
    setListOpen(true);
  };
  const handleLeave = () => {
    setListOpen(false);
  };

  const getAllDomains = async () => {
    const domains = await getDomains(userId);
    if (domains?.data?.domains) {
      setAllDomain(domains?.data?.domains);
    }
  };
  const selectDomain = async (data) => {
    setDomain(data?.name);
    setListOpen(false);
    setDomainUuid(data?.uuid);
    setSkillUuid("");
    setCategoryUuid("");
  };

  const selectCategory = async (data) => {
    setCategory(data?.name);
    setCategoryList(false);
    setCategoryUuid(data?.uuid);
    setSkillUuid("");
  };

  const selectSkill = async (data) => {
    setSkill(data?.name);
    setSkillUuid(data?.uuid);
    setIsSkillOpen(false);
  };
  const getAllCategoryList = async () => {
    if (domainUuid) {
      const res = await getCategories(domainUuid);
      setAllCategoryList(res?.data?.category);
    } else {
      const response = await getAllCaetgory();
      setAllCategoryList(response?.data?.allCategory);
    }
  };
  const handleCategoryList = () => {
    setCategoryList(true);
  };
  const handleSkillList = () => {
    setIsSkillOpen(true);
  };

  const getAllSkillList = async () => {
    if (categoryUuid) {
      const res = await getSkills(categoryUuid);
      setSkillList(res?.data?.skill);
    } else {
      const res = await getAllSkills(userId);
      setSkillList(res?.data?.skillList);
    }
  };

  const getFilterAssessment = () => {
    if (domainUuid && categoryUuid) {
      navigate(
        `/student/assesment/domain/${domainUuid}/category/${categoryUuid}/skill/${skillUuid}/assessment/list`
      );
    } else {
      navigate(`/student/assesment/skill/${skillUuid}/assessment/list`);
    }
  };

  const handleReset = () => {
    setDomain("");
    setCategory("");
    setSkill("");
    setDomainUuid("");
    setCategoryUuid("");
    setSkillUuid("");
  };

  useEffect(() => {
    getAllDomains();
    getAllCategoryList();
    getAllSkillList();
  }, []);
  useEffect(() => {
    getAllCategoryList();
  }, [domainUuid]);

  useEffect(() => {
    getAllSkillList();
  }, [categoryUuid]);

  return (
    <div className="relative">
      <Tooltip title="Filter Assessment">
        <div>
          <button onClick={showDrawer} className="text-gray-700 focus:outline-none  font-semibold  mt-4 flex gap-x-2 lg:mt-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
              fill="currentColor"
              className="text-gray-700"
              width={20}
              height={20}
            >
              <path d="M3.9 54.9C10.5 40.9 24.5 32 40 32H472c15.5 0 29.5 8.9 36.1 22.9s4.6 30.5-5.2 42.5L320 320.9V448c0 12.1-6.8 23.2-17.7 28.6s-23.8 4.3-33.5-3l-64-48c-8.1-6-12.8-15.5-12.8-25.6V320.9L9 97.3C-.7 85.4-2.8 68.8 3.9 54.9z" />
            </svg>
            Filter{" "}
          </button>
          {/* <p>Filter</p> */}
        </div>
      </Tooltip>

      <Drawer title="Filter Assessment by" onClose={onClose} open={open}>
        {/* <Link to="/student/assesment/domain"> */}

        {/* </Link> */}
        {/* <Link to="/student/assesment/category"> */}

        <input
          type="text"
          placeholder="Filter by Domain"
          value={domain}
          onChange={(e) => setDomain(e.target.value)}
          onFocus={handleList}
          className={`lg:p-2  p-2 text-sm lg:mt-2 border border-gray-300 focus:outline-none w-full rounded-lg`}
          // onMouseLeave={handleLeave}
        />
        {listOpen && (
          <ul>
            {filteredProducts.map((product, index) => (
              <div key={index}>
                <li
                  key={index}
                  className={`${
                    domainUuid === product?.uuid
                      ? "text-white bg-brand-500 w-full border cursor-pointer p-1 "
                      : "bg-white w-full border cursor-pointer p-1 hover:bg-brand-50"
                  }`}
                  onClick={(e) => selectDomain(product)}
                >
                  {product?.name}
                </li>
              </div>
            ))}
          </ul>
        )}

        <input
          type="text"
          placeholder="Filter by Category"
          value={category}
          onChange={(e) => setCategory(e.target.value)}
          onFocus={handleCategoryList}
          className={`lg:p-2  p-2 text-sm lg:mt-2 border border-gray-300 focus:outline-none w-full rounded-lg`}

          // onMouseLeave={handleLeave}
        />
        {categoryList && (
          <ul>
            {filteredCategory?.length > 0 ? (
              filteredCategory.map((product) => (
                <li
                  key={product.id}
                  className={`${
                    categoryUuid === product?.uuid
                      ? "text-white bg-brand-500 w-full border cursor-pointer p-1 "
                      : "bg-white w-full border cursor-pointer p-1 hover:bg-brand-50"
                  }`}
                  onClick={(e) => selectCategory(product)}
                >
                  {product.name}
                </li>
              ))
            ) : (
              <li>Category not found </li>
            )}
          </ul>
        )}
        {/* </Link> */}
        {/* <Link to="/student/assesment/skill"> */}

        <input
          type="text"
          placeholder="Filter by Skill"
          value={skill}
          onChange={(e) => setSkill(e.target.value)}
          onFocus={handleSkillList}
          className={`lg:p-2  p-2 text-sm lg:mt-2 border border-gray-300 focus:outline-none w-full rounded-lg`}

          // onMouseOver={handleList}
          // onMouseLeave={handleLeave}
        />
        {isSkillOpen && (
          <ul>
            {filteredSkill?.length > 0 ? (
              filteredSkill.map((product) => (
                <li
                  key={product.id}
                  className={`${
                    skillUuid === product?.uuid
                      ? "text-white bg-brand-500 w-full border cursor-pointer p-1 "
                      : "bg-white w-full border cursor-pointer p-1 hover:bg-brand-50"
                  }`}
                  onClick={(e) => selectSkill(product)}
                >
                  {product.name}
                </li>
              ))
            ) : (
              <li>Skill not found </li>
            )}
          </ul>
        )}

        {/* </Link> */}

        <div className="flex gap-5 mt-2">
          {/* <button onClick={handleReset}>Reset</button>
          <button onClick={getFilterAssessment}>Apply</button> */}
          <PrimaryButton
            text="Reset"
            onclick={handleReset}
            disabledStatus={skillUuid ? false : true}
            className={`${
              skillUuid
                ? "bg-black hover:bg-transparent hover:border-black border-black hover:text-black cursor-pointer"
                : "bg-gray-300 cursor-not-allowed border-gray-300"
            }`}
          />
          <PrimaryButton
            text="Apply"
            onclick={getFilterAssessment}
            disabledStatus={skillUuid ? false : true}
            className={`${
              skillUuid
                ? "bg-black hover:bg-transparent hover:border-black border-black hover:text-black cursor-pointer"
                : "bg-gray-300 cursor-not-allowed border-gray-300"
            }`}
          />
        </div>
      </Drawer>
    </div>
  );
}
