import React from "react";
import { FacebookOutlined, LinkedinOutlined } from "@ant-design/icons";
export default function SocialShare(props) {
  const handleClick = (url) => {
    window.open(
      `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`,
      "_blank"
    );
  };
  const linkedInShare = (url) => {
    window.open(
      `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
        url
      )}`,
      "_blank"
    );
  };
  const shareUrl = "https://uat.skillcef.com";
  const url = `${shareUrl}${props?.shareUrl}`;
  return (
    <div>
      <div className="social-share-buttons flex gap-5 mt-2">
        <button
          onClick={(e) => handleClick(url)}
          style={{
            backgroundColor: "#007bff",
            color: "#fff",
            border: "none",
            padding: "10px 20px",
            cursor: "pointer",
            borderRadius: "5px",
            fontSize: "16px",
          }}
        >
          <FacebookOutlined style={{ width: "20", height: "20" }} />
        </button>
        <button
          onClick={(e) => linkedInShare(url)}
          style={{
            backgroundColor: "#007bff",
            color: "#fff",
            border: "none",
            padding: "10px 20px",
            cursor: "pointer",
            borderRadius: "5px",
            fontSize: "16px",
          }}
        >
          <LinkedinOutlined />
        </button>
      </div>
    </div>
  );
}
