import React from "react";
import { PopupModel } from "../../views/authenticateViews/SuperAdmin/popupModel";
import { Link } from "react-router-dom";
import { EditButton } from "../buttons/EditButton";
import { DeleteButton } from "../buttons/DeleteButton";
import { ActivateButton } from "../buttons/ActivateButton";

interface CourseCardType {
  type?: string | undefined;
  uuid?: any;
  CardHeight?: string;
  activate?: any;
  role?: string | undefined;
  status?: string | undefined;
  title?: string | undefined;
  description?: string | undefined;
  image?: string | undefined;
  route?: string | undefined;
  message?: string | undefined;
  editRoute?: string;
  deleteRes?: any;
  reqStatus?: any;
}
export default function CourseCard(props: CourseCardType) {
  const [status, setStatus] = React.useState(false);
  const modalStatus = (data: any) => {
    setStatus(data);
  };

  return (
    <div className="p-2 border  flex flex-col justify-between rounded-xl h-full w-full  bg-white shadow-xl relative ">
      <div className="flex flex-col justify-between">
        <>
          <div data-testid={props.title} className={`${props?.CardHeight}`}>
            {props.image ? (
              <img
                className="h-full rounded-xl w-full object-center object-cover"
                src={props.image ? props?.image : ""}
                alt={props.title}
              />
            ) : (
              <img
                className="h-full rounded-xl w-full object-center object-contain"
                src={props.image ? props.image : ""}
                alt={props.title}
              />
            )}
          </div>
          <div>
            <p
              data-testid="domainTitleId"
              className="lg:text-lg  text-base font-primary font-medium text-left capitalize text-gray-900 py-2 lg:pt-5  m-0"
            >
              {props.title}
            </p>
            {props?.description && (
              <p className="overflow-para overflow-wrap: break-word md:text-sm my-2 pb-3 lg:text-xs font-primary capitalize text-xs  text-gray-500 ">
                {props.description}
              </p>
            )}
          </div>
        </>
      </div>

      <div
        className={`border-t border-brand-300 pt-5 flex ${
          props.route ? "justify-between" : "justify-end"
        } items-center`}
      >
        <div className="bg-black group hover:text-white border hover:bg-gray-500 border-black rounded-full flex items-center justify-center w-full h-8 p-1">
          <span className="text-white hover:text-white">View</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-5 h-5 hover:text-white text-white"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3"
            />
          </svg>
        </div>
      </div>
    </div>
  );
}
