import React from "react";
import { Column } from "@ant-design/plots";

const EventActivityChart = (props: any) => {
  const eventData: any = props?.response;
  const data = eventData?.map((item) => {
    return {
      date: item.createdAt,
      event_name: eventData.length,
    };
  });
  const config = {
    data,
    xField: "date",
    yField: "event_name",
    columnWidth: 0.2,
    columnWidthRatio: 0.1,
    columnWidthRadius: 0.1,

    xAxis: {
      line: {
        style: {
          lineWidth: 2,
        },
      },
      title: {
        text: "Date",
      },
      label: {
        autoHide: false,
        autoRotate: false,
      },
    },
    yAxis: {
      line: {
        style: {
          lineWidth: 2,
        },
      },
      title: {
        text: "No. of Event",
      },
      label: {
        autoHide: false,
        autoRotate: false,
      },
    },

    meta: {
      date: {
        alias: "Registration Date",
      },
      event_name: {
        alias: "No. of Event",
      },
    },
  };
  return <Column {...config} />;
};

export default EventActivityChart;
