import React, { useEffect, useState } from "react";
import { CardSkeletonLoader } from "../../../../components/skeleton/CardSkeleton";
import { BreadcrumbComp } from "../../../../components/Breadcrumb/Breadcrumb";
import { useAuth0 } from "@auth0/auth0-react";
import { Link } from "react-router-dom";
import { getWalletDetails } from "../../../../serviceApi/api";

export default function StudentWallet() {
  const { user } = useAuth0();
  const email: string | any = user as string | undefined;
  const [balance, setBalance] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const studentbreadcrumbData = [
    {
      path: "/student/dashboard",
      text: "Dashboard",
    },
    {
      text: "My wallet",
    },
  ];

  const getUserWalletDetails = async () => {
    const walletDetails = await getWalletDetails(email?.email);
    if (walletDetails?.data?.getWallet?.length > 0) {
      setBalance(walletDetails?.data?.getWallet[0]?.balance);
    }
  };

  useEffect(() => {
    getUserWalletDetails();
    setIsLoading(false);
  }, [email]);
  return (
    <>
      <div className="w-full max-h-[86vh] overflow-y-auto lg:p-10 p-5">
        <h1 className="text-2xl pb-0 mb-0  font-primary  capitalize text-black-500 font-semibold">
          My wallet
        </h1>
        <div className="mb-4">
          <BreadcrumbComp breadcrumbData={studentbreadcrumbData} />
        </div>

        <div className="border-t pt-5 pb-10">
          {isLoading ? (
            <>
              <CardSkeletonLoader />
            </>
          ) : (
            <div className=" bg-white max-w-5xl mx-auto shadow-lg p-5  space-2">
              <div className="w-64 h-64 bg-white rounded-full p-3 mx-auto">
                <img
                  src="/Assets/money.png"
                  alt="coin"
                  className="p-3 h-full w-full"
                />
              </div>
              <p className="text-xl font-semibold font-primary text-center">
                You have earned {balance} coins
              </p>
              <p className="text-lg text-center font-primary">
                Attempt More Assessment, Earn More Coins
              </p>
              <Link to="/student/assesment">
                <div className="rounded-full w-48 flex justify-center  text-center mx-auto py-2 px-8 text-white font-primary bg-[#0E80FD] hover:bg-[#0e66fd] transition-all ease-in duration-500">
                  Attempt now{" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6 ml-2"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3"
                    />
                  </svg>
                </div>
              </Link>
              <p className="font-primary mt-5 text-sm">
                {/* <span className="text-sm font-semibold mr-3">Note:-</span>
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Dolorum, praesentium vitae similique necessitatibus cumque
                doloribus soluta fugit recusandae itaque beatae ratione, minus
                suscipit architecto ducimus iusto saepe explicabo perspiciatis?
                Aliquam. */}
              </p>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
