import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getTopperList } from "../../../../serviceApi/api";
import userIcon from "../../../../assets/leftnavicons/UserIcon.svg";
import LeaderBoardSkeleton from "../../../../components/skeleton/LeaderBoardSkeleton";

export const TopStudents = () => {
  const { user } = useAuth0();
  const userId: string | undefined = user?.email;
  const { assessmentId }: any = useParams();
  const [isLoading, setIsLoading] = useState(true);

  const [topperData, setTopperData] = useState<any>([]);
  const leadershipData = async () => {
    const topper = await getTopperList(assessmentId);
    if (topper) {
      setTopperData(topper?.data?.getTopperList);
      setIsLoading(false);
    }
  };

  const leaderBoardData: any = topperData?.filter((data) => {
    return data?.finalPer > 0.6;
  });
  const newArray: any | undefined = topperData?.map((m: any) => [m?.userId, m]);
  const newMap: any = new Map(newArray);
  const iterator = newMap.values();
  const uniqueMembers = [...iterator];
  const rank = uniqueMembers.sort((a, b) => b.finalPer - a.finalPer);
  useEffect(() => {
    leadershipData();
  }, [userId]);

  return (
    <>
      {isLoading ? (
        <LeaderBoardSkeleton />
      ) : (
        <section className="bg-white w-96 top-16 px-2 mr-2">
          <p className="text-brand-900 mt-2 text-lg">Top Students</p>
          <hr />
          {topperData && topperData?.length > 0 ? (
            leaderBoardData?.length > 0 ? (
              uniqueMembers?.map((data: any, i: number) => {
                if (data?.finalPer * 100 >= 60) {
                  return (
                    <div key={i} className=" w-full mr-4">
                      <div className="grid grid-cols-1 gap-5 mt-3 w-full">
                        <div
                          className={`${
                            userId !== data?.userId
                              ? "flex bg-gray-100 flex-row items-center  p-2 rounded-md hover:bg-blue-100 shadow-inner"
                              : "flex bg-brand-50 text-white flex-row items-center  p-2 rounded-md shadow-xl"
                          }`}
                        >
                          <img
                            className="w-10 h-10 object-cover rounded-full shadow-2xl"
                            src={
                              data.image
                                ? `${`${process.env.REACT_APP_MEDIA_URL}/api/media/download${data.image}`}`
                                : `${userIcon}`
                            }
                            alt="image"
                          />
                          <div className="pl-2">
                            <p className="m-0 text-black text-primary font-semibold">
                              Name- {data.firstName}
                            </p>
                            <div className="flex flex-row grid-flow-row gap-1">
                              <div className="flex flex-row justify-between items-center">
                                <p className="m-0 text-black text-sm ">
                                  {" "}
                                  Rank- {i + 1}{" "}
                                </p>
                              </div>

                              <div className="flex flex-row justify-between items-center">
                                <p className="m-0 text-black text-xs">
                                  Score - {data.finalPer * 100} %
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                }
              })
            ) : (
              <div className="w-full flex flex-row items-center justify-center">
                <div className="bg-white w-64  mx-auto py-10 my-10 shadow-2xl ">
                  <div className="text-2xl capitalize  text-center font-bold">
                    Data Not found
                  </div>
                </div>
              </div>
            )
          ) : (
            <div className="w-full flex flex-row items-center justify-center">
              <div className="bg-white w-64  mx-auto py-10 my-10 shadow-2xl ">
                <div className="text-2xl capitalize  text-center font-bold">
                  Data Not found
                </div>
              </div>
            </div>
          )}
        </section>
      )}
    </>
  );
};
