import { ApolloClient, createHttpLink, InMemoryCache } from "@apollo/client";
import { setContext } from "apollo-link-context";
import gql from "graphql-tag";
import { getCookie } from "../../utils";
import Swal from "sweetalert2";

interface DefaultOptions {
  watchQuery: any;
  query: any;
}
interface SkillInput {
  catValue?: string | undefined;
  name?: string | undefined;
  description: string;
  userId: string | undefined;
}

interface SkillUpdateInput {
  name?: string | undefined;
  description: string;
  skillId: string | undefined;
}
const httpLink: any = createHttpLink({
  uri: `${process.env.REACT_APP_GRAPHQL_URL}`,
  fetch,
});

const authLink: any = setContext((_, { headers }) => {
  const token = getCookie("token");

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});
const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: "no-cache",
    errorPolicy: "ignore",
  },
  query: {
    fetchPolicy: "no-cache",
    errorPolicy: "all",
  },
};
const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
  defaultOptions: defaultOptions,
});

const fetchQuery = async (query, variables) => {
  try {
    const request = await client.query({
      query,
      variables,
    });
    return request;
  } catch (err: any) {
    Swal.fire({
      icon: "error",
      title: `Something went wrong! ${err?.message}`,
      showConfirmButton: false,
      timer: 3000,
    });
    throw new Error(err);
  }
};

export const createSkill = async (skillInput: SkillInput) => {
  const createSkill = gql`
    mutation createSkill($skillInput: SkillInput!) {
      createSkill(skillInput: $skillInput) {
        id
        name
        categoryId
        uuid
        image
        status
      }
    }
  `;
  try {
    const result = await client.mutate({
      mutation: createSkill,
      variables: { skillInput },
    });
    return result;
  } catch (err) {
    console.log("skillInput graphql error occurred", JSON.stringify(err));
  }
};

export const updateSkill = async (skillInput: SkillUpdateInput) => {
  const createSkill = gql`
    mutation updateSkill($skillInput: SkillUpdateInput!) {
      updateSkill(skillInput: $skillInput) {
        id
        name
        categoryId
        uuid
        image
        status
        message
      }
    }
  `;
  try {
    const result = await client.mutate({
      mutation: createSkill,
      variables: { skillInput },
    });
    return result;
  } catch (err) {
    console.log("skillInput graphql error occurred", JSON.stringify(err));
  }
};

export const getAllSkills = async (userId: string | undefined) => {
  const query = gql`
    query skillList($userId: String!) {
      skillList(userId: $userId) {
        uuid
        id
        name
        description
        image
        categoryId
      }
    }
  `;
  const variables = {
    userId,
  };

  const res = await fetchQuery(query, variables);
  return res;
};

export const getUserValidateSkill = async (
  userId: string | undefined,
  skillId: string | undefined
) => {
  const query = gql`
    query skillValidateUser($userId: String!, $skillId: String!) {
      skillValidateUser(userId: $userId, skillId: $skillId) {
        uuid
        id
        name
        description
        image
        categoryId
      }
    }
  `;
  const variables = {
    userId,
    skillId,
  };

  const res = await fetchQuery(query, variables);
  return res;
};

export const getAllCodingQuestions = async () => {
  const query = gql`
    query codingList {
      codingList {
        uuid
        id
        questionType
      }
    }
  `;

  try {
    return await client.query({
      query,
    });
  } catch (err) {
    console.log(
      "getCodingQuestions graphql error occurred",
      JSON.stringify(err)
    );
  }
};

export const getCourses = async (
  uuid: string | undefined,
  num_recommendations: number
) => {
  const query = gql`
    query getCourses($uuid: String!, $num_recommendations: Int!) {
      getCourses(uuid: $uuid, num_recommendations: $num_recommendations) {
        Video
      }
    }
  `;
  const variables = {
    uuid,
    num_recommendations,
  };
  const res = await fetchQuery(query, variables);
  return res;
};

export const fetchUserCoursesOnActivity = async (
  studentId: number | undefined,
  assessmentId: string | undefined,
  topics: string | undefined
) => {
  const query = gql`
    query fetchActivityCourses(
      $studentId: Int!
      $assessmentId: String!
      $topics: String!
    ) {
      fetchActivityCourses(
        studentId: $studentId
        assessmentId: $assessmentId
        topics: $topics
      ) {
        Video
      }
    }
  `;
  const variables = {
    studentId,
    assessmentId,
    topics,
  };
  const res = await fetchQuery(query, variables);
  return res;
};

export const getRecentActivityCourses = async (
  studentId: number | undefined,
  assessmentId: string | undefined,
  recommendationType: string | undefined
) => {
  const query = gql`
    query getRecentActivityCourses(
      $studentId: Int!
      $assessmentId: String!
      $recommendationType: String!
    ) {
      getRecentActivityCourses(
        studentId: $studentId
        assessmentId: $assessmentId
        recommendationType: $recommendationType
      ) {
        courses
      }
    }
  `;
  const variables = {
    studentId,
    assessmentId,
    recommendationType,
  };
  const res = await fetchQuery(query, variables);
  return res;
};

export const getStoredCourses = async (userId: number) => {
  const query = gql`
    query getUserCourses($userId: Int!) {
      getUserCourses(userId: $userId) {
        courses
      }
    }
  `;
  const variables = {
    userId,
  };
  const res = await fetchQuery(query, variables);
  return res;
};

export const getSkills = async (categoryId: any) => {
  const query = gql`
    query Skill($categoryId: ID!) {
      skill(categoryId: $categoryId) {
        uuid
        id
        name
        description
        image
      }
    }
  `;
  const variables = {
    categoryId,
  };

  const res = await fetchQuery(query, variables);
  return res;
};

export const deleteSkill = async (skillId: string) => {
  const query = gql`
    query deleteSkill($skillId: String!) {
      deleteSkill(skillId: $skillId) {
        uuid
        id
        skillId
      }
    }
  `;
  const variables = {
    skillId,
  };
  const res = await fetchQuery(query, variables);
  return res;
};
