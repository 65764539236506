import React from "react";
import { Column } from "@ant-design/plots";
import date1 from "date-and-time";
import moment from "moment";

const ColumnChart = (props: any) => {
  const studentAssessmentData: any = props?.response;
  const data = studentAssessmentData?.map((topic) => {
    return {
      date: moment(topic?.date).format("MM-DD-YY"),
      assessment: topic?.number,
    };
  });
  const config = {
    data,
    xField: "date",
    yField: "assessment",
    columnWidth: 0.5,
    columnWidthRatio: 0.2,
    columnWidthRadius: 0.2,

    xAxis: {
      line: {
        style: {
          lineWidth: 1,
        },
      },
      title: {
        text: "Date",
      },
      label: {
        autoHide: false,
        autoRotate: false,
      },
    },
    yAxis: {
      line: {
        style: {
          lineWidth: 1,
        },
      },
      title: {
        text: "No. of Assessment Attempt",
      },
      label: {
        autoHide: false,
        autoRotate: false,
      },
    },

    meta: {
      date: {
        alias: "Attempted Date",
      },
      assessment: {
        alias: "No. of Assessment Attempt",
      },
    },
  };
  return <Column {...config} />;
};

export default ColumnChart;
