import { CreateSkills } from "../views/authenticateViews/Admin/CreateSkills/CreateSkills";
import { AssessmentList } from "../views/authenticateViews/Admin/assessments/assessmentList";
import { Assessments } from "../views/authenticateViews/Admin/assessments/assessments";
import { CreateAssessment } from "../views/authenticateViews/Admin/assessments/create/create";
import { CreateQuestion } from "../views/authenticateViews/Admin/assessments/questions/CreateQuestion";
import { QuestionList } from "../views/authenticateViews/Admin/assessments/questions/QuestionList";
import { QuestionsEdit } from "../views/authenticateViews/Admin/assessments/questions/QuestionsEdit";
import { CreateEvent } from "../views/authenticateViews/Admin/event/CreateEvent";
import { Category } from "../views/authenticateViews/Admin/subAdminDashboard/categories/Category";
import AllDomains from "../views/authenticateViews/Admin/subAdminDashboard/domains/AllDomains";
import { ApprovedDomain } from "../views/authenticateViews/Admin/subAdminDashboard/domains/ApprovedDomain";
import { Domain } from "../views/authenticateViews/Admin/subAdminDashboard/domains/Domain";
import { AllSkills } from "../views/authenticateViews/Admin/subAdminDashboard/skills/AllSkills";
import { EditSkill } from "../views/authenticateViews/Admin/subAdminDashboard/skills/EditSkill";
import { Skills } from "../views/authenticateViews/Admin/subAdminDashboard/skills/Skills";
import { Question } from "../views/authenticateViews/students/assesments/questions/Question";
import AdminEvents from "../views/authenticateViews/Admin/event/AdminEvents";
import { EditEvent } from "../views/authenticateViews/Admin/event/EditEvent";
import EventRegister from "../views/authenticateViews/Admin/event/EventRegister";
import StudentsReport from "../views/authenticateViews/Admin/report/StudentsReport";
import { CreateJob } from "../views/authenticateViews/Admin/job/CreateJob";
import { AllJobs } from "../views/authenticateViews/Admin/job/AllJobs";
import JobApplicants from "../views/authenticateViews/Admin/job/JobApplicants";
// import { McqQuestionsEdit } from "../views/authenticateViews/Admin/assessments/questions/McqQuestionsEdit";

const coreRoutes = [
  {
    path: "AllDomains/list",
    title: "AllDomains/list",
    component: AllDomains,
  },
  {
    path: "approved/domain",
    title: "approved/domain",
    component: ApprovedDomain,
  },
  {
    path: "skills/create",
    title: "CreateSkills",
    component: CreateSkills,
  },
  {
    path: "skills/create",
    title: "CreateSkills",
    component: CreateSkills,
  },
  {
    path: "skills/list",
    title: "skills/list",
    component: AllSkills,
  },
  {
    path: "domain",
    title: "domain",
    component: Domain,
  },
  {
    path: "admin/create-event",
    title: "admin/create-event",
    component: AdminEvents,
  },
  {
    path: "admin/create-jobs",
    title: "admin/create-jobs",
    component: CreateJob,
  },
  {
    path: "admin/job/list",
    title: "admin/job/list",
    component: AllJobs,
  },
  {
    path: "admin/job/:id/applicants",
    title: "admin/job/:id/applicants",
    component: JobApplicants,
  },
  {
    path: "admin/edit-event/:eventid",
    title: "admin/edit-event",
    component: EditEvent,
  },
  {
    path: "admin/event/:eventid",
    title: "admin/event",
    component: EventRegister,
  },
  {
    path: "/subadmin/domain/:id/category",
    title: "Category",
    component: Category,
  },
  {
    path: "/subadmin/domain/:domainId/category/:categoryId/skills",
    title: "Skills",
    component: Skills,
  },
  {
    path: "/subadmin/domain/:domainId/category/:categoryId/skill/:skillId/assessment/list",
    title: "AssessmentList",
    component: AssessmentList,
  },
  {
    path: "/subadmin/domain/skill/:skillId/assessment/list",
    title: "Assessments",
    component: Assessments,
  },
  {
    path: "/subadmin/domain/skill/:skillId/edit",
    title: "EditSkill",
    component: EditSkill,
  },
  {
    path: "/subadmin/domain/skill/:skillId/assessment/:assessmentId/questions",
    title: "EditSkill",
    component: Question,
  },
  {
    path: "/subadmin/domain/skills/:skillId/assessment/create",
    title: "CreateAssessment",
    component: CreateAssessment,
  },
  {
    path: "/subadmin/domain/skills/:skillId/assessment/:assessmentId/edit",
    title: "CreateAssessment",
    component: CreateAssessment,
  },
  {
    path: "/subadmin/domain/skill/:skillId/assessment/:assessmentId/questions/list",
    title: "CreateAssessment",
    component: QuestionList,
  },
  {
    path: "/subadmin/domain/skill/:skillId/assessment/:assessmentId/question/create",
    title: "CreateQuestion",
    component: CreateQuestion,
  },
  {
    path: "/subadmin/domain/skill/:skillId/assessment/:assessmentId/question/:questionId/:questionType/edit",
    title: "QuestionsEdit",
    component: QuestionsEdit,
    // component: McqQuestionsEdit,
  },
  {
    path: "/subadmin/domain/skill/:skillId/assessment/:assessmentId/students/reports",
    title: "StudentsReport",
    component: StudentsReport,
  },
];

const AdminRoute = [...coreRoutes];
export default AdminRoute;
