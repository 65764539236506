import React from "react";

export const TimeDisplay = ({ value, isDanger }) => {
  return (
    <div className="px-1">
      <div className={isDanger ? "countdown danger" : "countdown"}>
        <p className="flex text-black text-xl font-semibold my-0 py-0">
          {value > 9 ? value : "0" + value } 
        </p>
      </div>
    </div>
  );
};
