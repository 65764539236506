import React from "react";
import { Tooltip, Popconfirm } from "antd";
import { deleteDomain } from "../../serviceApi/superAdminApi/DomainApi";
import { QuestionCircleOutlined } from "@ant-design/icons";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { deleteCategory } from "../../serviceApi/superAdminApi/CategoryApi";
import axios from "axios";
import { getCookie } from "../../utils";
import { deleteSkill } from "../../serviceApi/adminApi/SkillApi";

export const DeleteSkillButton = (props: any) => {
  const navigate = useNavigate();
  const colorTip = "red";
  const handleCancel = () => {
    if (props?.type == "domain") {
      navigate(`/home/dashboard`);
    }
  };

  const Deleteskill = async (uuid: any, type: string) => {
    const result = await deleteSkill(uuid);
    if (result?.data?.deleteSkill) {
      props.response(true);
      const formData = new FormData();
      formData.append("image", props?.image);
      formData.append("uuid", uuid);
      formData.append("type", "skill");
      const { data }: any = await axios.post(
        `${process.env.REACT_APP_MEDIA_URL}/api/media/delete`,
        formData,
        {
          headers: {
            enctype: "multipart/form-data",
            Authorization: `Bearer ${getCookie("token")}`,
          },
        }
      );
      if (data?.status == true) {
        Swal.fire({
          icon: "success",
          title: "Skill Deleted Successfully",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    }
  };

  return (
    <>
      {props.message == 0 ? (
        <Tooltip
          title={`All created assessments will be lost when skill deleted.`}
          color={colorTip}
          key="blue"
        >
          <div className="bg-gray-300 w-8 h-8 cursor-not-allowed flex items-center justify-center rounded-lg">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-5 h-5 text-white"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
              />
            </svg>
          </div>
        </Tooltip>
      ) : (
        <Popconfirm
          title="Are you sure to  delete this skill? All created assements will be lost."
          onConfirm={() => Deleteskill(props?.uuid, props?.type)}
          okButtonProps={{ loading: false }}
          onCancel={handleCancel}
          icon={<QuestionCircleOutlined style={{ color: "red" }} />}
        >
          <div className="bg-red-500 w-8 h-8 cursor-pointer flex items-center justify-center rounded-lg">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-5 h-5 text-white"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
              />
            </svg>
          </div>
        </Popconfirm>
      )}
    </>
  );
};
