import React, { Fragment, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { loginContext } from "../components/context/LoginContext";
import { getUsers } from "../serviceApi/api";
import { getCookie } from ".";

interface Props {
  children: JSX.Element;
}

const StudentProtectRoute = ({ children }: Props) => {
  const navigate = useNavigate();
  const { user } = useAuth0();
  const { role, setRole }: any = useContext(loginContext);
  const token = getCookie("token");
  // const token: string | undefined = window?.localStorage.getItem(
  //   "@@Auth"
  // ) as string;
  const email: string | undefined = user?.email as string;

  const getUserInfo = async (email: any) => {
    const userInfo = await getUsers(email);
    if (userInfo?.data?.user == null) {
      navigate("/profile");
    } else {
      setRole(userInfo?.data?.user[0]?.role);
    }
  };

  useEffect(() => {
    if (email) {
      getUserInfo(email);
    }
    const newUrl = window?.localStorage?.getItem("url");
    const userRouter = window?.localStorage?.getItem("Role");
    if (newUrl) {
      if (userRouter == "Student") {
        navigate(newUrl);
      }
      if (window.localStorage.getItem("isPasted") == "1") {
        window.localStorage.removeItem("url");
        window.localStorage.removeItem("isPasted");
        window.localStorage.removeItem("Role");
      }
    }
  }, [email]);

  useEffect(() => {
    if (!token) {
      if (window.localStorage.getItem("isPasted") == null) {
        window.localStorage.setItem("url", window.location.pathname);
        window.localStorage.setItem("isPasted", "1");
        window.localStorage.setItem("Role", "Student");
      }
      navigate("/");
    } else if (role && role != "Student") {
      navigate("/404");
    }
  }, [role]);

  return <Fragment>{token && role === "Student" && children}</Fragment>;
};

export default StudentProtectRoute;
