import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { DomainCard } from "../../../../../components/card/DomainCard";
import { getUsers } from "../../../../../serviceApi/api";
import {
  getApprovedDomain,
  getDomainNameById,
} from "../../../../../serviceApi/superAdminApi/DomainApi";
import { CategoryCardSkeleton } from "../../../../../components/skeleton/CardSkeleton";
import { getCategoryNameById } from "../../../../../serviceApi/superAdminApi/CategoryApi";
import { getSkills } from "../../../../../serviceApi/adminApi/SkillApi";
import { SearchFilterInput } from "../../../../../components/search/SearchInput";
import { BreadcrumbComp } from "../../../../../components/Breadcrumb/Breadcrumb";
import PaginationData from "../../../../../components/pagination/Pagination";
import { Spin } from "antd";  
import SkillCard from "../../../../../components/card/SkillCard";

export const Skills = () => {
  const { domainId, categoryId }: any = useParams();
  const navigate = useNavigate();
  const [deleteRes, setDeleteRes] = useState<boolean>();
  const [skillsList, setSkillsList] = useState([]);
  const [role, setRole] = useState("");
  const [domainName, setDomainName] = React.useState();
  const [categoryName, setCategoryName] = React.useState();
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthenticate, setIsAuthenticate] = useState(false);
  const [paginatedData, setPaginatedData] = useState([]);
  const [input, setInput] = useState("");
  const [isSearch, setIsSearch] = useState(false);
  const { user } = useAuth0();
  const email: string | undefined = user?.email as string;
  const [getSearch, setGetSearch] = useState(true);

  const getSkillsList = async (categoryId: string) => {
    setIsLoading(true);
    const skills = await getSkills(categoryId);
    setTimeout(() => {
      if (skills?.data?.skill?.length > 0) {
        setIsLoading(false);
        setSkillsList(skills?.data?.skill);
        getCategoryName(skills?.data?.skill[0]?.categoryId);
      } else if (skills?.data?.skill?.length == 0) {
        setIsLoading(false);
      } else setIsLoading(false);
    }, 1000);
  };

  const getUserRole = async () => {
    if (email) {
      const userRole = await getUsers(email);
      setRole(userRole?.data?.user[0]?.role);
      if (userRole?.data?.user[0]?.role == "Admin") {
        const domains = await getApprovedDomain(email, domainId);
        if (domains?.data?.approvedDomain[0]?.status == "Approved") {
          setIsAuthenticate(true);
        } else {
          navigate("/404");
        }
      } else {
        setIsAuthenticate(true);
      }
    }
  };

  const getDomainName = async () => {
    const response = await getDomainNameById(domainId);
    setDomainName(response?.data?.domainNameById[0]?.name);
  };
  const getCategoryName = async (categoryId: string) => {
    if (categoryId) {
      const response = await getCategoryNameById(categoryId);
      setCategoryName(response?.data?.categoryNameById[0]?.name);
    }
  };

  const getSearchContent = (data, getClick) => {
    if (data?.length > 0 && typeof data != "string") {
      if (getClick) {
        setGetSearch(true);
        setPaginatedData(data);
        setIsSearch(true);
        setSkillsList(data);
      } else {
        setPaginatedData(data);
        setGetSearch(true);
        setIsSearch(!isSearch);
        setSkillsList(data);
      }
    } else if (data === "value not filled" && typeof data === "string") {
      getSkillsList(categoryId);
      setPaginatedData([]);
      setIsSearch(true);
      setGetSearch(true);
      setIsSearch(false);
      setSkillsList(paginatedData);
      setInput("");
    } else if (data === "not found data" && typeof data === "string") {
      setPaginatedData([]);
      setGetSearch(false);
      setIsSearch(!isSearch);
      getSkillsList(categoryId);
    }
  };

  useEffect(() => {
    getSkillsList(categoryId);
    getDomainName();
    getCategoryName(categoryId);
  }, [categoryId]);

  useEffect(() => {
    getUserRole();
  }, [email]);

  const breadcrumbData = [
    {
      path:
        role === "Student" ? "/student/dashboard" : "/subadmin/AllDomains/list",
      text: "Dashboard",
    },
    { text: "Domain" },
    {
      path:
        role === "Student" ? `/student/assesment/domain` : `/subadmin/domain`,
      text: domainName,
    },
    { text: "Category" },
    {
      path:
        role === "Student"
          ? `/student/assesment/domain/category/${domainId}`
          : `/subadmin/domain/${domainId}/category`,
      text: categoryName,
    },
  ];

  return (
    <>
      {isAuthenticate ? (
        <div className="w-full max-h-[86vh] overflow-y-auto lg:p-10 p-5">
          <p className="text-2xl mb-0 pb-0 font-primary font-medium text-gray-800">
            Select Skills
          </p>
          <div className="lg:mb-4 my-2">
            <BreadcrumbComp breadcrumbData={breadcrumbData} />
          </div>
          <div className="mb-5 ml-auto lg:w-64 w-full">
            {skillsList?.length > 0 && (
              <SearchFilterInput
                SearchContent={getSearchContent}
                data={skillsList}
                userId={email}
                type="Category_Skill"
                domainId={categoryId}
                name={setInput}
              />
            )}
          </div>
          <div className="grid lg:grid-cols-4 grid-cols-2 my-10 lg:gap-10 gap-5">
            {isLoading ? (
              <>
                <CategoryCardSkeleton />
                <CategoryCardSkeleton />
                <CategoryCardSkeleton />
                <CategoryCardSkeleton />
                <CategoryCardSkeleton />
                <CategoryCardSkeleton />
                <CategoryCardSkeleton />
                <CategoryCardSkeleton />
              </>
            ) : paginatedData && paginatedData?.length > 0 ? (
              paginatedData?.map((data: any, i: number) => (
                <div key={i}>
                  {role === "Admin" ? (
                    <SkillCard
                      CardHeight="h-40"
                      key={i}
                      uuid={data?.uuid}
                      image={data?.image ? data?.image : ""}
                      title={data?.name}
                      description={data?.description}
                      route={`${
                        role === "Admin"
                          ? `/subadmin/domain/skill/${data.uuid}/assessment/list`
                          : ""
                      }`}
                      role="Admin"
                      deleteRes={setDeleteRes}
                      editRoute={`/subadmin/domain/skill/${data.uuid}/edit`}
                    />
                  ) : (
                    <DomainCard
                      CardHeight="h-44"
                      key={i}
                      uuid={data?.uuid}
                      image={data?.image ? data?.image : ""}
                      title={data?.name}
                      description={data?.description}
                      route={`${
                        role === "Admin"
                          ? `/subadmin/domain/skill/${data.uuid}/assessment/list`
                          : `/student/assesment/domain/${domainId}/category/${categoryId}/skill/${data.uuid}/assessment/list`
                      }`}
                    />
                  )}
                </div>
              ))
            ) : (
              <div className="shadow-md flex  flex-row items-center justify-center h-32 w-60 border-gray-300 rounded-md">
                <h1 className="font-primary justify-center items-center text-black text-sm ">
                  No skills with category
                </h1>
              </div>
            )}
          </div>
          {paginatedData && getSearch && (
            <div
              className={`${
                skillsList?.length >= 8
                  ? paginatedData?.length >= 5
                    ? "mb-2"
                    : "absolute left-0 right-0 bottom-20 mb-2"
                  : "hidden"
              }`}
            >
              <PaginationData
                data={skillsList}
                paginatedData={setPaginatedData}
                userId={role === "Admin" ? email : ""}
                getSearchActive={isSearch}
                type="Category_Skill"
                domainId={categoryId}
                name={input}
              />
            </div>
          )}
        </div>
      ) : (
        <div
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <Spin tip="Loading..."></Spin>
        </div>
      )}
    </>
  );
};
