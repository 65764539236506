import React, { useEffect, useState } from "react";
import { Select } from "antd";
import { PopupModel } from "../../../SuperAdmin/popupModel";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate, useParams } from "react-router-dom";
import { getCategories } from "../../../../../serviceApi/superAdminApi/CategoryApi";
import { PrimaryButton } from "../../../../../components/buttons/PrimaryButton";
import {
  createSkill,
  updateSkill,
} from "../../../../../serviceApi/adminApi/SkillApi";
import { DomainCard } from "../../../../../components/card/DomainCard";
import { getDomainNamebyuser } from "../../../../../serviceApi/adminApi/DomainApi";
import Swal from "sweetalert2";
import { getCookie } from "../../../../../utils";
import axios from "axios";
import { InputField } from "../../../../../components/inputFields/InputField";
import { BreadcrumbComp } from "../../../../../components/Breadcrumb/Breadcrumb";
import { getSkillNameBySkillId } from "../../../../../serviceApi/api";

export const EditSkill = () => {
  const navigate = useNavigate();
  const { skillId }: any = useParams();
  const { user }: any = useAuth0();
  const userId: string | undefined = user?.email;
  const [skillNameErr, setSkillNameErr] = useState("");
  const [name, setName] = useState<string>("");
  const [description, setDescription] = useState("");
  const [registeredskills, setRegisteredSkills] = useState<boolean>(false);
  const [createSkills, setCreateSkills] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState([]);
  const [status, setStatus] = useState(true);
  const [skillImage, setSkillImage] = useState<any>(null);
  const [skillEditImage, setSkillEditImage] = useState<any>(null);
  const [skillImagePreview, setSkillImagePreview] = useState<any>();

  const handleSkillImage = (e: React.ChangeEvent<HTMLInputElement> | any) => {
    e.preventDefault();
    const selectedFiles: any = e.target.files[0];
    const file = e.target.files[0];
    if (selectedFiles.size >= 2097152) {
      Swal.fire({
        icon: "warning",
        title: "Image size should be max 2MB.",
        showConfirmButton: false,
        timer: 3000,
      });
    } else if (
      selectedFiles.type === "image/png" ||
      selectedFiles.type === "image/jpg" ||
      selectedFiles.type === "image/jpeg" ||
      selectedFiles.type === "image/webp" ||
      selectedFiles.type === "image/jfif" ||
      selectedFiles.type === "image/svg"
    ) {
      setSkillImage(file);
      setSkillImagePreview(URL.createObjectURL(selectedFiles));
    } else {
      Swal.fire({
        icon: "warning",
        title: "Image formate should be png,jpg,jpeg,webp,jfif,svg",
        showConfirmButton: false,
        timer: 3000,
      });
    }
  };

  const deleteSkillImage = () => {
    setSkillImagePreview(URL.revokeObjectURL(skillImagePreview));
    setSkillImage(null);
  };

  const editSkill = async () => {
    const skills = await updateSkill({
      skillId,
      name,
      description,
    });
    if (skills?.data?.updateSkill?.message) {
      if (skillImage) {
        const formData = new FormData();
        formData.append("image", skillImage);
        formData.append("uuid", skillId);
        formData.append("type", "skill");
        try {
          const { data } = await axios.post(
            `${process.env.REACT_APP_MEDIA_URL}/api/media/upload`,
            formData,
            {
              headers: {
                enctype: "multipart/form-data",
                Authorization: `Bearer ${getCookie("token")}`,
              },
            }
          );
          if (data.message === "image uploaded Successfully") {
            Swal.fire({
              icon: "success",
              title: "Skill Updated Successfully",
              showConfirmButton: false,
              timer: 3000,
            });
            navigate("/subadmin/skills/list");
            setRegisteredSkills(false);
            setName("");
            setDescription("");
            setCreateSkills(true);
            setStatus(true);
          }
        } catch (error) {
          console.log(error, "error ");
        }
      } else {
        Swal.fire({
          icon: "success",
          title: "Skill Updated Successfully",
          showConfirmButton: false,
          timer: 3000,
        });
        navigate("/subadmin/skills/list");
      }
    } else {
      Swal.fire({
        icon: "warning",
        title: "Something went wrong or Invalid data please remove symbols.",
        showConfirmButton: false,
        timer: 3000,
      });
    }
  };

  const filter1 = async (value: string) => {
    const keyword = value;
    if (keyword !== "") {
      if (keyword.length > 60) {
        setStatus(true);
      } else {
        setStatus(false);
      }
      const results = inputValue?.filter((user: any) => {
        return user.name?.toLowerCase().includes(keyword.toLowerCase()); // Use the toLowerCase() method to make it case-insensitive
      });
      setInputValue(results);
    }
    setName(keyword);
  };

  const skillNameBySkillId = async () => {
    const skillNameById = await getSkillNameBySkillId(skillId);
    if (skillNameById?.data?.skillNameById?.length > 0) {
      setName(skillNameById?.data?.skillNameById[0]?.name);
      setDescription(skillNameById?.data?.skillNameById[0]?.description);
      setSkillEditImage(skillNameById?.data?.skillNameById[0]?.image);
    } else {
      navigate("/404");
    }
  };
  useEffect(() => {
    skillNameBySkillId();
  }, [userId]);

  const modalStatus = (data: any) => {
    setCreateSkills(data);
  };

  useEffect(() => {
    if (
      name?.trim()?.length <= 60 &&
      name?.trim()?.length >= 2 &&
      (skillImagePreview || skillEditImage) &&
      skillNameErr == ""
    ) {
      setStatus(false);
    } else setStatus(true);
  }, [name, skillEditImage, skillImagePreview, skillNameErr]);

  const breadcrumbData = [
    {
      path: "/subadmin/skills/list",
      text: "All Skills",
    },
    {
      text: "Edit Skill",
    },
  ];

  return (
    <>
      <section className="w-full max-h-[86vh] overflow-y-auto lg:p-10 p-5">
        {createSkills ? (
          <PopupModel
            modalStatus={modalStatus}
            title="Skill created successfully!!"
          />
        ) : (
          ""
        )}
        <p className="text-2xl mb-0 pb-0 font-primary font-medium text-gray-800">
          Edit Skill
        </p>
        <div className="lg:mb-4 lg:mt-0 my-3">
          <BreadcrumbComp breadcrumbData={breadcrumbData} />
        </div>
        <div className="bg-white rounded-2xl mt-10 p-5 shadow-lg border">
          <div className="grid lg:grid-cols-2 gap-5 lg:gap-x-20">
            <div className="flex flex-col">
              <InputField
                id="Skill Name"
                className="focus:outline-none  text-sm border border-gray-300 rounded-md lg:w-[710px] p-4"
                type="text"
                placeholder="Enter skill name"
                value={name}
                onChange={filter1}
                containsSpecialCharsMsg={setSkillNameErr}
              />
              <span className="text-red-500 mt-[6px]">{skillNameErr}</span>
              {registeredskills ? (
                <p className="text-red-500">Skill is already exist !</p>
              ) : (
                ""
              )}
            </div>
            <div className="flex flex-col mt-2">
              <label
                htmlFor="description"
                className="lg:text-sm text-sm pb-0 mb-1 md:mb-2 lg:mb-2  capitalize text-black "
              >
                Description
              </label>
              <input
                id="description"
                className="focus:outline-none  text-sm border border-gray-300 rounded-md w-full lg:w-full p-4"
                type="text"
                placeholder="Enter description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </div>
            {/* image uploading start*/}
            <div>
              <p>
                Upload Skill Image <span className="text-red-600">*</span>
              </p>
              {skillImagePreview ? (
                <div className="h-44 border relative  mb-10 w-44">
                  <img
                    className="h-full w-full p-1 rounded-lg object-cover object-center"
                    src={skillImagePreview}
                    alt={skillImagePreview}
                  />
                  <div
                    onClick={deleteSkillImage}
                    className="bg-black cursor-pointer  w-8 h-8 flex items-center justify-center absolute top-0 right-0 rounded-full"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-5 h-5 mx-auto text-white"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                      />
                    </svg>
                  </div>
                </div>
              ) : (
                <button type="submit" className="h-44 w-44">
                  <div className="flex items-center justify-center">
                    <label
                      data-testid="assessmentImage"
                      htmlFor="dropzone-file"
                      className="flex flex-col items-center justify-center w-full h-auto border-2 bg-gray-50 border-gray-300 border-dashed rounded-lg cursor-pointer  dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
                    >
                      <div className="flex flex-col items-center justify-center pt-5 pb-6">
                        <svg
                          aria-hidden="true"
                          className="w-10 h-10 mb-3 text-gray-400"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                          ></path>
                        </svg>
                        <p className="mb-2 text-sm font-primary text-gray-500 dark:text-gray-400">
                          <span className="font-semibold font-primary">
                            Click to upload
                          </span>{" "}
                        </p>
                        <p className="text-xs text-center text-gray-500 dark:text-gray-400">
                          WEBP, PNG, JPG or GIF (MAX Size. 2MB)
                        </p>
                      </div>
                      {skillEditImage ? (
                        <div className="group  -mt-44 relative bg-white p-1 rounded-xl border border-dashed border-black">
                          <div className="h-full text-2xl w-full group-hover:opacity-100 opacity-0 absolute z-20  text-white flex items-center justify-center inset-0">
                            <svg
                              aria-hidden="true"
                              className="w-10 h-10 mb-3 text-white"
                              fill="none"
                              stroke="currentColor"
                              viewBox="0 0 24 24"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                              ></path>
                            </svg>
                            <p className="mb-2 text-sm text-text-white dark:text-gray-400">
                              <span className="font-semibold">
                                Click to upload
                              </span>{" "}
                            </p>
                          </div>
                          <div className="absolute group-hover:bg-black opacity-40 text-center w-full h-full rounded-xl  text-white"></div>
                          <img
                            className="w-44 h-44 object-cover object-center rounded-xl"
                            src={`${process.env.REACT_APP_MEDIA_URL}/api/media/download${skillEditImage}`}
                            alt={skillEditImage}
                          />
                        </div>
                      ) : null}
                      <input
                        id="dropzone-file"
                        onChange={(e) => handleSkillImage(e)}
                        type="file"
                        className="hidden"
                      />
                    </label>
                  </div>
                </button>
              )}
            </div>
            {/* {/ image uploading end /} */}
          </div>
          <div className="mt-10">
            <PrimaryButton
              text="Update"
              onclick={editSkill}
              disabledStatus={status ? true : false}
              className={`${
                status
                  ? "bg-gray-300 cursor-not-allowed border-gray-300"
                  : "bg-black hover:bg-transparent hover:border-black border-black hover:text-black"
              }`}
            />
          </div>
        </div>

        <div className="grid lg:grid-cols-5 grid-cols-2 mt-6 mb-28 lg:gap-x-10 lg:gap-y-10 gap-x-5">
          {inputValue && inputValue.length > 0
            ? inputValue.map((data: any, i) => {
                return (
                  <DomainCard
                    CardHeight="h-36"
                    key={i}
                    title={data.name}
                    description={data.description}
                    image={data.image ? `${data.image}` : ""}
                  />
                );
              })
            : ""}
        </div>
      </section>
    </>
  );
};
