import React from "react";
import UserIcon from "../../assets/images/user.jpeg"

const ImageField = (props) => {
  return (
    <>
      <img
        className="w-full h-full object-cover object-center rounded-xl"
        src={
            props?.image
            ? `${process.env.REACT_APP_MEDIA_URL}/api/media/download${props?.image}`
            : UserIcon
        }
        loading="lazy"
        alt={props?.image}
      />
    </>
  );
};

export default ImageField;
