import React, { useEffect, useRef, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { getEventsById } from "../../../serviceApi/adminApi/EventApi";
import { useNavigate, useParams } from "react-router-dom";
import { setCookie } from "../../../utils";
import { ClockCircleOutlined, UserOutlined } from "@ant-design/icons";
import GoBackbutton from "../../../components/buttons/GoBackbutton";
import PublicHeader from "./PublicHeader";
export const SpecificEvent = () => {
  const navigate = useNavigate();
  const { loginWithRedirect } = useAuth0();
  const [isVisible, setIsVisible] = useState(false);
  const prevScrollPos = useRef(0);

  const paramdata: any = useParams();
  const [events, setEvents] = useState<any>([]);

  const handleEventRegister = (eventId) => {
    setCookie({ eventId }, 1800);
    loginWithRedirect({});
  };
  const getEvent = async () => {
    const data = await getEventsById(paramdata?.id);
    if (data?.data?.getEventsById.length > 0) {
      const events = data?.data?.getEventsById[0];
      setEvents(events);
    } else {
      navigate("/404");
    }
  };
  useEffect(() => {
    getEvent();
  }, []);
  useEffect(() => {
    const toggleVisibility = () => {
      const currentScrollPos = window.pageYOffset;

      // Button is displayed after scrolling for 500 pixels
      if (currentScrollPos > 500 && currentScrollPos > prevScrollPos.current) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }

      prevScrollPos.current = currentScrollPos;
    };

    window.addEventListener("scroll", toggleVisibility);

    return () => window.removeEventListener("scroll", toggleVisibility);
  }, [isVisible]);

  const time = new Date(parseInt(events?.event_start_date)).toUTCString();
  const endtime = new Date(parseInt(events?.event_end_date)).toUTCString();
  const currentDate = new Date();

  // Define the target date you want to calculate remaining days for
  const targetDate = new Date(Number(events?.event_start_date)); // Note: Months are zero-based (0 for January)

  // Calculate the difference in milliseconds between the target date and current date
  const differenceMs = targetDate.getTime() - currentDate.getTime();

  // Convert milliseconds to days
  const remainingDays = Math.ceil(differenceMs / (1000 * 60 * 60 * 24));

  return (
    <div className="relative">
      <PublicHeader />
      <div className=" absolute mt-[90%] z-50 ml-[80%]">
        {" "}
        {isVisible && (
          <div className="w-[300px]">
            <button
              className="z-50 bg-[#FF561E] focus:outline-none hover:bg-[#f5450b]  text-white w-[230px] h-[60px] rounded-[30px]"
              onClick={(e) => handleEventRegister(paramdata?.id)}
            >
              {" "}
              Register
            </button>
          </div>
        )}
      </div>

      <div className="mx-auto min-h-screen max-w-screen-lg px-4 py-16 md:px-8 lg:px-0">
        <div className="bg-white shadow-lg rounded-xl overflow-hidden">
          <img
            src={`${`${process.env.REACT_APP_MEDIA_URL}/api/media/download${events?.image}`}`}
            alt="event-image"
            className="h-[380px] w-full rounded-[30px] object-cover object-center"
          />
          {/* <img
          className="h-[400px] w-[900px] rounded-[30px] object-cover object-center"
          alt="hero-image"
          src={`${`${process.env.REACT_APP_MEDIA_URL}/api/media/download${events?.image}`}`}
        /> */}
        </div>
        <div className="bg-white shadow-lg rounded-xl overflow-hidden mt-[50px]">
          <div className="flex flex-row justify-between">
            <div className="w-[500px] ">
              <h1 className="p-[40px] text-[30px] font-[500]">
                Unlock Your {events?.name} Potential : Join Our Exclusive Online
                Event!
              </h1>
              <div className="flex flex-row pl-[50px] gap-[40px]">
                <div className="shadow-md rounded-xl overflow-hidden  h-[50px] w-[50px] p-[15px] border-[#ccc7c7] border-[2px]">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="#1B1B1880"
                    viewBox="0 0 24 24"
                    fillOpacity="1"
                  >
                    <path d="M22.5 3H21V2a1 1 0 0 0-1-1h-1a1 1 0 0 0-1 1v1h-4V2a1 1 0 0 0-1-1h-1a1 1 0 0 0-1 1v1H7V2a1 1 0 0 0-1-1H5a1 1 0 0 0-1 1v1H2.5A1.5 1.5 0 0 0 1 4.5v18A1.5 1.5 0 0 0 2.5 24h20a1.5 1.5 0 0 0 1.5-1.5v-18A1.5 1.5 0 0 0 22.5 3zM19 2h1v3h-1zm-7 0h1v3h-1zM5 2h1v3H5zM2.5 4H4v1a1 1 0 0 0 1 1h1a1 1 0 0 0 1-1V4h4v1a1 1 0 0 0 1 1h1a1 1 0 0 0 1-1V4h4v1a1 1 0 0 0 1 1h1a1 1 0 0 0 1-1V4h1.5a.5.5 0 0 1 .5.5V8H2V4.5a.5.5 0 0 1 .5-.5zm20 19h-20a.5.5 0 0 1-.5-.5V9h21v13.5a.5.5 0 0 1-.5.5z" />
                  </svg>
                </div>
                <div>
                  <h1 className="text-[15px] font-[700]"> Event Start Date</h1>
                  <h1 className="text-[14px] font-[350]">{time}</h1>
                </div>
              </div>
              <div className="flex flex-row pl-[50px] mt-[20px] gap-[40px]">
                <div className="shadow-md rounded-xl overflow-hidden  h-[50px] w-[50px] p-[15px] border-[#ccc7c7] border-[2px]">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="#1B1B1880"
                    viewBox="0 0 24 24"
                    fillOpacity="1"
                  >
                    <path d="M22.5 3H21V2a1 1 0 0 0-1-1h-1a1 1 0 0 0-1 1v1h-4V2a1 1 0 0 0-1-1h-1a1 1 0 0 0-1 1v1H7V2a1 1 0 0 0-1-1H5a1 1 0 0 0-1 1v1H2.5A1.5 1.5 0 0 0 1 4.5v18A1.5 1.5 0 0 0 2.5 24h20a1.5 1.5 0 0 0 1.5-1.5v-18A1.5 1.5 0 0 0 22.5 3zM19 2h1v3h-1zm-7 0h1v3h-1zM5 2h1v3H5zM2.5 4H4v1a1 1 0 0 0 1 1h1a1 1 0 0 0 1-1V4h4v1a1 1 0 0 0 1 1h1a1 1 0 0 0 1-1V4h4v1a1 1 0 0 0 1 1h1a1 1 0 0 0 1-1V4h1.5a.5.5 0 0 1 .5.5V8H2V4.5a.5.5 0 0 1 .5-.5zm20 19h-20a.5.5 0 0 1-.5-.5V9h21v13.5a.5.5 0 0 1-.5.5z" />
                  </svg>
                </div>
                <div>
                  <h1 className="text-[15px] font-[700]"> Event End Date</h1>
                  <h1 className="text-[14px] font-[350]">{endtime}</h1>
                </div>
              </div>
              <div className="flex flex-row pl-[50px] mt-[20px] gap-[40px]">
                <div className="shadow-md rounded-xl overflow-hidden  h-[50px] w-[50px] p-[15px] border-[#ccc7c7] border-[2px]">
                  <ClockCircleOutlined />
                </div>
                <div>
                  <h1 className="text-[15px] font-[700]">
                    {" "}
                    Event Registration Deadline
                  </h1>
                  <h1 className="text-[14px] font-[350]">
                    {remainingDays} days left
                  </h1>
                </div>
              </div>

              <div className="flex flex-row pl-[50px] mt-[20px] gap-[40px]">
                <div className="shadow-md rounded-xl overflow-hidden  h-[50px] w-[50px] p-[15px] border-[#ccc7c7] border-[2px]">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="size-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9.813 15.904 9 18.75l-.813-2.846a4.5 4.5 0 0 0-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 0 0 3.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 0 0 3.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 0 0-3.09 3.09ZM18.259 8.715 18 9.75l-.259-1.035a3.375 3.375 0 0 0-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 0 0 2.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 0 0 2.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 0 0-2.456 2.456ZM16.894 20.567 16.5 21.75l-.394-1.183a2.25 2.25 0 0 0-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 0 0 1.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 0 0 1.423 1.423l1.183.394-1.183.394a2.25 2.25 0 0 0-1.423 1.423Z"
                    />
                  </svg>
                </div>
                <div>
                  <h1 className="text-[15px] font-[700]"> Eligibility</h1>
                  <h1 className="text-[14px] font-[350]">Everyone can apply</h1>
                </div>
              </div>

              <div className="flex flex-row pl-[50px] mt-[20px] gap-[40px] mb-[40px]">
                <div className="shadow-md rounded-xl overflow-hidden h-[50px] w-[50px] p-[15px] border-[#ccc7c7] border-[2px] ">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="#1B1B1880"
                    viewBox="0 0 24 24"
                    fillOpacity="1"
                    className=""
                  >
                    <path
                      fillRule="evenodd"
                      d="M6.759 4h5.482c.805 0 1.47 0 2.01.044.563.046 1.08.145 1.565.392a4 4 0 0 1 1.748 1.748c.247.485.346 1.002.392 1.564.04.502.044 1.11.044 1.838l1.954-1.954c.19-.191.379-.379.543-.517.147-.123.457-.37.885-.403a1.5 1.5 0 0 1 1.259.52c.279.328.323.721.34.912.02.214.019.48.019.75v6.212c0 .27 0 .536-.019.75-.017.19-.061.584-.34.911a1.5 1.5 0 0 1-1.259.521c-.428-.034-.738-.28-.885-.403a9 9 0 0 1-.543-.517L18 14.414c0 .728-.003 1.336-.044 1.838-.046.562-.145 1.079-.392 1.564a4 4 0 0 1-1.748 1.748c-.485.247-1.002.346-1.564.392-.541.044-1.206.044-2.01.044H6.758c-.805 0-1.47 0-2.01-.044-.563-.046-1.08-.145-1.565-.392a4 4 0 0 1-1.748-1.748c-.247-.485-.346-1.002-.392-1.564C1 15.71 1 15.046 1 14.242V9.758c0-.805 0-1.47.044-2.01.046-.563.145-1.08.392-1.565a4 4 0 0 1 1.748-1.748c.485-.247 1.002-.346 1.564-.392C5.29 4 5.954 4 6.758 4ZM16 9.8c0-.857 0-1.439-.038-1.889-.035-.438-.1-.663-.18-.819a2 2 0 0 0-.874-.874c-.156-.08-.38-.145-.819-.18C13.639 6 13.057 6 12.2 6H6.8c-.857 0-1.439 0-1.889.038-.438.035-.663.1-.819.18a2 2 0 0 0-.874.874c-.08.156-.145.38-.18.819C3 8.361 3 8.943 3 9.8v4.4c0 .857 0 1.439.038 1.889.035.438.1.663.18.819a2 2 0 0 0 .874.874c.156.08.38.145.819.18C5.361 18 5.943 18 6.8 18h5.4c.857 0 1.439 0 1.889-.038.438-.035.663-.1.819-.18a2 2 0 0 0 .874-.874c.08-.156.145-.38.18-.819.037-.45.038-1.032.038-1.889zm2.414 2.2L21 14.586V9.414z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                </div>
                <div>
                  <h1 className="text-[15px] font-[700]">Online</h1>
                  <h1 className="text-[14px] font-[350]">
                    Register to get link
                  </h1>
                </div>
              </div>
            </div>

            <div className="w-[300px] p-72">
              <button
                className="bg-[#FF561E] focus:outline-none hover:bg-[#f5450b]  text-white w-[230px] h-[60px] rounded-[30px]"
                onClick={(e) => handleEventRegister(paramdata?.id)}
              >
                {" "}
                Register
              </button>
            </div>
          </div>
        </div>
        <div className="bg-white shadow-lg rounded-xl overflow-hidden mt-[50px]">
          <button className="flex w-full items-center gap-[450px]">
            <div className="flex-shrink-1 flex-grow">
              <h3 className="text-[25px] font-bold leading-[26px] text-neutral-10">
                Hosted By
                <div className="underline" data-testid="event-community-name">
                  Skillcef
                </div>
              </h3>
              <h4 className="leading-20 mt-8 text-[20px] font-medium text-black">
                With Skillcef{" "}
              </h4>
            </div>
            <div className="h-60 w-60 rounded-full pt-20">
              <img
                className="non-optimized-image rounded-full"
                width="0"
                height="60"
                src="https://d2oi1rqwb0pj00.cloudfront.net/socialProfilePics/user_4631088.png"
              />
            </div>
          </button>
          <div className=" h-[1px] bg-gray-400 ml-[40px] w-[940px] "></div>
          <div className="gap-[450px] mb-[20px]">
            <h1 className="font-[600] text-[20px] pt-[40px] pl-[60px]">
              {" "}
              Where
            </h1>
            <div className="flex flex-row pt-[10px] pl-[60px] gap-[30px]">
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="#1B1B18"
                  viewBox="0 0 24 24"
                  fillOpacity="1"
                  className=""
                >
                  <path
                    fillRule="evenodd"
                    d="M3.223 10a9 9 0 0 0 0 4h3.94A16 16 0 0 1 7 11.98 16 16 0 0 1 7.162 10zm.713-2h3.643a16.3 16.3 0 0 1 2.147-4.71A9.02 9.02 0 0 0 3.936 8M12 3.55A14.3 14.3 0 0 0 9.664 8h4.672A14.3 14.3 0 0 0 12 3.55M14.814 10H9.186q-.162.988-.186 2 .023 1.012.186 2h5.628q.162-.988.186-2a14 14 0 0 0-.186-2m2.024 4A16 16 0 0 0 17 11.98a16 16 0 0 0-.162-1.98h3.939a9 9 0 0 1 0 4h-3.94Zm-2.502 2H9.664A14.3 14.3 0 0 0 12 20.45 14.3 14.3 0 0 0 14.336 16m-4.61 4.71A16.3 16.3 0 0 1 7.579 16H3.936a9.02 9.02 0 0 0 5.79 4.71m4.548 0A16.3 16.3 0 0 0 16.421 16h3.643a9.02 9.02 0 0 1-5.79 4.71M20.064 8h-3.643a16.3 16.3 0 0 0-2.147-4.71A9.02 9.02 0 0 1 20.064 8M1 12C1 5.925 5.925 1 12 1s11 4.925 11 11-4.925 11-11 11S1 18.075 1 12"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </div>
              <div>
                <h4 className="leading-20  text-[18px] font-medium text-black">
                  Online
                </h4>
              </div>
            </div>
          </div>
          <div className=" h-[1px] bg-gray-400 ml-[40px] mb-[20px] w-[940px]"></div>
          <div className="mb-[20px]">
            <h1 className="font-[700] text-[20px] pt-[40px] pl-[60px]">
              {" "}
              Event Details{" "}
            </h1>
            <div className="whitespace-pre-line break-words text-12  ml-[40px] font-base py-4 pt-8 text-lg  text-black block ">
              <div
                dangerouslySetInnerHTML={{
                  __html: events?.description,
                }}
                style={{ overflowWrap: "break-word" }}
              ></div>
            </div>
          </div>

          <div className=" h-[1px] bg-gray-400 ml-[40px] mb-[20px] w-[940px]"></div>
          <div>
            <h1 className="font-[600] text-[20px] pt-[40px] pl-[60px]">
              {" "}
              10 People Attending{" "}
            </h1>
            <div className="flex flex-row justify-between items-center mb-[30px]">
              <div className="flex flex-row-reverse pl-[60px] space-x-1">
                {[1, 2, 3, 4, 5].map((user, index) => {
                  return (
                    <div className="-mr-2" key={index}>
                      <UserOutlined className="p-2 box-border h-[40px] w-[40px]  rounded-full border-2 border-white-default" />
                    </div>
                  );
                })}
              </div>
              <div>
                <button className=" w-[200px] h-[60px] rounded-[30px] border-[#ccc7c7] border-[2px] mr-[30px]">
                  {" "}
                  Spread the word
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-white shadow-lg rounded-xl overflow-hidden mt-[50px] pb-[30px]">
          <h4 className="leading-20 mt-8 text-[20px] pl-[60px] font-medium text-gray-500">
            This event is part of a community
          </h4>

          <span className="box-border block overflow-hidden  inset-0 pl-[60px] pt-[20px]">
            <img
              alt="Community Image"
              src="https://d2oi1rqwb0pj00.cloudfront.net/community/nio_1722835721164_100.webp"
              className="w-[60px] h-[60px]"
            />
          </span>
          <h3 className="text-[25px] font-bold leading-[26px] text-neutral-10 pl-[60px] pt-[10px]">
            <div className="underline" data-testid="event-community-name">
              Skillcef
            </div>
          </h3>
          <h4 className="leading-20 mt-4 text-[18px] font-medium text-gray-500 pl-[60px]">
            Hosted By Skillcef{" "}
          </h4>
          <div className="flex flex-row  pl-[60px] gap-[10px]">
            <svg
              width="28"
              height="28"
              viewBox="0 0 48 48"
              fill="none"
              className="c-IconsV2-Whatsapp "
            >
              <path
                d="M3.98535 44.1057L6.81287 33.7803C5.06874 30.7583 4.15134 27.3303 4.15262 23.8183C4.1571 12.8316 13.0979 3.89307 24.0843 3.89307C29.4157 3.89531 34.4199 5.97044 38.1831 9.73683C41.946 13.5032 44.0176 18.5097 44.0156 23.8344C44.0108 34.8208 35.0688 43.7606 24.0843 43.7606C24.0836 43.7606 24.0846 43.7606 24.0843 43.7606H24.0756C20.7399 43.7593 17.4625 42.9226 14.5514 41.3349L3.98535 44.1057ZM15.0407 37.7278L15.646 38.0863C18.1889 39.5956 21.1046 40.3938 24.0779 40.3954H24.0843C33.2148 40.3954 40.6463 32.9651 40.6501 23.8331C40.6517 19.4075 38.9303 15.2461 35.8026 12.1157C32.6745 8.98541 28.5156 7.26051 24.0904 7.25859C14.9525 7.25859 7.52135 14.6879 7.51782 23.8196C7.51654 26.949 8.39197 29.9963 10.0505 32.6335L10.4447 33.2603L8.77072 39.3716L15.0407 37.7278Z"
                fill="white"
              ></path>
              <path
                d="M4.67432 43.41L7.4041 33.4416C5.71989 30.5244 4.83453 27.2146 4.83549 23.8247C4.83966 13.2183 13.4712 4.58936 24.0766 4.58936C29.2241 4.5916 34.0553 6.59496 37.6874 10.2309C41.3205 13.8669 43.32 18.7 43.3181 23.8398C43.3136 34.4465 34.6817 43.0764 24.0776 43.0764C24.0766 43.0764 24.0779 43.0764 24.0776 43.0764H24.0689C20.8489 43.0751 17.6846 42.267 14.8744 40.7353L4.67432 43.41Z"
                fill="#25D366"
              ></path>
              <path
                d="M3.98047 44.1064L6.80798 33.7811C5.06386 30.759 4.14645 27.331 4.14774 23.8191C4.15222 12.8323 13.093 3.8938 24.0794 3.8938C29.4108 3.89604 34.415 5.97118 38.1782 9.73756C41.9411 13.504 44.0127 18.5104 44.0108 23.8351C44.006 34.8215 35.0639 43.7613 24.0794 43.7613C24.0788 43.7613 24.0797 43.7613 24.0794 43.7613H24.0708C20.735 43.76 17.4576 42.9234 14.5465 41.3356L3.98047 44.1064ZM15.0358 37.7285L15.6411 38.0871C18.1841 39.5963 21.0997 40.3945 24.073 40.3961H24.0794C33.2099 40.3961 40.6414 32.9659 40.6452 23.8338C40.6468 19.4083 38.9255 15.2468 35.7977 12.1165C32.6696 8.98615 28.5107 7.26125 24.0855 7.25932C14.9477 7.25932 7.51646 14.6886 7.51294 23.8203C7.51166 26.9497 8.38708 29.997 10.0457 32.6342L10.4398 33.261L8.76584 39.3723L15.0358 37.7285Z"
                fill="#25D366"
              ></path>
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M19.0938 15.4884C18.7208 14.6592 18.3279 14.6425 17.9729 14.6281C17.6826 14.6156 17.3503 14.6162 17.0186 14.6162C16.6867 14.6162 16.147 14.7412 15.6908 15.2395C15.2341 15.7377 13.9473 16.9426 13.9473 19.3929C13.9473 21.8436 15.7321 24.2113 15.9811 24.5439C16.23 24.8762 19.4264 30.0653 24.4883 32.0616C28.6953 33.7208 29.5511 33.3908 30.4644 33.3078C31.3776 33.2248 33.4108 32.103 33.8257 30.9401C34.241 29.7773 34.241 28.7807 34.1164 28.5721C33.9917 28.3648 33.6598 28.2401 33.1618 27.9912C32.6638 27.7422 30.2154 26.537 29.7588 26.371C29.3022 26.2047 28.9702 26.1221 28.6382 26.6207C28.3059 27.1186 27.3523 28.2401 27.0617 28.5721C26.7711 28.905 26.4804 28.9467 25.9825 28.6974C25.4845 28.4478 23.8804 27.9223 21.9777 26.2256C20.4973 24.9057 19.4975 23.2753 19.2069 22.7767C18.9166 22.2788 19.2021 22.0327 19.4257 21.7606C19.8285 21.2707 20.5043 20.3898 20.6703 20.0578C20.8363 19.7252 20.7533 19.4346 20.629 19.1853C20.5043 18.9363 19.5363 16.4735 19.0938 15.4884Z"
                fill="white"
              ></path>
            </svg>
            <h1 className="font-[600] mt-[10px] text-[18px] ">
              {" "}
              Whatsapp Group Chat{" "}
            </h1>
          </div>
        </div>

        <GoBackbutton />
      </div>
    </div>
  );
};
