import React, { Fragment } from "react";
import { Breadcrumb } from "antd";
import { Link, useNavigate } from "react-router-dom";
import GoBackbutton from "../buttons/GoBackbutton";

interface BreadcrumbType {
  path: string;
  text: string;
}
export const BreadcrumbComp = ({ breadcrumbData }) => {
  return (
    <div className="flex">
      {/* {breadcrumbData?.length > 2 && <GoBackbutton />} */}
      <Breadcrumb>
        {breadcrumbData.length > 0 &&
          breadcrumbData.map((list: BreadcrumbType, i: number) => (
            <Fragment key={i}>
              <Breadcrumb.Item>
                {list.path ? (
                  <Link to={list.path}>{list.text}</Link>
                ) : (
                  list.text
                )}
              </Breadcrumb.Item>
            </Fragment>
          ))}
      </Breadcrumb>
    </div>
  );
};
