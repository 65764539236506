import { Modal, Result } from "antd";
import React from "react";

export const PopupModel = (props: any) => {
  const [modal2Open, setModal2Open] = React.useState(true);
  const status = false;

  return (
    <>
      <Modal
        title={props.title}
        centered
        open={modal2Open}
        onCancel={() => setModal2Open(false)}
        className="cat-modal"
      >
        {props.status ? (
          <Result
            status="error"
            title="You have already purchased this domain"
          />
        ) : props.success ? (
          <Result
            status="success"
            title="You have created the domain successfully"
          />
        ) : props.fail ? (
          <Result status="warning" title="This domains is already created" />
        ) : props.catsuccess ? (
          <Result
            status="success"
            title="You have created the category successfully"
          />
        ) : props.catfail ? (
          <Result status="warning" title="This category is already created" />
        ) : status ? (
          <Result status="success" title="request approved" />
        ) : (
          ""
        )}
      </Modal>
    </>
  );
};
