import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  gql,
} from "@apollo/client";
import { setContext } from "apollo-link-context";
import { getCookie } from "../../utils";
import Swal from "sweetalert2";

interface DefaultOptions {
  watchQuery: any;
  query: any;
}
interface InvitationInput {
  emailId: string;
  domainId: any;
  categoryId: any;
  skillId: any;
  assessmentId: string;
  expiryDate: string;
}

interface ReminderInput {
  eventId: string;
}
const httpLink: any = createHttpLink({
  uri: `${process.env.REACT_APP_GRAPHQL_URL}`,
  fetch,
});

const authLink: any = setContext((_, { headers }) => {
  const token = getCookie("token");
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: "no-cache",
    errorPolicy: "ignore",
  },
  query: {
    fetchPolicy: "no-cache",
    errorPolicy: "all",
  },
};

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
  defaultOptions: defaultOptions,
});

export const invitStudentByGmail = async (invitationInput: InvitationInput) => {
  const createInvitation = gql`
    mutation createInvitation($invitationInput: InvitationInput!) {
      createInvitation(invitationInput: $invitationInput) {
        uuid
        id
      }
    }
  `;
  try {
    const response = await client.mutate({
      mutation: createInvitation,
      variables: { invitationInput },
    });
    return response;
  } catch (err: any) {
    return { data: false };
  }
};

export const sendReminderByGmail = async (reminderInput: ReminderInput) => {
  const sendReminder = gql`
    mutation sendReminder($reminderInput: ReminderInput!) {
      sendReminder(reminderInput: $reminderInput) {
        uuid
        id
      }
    }
  `;
  try {
    const response = await client.mutate({
      mutation: sendReminder,
      variables: { reminderInput },
    });
    return response;
  } catch (err: any) {
    return { data: false };
  }
};

export const assessmentDelete = async (uuid: string) => {
  const query = gql`
    query deleteAssessment($uuid: String!) {
      deleteAssessment(uuid: $uuid) {
        uuid
        id
      }
    }
  `;
  const variables = {
    uuid,
  };
  try {
    const result = await client.query({
      query,
      variables,
    });
    return result;
  } catch (err) {
    console.log(" assessment delete graphql error occurred", err);
  }
};

export const favassessmentDelete = async (uuid: string, userId: string) => {
  const query = gql`
    query deleteFavAssessment($uuid: String!, $userId: String!) {
      deleteFavAssessment(uuid: $uuid, userId: $userId) {
        uuid
        id
        status
        # assessmentId
        userId
      }
    }
  `;
  const variables = {
    uuid,
    userId,
  };
  try {
    const result = await client.query({
      query,
      variables,
    });
    return result;
  } catch (err) {
    Swal.fire({
      icon: "error",
      title: "Somehting went wrong!",
      showConfirmButton: false,
      timer: 1500,
    });
  }
};
