import React, { useState, useEffect, ChangeEvent } from "react";

import { useParams } from "react-router-dom";

import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { deleteQuestion } from "../../../serviceApi/api";
import { InputField } from "../../../components/inputFields/InputField";
import { PrimaryButton } from "../../../components/buttons/PrimaryButton";
import { getCookie } from "../../../utils";
import {
  createDomain,
  getDomainNameById,
  getDomains,
  updateDomainData,
} from "../../../serviceApi/superAdminApi/DomainApi";
import Swal from "sweetalert2";
import axios from "axios";
import { containsSpecialChars } from "../../../utils/validation";
import { BreadcrumbComp } from "../../../components/Breadcrumb/Breadcrumb";
import ImageField from "../../../components/imageField/ImageField";

export const CreateDomain = () => {
  const navigate = useNavigate();
  const { user } = useAuth0();
  const userId: string = user?.email as string;
  const { domainId }: any = useParams();
  const [name, setName] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [coverImage, setCoverImage] = useState<any>(null);
  const [coverImagePreview, setCoverImagePreview] = useState<any>();
  const [uploadedImage, setUploadedImage] = useState("");
  const [domain, setDomain] = useState([]);
  const [status, setStatus] = useState(false);
  const [domainNameErr, setDomainNameErr] = useState("");

  const getDomainsList = async () => {
    const domains = await getDomains(userId);
    if (domains?.data?.domains) {
      setDomain(domains?.data?.domains);
    }
  };

  const getDomainDetailsData = async (domainId: string) => {
    const response = await getDomainNameById(domainId);
    if (response?.data?.domainNameById) {
      setName(response?.data?.domainNameById[0]?.name);
      setDescription(response?.data?.domainNameById[0]?.description);
      setUploadedImage(response?.data?.domainNameById[0]?.image);
    }
  };

  const updateDomain = async (
    domainId: string,
    name: string,
    description: string
  ) => {
    const domains = await updateDomainData(domainId, name, description);
    if (domains?.data?.name) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: `Something went wrong ${domains?.data?.message}`,
        showConfirmButton: false,
        timer: 1500,
      });
    } else if (domains?.data?.updateDomain?.message) {
      if (coverImage) {
        const formData = new FormData();
        formData.append("image", coverImage);
        formData.append("uuid", domainId);
        formData.append("type", "domain");
        try {
          const { data } = await axios.post(
            `${process.env.REACT_APP_MEDIA_URL}/api/media/upload`,
            formData,
            {
              headers: {
                enctype: "multipart/form-data",
                Authorization: `Bearer ${getCookie("token")}`,
              },
            }
          );
          if (data?.status == true) {
            Swal.fire({
              icon: "success",
              title: "Domain updated successfully",
              showConfirmButton: false,
              timer: 1500,
            });
            setTimeout(() => {
              navigate("/home/dashboard");
            }, 1800);

            if (uploadedImage != "") {
              const formData = new FormData();
              formData.append("image", uploadedImage);
              formData.append("uuid", domainId);
              formData.append("type", "domain");
              const { data }: any = await axios.post(
                `${process.env.REACT_APP_MEDIA_URL}/api/media/delete`,
                formData,
                {
                  headers: {
                    enctype: "multipart/form-data",
                    Authorization: `Bearer ${getCookie("token")}`,
                  },
                }
              );
              if (data?.status == true) {
                await deleteQuestion(domainId, "domain");
                getDomainDetailsData(domainId);
              } else {
                Swal.fire({
                  icon: "error",
                  title: "Something went wrong!",
                  showConfirmButton: false,
                  timer: 2000,
                });
              }
            }
          }
        } catch (error: any) {
          throw new Error(error);
        }
      } else {
        Swal.fire({
          icon: "success",
          title: "Domain updated successfully",
          showConfirmButton: false,
          timer: 1500,
        });
        navigate("/home/dashboard");
      }
      await getDomainDetailsData(domainId);
    } else {
      Swal.fire({
        icon: "warning",
        title: "Invalid data",
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };

  const filter1 = async (value: string) => {
    const keyword = value;
    if (keyword !== "") {
      if (keyword.length > 60) {
        setStatus(true);
      } else {
        setStatus(false);
      }
      const results = domain?.filter((user: any) => {
        return user.name?.toLowerCase().includes(keyword.toLowerCase()); // Use the toLowerCase() method to make it case-insensitive
      });
      setDomain(results);
    }
    setName(keyword);
  };

  const DomainImageUpload = (
    event: React.ChangeEvent<HTMLInputElement> | any
  ) => {
    event.preventDefault();
    event.stopPropagation();

    const selectedFiles = event.target.files[0];
    if (selectedFiles.size >= 2097152) {
      Swal.fire({
        icon: "warning",
        title: "Image size should be max 2MB.",
        showConfirmButton: false,
        timer: 3000,
      });
    } else if (
      selectedFiles.type === "image/png" ||
      selectedFiles.type === "image/jpg" ||
      selectedFiles.type === "image/jpeg" ||
      selectedFiles.type === "image/webp" ||
      selectedFiles.type === "image/jfif" ||
      selectedFiles.type === "image/svg"
    ) {
      setCoverImage(selectedFiles);
      setCoverImagePreview(URL.createObjectURL(selectedFiles));
    } else {
      Swal.fire({
        icon: "warning",
        title: "Image formate should be png,jpg,jpeg,webp,jfif,svg",
        showConfirmButton: false,
        timer: 3000,
      });
    }
  };

  const deleteCoverImage = () => {
    setCoverImagePreview(URL.revokeObjectURL(coverImagePreview));
    setCoverImage(null);
  };

  const createDomainList = async (e: React.EventHandler<ChangeEvent> | any) => {
    e.preventDefault();
    const domains = await createDomain({
      name,
      description,
    });
    if (domains?.data?.createDomain?.status) {
      Swal.fire({
        icon: "error",
        title: "Domain already exist!",
        showConfirmButton: false,
      });
    } else if (domains?.data?.createDomain?.uuid != null) {
      const formData = new FormData();
      formData.append("image", coverImage);
      formData.append("uuid", domains?.data?.createDomain?.uuid);
      formData.append("type", "domain");
      try {
        const { data } = await axios.post(
          `${process.env.REACT_APP_MEDIA_URL}/api/media/upload`,
          formData,
          {
            headers: {
              enctype: "multipart/form-data",
              Authorization: `Bearer ${getCookie("token")}`,
            },
          }
        );
        if (data?.status === true) {
          Swal.fire({
            icon: "success",
            title: "Domain created successfully",
            showConfirmButton: false,
            timer: 1500,
          });
          setName("");
          setStatus(true);
          navigate("/home/dashboard");
        }
      } catch (error: any) {
        throw new Error(error);
      }
    } else {
      Swal.fire({
        icon: "warning",
        title: "Invalid data please remove symbols.",
        showConfirmButton: false,
        timer: 3000,
      });
    }
  };

  const deleteImage = async () => {
    const formData = new FormData();
    formData.append("image", uploadedImage);
    formData.append("uuid", domainId);
    formData.append("type", "domain");
    const { data }: any = await axios.post(
      `${process.env.REACT_APP_MEDIA_URL}/api/media/delete`,
      formData,
      {
        headers: {
          enctype: "multipart/form-data",
          Authorization: `Bearer ${getCookie("token")}`,
        },
      }
    );
    if (data?.status == true) {
      await deleteQuestion(domainId, "domain");
      getDomainDetailsData(domainId);
    } else {
      Swal.fire({
        icon: "error",
        title: "Something went wrong!",
        showConfirmButton: false,
        timer: 2000,
      });
    }
  };
  useEffect(() => {
    if (
      !containsSpecialChars(name) &&
      name != " " &&
      name.trim().length <= 60 &&
      name.trim().length >= 2 &&
      description?.trim()?.length > 0 &&
      (coverImage != null || uploadedImage)
    ) {
      setStatus(false);
    } else setStatus(true);
  }, [name, description, coverImage, uploadedImage]);

  useEffect(() => {
    if (domainId) {
      getDomainDetailsData(domainId);
    } else {
      setName("");
      setUploadedImage("");
      setDescription("");
    }
  }, [domainId]);

  useEffect(() => {
    getDomainsList();
  }, [userId]);

  const breadcrumbData = [
    {
      path: "/home/dashboard",
      text: "Dashboard",
    },
    {
      path: "/subadmin/AllDomains/list",
      text: domainId ? "Update Domain" : "Create Domain",
    },
  ];

  return (
    <>
      <div className="w-full mt-2 max-h-[86vh] overflow-y-auto lg:p-8 p-5">
        <div className="lg:my-4 my-2 lg:sticky">
          <p className="lg:text-2xl text-lg mb-0 pb-0 font-primary font-semibold text-gray-800">
            {domainId ? "Update" : "Create"} Domain
          </p>

          <BreadcrumbComp breadcrumbData={breadcrumbData} />
        </div>
        <section className="lg:mt-5 mt-5 max-w-2xl  flex lg:flex-row flex-col justify-between gap-5 items-start">
          <section className="bg-white border w-full shadow-xl  rounded-xl">
            <h1 className="lg:text-2xl text-lg  text-black border-b border-gray-300 p-5  lg:px-5 font-primary font-normal text-left capitalize bg-[#dbdbdb]/[0.75] rounded-t-xl">
              {domainId ? "Update" : "Create new"} domain
            </h1>
            <div className="flex flex-col px-5 lg:px-5 mt-2 lg:mt-5">
              <InputField
                placeholder="Create a domain"
                onChange={filter1}
                value={name}
                type="text"
                containsSpecialCharsMsg={setDomainNameErr}
                id="Domain name"
              />
              <span className="text-red-500 mt-[6px]">{domainNameErr}</span>
            </div>

            <div className="flex flex-col mt-5 px-5 lg:px-5">
              <label className="text-sm  font-primary lg:text-base pb-0  mb-2   text-black ">
                Domain Description <span className="text-red-600">*</span>
              </label>
              <textarea
                className="focus:outline-none text-sm lg:text-sm  rounded-md w-full border border-gray-300 p-4 "
                placeholder="Add a Description..."
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </div>
            <div className="px-5 lg:px-5 mt-5 flex flex-col">
              <label className="text-base font-primary lg:text-base pb-0  mb-2   text-black ">
                Domain image <span className="text-red-500">*</span>
              </label>
              {coverImagePreview ? (
                <div className="h-44 border relative  mb-10 w-44">
                  {coverImagePreview ? (
                    <img
                      className="h-full w-full p-1 rounded-lg object-cover object-center"
                      src={coverImagePreview}
                      alt={coverImagePreview}
                    />
                  ) : (
                    <ImageField
                      image={uploadedImage ? uploadedImage : coverImagePreview}
                    />
                  )}
                  <div
                    onClick={
                      uploadedImage ? deleteCoverImage : deleteCoverImage
                    }
                    className="bg-black hover:bg-red-500 cursor-pointer  w-8 h-8 flex items-center justify-center absolute top-1 right-1 rounded-full"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-5 h-5 mx-auto text-white"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                      />
                    </svg>
                  </div>
                </div>
              ) : (
                <button type="submit">
                  <div className="flex items-center justify-center w-44 h-44">
                    <label
                      data-testid="domainImage"
                      htmlFor="dropzone-file"
                      className="flex flex-col items-center justify-center w-full h-auto border-2 bg-gray-50 border-gray-300 border-dashed rounded-lg cursor-pointer  dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
                    >
                      <div className="flex flex-col items-center justify-center pt-5 pb-6">
                        <svg
                          aria-hidden="true"
                          className="w-10 h-10 mb-3 text-gray-400"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                          ></path>
                        </svg>
                        <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                          <span className="font-semibold">Click to upload</span>{" "}
                        </p>
                        <p className="text-xs text-center text-gray-500 dark:text-gray-400">
                          WEBP, PNG, JPG or GIF (MAX Size. 2MB)
                        </p>
                      </div>
                      {uploadedImage ? (
                        <div className="group  -mt-44 relative bg-white p-1 rounded-xl border border-dashed border-black">
                          <div className="h-full text-2xl w-full group-hover:opacity-100 opacity-0 absolute z-20  text-white flex items-center justify-center inset-0">
                            <svg
                              aria-hidden="true"
                              className="w-10 h-10 mb-3 text-white"
                              fill="none"
                              stroke="currentColor"
                              viewBox="0 0 24 24"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                              ></path>
                            </svg>
                            <p className="mb-2 text-sm text-text-white dark:text-gray-400">
                              <span className="font-semibold">
                                Click to upload
                              </span>{" "}
                            </p>
                          </div>
                          <div className="absolute group-hover:bg-black opacity-40 text-center w-full h-full rounded-xl  text-white"></div>
                          <img
                            className="w-44 h-40 object-cover object-center rounded-xl"
                            src={`${process.env.REACT_APP_MEDIA_URL}/api/media/download${uploadedImage}`}
                            alt={uploadedImage}
                          />
                        </div>
                      ) : null}
                      <input
                        id="dropzone-file"
                        onChange={(e) => DomainImageUpload(e)}
                        type="file"
                        className="hidden"
                      />
                    </label>
                  </div>
                </button>
              )}
            </div>
            <div className="mt-5 px-5 lg:px-5 pb-10">
              <PrimaryButton
                onclick={
                  domainId
                    ? () => updateDomain(domainId, name, description)
                    : createDomainList
                }
                disabledStatus={status}
                text={`${domainId ? "Update" : "Create"}`}
                className={`${
                  status
                    ? "bg-gray-300 cursor-not-allowed border-gray-300"
                    : "bg-black hover:bg-transparent hover:border-black border-black hover:text-black"
                }`}
              />
            </div>
          </section>
        </section>
      </div>
    </>
  );
};
