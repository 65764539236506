import React from "react";
import {useNavigate} from 'react-router-dom';

export default function GoBackbutton() {
  const navigate = useNavigate();
  const handleBack = async () => {
    navigate(-1);
  };
  return (
    <div className="container">
      <button
        type="button"
        onClick={handleBack}
        className="floating-button"
        // className="w-full flex items-center justify-center px-5 py-0 text-sm text-gray-700 transition-colors duration-200 gap-x-3 sm:w-auto dark:hover:bg-gray-900 dark:bg-gray-900 hover:bg-gray-100 dark:text-gray-200 dark:border-gray-700"
      >
        <svg
          className="w-5 h-5 rtl:rotate-180"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth="1.5"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M6.75 15.75L3 12m0 0l3.75-3.75M3 12h18"
          />
        </svg>
        <span>Go Back</span>
      </button>
    </div>
  );
}
