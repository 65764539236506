import { QuestionCircleOutlined } from "@ant-design/icons";
import { Popconfirm, Table } from "antd";
import { ColumnsType, TablePaginationConfig } from "antd/es/table";
import { FilterValue, SorterResult } from "antd/lib/table/interface";
import React, { useEffect, useState } from "react";
import {
  approvedRequest,
  denyRequest,
  getDomainsRequest,
} from "../../../serviceApi/superAdminApi/DomainApi";
import { useAuth0 } from "@auth0/auth0-react";
import UserIcon from "../../../assets/leftnavicons/UserIcon.svg";
import { TableSkeleton } from "@ant-design/pro-components";
import { BreadcrumbComp } from "../../../components/Breadcrumb/Breadcrumb";

interface DataType {
  name: {
    first: string;
    last: string;
  };
  duration: string;
  question: any;
  id: number;
  description: string;
}

interface TableParams {
  pagination?: TablePaginationConfig;
  sortField?: string;
  sortOrder?: string;
  filters?: Record<string, FilterValue>;
}

const AllDomainRequest = () => {
  const { user } = useAuth0();
  const userId: any = user?.email;
  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: 1,
      pageSize: 5,
    },
  });
  const [page, setPage] = React.useState(1);
  const [isResponse, setIsResponse] = React.useState(true);
  const [domains, setDomains] = React.useState([]);

  const getDomainsRequestList = async (userId: string) => {
    if (userId) {
      const response = await getDomainsRequest(userId);
      if (response?.data?.domainsRequest) {
        setDomains(response?.data?.domainsRequest);
        setIsResponse(false);
      } else {
        setIsResponse(false);
      }
    }
  };
  const requestAccept = async (domainId: any, userId: any) => {
    const result = await approvedRequest(domainId, userId);
    if (result?.data?.approvedDomainRequest?.length > 0) {
      await getDomainsRequestList(userId);
    }
  };
  const requestDeny = async (domainId: string, userId: string) => {
    const result = await denyRequest(domainId, userId);
    if (result) {
      getDomainsRequestList(userId);
    }
    await getDomainsRequestList(userId);
  };

  const columns: ColumnsType<DataType> = [
    {
      title: "Sr. No.",
      dataIndex: "id",
      width: "5%",
      render: (id, record, index: any) => {
        return (
          <p className="text-center">
            {(page - 1) * Number(tableParams?.pagination?.pageSize) + index + 1}
          </p>
        );
      },
    },

    {
      title: "Domain Image",
      dataIndex: "image",
      width: "10%",
      defaultSortOrder: "descend",
      render: (image) => {
        return (
          <>
            <p>
              {" "}
              <img
                src={
                  image
                    ? `${process.env.REACT_APP_MEDIA_URL}/api/media/download${image}`
                    : UserIcon
                }
                alt="brandimage"
                className="h-24 w-24 mx-auto rounded-lg"
              />
            </p>
          </>
        );
      },
    },

    {
      title: "Domain Name",
      dataIndex: "name",
      width: "20%",
      defaultSortOrder: "descend",
      render: (name) => {
        return (
          <>
            <p className="text-center my-0">{name}</p>
          </>
        );
      },
    },

    {
      title: "User ID",
      dataIndex: "userId",
      width: "20%",
      defaultSortOrder: "descend",
      render: (userId) => {
        return (
          <>
            <p className="text-center my-0">{userId}</p>
          </>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      width: "10%",
      defaultSortOrder: "descend",
      render: (status) => {
        return (
          <>
            {status === "Pending" ? (
              <p className="text-white bg-orange-500 p-1 rounded-full text-center font-primary">
                {" "}
                Pending
              </p>
            ) : status === "Approved" ? (
              <p className="text-white bg-green-500 p-1 rounded-full text-center font-primary">
                {" "}
                Approved
              </p>
            ) : status === "Denied" ? (
              <p className="text-white bg-red-500 p-1 rounded-full text-center font-primary">
                {" "}
                Denied
              </p>
            ) : null}
          </>
        );
      },
    },
    {
      title: "Action",
      width: "8%",
      dataIndex: "status",
      render: (status, domains: any) => {
        return (
          <>
            <div className="flex flex-row justify-center space-x-3">
              <Popconfirm
                title="Are you sure you want to accept this request?"
                onConfirm={() =>
                  requestAccept(domains.domainId, domains.userId)
                }
                icon={<QuestionCircleOutlined style={{ color: "red" }} />}
              >
                {status == "Pending" ? (
                  <div className="font-primary cursor-pointer text-center flex flex-col justify-center items-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1}
                      stroke="currentColor"
                      className="w-8 h-8 text-green-500 mx-auto text-center"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                      />
                    </svg>

                    <span className="font-light text-gray-500">Accept</span>
                  </div>
                ) : (
                  ""
                )}
              </Popconfirm>
              <Popconfirm
                title="Are you sure you want to deny this request?"
                onConfirm={() => requestDeny(domains.domainId, domains.userId)}
                icon={<QuestionCircleOutlined style={{ color: "red" }} />}
              >
                {status == "Pending" ? (
                  <div className="font-primary cursor-pointer text-center flex flex-col justify-center items-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1}
                      stroke="currentColor"
                      className="w-8 h-8 text-red-500"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                      />
                    </svg>
                    <span className="font-light text-gray-500">Deny</span>
                  </div>
                ) : (
                  ""
                )}
              </Popconfirm>
            </div>
          </>
        );
      },
    },
  ];
  const handleTableChange: any = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue>,
    sorter: SorterResult<DataType>
  ) => {
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });
  };

  const breadcrumbData = [
    {
      path: "/home/dashboard",
      text: "Dashboard",
    },
    {
      text: "Domains request",
    },
  ];

  useEffect(() => {
    getDomainsRequestList(userId);
  }, [userId]);

  return (
    <>
      <section className="w-full mt-2 max-h-[86vh] overflow-y-auto lg:p-10 p-5">
        <h1 className="text-2xl  font-primary font-medium text-gray-800">
          Domains request
        </h1>
        <div className="mb-5">
          <BreadcrumbComp breadcrumbData={breadcrumbData} />
        </div>
        {isResponse ? (
          <TableSkeleton active={true} />
        ) : (
          <Table
            className="domain-request-table"
            columns={columns}
            rowKey={(record) => record.id}
            dataSource={domains}
            pagination={{
              onChange(current) {
                setPage(current);
              },
              defaultPageSize: 5,
              hideOnSinglePage: false,
              showSizeChanger: true,
            }}
            loading={isResponse}
            onChange={handleTableChange}
          />
        )}
      </section>
    </>
  );
};

export default AllDomainRequest;
