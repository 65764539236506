import React from "react";
import "../../views/authenticateViews/Admin/job/JobCard.css";
import companyicon from "../../assets/images/companylogo.jpeg";

const JobCard = ({ job, role, onclick }) => {
  var currentdate = new Date();
  var day = currentdate.getDate();

  var createddate = new Date(job.createdAt);
  var getday = createddate.getDate();

  var currentDate = new Date();

  // Define the target date you want to calculate remaining days for
  var targetDate = new Date(job?.createdAt); // Note: Months are zero-based (0 for January)

  // Calculate the difference in milliseconds between the target date and current date
  var differenceMs = currentDate.getTime() - targetDate.getTime();

  // Convert milliseconds to days
  var remainingDays = Math.ceil(differenceMs / (1000 * 60 * 60 * 24));

  return (
    <div className="job-card cursor-pointer font-primary" onClick={onclick}>
      <div className="job-card-header">
        <div>
          {/* <img className="w-20" src={`${companyicon}`} alt="" /> */}
          <div className="bg-blue-500 w-14 h-14  flex justify-center items-center text-white capitalize text-2xl rounded-lg p-5 font-bold font-primary">
            {job?.organisation?.slice(0, 1)}.
          </div>
          <h2 className="max-w-40 font-primary">{job?.title}</h2>
          <h3 className="max-w-40 font-primary">{job?.organisation}</h3>
        </div>
      </div>
      <div className="job-card-body font-primary">
        <p className="max-w-40 font-primary">
          {job?.country}, {job?.region}, {job?.city}
        </p>
        <p className="max-w-40">{job?.type}</p>
        <p className="max-w-40">{job?.salary}</p>
        <li className="max-w-40">{job?.job_role}</li>
        <li>{job?.job_type}</li>
        <li>{job?.job_workingType}</li>
        <li>{job?.status}</li>
        <p className="max-w-40 font-primary">
          Required Experience : {job?.experience} in Years
        </p>
      </div>
      <div className="job-card-footer">
        <p className="max-w-40">
          {remainingDays == 0
            ? "Today"
            : remainingDays == 1
            ? `Today`
            : `${remainingDays} days ago`}
        </p>
      </div>
    </div>
  );
};

export default JobCard;
