import React, { useEffect, useState } from "react";
import { Breadcrumb, Button, Modal, Result } from "antd";
import { Link, NavLink, useNavigate, useParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import date from "date-and-time";
import Swal from "sweetalert2";
import {
  getAssessmentNameById,
  getStatus,
  getUsers,
  previousAnswersDelete,
} from "../../../../serviceApi/api";
import instructionData from "../../../../components/instruction/studentInstruction.json";
import { BreadcrumbComp } from "../../../../components/Breadcrumb/Breadcrumb";
import { checkUserEventAssessment } from "../../../../serviceApi/adminApi/EventApi";

export const ExamInstructions = () => {
  const navigate = useNavigate();
  const { user } = useAuth0();
  const email: any = user?.email;
  const { assessmentId } = useParams();
  const [status, setStatus] = useState();
  const [length, setLength] = useState(0);
  const [checked, setChecked] = useState(false);
  const [isAttempt, setIsAttempt] = useState(false);
  const [loadings, setLoadings] = useState<boolean[]>([]);
  const [type, setType] = useState("Public");
  const [assessmentName, setAssessmentName] = React.useState();

  const enterLoading = (index: number) => {
    setLoadings((prevLoadings) => {
      const newLoadings = [...prevLoadings];
      newLoadings[index] = true;
      return newLoadings;
    });
    setTimeout(() => {
      navigate(`/student/assessment/${assessmentId}/exam/start`);
    }, 2000);
  };

  const getAssessmentStatus = async () => {
    const response = await getStatus(email, assessmentId);
    if (response?.data?.getStatus?.length > 0) {
      const status = response?.data?.getStatus[0]?.status;
      if (status == "Completed") {
        setStatus(status);
        setType("Private");
        setLength(1);
      }
    } else {
      setLength(0);
    }
  };
  const getAssessmentName = async () => {
    const response = await getAssessmentNameById(assessmentId);
    if (response?.data?.assessmentNameById?.length > 0) {
      setAssessmentName(response?.data?.assessmentNameById[0]?.name);
      if (response?.data?.assessmentNameById[0]?.type == "private") {
        userEventAuthenticate(assessmentId);
      }
    } else {
      navigate("/404");
    }
  };
  const handleChange = () => {
    setChecked(true);
    if (checked) {
      setChecked(false);
    } else {
      setChecked(true);
    }
  };

  const navigatePageToAssessment = () => {
    navigate(`/student/assesment`);
  };

  const userEventAuthenticate = async (assessmentId: any) => {
    const result = await checkUserEventAssessment(email, assessmentId);
    if (result?.data?.checkEventAssessment?.length > 0) {
      setIsAttempt(true);
    } else {
      Swal.fire({
        icon: "error",
        title: "Sorry! Your are not registered for the event",
        showConfirmButton: false,
        timer: 3000,
      });
      navigate("/404");
    }
  };

  useEffect(() => {
    if (email) {
      getAssessmentName();
      getAssessmentStatus();
    }
  }, [email]);

  const deletePreviousAnswers = async (studentId: number) => {
    if (studentId) {
      await previousAnswersDelete(assessmentId, studentId);
    }
  };

  const getUserInfo = async (email: string) => {
    const userInfo = await getUsers(email);
    if (userInfo?.data?.user?.length > 0) {
      deletePreviousAnswers(userInfo?.data?.user[0]?.id);
    }
  };

  useEffect(() => {
    if (email) {
      if (localStorage.getItem("firstLoadDone") == "1") {
        localStorage.removeItem("firstLoadDone");
        localStorage.removeItem("codeStorage");
      }
      getUserInfo(email);
    }
  }, [email]);

  const breadcrumbData = [
    {
      path: "/student/Dashboard",
      text: "Dashboard",
    },
    { text: "assessment" },
    {
      path: `/student/assesment`,
      text: assessmentName,
    },
    { text: "exam instructions" },
  ];

  return (
    <>
      {length == 1 && type == "Private" && isAttempt ? (
        <Modal
          centered
          open={true}
          onOk={() => navigatePageToAssessment()}
          onCancel={() => navigatePageToAssessment()}
        >
          <Result
            status="success"
            title="You have reached maximum Limit for this assessment."
            subTitle={`Assessment Name: ${assessmentName} `}
            extra={[
              <NavLink to={`/student/assesment`}>
                <button className="bg-brand-500 font-primary text-white px-5 py-1 rounded-lg capitalize">
                  Take another assessment
                </button>
              </NavLink>,
            ]}
          />
        </Modal>
      ) : (
        ""
      )}
      <section className="w-full max-h-[86vh] overflow-y-auto lg:p-10 p-5">
        <p className="font-primary font-semibold mb-0 text-lg lg:text-2xl text-black">
          Exam instructions
        </p>
        <div className="mb-4">
          <BreadcrumbComp breadcrumbData={breadcrumbData} />
        </div>

        <div className="w-full h-26 p-6 bg-white border border-gray-200 rounded-lg shadow-md ">
          <h1 className="font-primary font-semibold text-lg lg:text-2xl text-black">
            Assessment Instructions
          </h1>
          <p>
            This assessment is subject to copyright protection and by accessing
            the assessment you are obliged to accept the following regulations:
          </p>
          <hr className="h-px  bg-gray-200 border-1 w-full ... dark:bg-black-700" />
          <div className="flex items-center  lg:px-0 mt-5 px-3 lg:mb-0 mb-24">
            <div className="w-full bg-white">
              {instructionData.length > 0
                ? instructionData.map((data: string, i: number) => (
                    <p
                      key={i}
                      className="content-center my-0 text-sm lg:text-base font-primary"
                    >
                      {data}
                    </p>
                  ))
                : ""}
              <>
                <div className="pt-5">
                  <input
                    id="checkboxForTerms"
                    type="checkbox"
                    className="cursor-pointer"
                    onChange={handleChange}
                  />

                  <label
                    htmlFor="checkboxForTerms"
                    className="lg:text-lg text-sm cursor-pointer pl-2 text-black font-primary"
                  >
                    By accepting this agreement you acknowledge that you have
                    read, understand and agree to be bound by its terms and
                    conditions and you are aware that breaching of any of its
                    regulations may have legal and financial consequences.
                  </label>
                </div>
                <span className="text-brand-500 text-lg lg:text-2xl  lg:py-3 font-bold">
                  "Best of Luck."
                </span>
                <div>
                  <Button
                    className="rounded-lg mt-3 font-primary capitalize  px-8 content-center "
                    type="primary"
                    disabled={checked ? false : true}
                    loading={loadings[0]}
                    onClick={() => enterLoading(0)}
                  >
                    {status == "Completed" ? "Retake" : "Start assessment"}
                  </Button>
                </div>
              </>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
