import React, { HtmlHTMLAttributes, useEffect, useMemo, useState } from "react";
import "./HtmlEditor.css";
import { Modal, Tooltip } from "antd";
import { InputField } from "../../../../../../components/inputFields/InputField";
import { PrimaryButton } from "../../../../../../components/buttons/PrimaryButton";
import { useParams } from "react-router-dom";

export default function HtmlEditor() {
  const { id } = useParams<any>();
  const [j, setJ] = useState(0);
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState(false);
  const [isAligendRows, setIsAlignedRows] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [shareFileId, setShareFileId] = useState<any>();
  const [html, setHtml] = useState("");
  const [css, setCss] = useState("");
  const [jsCode, setJsCode] = useState("");
  const [btnText, setBtnText] = useState("Copy Link");
  const [isCopied, setIsCopied] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const origin = window.location.origin;
  //Function for live Rendering
  function update(i) {
    setMessage("");
    if (i == 0) {
      let htmlCode = (document.getElementById("htmlCode") as HTMLInputElement)
        ?.value;
      let cssCode = (document.getElementById("cssCode") as HTMLInputElement)
        ?.value;
      let javascriptCode = (
        document.getElementById("javascriptCode") as HTMLInputElement
      )?.value;
      if (
        htmlCode.trim().length > 0 &&
        cssCode.trim().length > 0 &&
        javascriptCode.trim().length
      ) {
        setStatus(true);
      } else {
        setStatus(false);
      }
      let text =
        htmlCode +
        "<style>" +
        cssCode +
        "</style>" +
        "<scri" +
        "pt>" +
        javascriptCode +
        "</scri" +
        "pt>";
      let iframe = (document.getElementById("viewer") as any)?.contentWindow
        .document;
      iframe?.open();
      iframe?.write(text);
      iframe?.close();
    } else if (i == 1) {
      let htmlCode = (document.getElementById("htmlCode") as HTMLInputElement)
        ?.value;
      let html = htmlCode.slice(0, htmlCode.length);
      (document.getElementById("htmlCode") as HTMLInputElement).value = html;
      setJ(1);
    }
  }

  //Auto Tag Closing functionality
  const closeChars = new Map([
    ["{", "}"],
    ["[", "]"],
    ["(", ")"],
    ["<", ">"],
    ['"', '"'],
    ["'", "'"],
  ]);
  const editHtml = () => {
    //Handling Html Code Auto Closing
    const htmlCode = document.getElementById("htmlCode") as HTMLInputElement;
    htmlCode.addEventListener("input", function (e: any) {
      if (j != 1) {
        const pos = e?.target?.selectionStart;
        const val = [...e.target.value];
        const char = val.slice(pos - 1, pos)[0];
        const closeChar = closeChars.get(char);
        if (closeChar) {
          val.splice(pos, 0, closeChar);
          // e.target.value = val.join("");
          e.target.selectionEnd = pos;
        }
      }
      setJ(0);
    });

    //Handling CSS Code Auto Closing
    const cssCode = document.getElementById("cssCode") as HTMLInputElement;
    cssCode.addEventListener("input", function (e: any) {
      if (j != 1) {
        const pos = e.target.selectionStart;
        const val = [...e.target.value];
        const char = val.slice(pos - 1, pos)[0];
        const closeChar = closeChars.get(char);
        if (closeChar) {
          val.splice(pos, 0, closeChar);
          // e.target.value = val.join("");
          e.target.selectionEnd = pos;
        }
      }
      setJ(0);
    });

    //Handling Javascript Code Auto Closing
    const javascriptCode = document.getElementById(
      "javascriptCode"
    ) as HTMLInputElement;
    javascriptCode.addEventListener("input", function (e: any) {
      if (j != 1) {
        const pos = e.target.selectionStart;
        const val = [...e.target.value];

        const char = val.slice(pos - 1, pos)[0];
        const closeChar = closeChars.get(char);

        if (closeChar) {
          val.splice(pos, 0, closeChar);
          // e.target.value = val.join("");
          e.target.selectionEnd = pos;
        }
      }
      setJ(0);
    });

    // Split([".container", ".iframe-container"]);
  };

  const generateCodeFormId = (id) => {
    const getData = localStorage.getItem("html_code");
    if (getData && id) {
      const shareFileData = JSON.parse(getData);
      const { html, css, jsCode } = shareFileData;
      setHtml(html);
      setCss(css);
      setJsCode(jsCode);
      return shareFileData;
    }
  };

  const defaultCode = useMemo(() => generateCodeFormId(id), [id]);

  const handleHtml = (event: any) => {
    if (event.key === 9) {
      var v = event.target.value,
        s = event.target.selectionStart,
        e = event.target.selectionEnd;
      event.target.value = v.substring(0, s) + "\t" + v.substring(e);
      event.target.selectionStart = event.target.selectionEnd = s + 1;
      return false;
    }
    if (event.key == 8) {
      update(1);
    }
  };
  function saveFile() {
    var fileName = Date.now();
    if (
      fileName != null &&
      ((document.getElementById("htmlCode") as HTMLInputElement).value != "" ||
        (document.getElementById("cssCode") as HTMLInputElement).value != "" ||
        (document.getElementById("javascriptCode") as HTMLInputElement).value !=
          "")
    ) {
      setMessage("");
      var htmlCode = (document.getElementById("htmlCode") as HTMLInputElement)
        .value;
      var cssCode = (document.getElementById("cssCode") as HTMLInputElement)
        .value;
      var javascriptCode = (
        document.getElementById("javascriptCode") as HTMLInputElement
      ).value;
      let text =
        htmlCode +
        "<style>" +
        cssCode +
        "</style>" +
        "<scri" +
        "pt>" +
        javascriptCode +
        "</scri" +
        "pt>";
      download(text, fileName + ".html", "text/plain");
    } else {
      setMessage("All fields should be mandatory");
    }
  }

  function shareFile() {
    setShareFileId(Date.now());
    setIsModalOpen(true);
  }

  function handleCopy() {
    var copyText = document.getElementById("link") as HTMLInputElement;

    copyText?.select();

    navigator.clipboard.writeText(copyText?.value);

    var fileName = Date.now();
    if (
      fileName != null &&
      ((document.getElementById("htmlCode") as HTMLInputElement).value != "" ||
        (document.getElementById("cssCode") as HTMLInputElement).value != "" ||
        (document.getElementById("javascriptCode") as HTMLInputElement).value !=
          "")
    ) {
      setMessage("");
      var htmlCode = (document.getElementById("htmlCode") as HTMLInputElement)
        .value;
      var cssCode = (document.getElementById("cssCode") as HTMLInputElement)
        .value;
      var javascriptCode = (
        document.getElementById("javascriptCode") as HTMLInputElement
      ).value;
      let text =
        htmlCode +
        "<style>" +
        cssCode +
        "</style>" +
        "<scri" +
        "pt>" +
        javascriptCode +
        "</scri" +
        "pt>";
      localStorage.setItem(
        "html_code",
        JSON.stringify({
          id: shareFileId,
          html: htmlCode,
          css: cssCode,
          jsCode: javascriptCode,
        })
      );
      // download(text, fileName + ".html", "text/plain");
    }
    setBtnText("Copied");
    setIsCopied(true);
    setTimeout(() => {
      setBtnText("Copy Link");
      setIsCopied(false);
    }, 2000);
  }

  const alignedRows = () => {
    if (isAligendRows) {
      (
        document.getElementById("mainContainer") as HTMLStyleElement
      ).style.flexDirection = "row";
      (document.getElementById("htmlCode") as HTMLStyleElement).style.height =
        "58vh";
      (document.getElementById("cssCode") as HTMLStyleElement).style.height =
        "58vh";

      (
        document.getElementById("javascriptCode") as HTMLStyleElement
      ).style.height = "58vh";
      setIsAlignedRows(false);
    } else {
      (
        document.getElementById("mainContainer") as HTMLStyleElement
      ).style.flexDirection = "column";
      (document.getElementById("htmlCode") as HTMLStyleElement).style.height =
        "17vh";
      (document.getElementById("cssCode") as HTMLStyleElement).style.height =
        "17vh";

      (
        document.getElementById("javascriptCode") as HTMLStyleElement
      ).style.height = "17vh";
      setIsAlignedRows(true);
    }
  };

  useEffect(() => {
    editHtml();
  }, []);

  // This function is from https://stackoverflow.com/questions/3665115/create-a-file-in-memory-for-user-to-download-not-through-server
  function download(data, filename, type) {
    var userFile = new Blob([data], { type: type });
    var a = document.createElement("a"),
      url = URL.createObjectURL(userFile);
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    setTimeout(function () {
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    }, 0);
  }

  // Function to toggle the dropdown
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const onMaximize = () => {
    if (isAligendRows === false) {
      (document.getElementById("maximize") as HTMLStyleElement).style.width =
        "100%";
    } else {
      (document.getElementById("maximize") as HTMLStyleElement).style.height =
        "100%";
    }
    setIsOpen(false);
  };
  const onMiniimize = () => {
    if (isAligendRows === false) {
      (document.getElementById("maximize") as HTMLStyleElement).style.width =
        "33%";
    } else {
      (document.getElementById("maximize") as HTMLStyleElement).style.width =
        "100%";
    }
    setIsOpen(false);
  };
  return (
    <div className="p-2 m-2">
      <Modal
        title="Share Link"
        centered
        open={isModalOpen}
        className="cat-modal"
        onCancel={() => setIsModalOpen(false)}
      >
        <div>
          <p className="lg:text-sm text-sm pb-0 mb-1 md:mb-2 lg:mb-2  capitalize text-black">
            source code is copied to below link.
          </p>

          <InputField
            type="name"
            placeholder="Name"
            id="link"
            value={`${origin}/htmleditor/${shareFileId}`}
          />
        </div>
        <div className="mt-8">
          <PrimaryButton
            id="copy"
            onclick={() => handleCopy()}
            text={btnText}
            className={`${
              isCopied
                ? "bg-blue-400 hover:bg-transparent hover:border-black "
                : "bg-black hover:bg-transparent hover:border-black border-black hover:text-black"
            }`}
          />
        </div>
      </Modal>
      <button
        id="btn"
        disabled={status ? false : true}
        onClick={() => saveFile()}
        className="m-2 p-2 rounded-xl bg-[#215df3] text-white hover:bg-blue-300"
      >
        Save
      </button>

      <button
        id="btn"
        disabled={status ? false : true}
        onClick={() => shareFile()}
        className="m-2 p-2 rounded-xl bg-[#215df3] text-white hover:bg-blue-300"
      >
        Share
      </button>

      <Tooltip title="View" placement="rightTop">
        <button className="w-4 ml-2" onClick={() => alignedRows()}>
          <svg viewBox="0 0 20 20" className="">
            <path d="M0 9.002C0 8.45.455 8 .992 8h18.016c.548 0 .992.456.992 1.002v9.996c0 .553-.455 1.002-.992 1.002H.992C.444 20 0 19.544 0 18.998V9.002Zm0-8C0 .45.451 0 .99 0h4.02A.99.99 0 0 1 6 1.003v4.994C6 6.551 5.549 7 5.01 7H.99A.99.99 0 0 1 0 5.997V1.003Zm7 0C7 .45 7.451 0 7.99 0h4.02A.99.99 0 0 1 13 1.003v4.994C13 6.551 12.549 7 12.01 7H7.99A.99.99 0 0 1 7 5.997V1.003Zm7 0C14 .45 14.451 0 14.99 0h4.02A.99.99 0 0 1 20 1.003v4.994C20 6.551 19.549 7 19.01 7h-4.02A.99.99 0 0 1 14 5.997V1.003Z"></path>
          </svg>
        </button>
      </Tooltip>
      <div className="htmlcontainer" id="mainContainer">
        {/* <Split
          sizes={[25, 75]}
          minSize={100}
          expandToMin={false}
          gutterSize={10}
          gutterAlign="center"
          snapOffset={30}
          dragInterval={1}
          direction="vertical"
          cursor="col-resize"
        > */}
        {/* {/ <!-- Text area for Html Code  --> /} */}
        <div
          className={`d-flex  ${isAligendRows ? "w-[100%]" : "w-[33%]"}`}
          //  / Adjust width based on flex direction /
          id="maximize"
        >
          <div className="flex flex-row relative  justify-between items-center">
            <div className="bg-red-500 h-[30px] w-[100%]"> HTML</div>
            <div
              className={`w-[20px] absolute ${
                isAligendRows ? "right-[20px]" : "right-[20px]"
              }`}
              onClick={toggleDropdown}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="m19.5 8.25-7.5 7.5-7.5-7.5"
                />
              </svg>
            </div>
          </div>
          {isOpen && (
            <div
              className={`absolute top-20 w-[150px] bg-white shadow-lg border border-gray-200 z-10 rounded-lg ${
                !isAligendRows ? "left-[500px]" : "left-[1800px]"
              }`}
            >
              <ul>
                <li
                  className="p-2 hover:bg-gray-100 cursor-pointer"
                  onClick={onMaximize}
                >
                  Maximize
                </li>
                <li
                  className="p-2 hover:bg-gray-100 cursor-pointer"
                  onClick={onMiniimize}
                >
                  Minimize
                </li>
              </ul>
            </div>
          )}

          <textarea
            id="htmlCode"
            placeholder="Type HTML code here"
            spellCheck="false"
            onInput={() => update(0)}
            onKeyDown={(event) => {
              handleHtml(event);
            }}
            value={html}
            onChange={(e) => setHtml(e.target.value)}
          ></textarea>
        </div>
        {/* {/ <!-- Text area for Css Code  --> /} */}
        <div
          className={`d-flex ${isAligendRows ? "w-[100%]" : "w-[33%]"}`}
          id="maximize"
        >
          <div className="flex flex-row relative justify-between items-center">
            <div className="bg-sky-500 h-[30px] w-[100%]">CSS</div>
            <div
              className={`w-[20px] absolute ${
                isAligendRows ? "right-[20px]" : "right-[20px]"
              }`}
              onClick={toggleDropdown}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="m19.5 8.25-7.5 7.5-7.5-7.5"
                />
              </svg>
            </div>
          </div>
          <textarea
            id="cssCode"
            placeholder="Type CSS code here"
            spellCheck="false"
            onInput={() => update(0)}
            onKeyDown={(event) => {
              handleHtml(event);
            }}
            value={css}
            onChange={(e) => setCss(e.target.value)}
          ></textarea>
        </div>
        {/* {/ <!-- Text area for Javascript Code  --> /} */}
        <div
          className={`d-flex ${isAligendRows ? "w-[100%]" : "w-[33%]"}`}
          id="maximize"
        >
          <div className="flex flex-row relative justify-between items-center">
            <div className="bg-yellow-500 h-[30px] w-[100%]"> JS</div>
            <div
              className={`w-[20px] absolute ${
                isAligendRows ? "right-[20px]" : "right-[20px]"
              }`}
              onClick={toggleDropdown}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="m19.5 8.25-7.5 7.5-7.5-7.5"
                />
              </svg>
            </div>
          </div>
          <textarea
            id="javascriptCode"
            spellCheck="false"
            placeholder="Type JavaScript code here"
            onInput={() => update(0)}
            onKeyDown={(event) => {
              handleHtml(event);
            }}
            value={jsCode}
            onChange={(e) => setJsCode(e.target.value)}
          ></textarea>
        </div>
        {/* </Split> */}
      </div>
      {message && <p className="text-red-500 font-base">{message}</p>}
      <div className="iframe-container split">
        <iframe id="viewer"></iframe>
      </div>
    </div>
  );
}
