/* eslint-disable no-var */
import React, { useEffect, useState } from "react";
import AceEditor from "react-ace";
import ace from "ace-builds";
import * as prettier from "https://unpkg.com/prettier@3.3.3/standalone.mjs";
import prettierPluginBabel from "https://unpkg.com/prettier@3.3.3/plugins/babel.mjs";
import prettierPluginEstree from "https://unpkg.com/prettier@3.3.3/plugins/estree.mjs";

import phpPlugin from "@prettier/plugin-php";
import javaPlugin from "prettier-plugin-java";
import "ace-builds/webpack-resolver";
import "ace-builds/src-noconflict/mode-java";
import "ace-builds/src-noconflict/mode-python";
import "ace-builds/src-noconflict/mode-javascript";
import "ace-builds/src-noconflict/mode-scala";
import "ace-builds/src-noconflict/mode-c_cpp";
import "ace-builds/src-noconflict/mode-php";
import "ace-builds/src-noconflict/mode-clojure";
import "ace-builds/src-noconflict/mode-ruby";
import "ace-builds/src-noconflict/mode-golang";
import "ace-builds/src-noconflict/mode-csharp";
import "ace-builds/src-noconflict/theme-terminal";
import "ace-builds/src-noconflict/theme-github";
import "ace-builds/src-noconflict/theme-twilight";
import "ace-builds/src-noconflict/theme-tomorrow";
import "ace-builds/src-noconflict/theme-tomorrow_night_blue";
import "ace-builds/src-noconflict/theme-xcode";
import "ace-builds/src-noconflict/theme-monokai";
import "ace-builds/src-noconflict/theme-textmate";
import "ace-builds/src-noconflict/theme-solarized_dark";
import "ace-builds/src-noconflict/theme-solarized_light";
import "ace-builds/src-noconflict/theme-kuroir";
import "ace-builds/src-noconflict/ext-language_tools";
ace.config.set("loadWorkerFromBlob", false);
import Split from "react-split";
import axios from "axios";
import { Space, Tabs } from "antd";

import data from "./remote-compiler.postman_collection.json";
import {
  PlayCircleOutlined,
  FormatPainterOutlined,
  LockOutlined,
  PauseOutlined,
} from "@ant-design/icons";
import { Select, Button, Tooltip, Spin } from "antd";
export const CodeEditor = (props) => {
  const localParseData = JSON.parse(localStorage.getItem("codeStorage"));
  const option = props.option;
  const questionId = props.option[0].questionId;
  const languageData = localParseData?.filter((data) => data.id == questionId);

  const [code, setCode] = useState(
    languageData == null
      ? "# select language and write code"
      : languageData[0]?.code
  );
  const [format, setFormat] = useState(true);
  const [output, setOutput] = useState("");
  const [value, setValue] = useState(
    languageData == null ? "13" : languageData[0]?.prefferLanguage
  );
  const [languageName, setLanguageName] = useState("");
  const [active, setActive] = useState(
    localStorage.getItem("codeStorage") == null ? false : true
  );
  const [theme, setTheme] = useState("");
  const [cmdLineArgs, setCmdLineArgs] = useState("");
  const [status, setStatus] = useState(false);
  const [, setActive1] = useState(false);
  const [test, setTest] = useState([]);
  const [allTestResponse, setAllTestResponse] = useState([]);
  const [response, setResponse] = useState(true);
  const [mode, setMode] = useState(
    languageData == null ? "javascript" : languageData[0]?.mode
  );
  const [error, setError] = useState(false);
  const [timeOutError, setTimeOutError] = useState("");
  const [nextTime, setNextTime] = useState("");
  const [countPassCases, setCountPassCases] = useState();
  const [countFailCases, setCountFailCases] = useState();
  const [type1, setType1] = useState(false);
  const [isRun, setIsRun] = useState(true);
  const [isSubmit, setIsSubmit] = useState(true);
  const abortController = new AbortController();

  useEffect(() => {
    setValue(
      localParseData && languageData?.length > 0
        ? languageData[0]?.prefferLanguage
        : "13"
    );
    setCode(
      localParseData && languageData?.length > 0
        ? languageData[0]?.code
        : "# select language and write code"
    );
    setMode(
      localParseData && languageData?.length > 0 ? languageData[0]?.mode : ""
    );
    setActive(localParseData && languageData?.length > 0 ? true : false);
  }, [props]);

  const handle1Change = (value) => {
    setValue(value);
    setActive(true);
    switch (parseInt(value)) {
      case 7: {
        // eslint-disable-next-line no-var
        var parseData = JSON.stringify(data || "");
        var languageData = JSON.parse(parseData);
        setLanguageName(languageData.item[0].name);
        languageData = languageData.item[0].request.body.raw;
        const cppLanguage = JSON.parse(languageData);
        if (localParseData?.length > 0) {
          localParseData?.forEach((data, index) => {
            if (data.id == questionId) {
              setCode(
                localParseData &&
                  localParseData[index]?.id == questionId &&
                  localParseData[index]?.prefferLanguage == value
                  ? localParseData[index]?.code
                  : cppLanguage.code
              );
            } else {
              setCode(cppLanguage.code);
            }
          });
        } else {
          setCode(cppLanguage.code);
        }
        setCmdLineArgs(cppLanguage.cmdLineArgs);
        setMode("c_cpp");
        setFormat(true);
        break;
      }
      case 0: {
        var parseData = JSON.stringify(data || "");
        var languageData = JSON.parse(parseData);
        setLanguageName(languageData.item[1].name);
        languageData = languageData.item[1].request.body.raw;
        const cppLanguage = JSON.parse(languageData);
        if (localParseData?.length > 0) {
          localParseData?.forEach((data, index) => {
            if (data.id == questionId) {
              setCode(
                localParseData &&
                  localParseData[index]?.id == questionId &&
                  localParseData[index]?.prefferLanguage == value
                  ? localParseData[index]?.code
                  : cppLanguage.code
              );
            } else {
              setCode(cppLanguage.code);
            }
          });
        } else {
          setCode(cppLanguage.code);
        }
        setCmdLineArgs(cppLanguage.cmdLineArgs);
        setMode("python");
        setFormat(true);
        break;
      }
      case 1: {
        var parseData = JSON.stringify(data || "");
        var languageData = JSON.parse(parseData);
        setLanguageName(languageData.item[2].name);
        languageData = languageData.item[2].request.body.raw;
        const cppLanguage = JSON.parse(languageData);
        if (localParseData?.length > 0) {
          localParseData?.forEach((data, index) => {
            if (data.id == questionId) {
              setCode(
                localParseData &&
                  localParseData[index]?.id == questionId &&
                  localParseData[index]?.prefferLanguage == value
                  ? localParseData[index]?.code
                  : cppLanguage.code
              );
            } else {
              setCode(cppLanguage.code);
            }
          });
        } else {
          setCode(cppLanguage.code);
        }
        setCmdLineArgs(cppLanguage.cmdLineArgs);
        setMode("ruby");
        setFormat(true);
        break;
      }

      case 2: {
        var parseData = JSON.stringify(data || "");
        var languageData = JSON.parse(parseData);
        setLanguageName(languageData.item[3].name);
        languageData = languageData.item[3].request.body.raw;
        const cppLanguage = JSON.parse(languageData);
        if (localParseData?.length > 0) {
          localParseData?.forEach((data, index) => {
            if (data.id == questionId) {
              setCode(
                localParseData &&
                  localParseData[index]?.id == questionId &&
                  localParseData[index]?.prefferLanguage == value
                  ? localParseData[index]?.code
                  : cppLanguage.code
              );
            } else {
              setCode(cppLanguage.code);
            }
          });
        } else {
          setCode(cppLanguage.code);
        }
        setCmdLineArgs(cppLanguage.cmdLineArgs);
        setMode("clojure");
        setFormat(true);
        break;
      }
      case 3: {
        var parseData = JSON.stringify(data || "");
        var languageData = JSON.parse(parseData);
        setLanguageName(languageData.item[4].name);
        languageData = languageData.item[4].request.body.raw;
        const cppLanguage = JSON.parse(languageData);
        if (localParseData?.length > 0) {
          localParseData?.forEach((data, index) => {
            if (data.id == questionId) {
              setCode(
                localParseData &&
                  localParseData[index]?.id == questionId &&
                  localParseData[index]?.prefferLanguage == value
                  ? localParseData[index]?.code
                  : cppLanguage.code
              );
            } else {
              setCode(cppLanguage.code);
            }
          });
        } else {
          setCode(cppLanguage.code);
        }
        setCmdLineArgs(cppLanguage.cmdLineArgs);
        setMode("php");
        setFormat(true);
        break;
      }
      case 4: {
        var parseData = JSON.stringify(data || "");
        var languageData = JSON.parse(parseData);
        setLanguageName(languageData.item[5].name);
        languageData = languageData.item[5].request.body.raw;
        const cppLanguage = JSON.parse(languageData);
        if (localParseData?.length > 0) {
          localParseData?.forEach((data, index) => {
            if (data.id == questionId) {
              setCode(
                localParseData &&
                  localParseData[index]?.id == questionId &&
                  localParseData[index]?.prefferLanguage == value
                  ? localParseData[index]?.code
                  : cppLanguage.code
              );
            } else {
              setCode(cppLanguage.code);
            }
          });
        } else {
          setCode(cppLanguage.code);
        }
        setCmdLineArgs(cppLanguage.cmdLineArgs);
        setMode("javascript");
        setFormat(true);
        break;
      }
      case 5: {
        var parseData = JSON.stringify(data || "");
        var languageData = JSON.parse(parseData);
        setLanguageName(languageData.item[6].name);
        languageData = languageData.item[6].request.body.raw;
        const cppLanguage = JSON.parse(languageData);
        if (localParseData?.length > 0) {
          localParseData?.forEach((data, index) => {
            if (data.id == questionId) {
              setCode(
                localParseData &&
                  localParseData[index]?.id == questionId &&
                  localParseData[index]?.prefferLanguage == value
                  ? localParseData[index]?.code
                  : cppLanguage.code
              );
            } else {
              setCode(cppLanguage.code);
            }
          });
        } else {
          setCode(cppLanguage.code);
        }
        setCmdLineArgs(cppLanguage.cmdLineArgs);
        setMode("scala");
        setFormat(true);
        break;
      }
      case 6: {
        var parseData = JSON.stringify(data || "");
        var languageData = JSON.parse(parseData);
        setLanguageName(languageData.item[7].name);
        languageData = languageData.item[7].request.body.raw;
        const cppLanguage = JSON.parse(languageData);
        if (localParseData?.length > 0) {
          localParseData?.forEach((data, index) => {
            if (data.id == questionId) {
              setCode(
                localParseData &&
                  localParseData[index]?.id == questionId &&
                  localParseData[index]?.prefferLanguage == value
                  ? localParseData[index]?.code
                  : cppLanguage.code
              );
            } else {
              setCode(cppLanguage.code);
            }
          });
        } else {
          setCode(cppLanguage.code);
        }
        setCmdLineArgs(cppLanguage.cmdLineArgs);
        setMode("golang");
        setFormat(true);
        break;
      }
      case 8: {
        var parseData = JSON.stringify(data || "");
        var languageData = JSON.parse(parseData);
        setLanguageName(languageData.item[8].name);
        languageData = languageData.item[8].request.body.raw;
        const cppLanguage = JSON.parse(languageData);
        if (localParseData?.length > 0) {
          localParseData?.forEach((data, index) => {
            if (data.id == questionId) {
              setCode(
                localParseData &&
                  localParseData[index]?.id == questionId &&
                  localParseData[index]?.prefferLanguage == value
                  ? localParseData[index]?.code
                  : cppLanguage.code
              );
            } else {
              setCode(cppLanguage.code);
            }
          });
        } else {
          setCode(cppLanguage.code);
        }
        setMode("java");
        setFormat(true);
        break;
      }
      case 9: {
        var parseData = JSON.stringify(data || "");
        var languageData = JSON.parse(parseData);
        setLanguageName(languageData.item[9].name);
        languageData = languageData.item[9].request.body.raw;
        const cppLanguage = JSON.parse(languageData);
        if (localParseData?.length > 0) {
          localParseData?.forEach((data, index) => {
            if (data.id == questionId) {
              setCode(
                localParseData &&
                  localParseData[index]?.id == questionId &&
                  localParseData[index]?.prefferLanguage == value
                  ? localParseData[index]?.code
                  : cppLanguage.code
              );
            } else {
              setCode(cppLanguage.code);
            }
          });
        } else {
          setCode(cppLanguage.code);
        }
        setCmdLineArgs(cppLanguage.cmdLineArgs);
        setMode("c_cpp");
        setFormat(true);
        break;
      }
      case 10: {
        var parseData = JSON.stringify(data || "");
        var languageData = JSON.parse(parseData);
        setLanguageName(languageData.item[10].name);
        languageData = languageData.item[10].request.body.raw;
        const cppLanguage = JSON.parse(languageData);
        if (localParseData?.length > 0) {
          localParseData?.forEach((data, index) => {
            if (data.id == questionId) {
              setCode(
                localParseData &&
                  localParseData[index]?.id == questionId &&
                  localParseData[index]?.prefferLanguage == value
                  ? localParseData[index]?.code
                  : cppLanguage.code
              );
            } else {
              setCode(cppLanguage.code);
            }
          });
        } else {
          setCode(cppLanguage.code);
        }
        setCmdLineArgs(cppLanguage.cmdLineArgs);
        setMode("csharp");
        setFormat(true);
        break;
      }
      case 11: {
        var parseData = JSON.stringify(data || "");
        var languageData = JSON.parse(parseData);
        setLanguageName(languageData.item[11].name);
        languageData = languageData.item[11].request.body.raw;
        const cppLanguage = JSON.parse(languageData);
        if (localParseData?.length > 0) {
          localParseData?.forEach((data, index) => {
            if (data.id == questionId) {
              setCode(
                localParseData &&
                  localParseData[index]?.id == questionId &&
                  localParseData[index]?.prefferLanguage == value
                  ? localParseData[index]?.code
                  : cppLanguage.code
              );
            } else {
              setCode(cppLanguage.code);
            }
          });
        } else {
          setCode(cppLanguage.code);
        }
        setCmdLineArgs(cppLanguage.cmdLineArgs);
        setMode("c_cpp");
        setFormat(true);
        break;
      }
      case 12: {
        var parseData = JSON.stringify(data || "");
        var languageData = JSON.parse(parseData);
        setLanguageName(languageData.item[12].name);
        languageData = languageData.item[12].request.body.raw;
        const cppLanguage = JSON.parse(languageData);
        if (localParseData?.length > 0) {
          localParseData?.forEach((data, index) => {
            if (data.id == questionId) {
              setCode(
                localParseData &&
                  localParseData[index]?.id == questionId &&
                  localParseData[index]?.prefferLanguage == value
                  ? localParseData[index]?.code
                  : cppLanguage.code
              );
            } else {
              setCode(cppLanguage.code);
            }
          });
        } else {
          setCode(cppLanguage.code);
        }
        setCmdLineArgs(cppLanguage.cmdLineArgs);
        setMode("c_cpp");
        setFormat(true);
        break;
      }
      default: {
        setValue(value);
        setActive(false);
      }
    }
  };
  const handleChange = (value1) => {
    switch (parseInt(value1)) {
      case 0: {
        setTheme("twilight");
        break;
      }
      case 1: {
        setTheme("github");
        break;
      }
      case 2: {
        setTheme("monokai");
        break;
      }
      case 3: {
        setTheme("tomorrow");
        break;
      }
      case 4: {
        setTheme("kuroir");
        break;
      }
      case 5: {
        setTheme("xcode");
        break;
      }
      case 6: {
        setTheme("textmate");
        break;
      }
      case 7: {
        setTheme("solarized_dark");
        break;
      }
      case 8: {
        setTheme("solarized_light");
        break;
      }
      case 9: {
        setTheme("terminal");
        break;
      }
      case 10: {
        setTheme("tomorrow_night_blue");
        break;
      }
    }
  };

  const prettify = async () => {
    switch (mode) {
      case "c_cpp": {
        const { data } = await axios.post(
          `${process.env.REACT_APP_COMPILER_URL}/format-code`,
          { code }
        );
        setCode(data.formattedCode);
        setActive(true);
        return;
      }

      case "javascript": {
        setMode("javascript");
        const formatted = await prettier.format(code, {
          parser: "babel", // Use babel parser for JavaScript
          plugins: [prettierPluginBabel, prettierPluginEstree], // Load the parser plugin for JavaScript
          singleQuote: true,
          semi: true,
        });
        setCode(formatted);
        setActive(true);
        return;
      }

      case "ruby": {
        setMode("ruby");
        const { data } = await axios.post(
          `${process.env.REACT_APP_COMPILER_URL}/format-ruby`,
          { code }
        );
        setCode(data.formattedCode);
        setActive(true);
        return;
      }
      case "java": {
        setMode("java");
        const formatted = await prettier.format(code, {
          parser: "java",
          plugins: [javaPlugin],
          singleQuote: true,
          semi: true,
        });
        setCode(formatted);
        setActive(true);
        return;
      }

      case "php": {
        setMode("php");
        const formatted = await prettier.format(code, {
          parser: "php",
          plugins: [phpPlugin],
          singleQuote: true,
          semi: true,
        });
        setCode(formatted);
        setActive(true);
        return;
      }
      case "python": {
        setMode("python");
        const { data } = await axios.post(
          `${process.env.REACT_APP_RECOMMENDATION_ENGINE_URL}/formate-python-code`,
          { code: code }
        );
        setCode(data.formattedCode);
        setActive(true);
        return;
      }
    }
  };

  const handleClick = async () => {
    const type = process.env.REACT_APP_TYPE_RUN;
    setAllTestResponse([]);
    setIsRun(false);
    if (localParseData?.length > 0) {
      localParseData?.forEach((data, index) => {
        if (data.id != questionId) {
          const list = localParseData;
          localParseData.push({
            prefferLanguage: value,
            code: code,
            mode: mode,
            id: questionId,
          });

          localStorage.setItem("codeStorage", JSON.stringify(list));
        } else {
          const list = localParseData;
          list[index] = {
            prefferLanguage: value,
            code: code,
            mode: mode,
            id: questionId,
          };
          localStorage.setItem("codeStorage", JSON.stringify(list));
        }
      });
    } else {
      localStorage.setItem(
        "codeStorage",
        JSON.stringify([
          {
            prefferLanguage: value,
            code: code,
            mode: mode,
            id: questionId,
          },
        ])
      );
    }
    axios
      .post(`${process?.env?.REACT_APP_COMPILER_URL}/compile`, {
        code,
        value,
        option,
        type,
      })
      .then((res, err) => {
        if (res?.data?.status == 1) {
          setTest(res?.data?.response);
          setOutput(res?.data?.output);
          setStatus(true);
          setResponse(true);
          setActive1(true);
          setType1(false);
          setIsRun(true);
        } else {
          setOutput(res?.data?.stderr);
          setResponse(true);
          setStatus(false);
          setActive1(true);
          setType1(false);
          setIsRun(true);
        }
      })
      .catch((err) => {
        setResponse(true);
        setStatus(false);
        setIsRun(true);
        if (err?.message !== "Request aborted") {
          setError(true);
        }
        setActive1(true);
        setTimeOutError(err?.response ? err?.response?.data?.error?.text : "");
        setNextTime(
          err?.response ? err?.response?.data?.error?.nextValidRequestDate : ""
        );
      });
    setResponse(false);
  };

  const handleSubmit = async () => {
    const type = process.env.REACT_APP_TYPE_SUBMIT;
    setIsSubmit(false);
    var countPass = 0;
    var countFail = 0;
    if (localParseData?.length > 0) {
      localParseData?.forEach((data, index) => {
        if (data.id != questionId) {
          const list = localParseData;
          // list[index] = {
          //   prefferLanguage: value,
          //   code: code,
          //   mode: mode,
          //   id: questionId,
          // };
          localParseData.push({
            prefferLanguage: value,
            code: code,
            mode: mode,
            id: questionId,
          });

          localStorage.setItem("codeStorage", JSON.stringify(list));
        } else {
          const list = localParseData;
          list[index] = {
            prefferLanguage: value,
            code: code,
            mode: mode,
            id: questionId,
          };
          // localParseData.push(list);
          localStorage.setItem("codeStorage", JSON.stringify(list));
        }
      });
    } else {
      localStorage.setItem(
        "codeStorage",
        JSON.stringify([
          {
            prefferLanguage: value,
            code: code,
            mode: mode,
            id: questionId,
          },
        ])
      );
    }
    axios
      .post(`${process?.env?.REACT_APP_COMPILER_URL}/compile`, {
        code,
        value,
        cmdLineArgs,
        option,
        type,
        signal: abortController.signal,
      })
      .then((res, err) => {
        if (res) {
          if (res?.data?.status == 1) {
            res?.data?.response?.forEach((status) => {
              if (status?.status == 1) {
                countPass++;
              } else {
                countFail++;
              }
            });
            const test1 = res?.data?.response?.every((a) => a.pass == true);
            setCountPassCases(countPass);
            setCountFailCases(countFail);
            setAllTestResponse(res?.data?.response);
            // setTest(res?.data?.response);
            setOutput(res?.data?.output);
            setStatus(true);
            setResponse(true);
            setActive1(true);
            setType1(true);
            setIsSubmit(true);
            props.fun(test1, languageName);
          } else {
            setOutput(res?.data?.stderr);
            setResponse(true);
            setStatus(false);
            setActive1(true);
            setIsSubmit(true);
            props.fun(false, languageName);
          }
        } else {
          throw new Error(err);
        }
      })
      .catch((err) => {
        setResponse(true);
        setStatus(false);
        setIsSubmit(true);
        if (err?.message !== "Request aborted") {
          setError(true);
        }
        setTimeOutError(err?.response ? err?.response?.data?.error?.text : "");
        setNextTime(
          err?.response ? err?.response?.data?.error?.nextValidRequestDate : ""
        );
      });
    setResponse(false);
  };

  const handleAbort = () => {
    setResponse(false);
    window.stop();
  };

  return (
    <>
      <div className="sticky top-0 bg-white shadow pb-2 z-20">
        <div className="flex w-full flex-row justify-between items-center">
          <div className="mt-4 space-x-3">
            <Select
              defaultValue="Select Language"
              value={`${value}`}
              style={{
                width: 160,
                border: "none",
                outline: "none",
              }}
              onChange={handle1Change}
              options={[
                { value: "13", label: "Select Language" },
                { value: "7", label: "C/CPP" },
                { value: "0", label: "PYTHON" },
                { value: "1", label: "RUBY" },
                // { value: "2", label: "Clojure" },
                { value: "3", label: "PHP" },
                { value: "4", label: "JS(Node Js)" },
                // { value: "5", label: "Scala" },
                { value: "6", label: "Golang" },
                { value: "8", label: "JAVA" },
                // { value: "9", label: ".Net" },
                { value: "10", label: "C#" },
                // { value: "11", label: "Bash" },
                // { value: "12", label: "Objective C" },
              ]}
            />
            <Select
              defaultValue="select theme"
              style={{ width: 160 }}
              onChange={handleChange}
              options={[
                { value: "0", label: "twilight" },
                { value: "1", label: "github" },
                { value: "2", label: "monokai" },
                { value: "3", label: "tomorrow" },
                { value: "4", label: "kuroir" },
                { value: "5", label: "xcode" },
                { value: "6", label: "textmate" },
                { value: "7", label: "solarized dark" },
                { value: "8", label: "solarized light" },
                { value: "9", label: "terminal" },
                { value: "10", label: "tomorrow_night_blue" },
              ]}
            />
          </div>
          <div>
            <Space>
              {active ? (
                <>
                  <Button type="primary" onClick={() => prettify()}>
                    <FormatPainterOutlined />
                    Beautify
                  </Button>
                  <button
                    className="bg-black font-primary space-x-4  text-white px-5 py-1 rounded-full"
                    onClick={isRun ? () => handleClick() : () => handleAbort()}
                  >
                    {isRun ? (
                      <PlayCircleOutlined className="mr-1" />
                    ) : (
                      <PauseOutlined className="mr-1" />
                    )}
                    {isRun ? "Run" : "Stop"}
                  </button>
                  <button
                    className="bg-brand font-primary space-x-4  text-white px-5 py-1 rounded-full"
                    onClick={
                      isSubmit ? () => handleSubmit() : () => handleAbort()
                    }
                  >
                    {isSubmit ? (
                      <PlayCircleOutlined className="mr-1" />
                    ) : (
                      <PauseOutlined className="mr-1" />
                    )}
                    {isSubmit ? "Submit the code" : "Stop"}
                  </button>
                </>
              ) : (
                <>
                  <Tooltip
                    placement="top"
                    title="Please select any langauge first"
                  >
                    <Button
                      type="primary"
                      className="bg-gray-300 text-white rounded-full"
                      disabled
                      onClick={() => prettify()}
                    >
                      <FormatPainterOutlined style={{ marginBottom: "10px" }} />
                      Beautify
                    </Button>
                  </Tooltip>
                  <Tooltip
                    placement="top"
                    title="Please select any langauge first"
                  >
                    <Button
                      type="primary"
                      className="bg-gray-300 text-white rounded-full"
                      disabled
                    >
                      <PlayCircleOutlined style={{ marginBottom: "10px" }} />{" "}
                      Run
                    </Button>
                  </Tooltip>
                  <Tooltip
                    placement="top"
                    title="Please select any langauge first"
                  >
                    <Button
                      type="primary"
                      className="bg-gray-300 text-white rounded-full"
                      disabled
                    >
                      <PlayCircleOutlined style={{ marginBottom: "10px" }} />{" "}
                      Submit the code
                    </Button>
                  </Tooltip>
                </>
              )}
              {/* <Button type="primary" id="start-togetherjs">
            <UsergroupAddOutlined style={{ marginBottom: "10px" }} />
            Start TogetherJS
          </Button> */}
            </Space>
          </div>
        </div>
      </div>
      <section className="mt-4 grid grid-cols-1 space-x-8 cursor-col-resize">
        <Split
          sizes={[50, 50]}
          direction="horizontal"
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "row",
          }}
        >
          <div className=" bg-white sticky top-20  rounded-xl h-[660px] overflow-y-scroll w-1/2">
            <span className="text-black font-primary font-semibold mb-2 text-xl">
              Question.
            </span>
            <div
              className="font-primary cursor-col-resize w-full "
              dangerouslySetInnerHTML={{ __html: props.question }}
            ></div>
          </div>

          <div className=" bg-white  border-l border-gray-200  w-1/2">
            <AceEditor
              value={code}
              //   setValue={props.value}
              mode={mode}
              theme={theme}
              //   language={props.language || "javascript"}
              onChange={(value) => setCode(value)}
              name="Ide_Editor"
              // defaultValue="//some comment and write the code or paste your code here ..// "
              editorProps={{ $blockScrolling: true }}
              fontSize="16px"
              width="100%"
              height="645px"
              showGutter={true}
            />
            <div className="p-4 bottom border bg-white h-[400px]   overflow-y-scroll ">
              <p className="font-medium font-primary text-xl ">Output</p>
              <p className="font-medium font-primary text-xm ">
                {response && status && type1
                  ? countPassCases +
                    "/" +
                    (countPassCases + countFailCases) +
                    " test cases passed"
                  : ""}
              </p>
              {response ? (
                status ? (
                  test.length == 1 && allTestResponse.length == 0 ? (
                    test?.map((data, i) => {
                      return data.status === 1 ? (
                        <div
                          key={i}
                          className="grid grid-cols-2 mt-2 bg-[#FBFEFF] shadow rounded-lg p-5 items-start"
                        >
                          <div
                            key={i}
                            className="text-green-500 font-primary font-medium flex flex-row text-sm  items-center capitalize"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke="currentColor"
                              className="w-6 h-6 text-green-500 mr-1"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                              />
                            </svg>
                            {i + 1} {data.message}
                          </div>
                          <div className="space-y-4">
                            <div key={i}>
                              <h1 className="font-primary text-base">Input</h1>
                              <div className="bg-gray-100 p-1 font-primary text-lg font-normal text-black">
                                {" "}
                                {data.input}
                              </div>
                            </div>
                            <div key={i}>
                              <h1 className="font-primary text-base">
                                Actual Output
                              </h1>
                              <div className="bg-gray-100 p-1 font-primary text-lg font-normal text-black">
                                {" "}
                                {data.output}
                              </div>
                            </div>
                            <div key={i}>
                              <h1 className="font-primary text-base">
                                Expected Output
                              </h1>{" "}
                              <div className="bg-gray-100 p-1 font-primary text-lg font-normal text-black">
                                {" "}
                                {data.expectedOutput}
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div
                          key={i}
                          className="grid grid-cols-2 mt-8 bg-[#FBFEFF] shadow rounded-lg p-5  items-start"
                        >
                          <div
                            key={i}
                            className="text-red-700 font-primary font-medium flex flex-row text-lg  items-center capitalize"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="currentColor"
                              className="h-8 w-8 text-red-700 mr-1"
                              viewBox="0 0 256 256"
                            >
                              <path d="M236.8,188.09,149.35,36.22h0a24.76,24.76,0,0,0-42.7,0L19.2,188.09a23.51,23.51,0,0,0,0,23.72A24.35,24.35,0,0,0,40.55,224h174.9a24.35,24.35,0,0,0,21.33-12.19A23.51,23.51,0,0,0,236.8,188.09ZM222.93,203.8a8.5,8.5,0,0,1-7.48,4.2H40.55a8.5,8.5,0,0,1-7.48-4.2,7.59,7.59,0,0,1,0-7.72L120.52,44.21a8.75,8.75,0,0,1,15,0l87.45,151.87A7.59,7.59,0,0,1,222.93,203.8ZM120,144V104a8,8,0,0,1,16,0v40a8,8,0,0,1-16,0Zm20,36a12,12,0,1,1-12-12A12,12,0,0,1,140,180Z"></path>
                            </svg>
                            {i + 1} {data.message}
                          </div>
                          <div className="space-y-4">
                            <div key={i}>
                              <h1 className="font-primary text-base">Input</h1>
                              <div className="bg-gray-100 p-1 font-primary text-lg font-normal text-black">
                                {" "}
                                {data.input}
                              </div>
                            </div>

                            <div key={i}>
                              <h1 className="font-primary text-base">
                                Actual Output
                              </h1>
                              <div className="bg-gray-100 p-1 font-primary text-lg font-normal text-black">
                                {" "}
                                {data.output}
                              </div>
                            </div>

                            <div key={i}>
                              <h1 className="font-primary text-base">
                                Expected Output
                              </h1>{" "}
                              <div className="bg-gray-100 p-1 font-primary text-lg font-normal text-black">
                                {" "}
                                {data.expectedOutput}
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <div>
                      <Tabs
                        className="question-output-tabs"
                        tabPosition="left"
                        items={allTestResponse.map((item, i) => {
                          const id = String(i + 1);
                          return item.status === 1
                            ? {
                                label: (
                                  <div
                                    className=" bg-[#FBFEFF] shadow rounded-lg p-2 items-start"
                                    key={id}
                                  >
                                    <div
                                      key={i}
                                      className="text-green-500 font-primary font-medium flex flex-row text-sm  items-center capitalize"
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        // strokeWidth={1.5}
                                        stroke="currentColor"
                                        className="w-4 h-4 text-green-500 mr-1"
                                      >
                                        <path
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                                        />
                                      </svg>
                                      #{id} {item.message}
                                      <div className="pt-1 pl-2">
                                        {i >= 2 ? (
                                          <>
                                            <LockOutlined />{" "}
                                          </>
                                        ) : (
                                          <> </>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                ),
                                key: i,
                                // disabled: i >= 2 ? true : false,
                                children:
                                  i >= 2 ? (
                                    <div className="space-y-4">
                                      <div key={i} className=" h-[400px]">
                                        <h1 className="bg-gray-100 p-1  font-primary text-base capitalize">
                                          {item.message}
                                        </h1>
                                        {/* <hr className="text-white"/> */}
                                        <div className="relative bg-gray-100 h-[400px]">
                                          <div className="absolute flex flex-col justify-center items-center w-full top-[25%]">
                                            <p className="font-primary text-lg font-normal text-black w-fit m-auto top-[35%]">
                                              {" "}
                                              Hidden Test Case
                                            </p>
                                            {/* <div className="w-full"> */}
                                            <LockOutlined
                                              className="mx-auto mt-[6px]"
                                              style={{
                                                color: "#000",
                                                fontSize: "40px",
                                                marginTop: "6px",
                                              }}
                                            />
                                            {/* </div> */}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  ) : (
                                    <div className="space-y-4" key={i}>
                                      <div key={i}>
                                        <h1 className="font-primary text-base">
                                          Input
                                        </h1>
                                        <div className="bg-gray-100 p-1 font-primary text-lg font-normal text-black">
                                          {" "}
                                          {item.input}
                                        </div>
                                      </div>

                                      <div key={i}>
                                        <h1 className="font-primary text-base">
                                          Actual Output
                                        </h1>
                                        <div className="bg-gray-100 p-1 font-primary text-lg font-normal text-black">
                                          {" "}
                                          {item.output}
                                        </div>
                                      </div>

                                      <div key={i}>
                                        <h1 className="font-primary text-base">
                                          Expected Output
                                        </h1>{" "}
                                        <div className="bg-gray-100 p-1 font-primary text-lg font-normal text-black">
                                          {" "}
                                          {item.expectedOutput}
                                        </div>
                                      </div>
                                    </div>
                                  ),
                              }
                            : {
                                label: (
                                  <div
                                    className=" bg-[#FBFEFF] shadow rounded-lg p-2  items-start"
                                    key={i}
                                  >
                                    <div
                                      key={i}
                                      className="text-red-600 font-primary font-medium flex flex-row text-sm  items-center capitalize"
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="currentColor"
                                        className="h-4 w-4 text-red-600 mr-1"
                                        viewBox="0 0 256 256"
                                      >
                                        <path d="M236.8,188.09,149.35,36.22h0a24.76,24.76,0,0,0-42.7,0L19.2,188.09a23.51,23.51,0,0,0,0,23.72A24.35,24.35,0,0,0,40.55,224h174.9a24.35,24.35,0,0,0,21.33-12.19A23.51,23.51,0,0,0,236.8,188.09ZM222.93,203.8a8.5,8.5,0,0,1-7.48,4.2H40.55a8.5,8.5,0,0,1-7.48-4.2,7.59,7.59,0,0,1,0-7.72L120.52,44.21a8.75,8.75,0,0,1,15,0l87.45,151.87A7.59,7.59,0,0,1,222.93,203.8ZM120,144V104a8,8,0,0,1,16,0v40a8,8,0,0,1-16,0Zm20,36a12,12,0,1,1-12-12A12,12,0,0,1,140,180Z"></path>
                                      </svg>
                                      #{id}
                                      {item.message}
                                      <div className="pt-1 pl-2">
                                        {i >= 2 ? (
                                          <>
                                            {" "}
                                            <LockOutlined />{" "}
                                          </>
                                        ) : (
                                          <> </>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                ),
                                key: i,
                                // disabled: i >= 2 ? true : false,
                                children:
                                  i >= 2 ? (
                                    <div className="space-y-4">
                                      <div key={i} className=" h-[400px]">
                                        <h1 className="bg-gray-100 p-1  font-primary text-base capitalize">
                                          {item.message}
                                        </h1>
                                        {/* <hr className="text-white"/> */}
                                        <div className="relative bg-gray-100 h-[400px]">
                                          <div className="absolute flex flex-col justify-center items-center w-full top-[25%]">
                                            <p className="font-primary text-lg font-normal text-black w-fit m-auto top-[35%]">
                                              {" "}
                                              Hidden Test Case
                                            </p>
                                            {/* <div className="w-full"> */}
                                            <LockOutlined
                                              className="mx-auto mt-[6px]"
                                              style={{
                                                color: "#000",
                                                fontSize: "40px",
                                                marginTop: "6px",
                                              }}
                                            />
                                            {/* </div> */}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  ) : (
                                    <div className="space-y-4">
                                      <div key={i}>
                                        <h1 className="font-primary text-base">
                                          Input
                                        </h1>
                                        <div className="bg-gray-100 p-1 font-primary text-lg font-normal text-black">
                                          {" "}
                                          {item.input}
                                        </div>
                                      </div>

                                      <div key={i}>
                                        <h1 className="font-primary text-base">
                                          Actual Output
                                        </h1>
                                        <div className="bg-gray-100 p-1 font-primary text-lg font-normal text-black">
                                          {" "}
                                          {item.output}
                                        </div>
                                      </div>

                                      <div key={i}>
                                        <h1 className="font-primary text-base">
                                          Expected Output
                                        </h1>{" "}
                                        <div className="bg-gray-100 p-1 font-primary text-lg font-normal text-black">
                                          {" "}
                                          {item.expectedOutput}
                                        </div>
                                      </div>
                                    </div>
                                  ),
                              };
                        })}
                      />
                      {/* {allTestResponse.map((item, i) => {
                        return item.status === 1 ? (
                          <div className=" mt-5 bg-[#FBFEFF] shadow rounded-lg p-1 items-start">
                            <div
                              key={i}
                              className="text-green-500 font-primary font-medium flex flex-row text-sm  items-center capitalize"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                // strokeWidth={1.5}
                                stroke="currentColor"
                                className="w-4 h-4 text-green-500 mr-1"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                                />
                              </svg>
                              {i + 1} {item.message} <LockOutlined />
                            </div>
                          </div>
                        ) : (
                          <div className=" mt-5 bg-[#FBFEFF] shadow rounded-lg p-1  items-start">
                            <div
                              key={i}
                              className="text-red-700 font-primary font-medium flex flex-row text-sm  items-center capitalize"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="currentColor"
                                className="h-4 w-4 text-red-700 mr-1"
                                viewBox="0 0 256 256"
                              >
                                <path d="M236.8,188.09,149.35,36.22h0a24.76,24.76,0,0,0-42.7,0L19.2,188.09a23.51,23.51,0,0,0,0,23.72A24.35,24.35,0,0,0,40.55,224h174.9a24.35,24.35,0,0,0,21.33-12.19A23.51,23.51,0,0,0,236.8,188.09ZM222.93,203.8a8.5,8.5,0,0,1-7.48,4.2H40.55a8.5,8.5,0,0,1-7.48-4.2,7.59,7.59,0,0,1,0-7.72L120.52,44.21a8.75,8.75,0,0,1,15,0l87.45,151.87A7.59,7.59,0,0,1,222.93,203.8ZM120,144V104a8,8,0,0,1,16,0v40a8,8,0,0,1-16,0Zm20,36a12,12,0,1,1-12-12A12,12,0,0,1,140,180Z"></path>
                              </svg>
                              {i + 1} {item.message}
                              <LockOutlined />
                            </div>
                          </div>
                        );
                      })} */}
                    </div>
                  )
                ) : error ? (
                  <>
                    <div className="text-red-600">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        className="h-8 w-8 text-red-600 mr-1"
                        viewBox="0 0 256 256"
                      >
                        <path d="M236.8,188.09,149.35,36.22h0a24.76,24.76,0,0,0-42.7,0L19.2,188.09a23.51,23.51,0,0,0,0,23.72A24.35,24.35,0,0,0,40.55,224h174.9a24.35,24.35,0,0,0,21.33-12.19A23.51,23.51,0,0,0,236.8,188.09ZM222.93,203.8a8.5,8.5,0,0,1-7.48,4.2H40.55a8.5,8.5,0,0,1-7.48-4.2,7.59,7.59,0,0,1,0-7.72L120.52,44.21a8.75,8.75,0,0,1,15,0l87.45,151.87A7.59,7.59,0,0,1,222.93,203.8ZM120,144V104a8,8,0,0,1,16,0v40a8,8,0,0,1-16,0Zm20,36a12,12,0,1,1-12-12A12,12,0,0,1,140,180Z"></path>
                      </svg>
                      <div>
                        {timeOutError} {nextTime}{" "}
                      </div>
                    </div>
                  </>
                ) : (
                  <div>
                    <div className="text-red-600"> {output} </div>
                  </div>
                )
              ) : (
                <Spin tip="Compiling" size="large">
                  <div className="content" />
                </Spin>
              )}
            </div>
          </div>
        </Split>
      </section>
    </>
  );
};
