import React, { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { UserOutlined } from "@ant-design/icons";
// import { DashboardFooter } from "../../footer/AuthenticateFooter";
import { Link, Outlet } from "react-router-dom";
import { Landing } from "../../../components/pages/landing/landing";
interface User {
  email: string;
  nickname: string;
  loginWithRedirect: any;
  user: string;
}
export default function EventsPages() {
  const { user, loginWithRedirect, isAuthenticated } = useAuth0<User>();
  const [open, setOpen] = useState<boolean>(false);

  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  return (
    <>
      <Landing />
    </>
  );
}
