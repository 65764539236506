import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { DomainCard } from "../../../components/card/DomainCard";
import { getUsers } from "../../../serviceApi/api";
import { SearchFilterInput } from "../../../components/search/SearchInput";
import { getDomains } from "../../../serviceApi/superAdminApi/DomainApi";
import { CardSkeletonLoader } from "../../../components/skeleton/CardSkeleton";
import PaginationData from "../../../components/pagination/Pagination";

interface DomainCardType {
  type?: string | undefined;
  uuid?: any;
  name: string;
  role?: string | undefined;
  status?: string | undefined;
  title?: string | undefined;
  description?: string | undefined;
  image?: string | undefined;
  route?: string | undefined;
  message?: string | undefined;
  editRoute?: string;
  height?: string;
  domainImage: string;
}

export const Dashboard = () => {
  const [domain, setDomain] = useState([]);
  const [role, setRole] = useState("");
  const [active, setActive] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [deleteRes, setDeleteRes] = useState<boolean>();
  const [paginatedData, setPaginatedData] = useState([]);
  const [getSearch, setGetSearch] = useState(true);
  const [input, setInput] = useState("");
  const [isSearch, setIsSearch] = useState(false);
  const { user } = useAuth0();
  const userId: string = user?.email as string;
  const getDomainsList = async (userId: string) => {
    const domains = await getDomains(userId);
    setTimeout(() => {
      if (domains?.data?.domains?.length > 0) {
        setDeleteRes(false);
        setIsLoading(false);
        setDomain(domains?.data?.domains);
      } else {
        setDeleteRes(false);
        setIsLoading(false);
        setActive(false);
      }
    }, 1000);
  };

  const validateUserRole = async (userId: string) => {
    if ((userId && userId != "") || userId != null) {
      const userRole = await getUsers(userId);
      if (userRole?.data?.user) {
        setRole(userRole?.data?.user[0]?.role);
      } else setRole("new user");
    }
  };
  const getSearchContent = async (data: any, getClick) => {
    if (data?.length > 0 && typeof data != "string") {
      if (getClick) {
        setGetSearch(true);
        setPaginatedData(data);
        setIsSearch(true);
        setActive(true);
        setDomain(data);
      } else {
        setGetSearch(true);
        setPaginatedData(data);
        setActive(true);
        setIsSearch(!isSearch);
        setDomain(data);
      }
    } else if (data === "value not filled" && typeof data === "string") {
      getDomainsList(userId);
      setPaginatedData([]);
      setGetSearch(true);
      setActive(true);
      setIsSearch(false);
      setInput("");
      setDomain(paginatedData);
    } else if (data === "not found data" && typeof data === "string") {
      setActive(false);
      setPaginatedData([]);
      setGetSearch(false);
      setIsSearch(!isSearch);
      getDomainsList(userId);
    }
  };

  useEffect(() => {
    if (userId) {
      getDomainsList(userId);
    }
    validateUserRole(userId);
  }, [userId, deleteRes]);

  return (
    <>
      <section className="max-h-[86vh] mt-2 overflow-y-auto w-full lg:p-10 p-5">
        <p className="text-2xl mb-0 pb-0 font-primary font-medium text-gray-800">
          All Domains list
        </p>
        <p className="text-sm mt-0 pt-0 font-primary font-thin text-gray-400">
          All Domains list
        </p>
        <div className="flex flex-row justify-end mb-5 ml-auto  w-full">
          <div className="lg:w-64">
            {domain?.length > 0 && (
              <SearchFilterInput
                SearchContent={getSearchContent}
                userId={userId}
                type="Domain"
                domainId=""
                name={setInput}
              />
            )}
          </div>
        </div>
        <div className="grid  border-t py-10 lg:grid-cols-4 grid-cols-1 lg:gap-x-12 gap-x-5 lg:gap-y-12 gap-y-5">
          {isLoading ? (
            <>
              <CardSkeletonLoader />
              <CardSkeletonLoader />
              <CardSkeletonLoader />
              <CardSkeletonLoader />
              <CardSkeletonLoader />
              <CardSkeletonLoader />
              <CardSkeletonLoader />
              <CardSkeletonLoader />
            </>
          ) : active ? (
            getSearch &&
            paginatedData?.map((list: DomainCardType, i) => {
              return (
                <div className="h-full" key={i}>
                  <DomainCard
                    key={i}
                    image={list?.image ? list?.image : ""}
                    title={list.name}
                    description={list.description}
                    role={role}
                    uuid={list.uuid}
                    CardHeight="h-44"
                    type="domain"
                    editRoute={`/home/dashboard/domain/${list.uuid}/edit`}
                    route={`/home/dashboard/domain/${list.uuid}/category/create`}
                    message={list.message}
                    deleteRes={setDeleteRes}
                  />
                </div>
              );
            })
          ) : (
            <div className="h-full">Domains Not Found</div>
          )}
        </div>
        {paginatedData && getSearch && (
          <div
            className={`${
              domain?.length >= 8
                ? paginatedData?.length >= 5
                  ? "mb-2"
                  : "absolute left-0 right-0 bottom-20 mb-2"
                : "mx-auto hidden"
            }`}
          >
            <PaginationData
              data={domain}
              paginatedData={setPaginatedData}
              userId={userId}
              getSearchActive={isSearch}
              type="Domain"
              domainId=""
              name={input}
            />{" "}
          </div>
        )}
      </section>
    </>
  );
};
