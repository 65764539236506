// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Open+Sans&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.htmlcontainer{
	background: #E7E7E7;
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 60vh;
	margin: 3px;

}

.htmlcontainer textarea {
	background-color:  #121212;
	border:1px solid #0dddf0;
	resize: none;
	width: 100%;
	height: 20vh;
	font-size: 1.4rem;
	padding: 10px;
	resize: vertical;
	overflow-y: scroll;
	color:white;
}
.htmlcontainer textarea:focus {
	outline: none;
	color:white;
}
.iframe-container{
	background: white;
	width: 50%;
	height: 100vh;
	margin: 3px;
}
#viewer{
	width: 100%;
	height: 30vh;
	border: black 1px solid;
}
.split {
  width:100%;
  height:100%;
}
.gutter {
  cursor: e-resize;
  height: 100%;
  background: grey;
}
.gutter.gutter-horizontal {
    cursor: ew-resize;
}



#btn{
	font-size: 1.4rem;
	width: 100px;
	height: 50px;
	/* make button center  */
	margin: 0 auto;	
	/* padding: 10px; */
	border: none;
	margin-left: 4px;
}`, "",{"version":3,"sources":["webpack://./src/views/authenticateViews/students/assesments/questions/Editor/HtmlEditor.css"],"names":[],"mappings":"AAEA;CACC,mBAAmB;CACnB,aAAa;CACb,sBAAsB;CACtB,WAAW;CACX,YAAY;CACZ,WAAW;;AAEZ;;AAEA;CACC,0BAA0B;CAC1B,wBAAwB;CACxB,YAAY;CACZ,WAAW;CACX,YAAY;CACZ,iBAAiB;CACjB,aAAa;CACb,gBAAgB;CAChB,kBAAkB;CAClB,WAAW;AACZ;AACA;CACC,aAAa;CACb,WAAW;AACZ;AACA;CACC,iBAAiB;CACjB,UAAU;CACV,aAAa;CACb,WAAW;AACZ;AACA;CACC,WAAW;CACX,YAAY;CACZ,uBAAuB;AACxB;AACA;EACE,UAAU;EACV,WAAW;AACb;AACA;EACE,gBAAgB;EAChB,YAAY;EACZ,gBAAgB;AAClB;AACA;IACI,iBAAiB;AACrB;;;;AAIA;CACC,iBAAiB;CACjB,YAAY;CACZ,YAAY;CACZ,wBAAwB;CACxB,cAAc;CACd,mBAAmB;CACnB,YAAY;CACZ,gBAAgB;AACjB","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');\n\n.htmlcontainer{\n\tbackground: #E7E7E7;\n\tdisplay: flex;\n\tflex-direction: column;\n\twidth: 100%;\n\theight: 60vh;\n\tmargin: 3px;\n\n}\n\n.htmlcontainer textarea {\n\tbackground-color:  #121212;\n\tborder:1px solid #0dddf0;\n\tresize: none;\n\twidth: 100%;\n\theight: 20vh;\n\tfont-size: 1.4rem;\n\tpadding: 10px;\n\tresize: vertical;\n\toverflow-y: scroll;\n\tcolor:white;\n}\n.htmlcontainer textarea:focus {\n\toutline: none;\n\tcolor:white;\n}\n.iframe-container{\n\tbackground: white;\n\twidth: 50%;\n\theight: 100vh;\n\tmargin: 3px;\n}\n#viewer{\n\twidth: 100%;\n\theight: 30vh;\n\tborder: black 1px solid;\n}\n.split {\n  width:100%;\n  height:100%;\n}\n.gutter {\n  cursor: e-resize;\n  height: 100%;\n  background: grey;\n}\n.gutter.gutter-horizontal {\n    cursor: ew-resize;\n}\n\n\n\n#btn{\n\tfont-size: 1.4rem;\n\twidth: 100px;\n\theight: 50px;\n\t/* make button center  */\n\tmargin: 0 auto;\t\n\t/* padding: 10px; */\n\tborder: none;\n\tmargin-left: 4px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
