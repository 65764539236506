import { Form, Popconfirm, Select, Spin } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
import React, { useEffect, useRef, useState } from "react";
import { InputField } from "../../../../../components/inputFields/InputField";
import { PrimaryButton } from "../../../../../components/buttons/PrimaryButton";
import { getDomainNameById } from "../../../../../serviceApi/superAdminApi/DomainApi";
import { useNavigate, useParams } from "react-router-dom";
import { getCategoryNameById } from "../../../../../serviceApi/superAdminApi/CategoryApi";
import {
  addAnswersList,
  deleteQuestion,
  getAssessmentNameById,
  getOptions,
  getOptionsByUuid,
  getQuestionDetails,
  getSkillNameBySkillId,
  questionAnswerCreate,
} from "../../../../../serviceApi/api";
import Swal from "sweetalert2";
import axios from "axios";
import { getCookie } from "../../../../../utils";
import { QuestionEditor } from "./QuestionEditor";
import { nameInputValid } from "../../../../../utils/validation";
import { BreadcrumbComp } from "../../../../../components/Breadcrumb/Breadcrumb";
import xlsx from "sheetjs-style";
import * as FileSaver from "file-saver";
import { useAuth0 } from "@auth0/auth0-react";
import {
  getSkills,
  getUserValidateSkill,
} from "../../../../../serviceApi/adminApi/SkillApi";
import SelectField from "../../../../../components/selectField/SelectField";
import { Editor } from "@tinymce/tinymce-react";
import QuillEditor from "react-quill";
import "react-quill/dist/quill.snow.css";
export const QuestionsEdit = () => {
  const { user } = useAuth0();
  const userId: string = user?.email as string;
  const navigate = useNavigate();
  const {
    domainId,
    categoryId,
    skillId,
    assessmentId,
    questionId,
    questionType,
  }: any = useParams();

  const quillRef = useRef<any>(null);
  const [domainName, setDomainName] = React.useState();
  const [categoryName, setCategoryName] = React.useState();
  const [skillName, setSkillName] = React.useState();
  const [deleteCorrectAnswers, setDeleteCorrectAnswers] = useState<any>([]);
  const [name, setName] = useState("");
  const [weightage, setWeightage] = useState("");
  const [topic, setTopic] = useState("");
  const [question, setQuestion] = useState<any>();
  const [correctAnswers, setCorrectAnswers] = useState<any>([]);
  const [answerOption, setAnswerOption] = useState<any>([{}]);
  const [questionImage, setQuestionImage] = useState(null);
  const [questionImagePreview, setQuestionImagePreview] = useState<any>();
  const [duplicateMessage, setDuplicateMessage] = React.useState(false);
  const [showcorrectAnswer, setShowcorrectAnswer] = useState(false);
  const [questionUuid, setQuestionUuid] = useState("");
  const [content, setContent] = useState("");
  const [topicError, setTopicError] = useState("");
  const [weightageErr, setWeightageErr] = useState("");
  const [formStatus, setFormStatus] = useState(true);
  const [, setAnswerImagePreview] = useState<any>();
  const [testCase, setTestcase] = useState(null);
  const [testCaseName, setTestcaseName] = useState<any>("");
  const [codingBtnStatus, setCodingBtnStatus] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [jsonData, setJsonData] = useState<any>("");
  const [answerFormStatus, setAnswerFormStatus] = useState(true);
  const [correctAnswerUuid, setCorrectAnswerUuid] = useState<any>([]);
  const [value1, setValue1] = useState<any>([]);
  const [isUpdated, setIsUpdated] = useState(true);
  const [, setIsChecked] = useState(true);
  const [changed, setChanged] = useState(true);
  const [isAuthenticate, setIsAuthenticate] = React.useState(false);
  const [deletedTestCasesFile, setDeletedTestCasesFile] = React.useState("");
  const [questionLevel, setQuestionLevel] = useState("");
  const [selectKey, setSelectKey] = useState(0);

  const addInputField = () => {
    setAnswerOption([...answerOption, {}]);
  };
  interface DataType {
    uuid: string;
    option: string;
  }

  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ align: ["right", "center", "justify"] }],
      [{ script: "sub" }, { script: "super" }],
      [{ list: "ordered" }, { list: "bullet" }],
      ["image"],
      [{ color: ["red", "#785412"] }],
      [{ background: ["red", "#785412"] }],
    ],
  };
  const answerModules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ align: ["right", "center", "justify"] }],
      [{ script: "sub" }, { script: "super" }],
      [{ color: ["red", "#785412"] }],
      [{ background: ["red", "#785412"] }],
    ],
  };
  const formats = [
    "header",
    "bold",
    "italic",
    "symbol",
    "script",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "align",
    "color",
    "background",
    "script",
    "size",
    "color",
    "clean",
  ];
  const answerFormats = [
    "header",
    "bold",
    "italic",
    "symbol",
    "script",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "align",
    "color",
    "background",
    "script",
    "size",
    "color",
    "clean",
  ];

  const removeInputFields = async (value: DataType) => {
    setLoading(true);
    let isFind;
    if (value?.uuid) {
      for (let i = 0; i < correctAnswerUuid?.length; i++) {
        if (value?.uuid == correctAnswerUuid[i]) {
          isFind = true;
          break;
        }
      }
      if (isFind) {
        if (value1?.length > 0) {
          setDeleteCorrectAnswers([...deleteCorrectAnswers, value?.uuid]);
          setAnswerOption(
            answerOption.filter((item: string | any) => {
              if (item?.option) {
                return item?.option !== value?.option;
              } else return item !== value;
            })
          );
          setChanged(false);
          setIsUpdated(false);
          setCorrectAnswers([]);
          setCorrectAnswerUuid(
            correctAnswerUuid.filter((item: string | any) => {
              if (value?.uuid) {
                return item !== value?.uuid;
              } else return item !== value;
            })
          );
        } else {
          setDeleteCorrectAnswers([...deleteCorrectAnswers, value?.uuid]);
          setAnswerOption(
            answerOption.filter((item: DataType) => {
              if (item?.option) {
                return item?.option !== value?.option;
              } else return item !== value;
            })
          );
        }
      } else {
        if (value?.uuid) {
          setDeleteCorrectAnswers([...deleteCorrectAnswers, value?.uuid]);
          setAnswerOption(
            answerOption.filter((item: DataType) => {
              if (item?.option) {
                return item?.option !== value?.option;
              } else return item !== value;
            })
          );
        }
      }
    } else {
      setAnswerOption(
        answerOption.filter((item: DataType) => {
          if (item?.option) {
            return item?.option !== value?.option;
          } else return item !== value;
        })
      );
    }
  };
  const selectCorrectAnswer = (newValue: string) => {
    setCorrectAnswers(newValue);
    setChanged(false);
  };

  const handleEditorChange = (event, index: number, data) => {
    const list = [...answerOption];
    list[index] = { uuid: data?.uuid ? data?.uuid : "", option: event };
    setAnswerOption(list);
    setAnswerFormStatus(false);
  };

  const handleChange = (event: any, index: number, data: any) => {
    const { value } = event.target;
    const list = [...answerOption];
    list[index] = { uuid: data?.uuid ? data?.uuid : "", option: value };
    setAnswerOption(list);
    setAnswerFormStatus(false);
  };

  const getDomainName = async () => {
    const response = await getDomainNameById(domainId);
    setDomainName(response?.data.domainNameById[0].name);
  };
  const getCategoryName = async () => {
    const response = await getCategoryNameById(categoryId);
    setCategoryName(response?.data.categoryNameById[0].name);
  };
  const getSkillName = async () => {
    const response = await getSkillNameBySkillId(skillId);
    setSkillName(response?.data.skillNameById[0].name);
  };
  const getAssessmentName = async () => {
    const response = await getAssessmentNameById(assessmentId);
    setName(response?.data.assessmentNameById[0].name);
  };
  const getQuestionDetailsData = async () => {
    const response = await getQuestionDetails(
      assessmentId,
      questionId,
      questionType
    );
    if (response?.data?.getQuestionDetails?.length > 0) {
      setWeightage(response?.data?.getQuestionDetails[0]?.weightage);
      setTopic(response?.data?.getQuestionDetails[0]?.Topic);
      setQuestionLevel(response?.data?.getQuestionDetails[0]?.complexity);
      setCorrectAnswerUuid(
        JSON.parse(response?.data?.getQuestionDetails[0]?.correctAnswers)
      );
      setCorrectAnswers(
        JSON.parse(response?.data?.getQuestionDetails[0]?.correctAnswers)
      );
      setAnswerOption(
        JSON.parse(response?.data?.getQuestionDetails[0]?.answers)
      );

      setQuestionUuid(response?.data?.getQuestionDetails[0]?.uuid);
      if (questionType == "5") {
        setContent(response?.data?.getQuestionDetails[0]?.question);
      } else {
        setQuestion(response?.data?.getQuestionDetails[0]?.question);
      }

      if (response?.data?.getQuestionDetails?.length > 0) {
        const result = await getOptions(questionId);
        // setAnswerOption(result?.data?.quesionsOptions);
      }
      setIsUpdated(true);
    } else {
      navigate("/404");
    }
  };

  const getQuestionDetailsDataFetched = async () => {
    const response = await getQuestionDetails(
      assessmentId,
      questionId,
      questionType
    );
    if (response?.data?.getQuestionDetails?.length > 0) {
      setWeightage(response?.data?.getQuestionDetails[0]?.weightage);
      setTopic(response?.data?.getQuestionDetails[0]?.Topic);
      setQuestionLevel(response?.data?.getQuestionDetails[0]?.complexity);
      setCorrectAnswerUuid(
        JSON.parse(response?.data?.getQuestionDetails[0]?.correctAnswers)
      );
      setCorrectAnswers(
        JSON.parse(response?.data?.getQuestionDetails[0]?.correctAnswers)
      );
      setQuestionUuid(response?.data?.getQuestionDetails[0]?.uuid);
      if (questionType == "5") {
        setContent(response?.data?.getQuestionDetails[0]?.question);
      } else {
        setQuestion(response?.data?.getQuestionDetails[0]?.question);
      }
      getOptionDetailsByUuid();
      setIsUpdated(true);
    } else {
      navigate("/404");
    }
  };
  const selectCorrectAnswers = (e) => {
    const { checked, value } = e.target;
    setIsChecked(false);
    setChanged(false);
    if (checked) {
      setCorrectAnswers([...correctAnswers, value]);
    } else if (!checked) {
      setCorrectAnswers(correctAnswers.filter((ans: string) => ans !== value));
    }
  };

  // this is for edit mcq question start
  const onFinish = async () => {
    setIsLoading(true);
    for (let i = 0; i < deleteCorrectAnswers?.length; i++) {
      await deleteQuestion(deleteCorrectAnswers[i], "option");
    }
    if (questionType == "5") {
      if (deleteCorrectAnswers?.length > 0) {
        const formData = new FormData();
        const assessmentNewId: any = assessmentId;
        formData.append(
          "image",
          `coding/${deleteCorrectAnswers[0]}/${deletedTestCasesFile}`
        );
        formData.append("questionType", "Media");
        formData.append("assessmentId", assessmentNewId);
        formData.append("uuid", deleteCorrectAnswers[0]);
        formData.append("type", "coding");
        const { data }: any = await axios.post(
          `${process.env.REACT_APP_MEDIA_URL}/api/media/delete`,
          formData,
          {
            headers: {
              enctype: "multipart/form-data",
              Authorization: `Bearer ${getCookie("token")}`,
            },
          }
        );
        if (data?.status == true) {
          await deleteQuestion(deleteCorrectAnswers[0], "coding");
        }
      }
    }
    const list = [...answerOption];
    for (const i in answerOption) {
      list[i] = {
        uuid: answerOption[i].uuid,
        option: answerOption[i].option,
      };
    }
    setAnswerOption(list);
    const response = await questionAnswerCreate({
      assessmentId,
      question: questionType == "5" ? content : question,
      weightage,
      answers: questionType == "5" ? [] : list,
      CorrectAnswer:
        questionType == "5"
          ? ["true"]
          : changed
          ? correctAnswerUuid
          : correctAnswers,
      topic: topic,
      questionId,
      questionType: questionType == "5" ? "5" : "1",
      action: "edit",
      questionLevel,
    });
    if (testCase && questionType == "5") {
      const formData = new FormData();
      formData.append("image", testCase);
      formData.append("uuid", questionUuid);
      formData.append("topic", topic);
      formData.append("type", "coding");
      const { data }: any = await axios.post(
        `${process.env.REACT_APP_MEDIA_URL}/api/media/upload`,
        formData,
        {
          headers: {
            enctype: "multipart/form-data",
            Authorization: `Bearer ${getCookie("token")}`,
          },
        }
      );
      if (data?.status == true) {
        await questionAnswerCreate({
          assessmentId,
          question: questionType == "5" ? content : question,
          weightage,
          answers: questionType == "5" ? [] : answerOption,
          CorrectAnswer: questionType == "5" ? ["true"] : correctAnswers,
          topic: topic,
          questionId,
          questionType: questionType == "5" ? "5" : "1",
          action: "newEdit",
          questionLevel,
        });
        setIsLoading(false);
        setFormStatus(false);
      } else {
        setIsLoading(false);
        Swal.fire({
          icon: "warning",
          title: data?.error + " Or Empty File data ",
          showConfirmButton: false,
          timer: 3000,
        });
        await deleteTestcase();
      }
    } else if (questionType == "5") {
      await questionAnswerCreate({
        assessmentId,
        question: questionType == "5" ? content : question,
        weightage,
        answers: questionType == "5" ? [] : answerOption,
        CorrectAnswer: questionType == "5" ? ["true"] : correctAnswers,
        topic: topic,
        questionId,
        questionType: questionType == "5" ? "5" : "1",
        action: "newEdit",
        questionLevel,
      });
      navigate(
        `/subadmin/domain/skill/${skillId}/assessment/${assessmentId}/questions/list`
      );
    }
    navigate(
      `/subadmin/domain/skill/${skillId}/assessment/${assessmentId}/questions/list`
    );
  };

  const exportToExcel = () => {
    const ws: any = xlsx?.utils?.json_to_sheet(JSON.parse(jsonData));
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = xlsx?.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    FileSaver.saveAs(data, testCaseName + ".xlsx");
  };

  // this is for edit Media question start
  const onFinishMedia = async () => {
    setIsLoading(true);
    setFormStatus(true);
    if (questionType == "3") {
      for (let i = 0; i < deleteCorrectAnswers?.length; i++) {
        await deleteQuestion(deleteCorrectAnswers[i], "option");
      }
      const map = {};
      let result = false;
      const answeroptionArr: any = [];
      for (let i = 0; i < answerOption?.length; i++) {
        answeroptionArr.push(
          answerOption[i]?.option ? answerOption[i]?.option : answerOption[i]
        );
        const value = answeroptionArr[i]?.toLowerCase();
        // check if object contains entry with this element as key
        if (map[value]) {
          result = true;
          // terminate the loop
          break;
        }
        // add entry in object with the element as key
        map[value] = true;
      }
      const list = [...answerOption];
      for (const i in answerOption) {
        list[i] = {
          uuid: answerOption[i].uuid,
          option: answerOption[i].option,
        };
      }
      if (result) {
        setDuplicateMessage(true);
      } else {
        setDuplicateMessage(false);
        const response = await questionAnswerCreate({
          assessmentId,
          question,
          weightage,
          answers: list,
          CorrectAnswer: changed ? correctAnswerUuid : correctAnswers,
          topic: topic,
          questionId,
          questionType: "3",
          action: "edit",
          questionLevel,
        });
        if (response?.data.createQuestionAnswer) {
          if (questionImage) {
            const formData = new FormData();
            const assessmentNewId: string = assessmentId;
            formData.append("image", questionImage);
            formData.append("questionType", "Media");
            formData.append("assessmentId", assessmentNewId);
            formData.append("uuid", questionUuid);
            formData.append("type", "question");
            const { data }: any = await axios.post(
              `${process.env.REACT_APP_MEDIA_URL}/api/media/upload`,
              formData,
              {
                headers: {
                  enctype: "multipart/form-data",
                  Authorization: `Bearer ${getCookie("token")}`,
                },
              }
            );
            if (data?.status == true) {
              const result = await getOptions(questionId);
              setAnswerOption(result?.data?.quesionsOptions);
              setShowcorrectAnswer(true);
              if (response?.data.createQuestionAnswer?.id == null) {
                navigate(
                  `/subadmin/domain/skill/${skillId}/assessment/${assessmentId}/questions/list`
                );
              }
            } else {
              navigate(
                `/subadmin/domain/skill/${skillId}/assessment/${assessmentId}/questions/list`
              );
            }
          } else {
            const result = await getOptions(questionId);
            setAnswerOption(result?.data?.quesionsOptions);
            setShowcorrectAnswer(true);
            if (response?.data.createQuestionAnswer?.id == null) {
              navigate(
                `/subadmin/domain/skill/${skillId}/assessment/${assessmentId}/questions/list`
              );
            }
          }
        }
      }
    } else {
      if (deleteCorrectAnswers?.length > 0) {
        for (let i = 0; i < deleteCorrectAnswers?.length; i++) {
          await deleteQuestion(deleteCorrectAnswers[i], "option");
        }
      }

      const answeroptionArr: any = [];
      for (let i = 0; i < answerOption?.length; i++) {
        answeroptionArr.push(
          answerOption[i]?.option
            ? answerOption[i]?.option
            : answerOption[i].option
        );
      }
      const list = [...answerOption];
      for (const i in answerOption) {
        list[i] = {
          uuid: answerOption[i].uuid,
          option: answerOption[i].option,
        };
      }
      const response = await questionAnswerCreate({
        assessmentId,
        question,
        weightage,
        CorrectAnswer: changed ? correctAnswerUuid : correctAnswers,
        topic: topic,
        questionId,
        answers: list,
        questionType: questionType == "2" ? "2" : "4",
        action: "edit",
        questionLevel,
      });
      if (response?.data.createQuestionAnswer) {
        if (questionImage) {
          const formData = new FormData();
          const assessmentNewId: string = assessmentId;
          formData.append("image", questionImage);
          formData.append("questionType", "Media");
          formData.append("assessmentId", assessmentNewId);
          formData.append("uuid", questionUuid);
          formData.append("type", "question");
          const { data }: any = await axios.post(
            `${process.env.REACT_APP_MEDIA_URL}/api/media/upload`,
            formData,
            {
              headers: {
                enctype: "multipart/form-data",
                Authorization: `Bearer ${getCookie("token")}`,
              },
            }
          );
          if (data?.status == true) {
            const result = await getOptions(questionId);
            setAnswerOption(result?.data?.quesionsOptions);
            setShowcorrectAnswer(true);
            setQuestion(result?.data?.question);
            if (response?.data.createQuestionAnswer?.id == null) {
              navigate(
                `/subadmin/domain/skill/${skillId}/assessment/${assessmentId}/questions/list`
              );
            }
          } else {
            navigate(
              `/subadmin/domain/skill/${skillId}/assessment/${assessmentId}/questions/list`
            );
          }
        } else {
          const result = await getOptions(questionId);
          setAnswerOption(result?.data?.quesionsOptions);
          setShowcorrectAnswer(true);
          if (response?.data.createQuestionAnswer?.id == null) {
            navigate(
              `/subadmin/domain/skill/${skillId}/assessment/${assessmentId}/questions/list`
            );
          }
        }
      } else {
        navigate(
          `/subadmin/domain//skill/${skillId}/assessment/${assessmentId}/questions/list`
        );
      }
    }
  };

  //For Coding
  const deleteTestcase = async () => {
    setTestcase(null);
    setDeleteCorrectAnswers([...deleteCorrectAnswers, questionUuid]);
    setJsonData([]);
    setTestcaseName("");
    setDeletedTestCasesFile(testCaseName);
  };

  const handleData = async (e: any) => {
    e.preventDefault();
    const testcaseFile = e.target.files[0];
    if (
      testcaseFile?.type ==
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
      testcaseFile?.type == "application/wps-office.xlsx"
    ) {
      setTestcase(testcaseFile);
      setTestcaseName(testcaseFile?.name);
    } else {
      Swal.fire({
        icon: "warning",
        title: "file type should be xlsx",
        showConfirmButton: false,
        timer: 3000,
      });
    }
  };
  const deleteQuestionImage = () => {
    setQuestionImagePreview(URL.revokeObjectURL(questionImagePreview));
    setQuestionImage(null);
  };

  const handleQuestionImage = async (e: any) => {
    e.preventDefault();
    const selectedFiles = e.target.files[0];
    if (selectedFiles.size >= 2097152) {
      Swal.fire({
        icon: "warning",
        title: "Image size should be max 2MB.",
        showConfirmButton: false,
        timer: 3000,
      });
    } else if (
      selectedFiles.type === "image/png" ||
      selectedFiles.type === "image/jpg" ||
      selectedFiles.type === "image/jpeg" ||
      selectedFiles.type === "image/webp" ||
      selectedFiles.type === "image/jfif" ||
      selectedFiles.type === "image/svg"
    ) {
      setQuestionImage(selectedFiles);
      setQuestionImagePreview(URL.createObjectURL(selectedFiles));
    } else {
      Swal.fire({
        icon: "warning",
        title: "Image formate should be png,jpg,jpeg,webp,jfif,svg",
        showConfirmButton: false,
        timer: 3000,
      });
    }
  };

  const deleteAnswerImage = async (uuid: string) => {
    setLoading(true);
    let isFind;
    if (uuid) {
      for (let i = 0; i < correctAnswerUuid?.length; i++) {
        if (uuid == correctAnswerUuid[i]) {
          isFind = true;
          break;
        }
      }
      if (isFind) {
        setAnswerOption(
          answerOption.filter((item: string | any) => {
            if (item?.option) {
              return item?.uuid !== uuid;
            } else return item !== uuid;
          })
        );
        setDeleteCorrectAnswers([...deleteCorrectAnswers, uuid]);
        setCorrectAnswers(correctAnswers.filter((item: any) => item !== uuid));
      } else {
        if (uuid) {
          setCorrectAnswers(
            correctAnswers.filter((item: string) => item !== uuid)
          );
          setAnswerOption(
            answerOption.filter((item: DataType) => {
              if (item?.option) {
                return item?.uuid !== uuid;
              }
            })
          );
          setDeleteCorrectAnswers([...deleteCorrectAnswers, uuid]);
        }
      }
    } else {
      setDeleteCorrectAnswers([...deleteCorrectAnswers, uuid]);
      setCorrectAnswers(correctAnswers.filter((item: string) => item !== uuid));
      setAnswerOption(
        answerOption.filter((item: DataType) => {
          if (item?.option) {
            return item?.uuid !== uuid;
          }
        })
      );
      setIsUpdated(false);
    }
  };

  const handleAnswerImageUpload = async (index, e) => {
    e.preventDefault();
    const list = [...answerOption];
    const selectedAnswerFiles = e.target.files[0];
    const assessmentNewId = assessmentId;
    if (selectedAnswerFiles.size >= 2097152) {
      Swal.fire({
        icon: "warning",
        title: "Image size should be max 2MB.",
        showConfirmButton: false,
        timer: 3000,
      });
    } else if (
      selectedAnswerFiles.type === "image/png" ||
      selectedAnswerFiles.type === "image/jpg" ||
      selectedAnswerFiles.type === "image/jpeg" ||
      selectedAnswerFiles.type === "image/webp" ||
      selectedAnswerFiles.type === "image/jfif" ||
      selectedAnswerFiles.type === "image/svg"
    ) {
      const response = await addAnswersList({
        answerId: "",
        answer: selectedAnswerFiles?.name,
      });

      const formData = new FormData();
      setAnswerImagePreview(URL.createObjectURL(selectedAnswerFiles));
      formData.append("image", selectedAnswerFiles);
      formData.append("questionType", "Media");
      formData.append("assessmentId", assessmentNewId);
      formData.append("uuid", response?.data?.createAnswersList?.uuid);
      formData.append("type", "option");
      const { data } = await axios.post(
        `${process.env.REACT_APP_MEDIA_URL}/api/media/upload`,
        formData,
        {
          headers: {
            enctype: "multipart/form-data",
            Authorization: `Bearer ${getCookie("token")}`,
          },
        }
      );
      if (data?.status == true) {
        await getOptionsByUuid(data?.uuid);
        list[answerOption?.length - 1] = {
          uuid: data?.uuid,
          option: `/option/${data?.uuid}/${selectedAnswerFiles?.name}`,
        };
        setAnswerOption(list);
      }
    } else {
      Swal.fire({
        icon: "warning",
        title: "Image formate should be png, jpg, jpeg, webp, jfif, svg",
        showConfirmButton: false,
        timer: 3000,
      });
    }
  };
  const val: any = [];
  const getOptionDetailsByUuid = async () => {
    if (correctAnswerUuid?.length > 0) {
      for (let i = 0; i < correctAnswerUuid?.length; i++) {
        const result = await getOptionsByUuid(correctAnswerUuid[i]);
        val.push(result?.data?.quesionsOptionsByUuid[0]?.option);
      }
    }
    setValue1(val);
  };

  const getUserValidateSkillApi = async () => {
    const isValidate = await getUserValidateSkill(userId, skillId);
    if (isValidate?.data?.skillValidateUser?.length > 0) {
      getQuestionDetailsData();
      getAssessmentName();
      getSkillName();
      setIsAuthenticate(true);
    } else {
      navigate("/404");
    }
  };

  useEffect(() => {
    getUserValidateSkillApi();
  }, [userId, skillId]);

  //fetch optons details by uuid
  useEffect(() => {
    getOptionDetailsByUuid();
  }, [answerOption?.length, isUpdated]);

  // To disable submit button at the beginning.
  // validate for check input is not empty
  useEffect(() => {
    if (
      answerOption?.length >= 2 &&
      weightage &&
      Number(weightage) <= 10 &&
      Number(weightage) > 0 &&
      nameInputValid(topic) &&
      !duplicateMessage &&
      topic?.trim()?.length <= 60 &&
      topic?.trim()?.length >= 2 &&
      (questionType == "1"
        ? question?.replace(/(<([^>]+)>)/g, "")?.trim()?.length > 2
        : question?.trim().length >= 2 || questionImagePreview) &&
      topic &&
      correctAnswers?.length > 0
    ) {
      const checkanswer = answerOption?.every((item: any) => {
        const formateItem = item?.option?.replace(/(<([^>]+)>)/g, "");
        return formateItem?.trim()?.length > 0;
      });
      if (!checkanswer) {
        setFormStatus(true);
        setAnswerFormStatus(true);
      } else {
        setFormStatus(false);
        setAnswerFormStatus(false);
      }
    } else {
      const checkanswer = answerOption?.every((item: any) => {
        const formateItem = item?.option?.replace(/(<([^>]+)>)/g, "");
        return formateItem?.trim()?.length > 0;
      });
      if (!checkanswer) {
        setFormStatus(true);
        setAnswerFormStatus(true);
      } else {
        if (
          changed &&
          value1?.length > 0 &&
          answerOption?.length >= 2 &&
          weightage &&
          Number(weightage) <= 10 &&
          Number(weightage) > 0 &&
          nameInputValid(topic) &&
          topic?.trim()?.length <= 60 &&
          topic?.trim()?.length >= 2 &&
          (questionType == "1"
            ? question?.replace(/(<([^>]+)>)/g, "")?.trim()?.length > 2
            : question?.trim().length >= 2 || questionImagePreview) &&
          topic &&
          correctAnswers?.length > 0
        ) {
          setAnswerFormStatus(false);
          setFormStatus(false);
        } else {
          if (
            answerOption?.length >= 2 &&
            weightage &&
            Number(weightage) <= 10 &&
            Number(weightage) > 0 &&
            nameInputValid(topic) &&
            topic?.trim()?.length <= 60 &&
            topic?.trim()?.length >= 2 &&
            (questionType == "1"
              ? question?.replace(/(<([^>]+)>)/g, "")?.trim()?.length > 2
              : question?.trim().length >= 2 || questionImagePreview) &&
            topic &&
            correctAnswers?.length > 0
          ) {
            setFormStatus(false);
            setAnswerFormStatus(false);
          } else {
            setFormStatus(true);
            setAnswerFormStatus(false);
          }
        }
      }
    }
  }, [
    topic,
    weightage,
    question,
    answerOption,
    answerOption.length,
    changed,
    correctAnswers?.length,
    questionImagePreview,
  ]);

  const insertSymbol = (symbol) => {
    if (quillRef.current) {
      const quill = quillRef.current.getEditor();
      const selection = quill.getSelection(true);
      if (selection) {
        quill.insertText(selection, symbol);
      } else {
        quill.insertText(selection, symbol);
      }
    }
  };

  //form validation on defaultValue set false==enable ,true==disable
  useEffect(() => {
    if (isUpdated && value1?.length > 0 && answerOption?.length >= 2) {
      setFormStatus(false);
      setAnswerFormStatus(false);
    } else if (isUpdated && answerOption?.length >= 2) {
      setFormStatus(false);
      setAnswerFormStatus(false);
    } else {
      setFormStatus(true);
    }
  }, [value1?.length, isUpdated]);

  useEffect(() => {
    const wordCount = countWords(content);
    if (wordCount) {
      setCodingBtnStatus(true);
    } else {
      if (
        weightage &&
        content?.trim()?.length > 0 &&
        Number(weightage) <= 10 &&
        nameInputValid(topic) &&
        topic?.trim()?.length <= 60 &&
        topic?.trim()?.length >= 2 &&
        topic &&
        (testCase || testCase != null || jsonData?.length > 0)
      ) {
        setCodingBtnStatus(false);
      } else setCodingBtnStatus(true);
    }
  }, [topic, weightage, testCase, jsonData, content]);

  // Remove HTML tags and count words
  const countWords = (text) => {
    const plainText = text.replace(/<[^>]*>/g, "");
    const words = plainText.match(/\b\w+\b/g);
    return words?.every((word) => word == "nbsp");
  };

  const getMedia = async () => {
    await axios
      .get(
        `${process.env.REACT_APP_MEDIA_URL}/api/media/download/coding/${questionUuid}`
      )
      .then((data) => {
        setTestcaseName(data.data.fileName[0]);
        setJsonData(data?.data?.response);
      })
      .catch(() => {
        setTestcaseName("");
        setJsonData("");
      });
  };

  useEffect(() => {
    const quilToolbar = document.getElementsByClassName("ql-toolbar ql-snow");
    const spanSigma = document.createElement("span");
    spanSigma.style.cursor = "pointer";
    spanSigma.innerHTML = "∑";
    spanSigma.setAttribute("id", "ql-formula");
    quilToolbar[0]?.appendChild(spanSigma);
    spanSigma.addEventListener("click", () => {
      insertSymbol("∑");
    });
    const spanIntegral = document.createElement("span");
    spanIntegral.style.cursor = "pointer";
    spanIntegral.style.marginLeft = "5px";
    spanIntegral.innerHTML = "∫";
    spanIntegral.setAttribute("id", "ql-formula");
    quilToolbar[0]?.appendChild(spanIntegral);
    spanIntegral.addEventListener("click", () => {
      insertSymbol("∫");
    });
    const spanSqrt = document.createElement("span");
    spanSqrt.style.cursor = "pointer";
    spanSqrt.style.marginLeft = "5px";
    spanSqrt.innerHTML = "√";
    spanSqrt.setAttribute("id", "ql-formula");
    quilToolbar[0]?.appendChild(spanSqrt);
    spanSqrt.addEventListener("click", () => {
      insertSymbol("√");
    });
  }, [value1?.length]);

  useEffect(() => {
    if (questionUuid && questionType == "5") {
      getMedia();
    }
  }, [questionUuid, questionType]);

  const breadcrumbData = [
    {
      path: "/subadmin/AllDomains/list",
      text: "Dashboard",
    },
    { text: "skill" },
    {
      path: `/subadmin/skills/list`,
      text: skillName,
    },
    { text: "assessment" },
    {
      path: `/subadmin/domain/skill/${skillId}/assessment/list`,
      text: name,
    },
    { text: "Edit Question" },
  ];

  const options = [
    {
      value: "Easy",
      label: "Easy",
    },
    {
      value: "Medium",
      label: "Medium",
    },
    {
      value: "Difficult",
      label: "Difficult",
    },
  ];

  const handleQuestionLevel = (newValue: any) => {
    setQuestionLevel(newValue);
  };

  // enter answer into database with onBlur
  const addAnswers = async (index: number, e: any, uuid: any) => {
    setAnswerFormStatus(true);
    setSelectKey((prevKey) => prevKey + 1);
    const answer = e.target.value;
    const list = [...answerOption];
    if (!answer.replace(/\s/g, "").length) {
      setFormStatus(true);
    } else {
      const map = {};
      let result = false;
      const answeroptionArr: string[] = [];
      for (let i = 0; i < answerOption?.length; i++) {
        answeroptionArr.push(
          answerOption[i]?.option && answerOption[i]?.option
        );
        const value = answeroptionArr && answeroptionArr[i]?.toLowerCase();
        if (map[value]) {
          result = true;
          break;
        }
        map[value] = true;
      }
      if (result) {
        setDuplicateMessage(true);
        setFormStatus(true);
      } else {
        setDuplicateMessage(false);
        if (uuid?.uuid) {
          list[index] = {
            uuid: uuid?.uuid,
            option: answer,
          };
          setAnswerOption(list);
        } else {
          const response = await addAnswersList({
            answerId: "",
            answer,
          });
          if (response?.data?.createAnswersList?.uuid) {
            list[index] = {
              uuid: response?.data?.createAnswersList?.uuid,
              option: response?.data?.createAnswersList?.option,
            };
            setAnswerOption(list);
            setAnswerFormStatus(false);
          }
        }
      }
      let isDeleted = false;

      for (let i = 0; i < deleteCorrectAnswers?.length; i++) {
        if (deleteCorrectAnswers != correctAnswerUuid) {
          isDeleted = true;
          break;
        }
      }
      if (!isDeleted) {
        getQuestionDetailsDataFetched();
      }
    }
  };

  const addMcqAnswers = async (index: number, e: any, uuid: any) => {
    setAnswerFormStatus(true);
    setSelectKey((prevKey) => prevKey + 1);
    const answer = e;
    const list = [...answerOption];
    const answerWithoutTag = answer?.replace(/(<([^>]+)>)/g, "");
    if (answerWithoutTag?.trim()?.length <= 0) {
      setAnswerFormStatus(true);
      setFormStatus(true);
      setSelectKey((prevKey) => prevKey + 1);
    } else {
      const map = {};
      let result = false;
      const answeroptionArr: string[] = [];
      for (let i = 0; i < answerOption?.length; i++) {
        answeroptionArr.push(
          answerOption[i]?.option && answerOption[i]?.option
        );
        const value = answeroptionArr && answeroptionArr[i]?.toLowerCase();
        if (map[value]) {
          result = true;
          break;
        }
        map[value] = true;
      }
      if (result) {
        setDuplicateMessage(true);
        setFormStatus(true);
      } else {
        setDuplicateMessage(false);
        if (uuid) {
          list[index] = {
            uuid: uuid,
            option: answer,
          };
          setAnswerOption(list);
        } else {
          const response = await addAnswersList({
            answerId: "",
            answer,
          });
          if (response?.data?.createAnswersList?.uuid) {
            list[index] = {
              uuid: response?.data?.createAnswersList?.uuid,
              option: response?.data?.createAnswersList?.option,
            };
            setAnswerOption(list);
            setAnswerFormStatus(false);
          }
        }
      }
      let isDeleted = false;
      for (let i = 0; i < deleteCorrectAnswers?.length; i++) {
        if (deleteCorrectAnswers != correctAnswerUuid) {
          isDeleted = true;
          break;
        }
      }
      if (!isDeleted) {
        getQuestionDetailsDataFetched();
      }
    }
  };

  const optionsData = answerOption?.map(
    (list: { uuid: string; option: string }) => {
      if (questionType == "3") {
        const val = list?.option;
        return {
          value: list.uuid,
          label: val, //replace html tags
        };
      } else {
        const val = list?.option;
        return {
          value: list.uuid,
          label: val?.replace(/(<([^>]+)>)/g, ""), //replace html tags
        };
      }
    }
  );

  //back
  const handleBack = () => {
    navigate(
      `/subadmin/domain/skill/${skillId}/assessment/${assessmentId}/questions/list`
    );
  };
  const deleteImageField = async (e, index) => {
    answerOption.splice(index, 1);
    setAnswerFormStatus(false);
  };

  return (
    <>
      {isAuthenticate && (
        <div className="p-5 pb-10 max-h-[900px] overflow-y-auto">
          <p className="text-2xl capitalize font-primary text-black font-semibold  my-0">
            {" "}
            Edit Question
          </p>
          <div className="mb-5">
            <BreadcrumbComp breadcrumbData={breadcrumbData} />
          </div>
          <p className="text-xl font-semibold font-primary">
            Edit{" "}
            {questionType === "1"
              ? "MCQ"
              : questionType === "4" ||
                questionType === "2" ||
                questionType === "3"
              ? "media"
              : questionType === "5"
              ? "Coding"
              : null}{" "}
            question and answer.
          </p>
          {questionType === "1" && value1?.length >= 0 ? (
            <>
              {/* MCQ question edit section start */}
              <div className="mt-5 border bg-white shadow-lg p-5  rounded-xl">
                <div className="grid grid-cols-3 gap-x-10 gap-y-8">
                  <div className="relative">
                    <InputField
                      id="Weightage"
                      value={weightage}
                      onChange={setWeightage}
                      type="number"
                      placeholder="enter Weightage"
                      containsWeightageMsg={setWeightageErr}
                    />
                    <span className="text-red-500 absolute">
                      {weightageErr}
                    </span>
                  </div>
                  <div className="relative">
                    <InputField
                      id="Topic Name"
                      value={topic}
                      onChange={setTopic}
                      type="topic"
                      placeholder="Enter topic name"
                      containsSpecialCharsMsg={setTopicError}
                    />
                    <span className="text-red-500 absolute">{topicError}</span>
                  </div>
                  <div className="relative">
                    <SelectField
                      label="select question level"
                      options={options}
                      placeholder="Select Question Level"
                      onChange={(e) => handleQuestionLevel(e)}
                      value={questionLevel}
                      defaultValue={questionLevel}
                    />
                    {/* <span className="text-red-500 mt-[6px] absolute">{topicError}</span> */}
                  </div>
                </div>
                <div className="mt-5">
                  <label htmlFor="Question">
                    Question <span className="text-red-600">*</span>
                  </label>
                  <QuillEditor
                    ref={quillRef}
                    theme="snow"
                    formats={formats}
                    modules={modules}
                    value={question}
                    onChange={(e) => setQuestion(e)}
                  />
                </div>
                <div className="mt-5">
                  <div className="w-full mt-2">
                    <div className="grid grid-cols-2 gap-x-10 gap-y-5">
                      {answerOption?.map((data: DataType, index: number) => {
                        return (
                          <div key={index}>
                            <div
                              className="flex flex-row justify-between w-full items-center"
                              key={index}
                            >
                              <div
                                className={`w-full ${
                                  index === 0 ? "mt-0" : "mt-0"
                                }`}
                              >
                                <label
                                  className="font-primary capitalize text-sm lg:text-base"
                                  htmlFor="answerSelect"
                                >
                                  Answer <span className="text-red-600">*</span>
                                </label>
                                <QuillEditor
                                  theme="snow"
                                  formats={answerFormats}
                                  modules={answerModules}
                                  value={data?.option ? data?.option : ""}
                                  onChange={(event) =>
                                    handleEditorChange(event, index, data)
                                  }
                                  onBlur={(e) =>
                                    addMcqAnswers(
                                      index,
                                      data?.option,
                                      data?.uuid ? data?.uuid : ""
                                    )
                                  }
                                />
                              </div>
                              {answerOption.length > 1 ? (
                                <svg
                                  onClick={() =>
                                    removeInputFields(
                                      data?.option ? data : data
                                    )
                                  }
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  strokeWidth={1.5}
                                  stroke="currentColor"
                                  className="w-6 h-6  ml-1 mt-5 cursor-pointer"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                                  />
                                </svg>
                              ) : null}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                    {showcorrectAnswer || answerOption?.length >= 6 ? (
                      ""
                    ) : (
                      <button
                        disabled={answerFormStatus ? true : false}
                        className={`${
                          answerFormStatus
                            ? "bg-gray-200  text-gray-400  cursor-not-allowed"
                            : "bg-brand-500 text-white cursor-pointer"
                        } w-32 text-center mt-5 rounded-lg font-primary  p-2`}
                        onClick={() => addInputField()}
                      >
                        Add New
                      </button>
                    )}
                  </div>
                </div>

                {answerOption?.length >= 0 ? (
                  isUpdated && value1?.length > 0 ? (
                    <Form.Item
                      label="Correct Answer"
                      name="CorrectAnswer"
                      className="correctAnswer"
                      rules={[
                        {
                          required: true,
                          message: "Please Select correct answer.",
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        key={selectKey}
                        autoClearSearchValue={true}
                        defaultValue={correctAnswerUuid}
                        onChange={selectCorrectAnswer}
                        mode="multiple"
                        placeholder="Search / Select Correct answers"
                        optionFilterProp="children"
                        style={{
                          width: "99%",
                        }}
                        filterOption={(input, option: any) =>
                          (option?.label ?? "")?.includes(input)
                        }
                        options={optionsData}
                      />
                    </Form.Item>
                  ) : (
                    <>
                      <p className="mt-2 mb-1 pb-1">
                        <span className="text-red-600">*</span> Correct Answer
                      </p>
                      <Select
                        className="correct-answer-field h-24 py-10"
                        showSearch
                        key={selectKey}
                        autoClearSearchValue={true}
                        defaultValue={correctAnswerUuid}
                        onChange={selectCorrectAnswer}
                        mode="multiple"
                        placeholder={`Search / Select Correct answers ${value1}`}
                        optionFilterProp="children"
                        style={{
                          width: "99%",
                        }}
                        filterOption={(input, option: any) =>
                          (option?.label ?? "")?.includes(input)
                        }
                        options={optionsData}
                      />
                    </>
                  )
                ) : (
                  ""
                )}
                {answerOption?.length < 2 && loading && (
                  <div className="text-black mt-2 border-spacing-1 border-l-8 bg-orange-300 shadow-sm height-2">
                    <QuestionCircleOutlined />
                    <span className="ml-2">
                      {" "}
                      Important: Answers options should be two or more than two
                    </span>
                  </div>
                )}
                {duplicateMessage && (
                  <>
                    <span className="text-red-500">
                      answers should be unique
                    </span>
                  </>
                )}
                <div className="flex flex-row gap-2">
                  <div className="mt-5">
                    <PrimaryButton
                      disabledStatus={formStatus ? true : false}
                      type="submit"
                      text="Save"
                      onclick={() => onFinish()}
                      className={`${
                        formStatus
                          ? "bg-gray-300 cursor-not-allowed border-gray-300"
                          : "bg-black hover:bg-transparent hover:border-black border-black hover:text-black"
                      }`}
                    />
                  </div>
                  <div className="mt-5">
                    <PrimaryButton
                      type="submit"
                      text="Back"
                      onclick={handleBack}
                      className="bg-black hover:bg-transparent hover:border-black border-black hover:text-black"
                    />
                  </div>
                </div>
              </div>
              {/* MCQ question edit section end */}
            </>
          ) : questionType === "4" ||
            questionType === "2" ||
            questionType === "3" ? (
            <>
              {/* Media question edit section start */}
              <div className="mt-5 border bg-white shadow-lg p-5  rounded-xl">
                <div className="grid grid-cols-3 gap-x-10 gap-y-5">
                  <div className="relative">
                    <InputField
                      id="Weightage"
                      value={weightage}
                      onChange={setWeightage}
                      type="number"
                      placeholder="enter Weightage"
                      containsWeightageMsg={setWeightageErr}
                    />
                    <span className="text-red-500 absolute">
                      {weightageErr}
                    </span>
                  </div>
                  <div className="relative">
                    <InputField
                      id="Topic Name"
                      value={topic}
                      onChange={setTopic}
                      type="topic"
                      placeholder="Enter topic name"
                      containsSpecialCharsMsg={setTopicError}
                    />
                    <span className="text-red-500 absolute">{topicError}</span>
                  </div>
                  <div className="relative">
                    <SelectField
                      label="select question level"
                      options={options}
                      placeholder="Select Question Level"
                      onChange={(e) => handleQuestionLevel(e)}
                      value={questionLevel}
                      defaultValue={questionLevel}
                    />
                    {/* <span className="text-red-500 mt-[6px] absolute">{topicError}</span> */}
                  </div>
                  {/* question uploading div start */}
                  <div className="my-5">
                    {questionType == "2" ? (
                      <>
                        <label htmlFor="Question">
                          Question <span className="text-red-600">*</span>
                        </label>
                        <textarea
                          id="Question"
                          value={question}
                          onChange={(e) => setQuestion(e.target.value)}
                          placeholder="Enter Question"
                          className="lg:p-4 p-2 text-sm lg:mt-2 border border-gray-300 focus:outline-none w-full rounded-lg"
                        ></textarea>
                      </>
                    ) : questionType === "4" || questionType == "3" ? (
                      <>
                        <p className="font-primary capitalize text-sm lg:text-base">
                          Question <span className="text-red-600">*</span>
                        </p>
                        {questionImagePreview ? (
                          <div className="h-44 border relative  mb-10 w-44">
                            <img
                              className="h-full w-full p-1 rounded-lg object-cover object-center"
                              src={questionImagePreview}
                              alt={questionImagePreview}
                            />
                            <div
                              onClick={deleteQuestionImage}
                              className="bg-black hover:bg-red-500 cursor-pointer  w-8 h-8 flex items-center justify-center absolute top-1 right-1 rounded-full"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="w-5 h-5 mx-auto text-white"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                                />
                              </svg>
                            </div>
                          </div>
                        ) : (
                          <button type="submit">
                            <div className="flex items-center justify-center w-44 h-44">
                              <label
                                data-testid="domainImage"
                                htmlFor="dropzone-file"
                                className="flex flex-col items-center justify-center w-full h-auto border-2 bg-gray-50 border-gray-300 border-dashed rounded-lg cursor-pointer  dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
                              >
                                <div className="flex flex-col items-center justify-center pt-5 pb-6">
                                  <svg
                                    aria-hidden="true"
                                    className="w-10 h-10 mb-3 text-gray-400"
                                    fill="none"
                                    stroke="currentColor"
                                    viewBox="0 0 24 24"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth="2"
                                      d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                                    ></path>
                                  </svg>
                                  <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                                    <span className="font-semibold">
                                      Click to upload
                                    </span>{" "}
                                  </p>
                                  <p className="text-xs text-center text-gray-500 dark:text-gray-400">
                                    WEBP, PNG, JPG or GIF (MAX Size. 2MB)
                                  </p>
                                </div>
                                {question ? (
                                  <div className="group  -mt-44 relative bg-white p-1 rounded-xl border border-dashed border-black">
                                    <div className="h-full text-2xl w-full group-hover:opacity-100 opacity-0 absolute z-20  text-white flex items-center justify-center inset-0">
                                      <svg
                                        aria-hidden="true"
                                        className="w-10 h-10 mb-3 text-white"
                                        fill="none"
                                        stroke="currentColor"
                                        viewBox="0 0 24 24"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          strokeWidth="2"
                                          d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                                        ></path>
                                      </svg>
                                      <p className="mb-2 text-sm text-text-white dark:text-gray-400">
                                        <span className="font-semibold">
                                          Click to upload
                                        </span>{" "}
                                      </p>
                                    </div>
                                    <div className="absolute group-hover:bg-black opacity-40 text-center w-full h-full rounded-xl  text-white"></div>
                                    <img
                                      className="w-44 h-40 object-cover object-center rounded-xl"
                                      src={`${process.env.REACT_APP_MEDIA_URL}/api/media/download${question}`}
                                      alt={question}
                                    />
                                  </div>
                                ) : null}
                                <input
                                  id="dropzone-file"
                                  onChange={(e) => handleQuestionImage(e)}
                                  type="file"
                                  className="hidden"
                                />
                              </label>
                            </div>
                          </button>
                        )}
                        {/* end of upload image type question */}
                        {questionType == "3" && answerOption?.length > 0 ? (
                          isUpdated && value1?.length > 0 ? (
                            <Form.Item
                              style={{ marginTop: "24px" }}
                              label="Correct Answer"
                              name="CorrectAnswer"
                              className="correctAnswer"
                              rules={[
                                {
                                  required: true,
                                  message: "Please Select correct answer.",
                                },
                              ]}
                            >
                              <Select
                                defaultValue={correctAnswerUuid}
                                onChange={selectCorrectAnswer}
                                key={selectKey}
                                mode="multiple"
                                placeholder="Search / Select Correct answers"
                                optionFilterProp="children"
                                style={{
                                  width: "99%",
                                }}
                                filterOption={(input, option: any) =>
                                  (option?.label ?? "")?.includes(input)
                                }
                                filterSort={(optionA, optionB) =>
                                  (optionA?.label ?? "")
                                    ?.toLowerCase()
                                    ?.localeCompare(
                                      (optionB?.label ?? "")?.toLowerCase()
                                    )
                                }
                                options={optionsData}
                              />
                            </Form.Item>
                          ) : (
                            <>
                              <p className="mt-2 mb-1 pb-1">
                                <span className="text-red-600">*</span> Correct
                                Answer
                              </p>
                              <Select
                                className="correct-answer-field h-24 py-10"
                                showSearch
                                onChange={selectCorrectAnswer}
                                defaultValue={correctAnswerUuid}
                                key={selectKey}
                                mode="multiple"
                                placeholder="Search / Select Correct answers"
                                optionFilterProp="children"
                                style={{
                                  width: "99%",
                                }}
                                filterOption={(input, option: any) =>
                                  (option?.label ?? "")?.includes(input)
                                }
                                filterSort={(optionA, optionB) =>
                                  (optionA?.label ?? "")
                                    ?.toLowerCase()
                                    ?.localeCompare(
                                      (optionB?.label ?? "")?.toLowerCase()
                                    )
                                }
                                options={optionsData}
                              />
                            </>
                          )
                        ) : (
                          ""
                        )}
                      </>
                    ) : null}
                  </div>
                  {/* question uploading div end */}
                  <div>
                    <div className="w-full mt-2">
                      <label
                        className="font-primary capitalize text-sm lg:text-base"
                        htmlFor="answerSelect"
                      >
                        Answer <span className="text-red-600">*</span>
                      </label>
                      <div className="grid grid-cols-2 gap-x-10 gap-y-5 mt-4">
                        {answerOption?.map((data: DataType, index: number) => {
                          return (
                            <div key={index}>
                              <div
                                key={index}
                                className="flex flex-row justify-between w-full items-center space-x-1"
                              >
                                <div
                                  className={`w-full ${
                                    index === 0 ? "mt-0" : "mt-0"
                                  }`}
                                >
                                  {questionType == "3" ? (
                                    <>
                                      <input
                                        id="answerSelect"
                                        type="text"
                                        name="answers"
                                        onBlur={(e) =>
                                          addAnswers(
                                            index,
                                            e,
                                            typeof data === "object"
                                              ? data
                                              : data
                                          )
                                        }
                                        onChange={(event) =>
                                          handleChange(event, index, data)
                                        }
                                        value={
                                          data?.option
                                            ? data?.option
                                            : data?.option
                                        }
                                        placeholder="Answer"
                                        className="lg:p-4 p-2 text-sm lg:mt-2 border border-gray-300 focus:outline-none w-full rounded-lg"
                                      />
                                    </>
                                  ) : (
                                    <>
                                      <button type="submit">
                                        <div className="flex items-center justify-center w-44 h-44 relative">
                                          <label
                                            data-testid="answerImage"
                                            htmlFor="dropzone4-file"
                                            className="flex flex-col items-center justify-center w-full h-auto border-2 bg-gray-50 border-gray-300 border-dashed rounded-lg cursor-pointer  dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
                                          >
                                            <div className="flex flex-col items-center justify-center pt-5 pb-6">
                                              <svg
                                                aria-hidden="true"
                                                className="w-10 h-10 mb-3 text-gray-400"
                                                fill="none"
                                                stroke="currentColor"
                                                viewBox="0 0 24 24"
                                                xmlns="http://www.w3.org/2000/svg"
                                              >
                                                <path
                                                  strokeLinecap="round"
                                                  strokeLinejoin="round"
                                                  strokeWidth="2"
                                                  d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                                                ></path>
                                              </svg>
                                              <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                                                <span className="font-semibold">
                                                  Click to upload
                                                </span>{" "}
                                              </p>
                                              <p className="text-xs text-center text-gray-500 dark:text-gray-400">
                                                WEBP, PNG, JPG or GIF (MAX Size.
                                                2MB)
                                              </p>
                                            </div>
                                            <input
                                              id="dropzone4-file"
                                              onChange={(e) =>
                                                handleAnswerImageUpload(
                                                  index,
                                                  e
                                                )
                                              }
                                              type="file"
                                              className="hidden"
                                            />
                                          </label>
                                          {answerOption?.length > 1 && (
                                            <div
                                              onClick={(e) =>
                                                deleteImageField(e, index)
                                              }
                                              className="bg-black cursor-pointer  w-8 h-8 flex items-center justify-center absolute top-1 right-1 rounded-full"
                                            >
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                strokeWidth={1.5}
                                                stroke="currentColor"
                                                className="w-5 h-5 mx-auto text-white"
                                              >
                                                <path
                                                  strokeLinecap="round"
                                                  strokeLinejoin="round"
                                                  d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                                                />
                                              </svg>
                                            </div>
                                          )}
                                        </div>
                                      </button>

                                      {data.option && (
                                        <div className="group h-44 w-44 -mt-44 relative bg-white p-1 rounded-xl border border-dashed border-black">
                                          <img
                                            className="w-44 h-40 object-cover object-center rounded-xl"
                                            src={`${process.env.REACT_APP_MEDIA_URL}/api/media/download/${data.option}`}
                                            alt={data.option}
                                          />
                                          <div
                                            onClick={() =>
                                              deleteAnswerImage(data?.uuid)
                                            }
                                            className="bg-black cursor-pointer  w-8 h-8 flex items-center justify-center absolute top-1 right-1 rounded-full"
                                          >
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              fill="none"
                                              viewBox="0 0 24 24"
                                              strokeWidth={1.5}
                                              stroke="currentColor"
                                              className="w-5 h-5 mx-auto text-white"
                                            >
                                              <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                                              />
                                            </svg>
                                          </div>
                                        </div>
                                      )}
                                    </>
                                  )}
                                </div>
                                {answerOption.length > 1 &&
                                questionType == "3" ? (
                                  <svg
                                    onClick={() =>
                                      removeInputFields(
                                        data?.option ? data : data
                                      )
                                    }
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth={1.5}
                                    stroke="currentColor"
                                    className="w-6 h-6 mt-5 cursor-pointer"
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                                    />
                                  </svg>
                                ) : null}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                      {showcorrectAnswer || answerOption?.length >= 6 ? (
                        ""
                      ) : (
                        <>
                          <button
                            disabled={answerFormStatus ? true : false}
                            className={`${
                              answerFormStatus
                                ? "bg-gray-200  text-gray-400  cursor-not-allowed"
                                : "bg-brand-500 text-white cursor-pointer"
                            } w-32 text-center mt-5 rounded-lg font-primary  p-2`}
                            onClick={() => addInputField()}
                          >
                            Add New
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                </div>
                {answerOption.length > 0 &&
                (questionType == "2" || questionType == "4") &&
                value1?.length >= 0 ? (
                  <div>
                    <p className="mb-0 mt-5">
                      Select correct Answers{" "}
                      <span className="text-red-600">*</span>
                    </p>
                    <div className="mt-5">
                      <div className="grid grid-cols-7 gap-5">
                        {answerOption.length > 0 &&
                          answerOption?.map((item) => {
                            return (
                              <>
                                {typeof item?.option != "undefined" &&
                                value1?.length >= 0 ? (
                                  <label
                                    key={item?.uuid}
                                    className="checkbox-image inline-block relative cursor-pointer ml-[30px] mr-[15px] mb-[250px]"
                                  >
                                    <input
                                      type="checkbox"
                                      onChange={selectCorrectAnswers}
                                      value={item?.uuid}
                                      name="checkbox-name"
                                      checked={correctAnswers?.includes(
                                        item?.uuid
                                      )}
                                    />
                                    <img
                                      src={`${process.env.REACT_APP_MEDIA_URL}/api/media/download/${item?.option}`}
                                      alt="optio-image"
                                    />
                                  </label>
                                ) : (
                                  ""
                                )}
                              </>
                            );
                          })}
                      </div>
                    </div>
                  </div>
                ) : null}
                {duplicateMessage ? (
                  <>
                    <span className="text-red-500">
                      answers should be unique
                    </span>
                  </>
                ) : (
                  ""
                )}
                {answerOption?.length < 2 && loading ? (
                  <div className="text-black mt-2 border-spacing-1 border-l-8 bg-orange-300 shadow-sm height-2">
                    <QuestionCircleOutlined />
                    <span className="ml-2">
                      {" "}
                      Important: Answers options should be two or more than two
                    </span>
                  </div>
                ) : (
                  ""
                )}
                <div className="flex flex-row gap-2">
                  <div className="mt-5">
                    <PrimaryButton
                      disabledStatus={formStatus ? true : false}
                      type="submit"
                      text="Save"
                      onclick={() => onFinishMedia()}
                      className={`${
                        formStatus
                          ? "bg-gray-300 cursor-not-allowed border-gray-300"
                          : "bg-black hover:bg-transparent hover:border-black border-black hover:text-black"
                      }`}
                    />
                  </div>
                  <div className="mt-5">
                    <PrimaryButton
                      type="submit"
                      text="Back"
                      onclick={handleBack}
                      className="bg-black hover:bg-transparent hover:border-black border-black hover:text-black"
                    />
                  </div>
                </div>
              </div>
              {/* Media question edit section end */}
            </>
          ) : questionType == "5" ? (
            <>
              <div className="mb-10 px-5 relative flex flex-col lg:flex-col h-full mx-auto">
                {/* {/ basic info /} */}
                <div className="pb-5 grid grid-cols-3 gap-x-10">
                  <div className="relative">
                    <InputField
                      id="Weightage"
                      placeholder="Weightage"
                      onChange={setWeightage}
                      containsWeightageMsg={setWeightageErr}
                      value={weightage}
                      type="number"
                    />
                    <span className="text-red-500 absolute">
                      {weightageErr}
                    </span>
                  </div>
                  <div className="relative">
                    <InputField
                      id="Topic Name"
                      placeholder="Topic"
                      type="topic"
                      containsSpecialCharsMsg={setTopicError}
                      value={topic}
                      onChange={setTopic}
                    />
                    <span className="text-red-500 absolute">{topicError}</span>
                  </div>
                  <div className="relative">
                    <SelectField
                      label="select question level"
                      options={options}
                      placeholder="Select Question Level"
                      onChange={(e) => handleQuestionLevel(e)}
                      value={questionLevel}
                      defaultValue={questionLevel}
                    />
                    {/* <span className="text-red-500 mt-[6px] absolute">{topicError}</span> */}
                  </div>
                </div>

                <h1 className="font-primary text-base font-normal">
                  Algorithmic Question Description{" "}
                  <span className="text-red-500">*</span>
                </h1>
                <div>
                  <QuestionEditor
                    contentData={content}
                    setContentData={setContent}
                  />
                </div>
                {/* {/ input and output form field start  /} */}
                <p className="font-primary mt-5 capitalize text-sm lg:text-base">
                  Upload Testcase <span className="text-red-600">*</span>
                </p>
                {jsonData?.length > 0 ? (
                  <>
                    <div className="h-44 border relative w-44 group">
                      <img
                        className="h-full w-full p-1 rounded-lg object-cover object-center"
                        src="/Assets/xlsx_logo.png"
                        alt="/Assets/xlsx_logo.png"
                      />
                      <div className="bg-gray-100 group-hover:opacity-80 opacity-0 absolute inset-0 space-x-4 flex justify-center items-center">
                        <div
                          onClick={() => {
                            exportToExcel();
                          }}
                          className="bg-blue-200 cursor-pointer p-2 w-10 h-10 hover:bg-blue-500 flex items-center justify-center  rounded-full"
                        >
                          <svg
                            fill="#000000"
                            version="1.1"
                            id="Layer_1"
                            xmlns="http://www.w3.org/1999/xlink"
                            viewBox="0 0 92 92"
                            enableBackground="new 0 0 92 92"
                          >
                            <path
                              id="XMLID_1239_"
                              d="M91.3,43.8C90.6,42.8,74.4,19,46,19C17.6,19,1.4,42.8,0.7,43.8c-0.9,1.3-0.9,3.1,0,4.5
	C1.4,49.2,17.6,73,46,73c28.4,0,44.6-23.8,45.3-24.8C92.2,46.9,92.2,45.1,91.3,43.8z M46,65C26.7,65,13.5,51.4,9,46
	c4.5-5.5,17.6-19,37-19c19.3,0,32.5,13.6,37,19C78.4,51.5,65.3,65,46,65z M48.3,29.6c-4.4-0.6-8.7,0.5-12.3,3.2c0,0,0,0,0,0
	c-7.3,5.5-8.8,15.9-3.3,23.2c2.7,3.6,6.5,5.8,10.9,6.5c0.8,0.1,1.6,0.2,2.3,0.2c3.6,0,7-1.2,9.9-3.3c7.3-5.5,8.8-15.9,3.3-23.2
	C56.6,32.5,52.7,30.2,48.3,29.6z M52.3,54.5c-2.2,1.7-5,2.4-7.8,2c-2.8-0.4-5.3-1.9-7-4.1C34.1,47.7,35,41,39.7,37.5
	c2.2-1.7,5-2.4,7.8-2c2.8,0.4,5.3,1.9,7,4.1C57.9,44.3,57,51,52.3,54.5z M51.9,40c0.8,0.7,1.2,1.8,1.2,2.8c0,1-0.4,2.1-1.2,2.8
	c-0.7,0.7-1.8,1.2-2.8,1.2c-1.1,0-2.1-0.4-2.8-1.2c-0.8-0.8-1.2-1.8-1.2-2.8c0-1.1,0.4-2.1,1.2-2.8c0.7-0.8,1.8-1.2,2.8-1.2
	C50.2,38.9,51.2,39.3,51.9,40z"
                            />
                          </svg>
                        </div>
                        <div className="bg-black cursor-pointer hover:bg-red-500 w-8 h-8 flex items-center justify-center sticky rounded-full">
                          <Popconfirm
                            title="Are you sure to delete testcases?"
                            onConfirm={deleteTestcase}
                            okButtonProps={{ loading: false }}
                          >
                            <button className="px-2  py-2 bg-red-600 text-white text-base rounded-full">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="w-4 h-4"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                                />
                              </svg>
                            </button>
                          </Popconfirm>
                        </div>
                      </div>
                    </div>
                    <p className="mt-2 font-bold">{testCaseName}</p>
                  </>
                ) : (
                  <>
                    <div className="h-48 w-48 group bg-white relative p-1 rounded-xl border border-dashed border-black">
                      <div className="absolute group-hover:bg-black opacity-40 text-center rounded-xl  w-44 h-44 text-white"></div>
                      {testCaseName ? (
                        <img
                          className="h-full w-full p-5 rounded-lg object-cover object-center"
                          src="/Assets/xlsx_logo.png"
                          alt="/Assets/xlsx_logo.png"
                        />
                      ) : (
                        <label
                          htmlFor="dropzone-file"
                          className={`flex flex-col absolute inset-0 top-0   cursor-pointer items-center justify-center w-full h-full border-2 border-gray-300 border-dashed rounded-xl   bg-gray-50  group-hover:bg-gray-100`}
                        >
                          <div className="flex flex-col items-center justify-center pt-5 pb-6">
                            <svg
                              aria-hidden="true"
                              className="w-10 h-10 mb-3 text-gray-400"
                              fill="none"
                              stroke="currentColor"
                              viewBox="0 0 24 24"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                              ></path>
                            </svg>
                            <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                              <span className="font-semibold">
                                Click to upload
                              </span>
                            </p>
                            <p className="text-xs text-center text-gray-500 dark:text-gray-400">
                              File formate XLSX
                            </p>
                          </div>

                          <input
                            id="dropzone-file"
                            type="file"
                            onChange={handleData}
                            className="hidden"
                          />
                        </label>
                      )}
                      <label
                        htmlFor="dropzone-file"
                        className={`flex flex-col absolute inset-0 bottom-0 top-0 opacity-0 group-hover:opacity-100  cursor-pointer items-center justify-center w-full h-full border-2 border-gray-300 border-dashed rounded-xl   bg-gray-50  group-hover:bg-gray-100`}
                      >
                        <div className="flex flex-col items-center justify-center pt-5 pb-6">
                          <svg
                            aria-hidden="true"
                            className="w-10 h-10 mb-3 text-gray-400"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                            ></path>
                          </svg>
                          <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                            <span className="font-semibold">
                              Click to upload
                            </span>
                          </p>
                          <p className="text-xs text-center text-gray-500 dark:text-gray-400">
                            File formate XLSX
                          </p>
                        </div>

                        <input
                          id="dropzone-file"
                          type="file"
                          onChange={handleData}
                          className="hidden"
                        />
                      </label>
                    </div>
                    <p className="relative mt-2">{testCaseName}</p>
                  </>
                )}

                <div className="flex flex-grow relative gap-2 mt-5">
                  <div className="">
                    <PrimaryButton
                      disabledStatus={codingBtnStatus ? true : false}
                      text="Submit"
                      type="submit"
                      onclick={onFinish}
                      className={`${
                        codingBtnStatus
                          ? "bg-gray-300 cursor-not-allowed border-gray-300"
                          : "bg-black hover:bg-transparent hover:border-black border-black hover:text-black"
                      }`}
                    />
                  </div>
                  <div className="">
                    <PrimaryButton
                      text="Back"
                      type="submit"
                      onclick={handleBack}
                      className="bg-black hover:bg-transparent hover:border-black border-black hover:text-black"
                    />
                  </div>
                </div>

                {/* {/ input and output form field end /} */}
              </div>
            </>
          ) : (
            ""
          )}
          {isLoading && (
            <div className="absolute  mx-auto w-full flex z-10 justify-center items-center inset-0">
              <Spin
                tip="Processing"
                size="large"
                className="mb-5 testcase-loader w-full "
              >
                <div className="content" />
              </Spin>
            </div>
          )}
        </div>
      )}
    </>
  );
};
