import React from "react";

export const PrimaryButton = (props) => {
  return (
    <>
      <button
        onClick={(e) => props.onclick(e)}
        disabled={props.disabledStatus ? true : false}
        className={`${
         props?.className
        } border rounded-full text-sm lg:text-base focus:outline-none font-primary text-white py-2 px-5 lg:px-14`}
      >
        {props.text}
      </button>
    </>
  );
};
