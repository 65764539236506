import React, { useState, useEffect } from "react";

import { useParams, useNavigate } from "react-router-dom";
import { Modal } from "antd";
import { PopupModel } from "./popupModel";
import { SearchFilterInput } from "../../../components/search/SearchInput";
import { CreateButton } from "../../../components/buttons/CreateButton";
import { DomainCard } from "../../../components/card/DomainCard";
import { InputField } from "../../../components/inputFields/InputField";
import { PrimaryButton } from "../../../components/buttons/PrimaryButton";
import {
  createCategory,
  getCategories,
} from "../../../serviceApi/superAdminApi/CategoryApi";
import { CategoryCardSkeleton } from "../../../components/skeleton/CardSkeleton";
import { getDomainNameById } from "../../../serviceApi/superAdminApi/DomainApi";
import axios from "axios";
import { getCookie } from "../../../utils";
import Swal from "sweetalert2";
import { containsSpecialChars } from "../../../utils/validation";
import { BreadcrumbComp } from "../../../components/Breadcrumb/Breadcrumb";
import { useAuth0 } from "@auth0/auth0-react";
import PaginationData from "../../../components/pagination/Pagination";

export const AdminCreateCategory = () => {
  const { user } = useAuth0();
  const userId = user?.email;
  const { id } = useParams();
  const navigate = useNavigate();

  const domainId: any = id;

  const [name, setName] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [domainName, setDomainName] = useState<string>("");
  const [category, setCategory] = useState([]);
  const [registeredCategories, setRegisteredCategories] =
    useState<boolean>(false);
  const [inputValue, setInputValue] = useState([]);
  const [status, setStatus] = useState(true);
  const [newCatStatus, setNewCatStatus] = useState<boolean>(false); // new domain status state
  const [modal2Open, setModal2Open] = React.useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [categoryImage, setCategoryImage] = useState(null);
  const [categoryImagePreview, setCategoryImagePreview] = useState<any>();
  const [deleteRes, setDeleteRes] = useState<boolean>(false);
  const [categoryNameErr, setCategoryNameErr] = useState("");
  const [paginatedData, setPaginatedData] = useState([]);
  const [getSearch, setGetSearch] = useState(true);
  const [isSearch, setIsSearch] = useState(false);
  const [input, setInput] = useState("");

  const getCategoryList = async () => {
    setIsLoading(true);
    const category = await getCategories(domainId);
    if (category?.data?.category) {
      setIsLoading(false);
      setDeleteRes(false);
      setInputValue(category?.data?.category);
    }
  };

  const createCategoryList = async (e) => {
    e.preventDefault();
    const categories = await createCategory({ domainId, name, description });
    if (categories?.data?.createCategory.status) {
      setRegisteredCategories(true);
    } else if (categories?.data?.createCategory.id != null) {
      if (categoryImage) {
        const formData = new FormData();
        formData.append("image", categoryImage);
        formData.append("uuid", categories?.data?.createCategory?.uuid);
        formData.append("type", "category");
        try {
          const { data } = await axios.post(
            `${process.env.REACT_APP_MEDIA_URL}/api/media/upload`,
            formData,
            {
              headers: {
                enctype: "multipart/form-data",
                Authorization: `Bearer ${getCookie("token")}`,
              },
            }
          );
          if (data?.status) {
            setRegisteredCategories(true);
            setName("");
            setDescription("");
            setStatus(false);
            setNewCatStatus(true);
            setTimeout(() => {
              navigate(`/home/dashboard`);
            }, 1800);
          }
        } catch (error) {
          console.log(error, "error in file upload in category");
        }
        await getCategoryList();
      }
    } else {
      Swal.fire({
        icon: "warning",
        title: "Invalid data please remove symbols.",
        showConfirmButton: false,
        timer: 3000,
      });
    }
  };

  const filter = (value: string) => {
    const keyword = value;
    if (keyword !== "") {
      const results = category.filter((user: any) => {
        return user.name.toLowerCase().startsWith(keyword.toLowerCase());
      });
      setCategory(results);
    }
    setName(keyword);
  };

  const modalStatus = (data: any) => {
    setRegisteredCategories(data);
    setNewCatStatus(data);
    setModal2Open(false);
  };

  const getDomainName = async () => {
    const response = await getDomainNameById(domainId);
    setDomainName(response?.data.domainNameById[0].name);
  };

  const handleOpen = () => {
    setName("");
    setDescription("");
    setModal2Open(true);
  };

  const CategoryImageUpload = async (event: any) => {
    event.preventDefault();
    const selectedFiles = event.target.files[0];
    if (selectedFiles.size >= 2097152) {
      Swal.fire({
        icon: "warning",
        title: "Image size should be max 2MB.",
        showConfirmButton: false,
        timer: 3000,
      });
    } else if (
      selectedFiles.type === "image/png" ||
      selectedFiles.type === "image/jpg" ||
      selectedFiles.type === "image/jpeg" ||
      selectedFiles.type === "image/webp" ||
      selectedFiles.type === "image/jfif" ||
      selectedFiles.type === "image/svg"
    ) {
      setCategoryImage(selectedFiles);
      setCategoryImagePreview(URL.createObjectURL(selectedFiles));
    } else {
      Swal.fire({
        icon: "warning",
        title: "Image formate should be png,jpg,jpeg,webp,jfif,svg",
        showConfirmButton: false,
        timer: 3000,
      });
    }
  };

  const deleteCategoryImage = () => {
    setCategoryImagePreview(URL.revokeObjectURL(categoryImagePreview));
    setCategoryImage(null);
  };
  const getSearchContent = (data: any, getClick) => {
    if (data?.length > 0 && typeof data != "string") {
      if (getClick) {
        setGetSearch(!isSearch);
        setPaginatedData(data);
        setIsSearch(true);
        setInputValue(data);
      } else {
        setGetSearch(!isSearch);
        setPaginatedData(data);
        setIsSearch(true);
        setInputValue(data);
      }
    } else if (data === "value not filled" && typeof data === "string") {
      setGetSearch(true);
      setPaginatedData([]);
      getCategoryList();
      setInputValue(paginatedData);
      setIsSearch(false);
      setInput("");
    } else if (data === "not found data" && typeof data === "string") {
      // getCategoryList();
      setGetSearch(false);
      setPaginatedData([]);
      setIsSearch(!isSearch);
      getCategoryList();
    }
  };
  useEffect(() => {
    if (
      !containsSpecialChars(name) &&
      name != " " &&
      name?.trim()?.length <= 60 &&
      name?.trim()?.length >= 2 &&
      description?.trim()?.length > 0 &&
      categoryImage != null
    ) {
      setStatus(false);
    } else setStatus(true);
  });

  useEffect(() => {
    getDomainName();
    getCategoryList();
  }, [registeredCategories, deleteRes]);

  const breadcrumbData = [
    {
      path: "/home/dashboard",
      text: "Dashboard",
    },
    {
      path: "/home/dashboard",
      text: domainName,
    },
  ];

  return (
    <>
      <div className="w-full mt-2 max-h-[86vh] overflow-y-auto lg:p-10 p-5">
        <p className="lg:text-2xl text-xl mb-0 pb-0 font-primary font-medium text-gray-800">
          All Category list
        </p>
        <div className="mb-8">
          <BreadcrumbComp breadcrumbData={breadcrumbData} />
        </div>
        <div className="flex lg:flex-row flex-col justify-between mb-8">
          <CreateButton onclick={handleOpen} text="Create new category" />
          {inputValue?.length > 0 && (
            <div className="lg:w-64 w-full lg:mt-2 mt-2">
              <SearchFilterInput
                SearchContent={getSearchContent}
                data={inputValue}
                userId={userId}
                type="Category"
                domainId={domainId}
                name={setInput}
              />
            </div>
          )}
        </div>
        <Modal
          title="Create Category"
          centered
          open={modal2Open}
          className="cat-modal"
          onCancel={() => setModal2Open(false)}
        >
          <InputField
            placeholder="Enter category Name"
            type="text"
            containsSpecialCharsMsg={setCategoryNameErr}
            onChange={filter}
            id="Category name"
          />
          <span className="text-red-500 mt-[6px] block">{categoryNameErr}</span>
          <div className="mt-5">
            <label className="text-base  pb-0  mb-2 text-black font-primary border-gray-300">
              Category Description <span className="text-red-600">*</span>
            </label>
            <textarea
              className="focus:outline-none text-sm lg:text-sm  rounded-md w-full border border-gray-300 p-4 mt-4"
              placeholder="Description"
              onChange={(e) => {
                setDescription(e.target.value);
              }}
              id="Category description"
            />
          </div>
          <div className="my-5 w-full flex flex-col">
            <label className="text-base font-primary lg:text-base  pb-0  mb-2 text-black ">
              Category image <span className="text-red-500">*</span>
            </label>
            {categoryImagePreview ? (
              <div className="h-44 border relative  mb-10 w-44">
                <img
                  className="h-full w-full p-1 rounded-lg object-cover object-center"
                  src={categoryImagePreview}
                  alt={categoryImagePreview}
                />
                <div
                  onClick={deleteCategoryImage}
                  className="bg-black cursor-pointer  w-8 h-8 flex items-center justify-center absolute top-0 right-0 rounded-full"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-5 h-5 mx-auto text-white"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                    />
                  </svg>
                </div>
              </div>
            ) : (
              <button type="submit">
                <div className="w-44 h-44 flex items-center justify-center">
                  <label
                    data-testid="category-Image"
                    htmlFor="dropzone-file"
                    className="flex flex-col items-center justify-center w-full h-auto border-2 bg-gray-50 border-gray-300 border-dashed rounded-lg cursor-pointer  dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
                  >
                    <div className="flex flex-col items-center justify-center pt-5 pb-6">
                      <svg
                        aria-hidden="true"
                        className="w-10 h-10 mb-3 text-gray-400"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                        ></path>
                      </svg>
                      <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                        <span className="font-semibold">Click to upload</span>{" "}
                      </p>
                      <p className="text-xs text-center text-gray-500 dark:text-gray-400">
                        WEBP, PNG, JPG or GIF (MAX Size. 2MB)
                      </p>
                    </div>

                    <input
                      id="dropzone-file"
                      onChange={CategoryImageUpload}
                      type="file"
                      className="hidden"
                    />
                  </label>
                </div>
              </button>
            )}
          </div>
          <div data-testid="category-create-btn">
            <PrimaryButton
              onclick={createCategoryList}
              disabledStatus={status}
              text="Create"
              className={`${
                status
                  ? "bg-gray-300 cursor-not-allowed border-gray-300"
                  : "bg-black hover:bg-transparent hover:border-black border-black hover:text-black"
              }`}
            />
          </div>
        </Modal>

        {registeredCategories ? (
          <PopupModel
            catfail={true}
            title="Category already exist"
            modalStatus={modalStatus}
          />
        ) : (
          ""
        )}
        {newCatStatus ? (
          <PopupModel
            catsuccess={true}
            title="category created successfully"
            modalStatus={modalStatus}
          />
        ) : (
          ""
        )}

        {/* card list start */}

        <div className="grid  border-t py-10 lg:grid-cols-4 grid-cols-1 lg:gap-x-12 gap-x-5 lg:gap-y-12 gap-y-5">
          {isLoading ? (
            <>
              <CategoryCardSkeleton />
              <CategoryCardSkeleton />
              <CategoryCardSkeleton />
              <CategoryCardSkeleton />
              <CategoryCardSkeleton />
              <CategoryCardSkeleton />
              <CategoryCardSkeleton />
              <CategoryCardSkeleton />
            </>
          ) : paginatedData && paginatedData?.length > 0 ? (
            paginatedData?.map((data: any, i) => {
              return (
                <div className="h-full" key={i}>
                  <DomainCard
                    key={i}
                    image={data?.image ? data?.image : ""}
                    CardHeight="h-44"
                    title={data.name}
                    description={data.description}
                    role="SuperAdmin"
                    uuid={data?.uuid}
                    type="category"
                    message={data.message}
                    editRoute={`/home/dashboard/domain/${domainId}/category/${data.uuid}/edit`}
                    deleteRes={setDeleteRes}
                  />
                </div>
              );
            })
          ) : (
            <div className="overflow-para shadow-md h-32 w-60 border-gray-300 rounded-md">
              <h1 className="font-primary justify-center items-center my-14 ml-5 text-sm">
                No category with domain {domainName}
              </h1>
            </div>
          )}
        </div>
        {/* card list end */}
        {paginatedData && getSearch && (
          <div
            className={`${
              inputValue?.length >= 8
                ? paginatedData.length >= 5
                  ? "mb-2"
                  : "absolute left-0 right-0 bottom-20 mb-2"
                : "mx-auto hidden"
            }`}
          >
            <PaginationData
              data={inputValue}
              paginatedData={setPaginatedData}
              userId={userId}
              getSearchActive={isSearch}
              type="Category"
              domainId={domainId}
              name={input}
            />
          </div>
        )}
      </div>
    </>
  );
};
